import { useContext, useEffect, useState } from "react"
import { useHistory, useParams } from "react-router-dom"
import Loader from "../../Components/Loader"
import authIntegration from '../../integrations/auth'
import { Context, StoreActions } from "../../store/useGlobalState"
import { parseErrorMsg } from "../../utils/misc"
import { ToastError, ToastSuccess } from "../../Components/Toast"
import MyLocalStorage, { lsKeys } from "../../utils/localStorage"
import { setAxiosToken } from "../../utils/axiosConfig"
import axios from "axios"

const FB_INFO_URL = "https://graph.facebook.com/me?fields=id,name,first_name,last_name,email,picture&locale=en_US&method=get&pretty=0&sdk=joey&suppress_http_code=0"

const FacebookLoginComponent = () => {
    const [loading, setLoading] = useState(true)
    const [success, setSuccess] = useState(false)
    const { mode } = useParams()
    const { globalDispatch } = useContext(Context)
    const history = useHistory()

    useEffect(() => {
        doAuth()
    }, [])

    const doLogin = async (res) => {
        const { id, name } = res

        if (!(id && name)) {
            ToastError("Ocurrió un error realizando la autenticación, por favor intente más tarde")
            throw Error("Error realizando el inicio de sesión")
        }

        const response = await authIntegration.login(id, id, true)

        setAxiosToken(response.token)
        MyLocalStorage.save(lsKeys.sesion, response)
        globalDispatch({ type: StoreActions.LOGIN, payload: response })

        history.push('/')
    }

    const doSignup = async (res) => {
        const { id, name, email, first_name, last_name } = res

        if (!(id && name && email)) {
            ToastError("Ocurrió un error realizando el registro, por favor intente más tarde")
            throw Error("Error realizando el registro")
        }

        try {
            const response = await authIntegration.shortRegister({
                username: email,
                email: email,
                facebook_id: id,
                name: first_name,
                lastname: last_name
            })

            ToastSuccess("El registro del usuario se realizó correctamente")
            setAxiosToken(response.token)
            MyLocalStorage.save(lsKeys.sesion, response)

            globalDispatch({ type: StoreActions.LOGIN, payload: response })
        } catch (err) {
            if (err.response?.status === 409) {
                const response = err.response.data
                if (response.fields?.email) {
                    ToastError('Este nombre de usuario o cuenta de Facebook ya existe, por favor intenta con uno nuevo')
                } else if (response.fields?.username)
                    ToastError('Este nombre de usuario o cuenta de Facebook ya existe, por favor intenta con uno nuevo')
            }
            else {
                ToastError(parseErrorMsg(err))
            }

            throw Error("Error realizando el registro")
        }
    }

    const doAuth = async () => {
        if ((!mode) || (!['inicio', 'registro'].includes(mode))) {
            setSuccess(false)
            setLoading(false)
            return
        }

        const search = window.location.search
        const params = new URLSearchParams(search)
        let access_token = null

        if (params.has('access_token')) {
            access_token = params.get('access_token')
        } else {
            const urlString = window.location.href
            if (urlString?.match(/^(.+)access_token=([^&]+)&(.+)$/)) {
                access_token = urlString.replace(/^(.+)access_token=([^&]+)&(.+)$/, '$2')
            }
        }

        if (!access_token) {
            setSuccess(false)
            setLoading(false)
            return
        }

        const URL = `${FB_INFO_URL}&access_token=${access_token}`
        const http = axios.create()

        try {
            const { data: response } = await http.get(URL)

            if (mode=='inicio') {
                return await doLogin(response)
            } else {
                return await doSignup(response)
            }
        } catch (error) {
            setSuccess(false)
            setLoading(false)
            return
        }
    }

    return (
        <div className="text-center p-6">
            {
                loading ? (
                    <>
                        {
                            mode == 'inicio' ? (
                                <div className="text-2xl text-primary">
                                    Iniciando sesión
                                </div>
                            ) : (
                                <div className="text-2xl text-primary">
                                    Realizando el registro
                                </div>
                            )
                        }
                        <div className="mt-10">
                            <Loader />
                        </div>
                    </>
                ) : (
                    <>
                        {
                            success ? (
                                <>
                                </>
                            ) : (
                                <div className="text-secondary font-semibold text-2xl">
                                    Ocurrió un error realizando el proceso
                                </div>
                            )
                        }
                    </>
                )
            }
        </div>
    )
}

export default FacebookLoginComponent