import React from "react"
import Recommended from "../../Components/Recommended"
import { Link } from "react-router-dom"
import assets from "../../Assets"
import { IconCheck2 } from "../../Components/Icons"

const EthicCode = () => {
  const sideMenuItems = [
    {
      title: "Manifiesto",
      url: "manifiesto"
    },
    {
      title: "Código de ética",
      url: "codigo-de-etica"
    },
    {
      title: "Satisfacción garantizada",
      url: "satisfaccion-garantizada"
    },
    {
      title: "Testimonios",
      url: "testimonios"
    },
    {
      title: "Contáctanos",
      url: "contacto"
    },
    {
      title: "Preguntas frecuentes",
      url: "preguntas-frecuentes"
    },
    {
      title: "Cómo funciona",
      url: "como-funciona"
    },
    {
      title: "Precios",
      url: "precios"
    }

  ]
  return <>
    <div className="px-3 max-w-md px-6  pt-10 mx-auto lg:max-w-7xl sm:max-w-3xl lg:px-0">
      <section className="grid grid-cols-1 md:grid-cols-5 text-primary">
        <div className="col-span-2 lg:pr-10 pb-3">
          <div className="text-3xl font-semibold mb-3">Código de ética</div>
          <SideMenu src={sideMenuItems} />
        </div>
        <div className="col-span-3 relative">
          <div className="absolute hidden md:inline-flex items-end right-0 top-40 -mr-10 z-10">
            <IconCheck2 fill="#D2544A" width={80} height={64} />
            <IconCheck2 fill="#6F8197" width={37} height={27} />
          </div>
          <img className="rounded" src={assets.EthicCode} alt="EthicCode" />
          <div className="absolute hidden md:inline-flex flex-row items-center z-10 -mt-30 ml-10">
            <span>
              <IconCheck2 fill="white" width={42} height={33} />
            </span>
            <span>
              <IconCheck2 fill="white" width={70} height={56} />
            </span>
          </div>
          <div className="block mt-5">
            <div className="mb-5">
              <div className="block mb-5 font-semibold">Código de ética de la comunidad de tutores 100 Tutores</div>
              <ul className="pl-4">
                <li className="list-disc">
                  Nuestros tutores aspiran a ser abiertos, honestos y asertivos en sus sesiones. 100 Tutores no premia a los tutores por alargar las sesiones más allá del tiempo que sea del mejor interés para el cliente o de la forma que dicte el contexto de la sesión.
                </li>
                <li className="list-disc">
                  Los tutores evitan imponer sus valores personales, actitudes, creencias y comportamientos, compartiendo estos solo como una forma de apoyo para que el cliente entienda el mensaje de la sesión.
                </li>
                <li className="list-disc">
                  Los clientes tienen la libertad de elegir si desean conectar solo una vez o regularmente con los tutores a través de nuestro servicio, y de terminar la conversación en cualquier momento. La cantidad de tiempo y dinero que un cliente gasta con un tutor es solo decisión del cliente.
                </li>
                <li className="list-disc">
                  Nuestros tutores entienden que la confianza es la base de la relación de servicio, y trabajan para ganarse la confianza de nuestros clientes a través de la honestidad, profesionalismo y confidencialidad. 100 Tutores no compartirá, bajo su conocimiento, el contenido de las interacciones entre los clientes y tutores. Al hacer uso de nuestros servicios en línea tu estás dando tu consentimiento de que sean grabados los contenidos de estas comunicaciones de forma privada para fines de calidad y protección.
                </li>
                <li className="list-disc">
                  100 Tutores comprende la gran responsabilidad que llevan los tutores. Es responsabilidad de nuestros tutores respetar la dignidad y promover el bienestar de nuestros clientes. Les pedimos a nuestros tutores que traten a los clientes con el mayor respeto, compasión y profesionalismo, lo cual incluye estar completamente enfocados en ti y tus necesidades.
                </li>
                <li className="list-disc">
                  Para asegurar la seguridad y protección, todos los servicios son a través de 100 Tutores.
                </li>
                <li className="list-disc">
                  Por la seguridad y confidencialidad del servicio y esta comunidad 100 Tutores nunca autorizará que un tutor comparta su información personal o de negocios con un cliente por ninguna razón, tienen prohibido hacerlo.
                </li>
                <li className="list-disc">
                  Para su protección, está prohibido que un tutor le pida a un cliente información personal de cualquier tipo para identificarlo, por ninguna razón.
                </li>
                <li className="list-disc">
                  Para proteger la naturaleza única del servicio, 100 Tutores no proporciona a nuestros tutores ninguna forma o herramienta para compartir información del cliente.
                </li>
                <li className="list-disc">
                  Está prohibido para todos los tutores pedir directamente a los clientes dinero u ofrecer cualquier negocio externo. Las relaciones románticas o personales con los clientes fuera del contexto de los servicios están prohibidas.
                </li>
              </ul>
            </div>
          </div>
          <div>
            <img src={assets.SatisfactionGuaranteed} className="h-40" />
          </div>
        </div>
      </section>
    </div>
    <Recommended />
  </>
}

const SideMenu = ({ src, ...props }) => {
  return <ul className="mt-30 pr-10 lg:pr-0">
    {
      src.map((x, i) => {
        return <li className="mb-6" key={`sub-consultation-${i}`}>
          <Link to={`/${x.url}`} className="pb-3 border-b block max-w-xs">{x.title}</Link>
        </li>
      })
    }
  </ul>
}


export default EthicCode