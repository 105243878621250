import { useEffect, useState } from "react"
import { ToastError } from "../../Components/Toast"
import payment from '../../integrations/payment'
import DataGrid from "./partials/DataGrid"
import Pagination from "../../Components/Pagination"
import moment from "moment"
const perPage = 10

const PaymentHistory = () => {
   const [paymentHistoryList, setPaymentHistoryList] = useState([])
   const [isLoading, setIsLoading] = useState(true)
   const [pagination, setPagination] = useState({})
   const [page, setPage] = useState(1)

   useEffect(() => {
      const loadHistory = async () => {
         setIsLoading(true)
         try {
            const res = await payment.getUserHistory()
            console.log("res", res)
            setPaymentHistoryList(res?.data)
            setPagination({
               prev: page === 1 ? null : page - 1,
               next: page === res.totalPages ? null : page + 1,
               totalPages: res.totalPages,
               pages: new Array(typeof res.totalPages === 'number' ? Math.ceil(res.totalPages) : 0).fill('page'),
               current: page
            })
            setIsLoading(false)
         } catch (error) {
            console.log("error", error)
            ToastError('No se pudieron obtener los métodos de pago.')
            setPaymentHistoryList([])
            setIsLoading(false)
         }
      }
      loadHistory()
   }, [page])

   const headings = [
      'ORDEN',
      'NÚMERO DE TARJETA / MÉTODO DE PAGO',
      'TITULAR DE TARJETA',
      'FECHA DE PAGO',
      'MONTO',
      'ESTATUS'
   ]

   const handlePagination = async (page) => {
      setPage(page)
      try {
         const res = await payment.getUserHistory(page)
         console.log("res", res)
         setPaymentHistoryList(res.data)
         setPagination({
            prev: page === 1 ? null : page - 1,
            next: page === res.totalPages ? null : page + 1,
            totalPages: res.totalPages,
            pages: new Array(typeof res.totalPages === 'number' ? Math.ceil(res.totalPages) : 0).fill('page'),
            current: page
         })
         setIsLoading(false)
      } catch (error) {
         console.log("error", error)
         ToastError('No se pudieron obtener los métodos de pago.')
         setPaymentHistoryList([])
         setIsLoading(false)
      }
   }

   return (
      <div className="border rounded-lg border-riptideGray3 p-6">
         <section>
            <div className="text-xl leading-loose font-semibold text-primary">Historial de pago</div>
            <p className="mt-4 text-sm text-black">Mantente informado de las transacciones que has realizado.</p>

            <div className="mt-8">
               {isLoading === true ? (
                     <span className="text-primary">Espera un momento...</span>
                  )
                  :
                  Array.isArray(paymentHistoryList) && paymentHistoryList.length === 0
                     ? <div className="py-2 text-center text-riptideGray2">
                        <div className="mb-6 text-2xl font-semibold">No has realizado ninguna transacción</div>
                        <p className="mx-auto lg:max-w-lg">
                           Te invitamos a ponerte en contacto con alguno de nuestros tutores expertos.
                        </p>
                     </div>
                     : <>
                        <DataGrid data={paymentHistoryList} headings={headings} row={CustomRow} />
                        <Pagination handlePagination={handlePagination} pagination={pagination} />
                     </>
               }
            </div>
         </section>
      </div>
   )
}

const STATUS_MAP = {
   "on_process": "EN PROCESO",
   "cancelled": "RECHAZADA",
   "finished": "FINALIZADA",
   "undefined": "SIN DEFINIR"
}

const CustomRow = ({ data }) => {
   const colClasses = "py-4 text-xs tracking-wide text-center text-primary"
   const { numOrder, createdAt, amount, paymentOwner, status } = data
   const date = moment(createdAt).format("DD/MM/YYYY")

   const getPaymentOption = ({ paymentMethod, card }) => {
      if (paymentMethod === "CARD") return '**** **** **** ****';
      return paymentMethod
   }

   return (
      <tr>
         <td className={colClasses}>{numOrder}</td>
         <td className={colClasses}>{getPaymentOption(data)}</td>
         <td className={colClasses}>{paymentOwner}</td>
         <td className={colClasses}>{date}</td>
         <td className={colClasses}>${amount.toFixed(2)}</td>
         <td className={colClasses}>{STATUS_MAP[status]}</td>
      </tr>
   )
}


export default PaymentHistory