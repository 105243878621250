import Icon from "../../../Assets/Icon"

export const OfferItemRightRow = ({ title, icon, description, href }) => (
    <div className="flex flex-row items-center content-center gap-x-6">
        <span className="flex flex-row text-left">
            <span className="flex-col inline-block w-0 h-8 mr-4 border-2 border-primary rounded-full">&nbsp;</span>
            <span className="flex flex-row items-center content-center w-8 h-8 text-center rounded"
                style={{backgroundColor:'rgb(246,221,219)'}}>
                <Icon name={icon} className="mx-auto w-6 h-6" />
            </span>
        </span>
        <aside className="flex flex-col text-left">
            <a href={href}>
                <span className="flex-row text-primary">{title}</span>
            </a>
            <small className="flex-row text-primary-light">{description}</small>
        </aside>
    </div>
)

export const CustomOfferItemRightRow = ({ title, icon, description, href }) => (
    <div className="flex flex-row items-start content-center gap-x-6">
        <span className="flex flex-row text-left">
            <span className="flex-col inline-block w-0 h-8 mr-4 border-2 rounded-full border-secondary">&nbsp;</span>
            <span className="flex flex-row items-center justify-center w-8 h-8 text-center rounded bg-secondary-lightest">
                {icon}
            </span>
        </span>
        <aside className="flex flex-col text-left">
            <a href={href}>
                <span className="flex-row text-primary">{title}</span>
            </a>
            <small className="flex-row line-clamp-3 text-primary-light mt-2" dangerouslySetInnerHTML={{__html:description}} />
        </aside>
    </div>
)


export default OfferItemRightRow