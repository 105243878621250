import React, { useState, useEffect, useRef } from "react";
import { IconMicrophoneWhite, UserProfileIcon } from "../Icons"

const MAX_MEDIA_DEVICES = 10;

const Index0Style = {
  width: "100%",
  height: "100%",
  objectFit: "cover",
  borderRadius: 10,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  position: "relative"
};

const Index1Style = {
  position: 'absolute',
  width: '120px',
  height: '120px',
  top: '0px',
  left: '0px',
  borderRadius: 10,
  border: '2px solid white'
};

const Participant = ({ participant, focusMe, key, reverseFocus, name, image }) => {
  const [videoTracks, setVideoTracks] = useState([]);
  const [audioTracks, setAudioTracks] = useState([]);
  const [audio, setAudio] = useState(true)
  const [participantName, setParticipantName] = useState('')
  const [participantPhoto, setParticipantPhoto] = useState(null)
  const videoRef = useRef(new Array(MAX_MEDIA_DEVICES).fill(null));
  const audioRef = useRef();

  useEffect(() => {
    setVideoTracks(trackpubsToTracks(participant.videoTracks));
    setAudioTracks(trackpubsToTracksAudio(participant.audioTracks));

    participant.on("trackDisabled", trackDisabled);
    participant.on("trackEnabled", trackEnable);
    participant.on("trackSubscribed", trackSubscribed);
    participant.on("trackUnsubscribed", trackUnsubscribed);
    participant.on("trackPublished", trackPublished);
    participant.on("trackRemoved", trackRemoved);

    return () => {
      try {
        videoTracks.forEach(track => {
          track.track.detach();
        });
        audioTracks.forEach(track => {
          track.track.detach();
        });
      } catch (error) {}
      setVideoTracks([]);
      setAudioTracks([]);
      participant.removeAllListeners();
    };
  }, [participant]);

  useEffect(() => {
    videoTracks.forEach((track, idx) => {
      track.track.attach(videoRef.current[idx]);
    });
  }, [videoTracks]);

  useEffect(() => {
    const audioTrack = audioTracks[0];
    if (audioTrack) {
      audioTrack.attach(audioRef.current);
    }
  }, [audioTracks]);

  const trackpubsToTracksAudio = (trackMap) => {
    return Array.from(trackMap.values())
      .map(publication => publication?.track)
      .filter(track => track !== null);
  }

  const trackpubsToTracks = (trackMap) => {
    return Array.from(trackMap.values())
        .map((publication) => ({
          track: publication?.track,
          visible: publication?.track? publication.track?.isEnabled: false,
          id: publication?.track? publication.track?.name: null
        }))
        .filter((track) => track.track !== null);
  }

  const trackSubscribed = (track) => {
    if (track.kind === "video") {
      setVideoTracks((videoTracks) => [...videoTracks, {
        track,
        visible:true,
        id: track?.name
      }]);
    } else if (track.kind === "audio") {
      setAudioTracks((audioTracks) => [...audioTracks, track]);
    }
  };

  const trackUnsubscribed = (track) => {
    if (track.kind === "video") {
      setVideoTracks((videoTracks) => videoTracks.filter((v) => v.id !== track.name));
    } else if (track.kind === "audio") {
      setAudioTracks((audioTracks) => audioTracks.filter((a) => a !== track));
    }
  };

  const trackEnable = (track) => {
    if (track.kind === 'video') {
      setVideoTracks(curr => curr.map(item => {
        if (track?.name==item?.id || track?.trackName==item?.id) item.visible=true;
        return item;
      }));
    }
    else if (track.kind === 'audio') {
      setAudio(true)
    }
  }

  const trackDisabled = (track, participant) => {
    if (track.kind === 'video') {
       setVideoTracks(curr => curr.map(item => ({
        ...item,
        visible: (track?.name==item?.id || track?.trackName==item?.id) ? false: true
      })));
    }
    else if (track.kind === 'audio') {
      setAudio(false)
    }
  }

  const trackPublished = (publication) => {
    if (publication.kind === "video") {
      setVideoTracks((videoTracks) => [...videoTracks, {
        track: publication?.track? publication?.track: null,
        visible:true,
        id: publication?.track? publication.track?.name: null
      }].filter(track => track.track !== null));
      
    } else if (publication.kind === "audio") {
      setAudioTracks((audioTracks) => [...audioTracks, publication?.track]);
    }
  }

  const trackRemoved = (track) => {
    if (track.kind === "video") {
      setVideoTracks((videoTracks) => videoTracks.filter((v) => v.id !== track.id));
    } else if (track.kind === "audio") {
      setAudioTracks((audioTracks) => audioTracks.filter((a) => a !== track));
    }
  };

  const sortVideoTracks = (e, track) => {
    e.preventDefault();
    e.stopPropagation();

    if (videoTracks.length<=1) return;

    const trackId = track.id;
    setVideoTracks((videoTracks) => videoTracks.sort((a, b) => {
      if (a.id === trackId) return -1;
      else if (b.id === trackId) return 1;
      return 0;
    }).map(item => item));
  }

  return (
    <div style={{
      width: "100%",
      height: "100%",
      objectFit: "cover",
      borderRadius: 10,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      position: "relative"
    }} onClick={reverseFocus}>
      {
        videoTracks.map((track, idx) => (
          <div  key={idx} style={idx==0?Index0Style:Index1Style} 
            onClick={(e)=>{ sortVideoTracks(e, track) }}>
            {
              track.visible ?
              <video style={{
                width: "100%",
                height: "100%",
                objectFit: "fill",
                borderRadius: 10,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                position: "relative"
              }} ref={el => {videoRef.current[idx] = el}} autoPlay={true} /> :
              (image != null && image != 'null' ?
              <img alt={participant.identity} src={image} style={{ width: 80, height: 80, objectFit: "cover", borderRadius: "50%" }} /> :
              <div style={{ width: 80, height: 80, objectFit: "cover", borderRadius: "50%" }}><UserProfileIcon /></div>)
            }
          </div>
        ))
      }
      {!audio &&
        <div
          style={{
            position: "absolute",
            right: 10,
            top: 10,
            backgroundColor: "#676767",
            borderRadius: "50%",
            padding: 10,
            fontSize: 20
          }} >
          <IconMicrophoneWhite width="20" height="20" fill="white" />
          <div className="absolute w-6 h-1 bg-white transform rotate-135 top-5 right-2"></div>
        </div>
      }
      <audio ref={audioRef} autoPlay={true} muted={false} />
      <h3 style={{
        position: "absolute",
        top: 10,
        textAlign: "center",
        width: "90%",
        color: "white",
        fontFamily: "Montserrat",
        fontSize: 16,
        fontWeight: "600",
        textShadow: "1px 1px 2px black"
      }} >{name ? name : participantName ? participantName : participant.identity}</h3>
    </div>
  );
};

export default Participant;
