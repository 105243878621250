import { useEffect, useState } from "react"
import BuyCredits from "./BuyCredits";
import PaymentHistory from "./PaymentHistory";
import PaymentMethods from "./PaymentMethods";
import SessionHistory from "./SessionHistory";
import Agenda from "./Agenda";
import UserData from "./UserData";
import { useParams } from "react-router";

const TabItem = ({ label, selected, onClick }) => (
   <div className="flex flex-col items-center flex-1 cursor-pointer min-w-sub-menu-item" onClick={onClick}>
      <div className={`flex items-center mb-5 ${selected ? 'text-primary' : 'text-gray-400'}`}>
         <span className="font-semibold truncate" >{label}</span>
      </div>
      <div className={`${selected ? 'bg-primary h-1' : 'bg-gray-400 h-0.5'} w-full`} />
   </div>
)

const ProfilePage = () => {
   let { step } = useParams();

   const [selectedTab, setSelectedTab] = useState(0)

   const selected = () => {
      switch (selectedTab) {
         case 0: return <UserData />

         case 1: return <BuyCredits onFinish={()=>setSelectedTab(0)} />
         case 2: return <PaymentMethods />
         case 3: return <PaymentHistory />
         case 4: return <SessionHistory />
         case 5: return <Agenda />

         default:
            return null;
      }
   }
   
   useEffect(()=>{
      window.scrollTo(0, 0);
   },[selectedTab])
   
   useEffect(()=>{
      if(step=="credits"){
         setSelectedTab(1)
      }
   },[])

   return <div className="flex flex-col pt-4">
      {/* top tabs */}
      <div className="flex mb-12 overflow-x-auto hide-scrollbar">
         <TabItem
            label='Perfil de usuario'
            onClick={() => { setSelectedTab(0) }}
            selected={selectedTab === 0}
         />
         <TabItem
            label='Compra de crédito'
            onClick={() => { setSelectedTab(1) }}
            selected={selectedTab === 1}
         />
         <TabItem
            label='Formas de pago'
            onClick={() => { setSelectedTab(2) }}
            selected={selectedTab === 2}
         />
         <TabItem
            label='Historial de pago'
            onClick={() => { setSelectedTab(3) }}
            selected={selectedTab === 3}
         />
         <TabItem
            label='Historial de sesiones'
            onClick={() => { setSelectedTab(4) }}
            selected={selectedTab === 4}
         />
         <TabItem
            label='Agenda'
            onClick={() => { setSelectedTab(5) }}
            selected={selectedTab === 5}
         />
      </div>

      {/* screens */}
      <div>
         {selected()}
      </div>
   </div>
}

export default ProfilePage