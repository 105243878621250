import React, { useState, useEffect } from "react";
import { IconCheck2, IconCheckRedWhite } from "../Components/Icons";

const RigthPanel = () => {
    const [selectedTab, setSelectedTab] = useState(0)
    const [animate, setAnimate] = useState(false)
    const [timeId, setTimeId] = useState(-1)

    useEffect(() => {
        setAnimate(true)

        if (timeId > 0) {
            try {
                clearTimeout(timeId)
            } catch (err) { }
        }

        setTimeId(window.setTimeout(() => {
            setTabTo((selectedTab + 1) % 4)
        }, 10000))
    }, [selectedTab])

    const contentStyle = {
        ...{
            position: 'relative',
            flexGrow: 1,
            opacity: '0',
            transition: 'opacity 0.8s linear'
        },
        ...animate ? { opacity: '1' } : {}
    }

    const setTabTo = (tab) => {
        setAnimate(false)
        setSelectedTab(tab)
    }

    return (
        <div className='relative pt-12 text-sm flex-5 bg-riptideGray p-7 text-primary'>
            {selectedTab === 0 && <div style={contentStyle}>
                <div className="absolute hidden md:inline-flex top-0 right-0 z-10">
                    <IconCheckRedWhite width={51} height={26} fill2="#14336C" />
                </div>
                <div className="absolute hidden lg:inline-flex bottom-0 left-0 z-10" style={{ marginBottom: '-18rem' }}>
                    <IconCheckRedWhite fill2="#14336C" />
                </div>
                <div className="pb-5 text-xl font-bold">Nuestros tutores, profesores y maestros</div>
                <p className="mb-2 font-light">
                    <span className="font-bold">100 Tutores</span>  es una comunidad confiable de
                    tutores, profesores y maestros que se dedican a empoderar vidas impulsados
                    por la misión de ayudar a más personas a lograr sus metas de aprendizaje.
                    No hay dos tutores en nuestra comunidad iguales, ya que cada uno tiene
                    su propio toque personal cuando se trata de brindar servicios profesionales
                    personalizados.
                </p>
                <p className="mb-2 font-light">
                    Solo aceptamos a tutores, profesores y maestros que realmente tengan
                    la preparación, los estudios y la experiencia y estén comprometidos a
                    ayudar a las personas a lograr sus metas de aprendizaje y ponemos a
                    todos los que aplican en un proceso riguroso de selección. Nos enorgullece
                    nuestra comunidad de tutores atentos y compasivos, con gran experiencia
                    y conocimiento.
                </p>
                <p className=" font-light">
                    Obtén el apoyo y ayuda que necesitas y logra las metas de aprendizaje
                    que te propongas. Sigue adelante con confianza.
                </p>
            </div>}
            {selectedTab === 1 && <div style={contentStyle}>
                <div className="absolute hidden md:inline-flex items-center top-0 right-0 z-10">
                    <IconCheck2 fill="#14336C" width={51} height={41} />
                    <IconCheck2 fill="#BCBCBC" width={12} height={10} />
                </div>
                <div className="absolute hidden lg:inline-flex bottom-0 left-0 z-10"
                    style={{ marginBottom: '-15rem' }}>
                    <IconCheck2 fill="#D2544A" width={30} height={25} />
                    <span className="ml-8 inline-block pt-4">
                        <IconCheck2 fill="#EBEBEB" width={51} height={41} />
                    </span>
                </div>
                <div className="pb-5 text-xl font-bold">¿Cómo funciona?</div>
                <p className=" font-light">
                    En <span className="font-bold">100 Tutores</span> tú controlas cuánto gastas,
                    y solo te cobramos por el tiempo que dure tu sesión con el tutor. No
                    hay costos escondidos o cargos sorpresa.
                    escondidos y cargos sorpresa.
                </p>
                <div className="my-2 font-bold">
                    Hacer tu consulta es muy fácil:
                </div>
                <p className="mb-2 font-light"><span className="font-bold">Paso 1:</span> Regístrate y abre una cuenta.</p>
                <p className="mb-2 font-light"><span className="font-bold">Paso 2:</span> Visita la página de nuestros tutores y elige al de tu preferencia.</p>
                <p className="mb-2 font-light"><span className="font-bold">Paso 3:</span> Haz clic en el botón “Llamar” o "Agendar" dependiendo de tu preferencia.</p>
                <p className="mb-2 font-light"><span className="font-bold">Paso 4:</span> Toma tu sesión. Se abrirá una ventana del chat para iniciar la consulta con el tutor.</p>
                <p className="mb-2 font-light"><span className="font-bold">Paso 5:</span> Termina la consulta. Tú decides cuando quieres terminar la consulta. Solo tienes que colgar o cerrar el chat.</p>
                <p className=" font-light">
                    También puedes agendar previamente una cita. La disponibilidad de horario varía de acuerdo con la agenda del tutor que selecciones.
                </p>
            </div>}
            {selectedTab === 2 && <div style={contentStyle}>
                <div className="absolute hidden md:inline-flex items-center top-0 right-0 z-10">
                    <IconCheck2 fill="#D2544A" width={51} height={41} />
                    <IconCheck2 fill="white" width={12} height={10} />
                </div>
                <div className="absolute hidden lg:inline-flex bottom-0 left-0 z-10"
                    style={{ marginBottom: '-17rem' }}>
                    <IconCheck2 fill="#D2544A" width={30} height={25} />
                    <span className="ml-2 inline-block">
                        <IconCheck2 fill="#14336C" width={48} height={38} />
                    </span>
                </div>
                <div className="pb-5 text-xl font-bold">Garantía de satisfacción</div>
                <p className="mb-2 font-light">
                    Nuestra garantía de satisfacción es simple: ¡Si no estás contento con
                    tu última sesión es gratis! Queremos que cada sesión en nuestra plataforma
                    cumpla con tus expectativas. Si no es así, nos comprometemos a compensarlo.
                    Si tuviste un problema con la plataforma, puedes ser elegible para recibir
                    un crédito en tu cuenta, hasta un límite de $500 pesos de 100 Tutores que
                    puede usarse en futuras conversaciones.
                </p>
                <p className=" font-light">
                    Llámanos a nuestro centro de servicio al cliente o envíanos un correo
                    electrónico a hola@100tutores.com. Navegar por el mundo académico no
                    siempre es fácil, usar nuestros servicios si lo debe ser. Tu satisfacción
                    es nuestra prioridad, por favor compártenos tus comentarios, preguntas
                    o problemas.
                </p>
                <div className="font-bold mt-2">
                    Estamos muy contentos de que estés aquí.
                </div>
                <p className=" font-light">
                    Gracias por elegirnos.
                </p>
            </div>}
            {selectedTab === 3 && <div style={contentStyle}>
                <div className="absolute hidden md:inline-flex items-center top-0 right-0 z-10">
                    <IconCheck2 fill="#14336C" width={20} height={14} />
                    <IconCheck2 fill="white" width={12} height={10} />
                </div>
                <div className="absolute hidden lg:inline-flex bottom-0 left-0 z-10"
                    style={{ marginBottom: '-24rem' }}>
                    <IconCheck2 fill="white" width={48} height={38} />
                    <span className="ml-2 inline-block">
                        <IconCheck2 fill="#D2544A" width={40} height={35} />
                    </span>
                </div>
                <div className="pb-5 text-xl font-bold">Estamos para ayudarte</div>
                <p className="mb-2 font-light">
                    En <span className="font-bold">100 Tutores</span> estamos dedicados a
                    ofrecerte la mejor atención y servicio, creemos de todo corazón que
                    cada experiencia debe ser impecable, y estamos comprometidos a lograrlo.
                </p>
                <p className="mb-2 font-light">
                    Nuestra prioridad es conectarte con los mejores tutores, profesores y
                    maestros  en la industria mientras protegemos tu seguridad y garantizamos
                    una experiencia de cliente de primer nivel.
                </p>
                <p className=" font-light">
                    Nuestro centro de atención a clientes está disponible las 24 horas
                    los 7 días de la semana. Por favor compártenos tus comentarios,
                    preguntas o problemas.
                </p>
            </div>}

            {/* tabs */}
            {<div className="absolute flex bottom-5 right-5">
                <div
                    className={`rounded-full h-2.5 ml-1 cursor-pointer ${selectedTab === 0 ? 'w-5 bg-secondary' : 'w-2.5 bg-white'}`}
                    onClick={() => setTabTo(0)} />
                <div
                    className={`rounded-full h-2.5 ml-1 cursor-pointer ${selectedTab === 1 ? 'w-5 bg-secondary' : 'w-2.5 bg-white'}`}
                    onClick={() => setTabTo(1)} />
                <div
                    className={`rounded-full h-2.5 ml-1 cursor-pointer ${selectedTab === 2 ? 'w-5 bg-secondary' : 'w-2.5 bg-white'}`}
                    onClick={() => setTabTo(2)} />
                <div
                    className={`rounded-full h-2.5 ml-1 cursor-pointer ${selectedTab === 3 ? 'w-5 bg-secondary' : 'w-2.5 bg-white'}`}
                    onClick={() => setTabTo(3)} />
            </div>}
        </div>
    )
}

const SlipitCard = ({ children }) => (
    <div className="flex flex-col w-full border-2 lg:flex-row border-superLight rounded-2xl min-h-44">
        {/* left */}
        <div className='flex flex-col w-full p-4 pb-8 lg:p-8 lg:pr-12 flex-8'>
            {children}
        </div>

        {/* rigth (purple) */}
        <RigthPanel />
    </div>
)

export default SlipitCard