import React from "react"
import Recommended from "../../Components/Recommended"
import { Link } from "react-router-dom"
import assets from "../../Assets"
import FAGImage from './../../Assets/Images/faq.jpg'
import { IconCheck2 } from "../../Components/Icons"

const FAQ = () => {
  const sideMenuItems = [
    {
      title: "Manifiesto",
      url: "manifiesto"
    },
    {
      title: "Código de ética",
      url: "codigo-de-etica"
    },
    {
      title: "Satisfacción garantizada",
      url: "satisfaccion-garantizada"
    },
    {
      title: "Testimonios",
      url: "testimonios"
    },
    {
      title: "Contáctanos",
      url: "contacto"
    },
    {
      title: "Preguntas frecuentes",
      url: "preguntas-frecuentes"
    },
    {
      title: "Cómo funciona",
      url: "como-funciona"
    },
    {
      title: "Precios",
      url: "precios"
    }

  ]
  return <>
    <div className="px-3 max-w-md px-6 pt-10 mx-auto lg:max-w-7xl sm:max-w-3xl lg:px-0">
      <section className="grid grid-cols-1 md:grid-cols-5 text-primary">
        <div className="col-span-2 lg:pr-10 pb-3">
          <div className="text-3xl font-semibold mb-3">Preguntas frecuentes</div>
          <SideMenu src={sideMenuItems} />
        </div>
        <div className="col-span-3 relative">
          <div className="absolute hidden md:inline-flex items-end right-0 top-40 -mr-10 z-10">
            <IconCheck2 fill="#D2544A" width={80} height={64} />
            <IconCheck2 fill="#6F8197" width={37} height={27} />
          </div>
          <img className="rounded" src={FAGImage} />
          <div className="absolute hidden md:inline-flex flex-col items-center z-10 -mt-20 -ml-8">
            <span>
              <IconCheck2 fill="white" width={27} height={21} />
            </span>
            <span className="-mt-8">
              <IconCheck2 fill="#D2544A" width={117} height={94} />
            </span>
          </div>
          <div className="block mt-5">
            <div className="mb-5">
              <div className="font-semibold mb-3">¿Quienes somos?</div>
              <p>
                100 Tutores es una plataforma confiable de tutores, maestros y profesores que se dedican a empoderar vidas impulsados por la misión de ayudar a más personas a lograr sus metas de aprendizaje, estudiar, aprender algo nuevo, recibir ayuda en tareas, sacar una buena calificación, o pasar un examen. Somos profesionales apasionados y compasivos, impulsados por la misión de ayudar a más personas a vivir una vida mejor y más feliz todos los días. Los tutores, maestros y profesores en 100 Tutores tienen diversas especialidades y pueden ofrecer clases, tutorías, sesiones de estudio y soluciones personalizadas de estudio para que logres tus metas de aprendizaje.. No hay dos tutores, maestros y profesores en nuestra comunidad iguales, ya que cada uno tiene su propio toque personal cuando se trata de ofrecer tutorías, clases y sesiones educativas en línea.
              </p>
            </div>
            <div className="mb-5">
              <div className="font-semibold mb-3">¿Qué hacemos en 100 Tutores?</div>
              <p>
                En 100 Tutores te conectamos con tutores, maestros y profesores de una forma segura, desde donde quieras y cuando quieras.
              </p>
            </div>
            <div className="mb-5">
              <div className="font-semibold mb-3">¿Por qué 100 Tutores?</div>
              <p>
                Por la experiencia, la comprensión y la atención de los tutores, maestros y profesores y el equipo de servicio al cliente.
              </p>
              <p>
                Los mejores tutores, maestros y profesores : Conocimiento, dones, habilidades y experiencia. Cada uno de nuestros tutores, maestros y profesores cuenta con capacidades y estilos diferentes. Encuentra el que más te guste.
              </p>
              <p>
                Flexibilidad: Haz tu sesión desde donde quieras y cuando quieras. Fácil de usar y directo.
              </p>
              <p>
                Código de ética: Nuestros tutores, maestros y profesores se comprometen a tratar a los clientes con respeto, compasión y profesionalismo.
              </p>
            </div>
            <div className="mb-5">
              <div className="font-semibold mb-3">¿Qué es y cómo funciona 100 Tutores?</div>
              <p className="mb-3">
                Los tutores, maestros y profesores de 100 Tutores ayudan a los clientes con clases, tutorías, sesiones de estudio y soluciones personalizadas para que logren sus metas de aprendizaje,. Sin importar si ya eres cliente regular o nuevo, tenemos diversas formas de contactar a un tutor, maestro o profesor de 100 Tutores. Conéctate fácilmente con tus tutores, maestros y profesores favoritos:
              </p>
              <ol className="mb-3 pl-4">
                <li className="list-decimal">Crea una cuenta</li>
                <li className="list-decimal">Agrega saldo</li>
                <li className="list-decimal">Elige al experto de tu preferencia</li>
                <li className="list-decimal">Haz tu sesión</li>
                <li className="list-decimal">Termina la sesión cuando tú quieras.</li>
              </ol>
              <p className="mb-3">
                100 Tutores está abierto 24/7 y nuestros tutores, maestros y profesores hacen lo posible por estar disponible en diversas zonas horarias, pero como son humanos y no computadoras, algunas veces, no están disponibles. Así que, si has elegido a un tutor, maestro o profesor y no está disponible, puedes programar una cita. No hay necesidad de que pierdas tu tiempo esperando si algún tutor, maestro o profesor en específico se pone disponible. Simplemente programa una cita a la hora que te sea más conveniente.
              </p>
            </div>
            <div className="mb-5">
              <div className="font-semibold mb-3">¿Qué es 100 Tutores?</div>
              <p>
                100 Tutores es una plataforma confiable de tutores, maestros y profesores que se dedican a empoderar vidas impulsados por la misión de ayudar a más personas a lograr sus metas de aprendizaje, como lo es estudiar para la escuela, aprender algo nuevo, recibir ayuda en tareas, sacar una buena calificación, o pasar un examen. Somos profesionales apasionados y compasivos, impulsados por la misión de ayudar a más personas a vivir una vida mejor y más feliz todos los días. Los tutores, maestros y profesores en 100 Tutores tienen diversas especialidades y pueden ofrecer clases, tutorías, sesiones de estudio y soluciones personalizadas de estudio para que logres tus metas de aprendizaje.. No hay dos tutores, maestros y profesores en nuestra comunidad iguales, ya que cada uno tiene su propio toque personal cuando se trata de ofrecer tutorías, clases y sesiones educativas en línea.
              </p>
            </div>
            <div className="mb-5">
              <div className="font-semibold mb-3">¿Cómo funcionan las sesións en línea en 100 Tutores?</div>
              <p className="mb-3">
                En 100 Tutores puedes hablar de forma segura y privada con un tutor, maestro o profesor de confianza por videollamada. Para tener una sesión en línea con alguno de nuestros tutores, maestros y profesores solo tienes que darle clic al botón de “videollamada” o puedes agendar previamente una cita. La disponibilidad de horario varía de acuerdo con la agenda del tutor, maestro o profesor que elijas. Hacer una sesión en 100 Tutores es muy fácil:
              </p>
              <ul className="mb-5">
                <li>Paso 1: Crea una cuenta</li>
                <li>Paso 2: Agrega saldo</li>
                <li>Paso 3: Elige al experto de tu preferencia</li>
                <li>Paso 4: Haz tu sesión</li>
                <li>Paso 5: Termina la sesión cuando tú quieras.</li>
              </ul>
            </div>
            <div className="mb-5">
              <div className="font-semibold mb-3">¿Cómo agendar mi primera cita en 100 Tutores?</div>
              <p>
                Haz clic en el botón “Agendar cita” del tutor, maestro o profesor que selecciones. La disponibilidad de horario varía de acuerdo con la agenda del tutor, maestro o profesor. Elige el tipo de sesión, la duración y el horario.
              </p>
            </div>
            <div className="mb-5">
              <div className="font-semibold mb-3">¿Cómo tomar mi primera cita en 100 Tutores?</div>
              <p>
                Una vez agendada la cita, cuando sea hora solo tienes que iniciar sesión e ingresar a tu cuenta y en la sección “Agenda” darle clic a la cita agendada en la fecha y hora programada.
              </p>
            </div>
            <div className="mb-5">
              <div className="font-semibold mb-3">¿Las sesiones en línea son efectivas?</div>
              <p>
                Las sesiones en línea te permiten recibir la misma atención que recibirías en una sesión presencial, pero de forma remota. Podrás platicar con tu tutor, maestro o profesor por videollamada sin salir de casa.
              </p>
            </div>
            <div className="mb-5">
              <div className="font-semibold mb-3">¿Necesito descargar alguna App de videollamada?</div>
              <p>
                No necesitas descargar ninguna App dentro de 100 Tutores se abrirá la sesión.
              </p>
            </div>
            <div className="mb-5">
              <div className="font-semibold mb-3">¿Los pagos son seguros?</div>
              <p>
                ¡Si! Nuestro sistema de pagos está actualizado con los estándares más altos de la industria. Toda la información personal es mantenida y protegida por un procesador de pagos externo. Nosotros nunca almacenamos ninguna información financiera personal.
              </p>
            </div>
          </div>
          <div>
            <img className="h-40" src={assets.SatisfactionGuaranteed} />
          </div>
        </div>
      </section>
    </div>
    <Recommended />
  </>
}

const SideMenu = ({ src, ...props }) => {
  return <ul className="mt-30 pr-10 lg:pr-0">
    {
      src.map((x, i) => {
        return <li className="mb-6" key={`sub-consultation-${i}`}>
          <Link to={`/${x.url}`} className="pb-3 border-b block max-w-xs">{x.title}</Link>
        </li>
      })
    }
  </ul>
}


export default FAQ