import React, { useEffect, useState } from "react";
import SecondaryLinkButton from "../../Components/Button/SecondaryLinkButton"
import CardExpert from '../CardExpert'
import { Link } from "react-router-dom";
import expertIntegration from "../../integrations/expert"
import assets from "../../Assets"
import Loader from "../../Components/Loader"
import { IconChevronRight } from "../Icons";

const Recommended = () => {
    const [experts, setExperts] = useState([])
    const [loading, setLoading] = useState(true)

    useEffect(async () => {
        setLoading(true)
        try {
            const expertsApi = await expertIntegration.getExperts(4, 1, '', [], "available", 'desc')
            setExperts(expertsApi?.data?.payload?.data)
            setLoading(prev => !prev)
        } catch (error) {
            setLoading(prev => !prev)
            setExperts([])
        }
    }, [])
    return <div className="pb-10 bg-white lg:pt-20 px-2 max-w-7xl mx-auto">
        <div className="">
            <div className="mt-2 text-3xl font-bold text-primary sm:text-3xl">
                Quizás podrían interesarte...
            </div>
            <div className="md:flex md:flex-row md:justify-between items-center">
                <p className="max-w-prose mt-3.5 text-xl tracking-normal text-primary">
                    Los mejores tutores en el tema te ayudarán
                </p>
            </div>
            <div className="mt-4">
                {
                    loading ? <Loader /> : (
                        <>
                            <div className="items-stretch grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4">
                                {
                                    experts?.map((expert, i) => {
                                        return <>
                                            <CardExpert
                                                id={expert._id}
                                                expert={expert}
                                                available={expert.available}
                                                photo={expert.image}
                                                stars={expert.rating}
                                                name={expert.username}
                                                isRecComp={true}
                                                key={`expert-key-${expert._id}`}
                                            />
                                        </>
                                    })
                                }
                            </div>
                            <div className="flex justify-center pt-20 md:col-span-4 sm:col-span-2 sm:p-20 md:p-4">
                                <Link to="/tutores" className="bg-secondary text-white px-4 py-3 rounded-md text-sm flex items-center">
                                    <span>Ver todos</span>
                                </Link>
                            </div>
                        </>
                    )
                }
            </div>
        </div>
    </div>
}
export default Recommended