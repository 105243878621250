import React, { useEffect } from 'react';
import { Field, useFormikContext } from 'formik';

export const castGenderToSelect = (gender) => {
  if (!gender) return {
    genderSelect: "other",
    gender: ""
  }

  return  {
    genderSelect: ["male", "female", "none", 'other'].includes(gender) ? gender : "other",
    gender,
  }
}

const GenderInput = ({ disabled }) => {
  const {
    values: { genderSelect, gender },
    setFieldValue,
    errors,
    touched
  } = useFormikContext();

  useEffect(() => {
    if (genderSelect === 'other') {
      if (isPredefined(gender)) {
        setFieldValue('gender', '')
      } else {
        setFieldValue('gender', gender)
      }
    } else if (isPredefined(genderSelect)) {
      setFieldValue('gender', genderSelect)
    }
  }, [genderSelect, setFieldValue])

  const isPredefined = (genderStr) => (
    ["male", "female", "none", 'other'].includes(genderStr)
  )

  return (
    <div className="flex">
      <Field name="genderSelect">
        {({ field }) => (
          <select
            {...field}
            disabled={disabled}
            className={`flex-1 text-primary text-sm bg-white ${genderSelect === 'other' && 'rounded-r-none border-r-0'} rounded-md w-full h-9 px-3 py-2 border border-primary-light ${errors.gender && touched.gender ? 'border-red-500' : ''} h-10 focus:outline-none`}
          >
            <option value="" />
            <option value="male">Hombre</option>
            <option value="female">Mujer</option>
            <option value="none">Prefiero no decir</option>
            <option value="other">Personalizado</option>
          </select>
        )}
      </Field>
      <Field
        disabled={disabled}
        name="gender"
        className={`${(genderSelect !== 'other') && 'hidden'} flex-2 text-sm rounded-r-md w-full px-3 py-2 border ${errors.gender && touched.gender ? 'border-red-500' : ''} h-10 focus:outline-none focus:border-secondary`}
      />
    </div>
  );
}

export default GenderInput