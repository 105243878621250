
export const lsKeys = {
    sesion: 'PARADIGMA_SESION',
    videoToken: '__prdgm_last_video_token'
}

const myLocalStorage = {
    save: (key, value) => {
        localStorage.setItem(key, JSON.stringify(value))
    },
    
    read: (key) => {
        let readed = localStorage.getItem(key)

        if (!readed) return null

        try {
            readed = JSON.parse(readed)
        } catch (error) {
            console.error(`${key} was corrupted`)
            localStorage.removeItem(key)
            readed = null;
        }
        
        return readed
    },

    delete: (key) => (
        localStorage.removeItem(key)
    ),

    clear: (key) => (
        localStorage.clear()
    )
}

export default myLocalStorage