import { useState, useEffect } from "react"
import { Link } from "react-router-dom"
import { LinkButton } from "../../Components/Button"
import CardExpert from "../../Components/CardExpert"
import { IconChevronRight } from "../../Components/Icons"
import Loader from "../../Components/Loader"
import expert from "../../integrations/expert"

const ChoosePsychic = () => {
    const [loading, setLoading] = useState(true)
    const [experts, setExperts] = useState([])

    useEffect(async () => {
        try {
            const res = await expert.getExperts(20, 1, '', [], "available", 'desc')
            setExperts(res?.data?.payload?.data.splice(0, 4))
            setLoading(false)
        } catch (error) {
            setLoading(false)
            setExperts([])
        }
    }, [])

    return (
        <>
            {/* This example requires Tailwind CSS v2.0+ */}
            <div className="pb-20 bg-white lg:pt-10">
                <div className="max-w-md px-6 pt-3 mx-auto lg:max-w-7xl sm:max-w-3xl sm:px-0">
                    <div className="text-3xl text-center nunito-font-bold tracking-tight text-blueStrong sm:text-4xl">
                        Encuentra a un tutor experto
                    </div>
                    <p className="mt-10 text-blueStrong text-lg tracking-normal text-center">
                        Desde álgebra hasta Java y desde fonética hasta preparación para el GMAT, 
                        encuentra clases en vivo en las materias que necesitas. Lecciones 
                        privadas 1 a 1 en vivo con el instructor que tú elijas. Tú decides 
                        cuánto quieres pagar y con quien quieres trabajar.
                    </p>
                    <div className="mt-10">
                        {
                            loading ? <Loader /> : (
                                <>
                                    <div className="items-stretch grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4">
                                        {
                                            experts?.map((expert, i) => {
                                                return <>
                                                    <CardExpert
                                                        id={expert._id}
                                                        expert={expert}
                                                        available={expert.available}
                                                        photo={expert.image}
                                                        stars={expert.rating}
                                                        name={expert.username}
                                                        isRecComp={true}
                                                        key={`expert-key-${expert._id}`}
                                                    />
                                                </>
                                            })
                                        }
                                    </div>
                                    <div className="flex justify-center pt-20 md:col-span-4 sm:col-span-2 sm:p-20 md:p-4">
                                        <Link to="/tutores" className="bg-secondary text-white px-4 py-3 rounded-md text-sm flex items-center">
                                            <span>Ver todos</span>
                                        </Link>
                                    </div>
                                </>
                            )
                        }
                    </div>
                </div>
            </div>
        </>
    )
}
export default ChoosePsychic
