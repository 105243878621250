import { Menu } from "@headlessui/react";
import { Link } from "react-router-dom";

export const Dropdown = ({ children }) => {
   return (
      <Menu>
         <Menu.Button className="outline-none text-primary">{children}</Menu.Button>
         <Menu.Items className="absolute flex flex-col border rounded-md outline-none focus:outline-none bg-gray-100 radius bottom-0 md:bottom-auto md:top-30 lg:top-20 z-10 overflow-hidden">
            <DropDownItem url="/perfil">Mi perfil</DropDownItem>
            <DropDownItem url="/cerrar-sesion">Cerrar sesión</DropDownItem>
         </Menu.Items>
      </Menu>
   );
}

const DropDownItem = ({ children, url }) => (
   <Menu.Item>
      {({ active }) => (
         <Link className={`px-3 py-2 ${active ? "bg-primary text-white": "text-primary"}`} to={url}>
            {children}
         </Link>
      )}
   </Menu.Item>
)
export default Dropdown
