import React, { useState, useEffect, useContext, useRef } from "react";
import { IconPhone, IconMicrophone, UserProfileIcon } from "../Icons"
import sessionIntegration from "../../integrations/session";
import expertsIntegration from "../../integrations/expert";
import { ToastError, ToastInfo, ToastSuccess, ToastWarn } from "../Toast";
import { Context } from "../../store/useGlobalState";
import moment from "moment";
import ModalDialog from "../ModalDialog";
import Loader from '../../Components/Loader'
import { Formik, Field, ErrorMessage, Form } from "formik";
import * as yup from 'yup';
import paymentApi from '../../integrations/payment';
import { IconLoader } from "../Icons";
import audioNotification from '../../Assets/Audio/notification-wrong.wav'

var disconnectionFired = false
var answerCall = false
var initTime = null
var initTimestamp = null
var disconnectedByClient = false
var sendReplyCallFired = false
var mustCloseTheCall = true

const VoiceChat = ({ call, removeCall, expert, handleSessionUpdated, appointmentDuration, updateAppointmentStatus }) => {
  const [mute, setMute] = useState(false)
  const [visible, setVisible] = useState(true)
  const [duration, setDuration] = useState(-1)
  const [hasAppointment, setHasAppointment] = useState(false)
  const [showExtendModal, setShowExtendModal] = useState(false)
  const [paymentMethods, setPaymentMethods] = useState([])
  const [extendingSession, setExtendingSession] = useState(false)
  const [loadingPaymentMethods, setLoadingPaymentMethods] = useState(false)
  const { globalState } = useContext(Context)
  const timerRef = useRef(null)
  var timeoutCall = null;
  var timeoutCalling = null;
  var timeoutTimer = null;
  var audio = new Audio(audioNotification);
  audio.load();

  if (!window.prdgm) {
    window.prdgm = {
      showingExtendModal: false,
      extendingCallHandled: false,
      session: null,
      elapsedTime: 0
    }
  }

  useEffect(() => {
    mustCloseTheCall = true
    timeoutCalling = setTimeout(() => {
      if (mustCloseTheCall) {
        ToastInfo("El experto no puede atender la sesión en este momento. Intenta de nuevo más tarde.")
        sendReplyCall();
        disconnectTwilioVoice();
      }
    }, 30000);

    updateExpertData();
    window.prdgm.showingExtendModal = false;
    window.prdgm.extendingCallHandled = false;

    initTimestamp = 0;
    initTime = undefined;
    timeoutTimer = setInterval(() => {
      if (timerRef && timerRef.current && timerRef.current.innerHTML) {
        if (initTimestamp != undefined && initTime != undefined) {

          initTimestamp += 1;
          window.prdgm.elapsedTime = initTimestamp;

          if (initTimestamp === 1) {
            updateSessionData(1, initTimestamp)
          } else if (initTimestamp % 10 === 0) {
            updateSessionData(10, initTimestamp)
          }

          const diffSecs = initTimestamp % 60
          const diffMins = Math.floor(initTimestamp / 60) % 60
          const diffHours = Math.floor(initTimestamp / (60 * 60)) % 24

          let secStr = diffSecs.toString()
          if (secStr.length < 2) {
            secStr = '0' + secStr
          }

          let minStr = diffMins.toString()
          if (minStr.length < 2) {
            minStr = '0' + minStr
          }

          let hourStr = diffHours.toString()
          if (hourStr.length < 2) {
            hourStr = '0' + hourStr
          }

          timerRef.current.innerHTML = `${hourStr}:${minStr}:${secStr}`
        }
      }
    }, 1000)

    return () => {
      closeDevices()
      clearTimeout(timeoutCalling);
      clearTimeout(timeoutCall);
      clearTimeout(window?.prdgm?.timeoutCall);
      clearInterval(timeoutTimer);
      audio = null;
    }
  }, [])

  const closeDevices = () => {
    if (call) {
      try {
        sendReplyCall();
        call.removeAllListeners()
        call.disconnect()
      } catch (error) { }
    }
  }

  const updateSessionData = async (eta, etaDuration) => {
    const dataToSend = {
      expert: expert._id,
      service: "Psiquico",
      date: moment().format("YYYY/MM/DD"),
      sessionType: "internet_call",
      duration: etaDuration,
      eta
    }

    if (window.prdgm?.session && window.prdgm.session?._id) {
      dataToSend.idSession = window.prdgm.session._id
    }

    try {
      const dataRes = await sessionIntegration.storeSession(dataToSend);
      window.prdgm.session = dataRes?.payload;
      return dataRes?.payload;
    } catch (error) { }

    return null;
  }

  const updateExpertData = async () => {
    var expertData = await expertsIntegration.getById(expert._id);
    const maxDuration = globalState.user.credit / expertData.price_phonecall;
    setHasAppointment(appointmentDuration != -1 && (appointmentDuration < maxDuration));
    setDuration(appointmentDuration != -1 ? (maxDuration < appointmentDuration ? maxDuration : appointmentDuration) : maxDuration);
  }

  disconnectedByClient = false

  const handleMute = () => {
    if (!mute) {
      setMute(true)
      call.mute(true)
    } else {
      setMute(false)
      call.mute(false)
    }
  }

  call.on('accept', call => {
    mustCloseTheCall = false;
    timerSetter();
    call.mute(mute);
    initTime = moment();
    initTimestamp = 0;
    answerCall = true;
    sendReplyCallFired = false;
    window.prdgm.session = null;
    window.prdgm.elapsedTime = 0;

    if (updateAppointmentStatus && typeof updateAppointmentStatus === "function") {
      updateAppointmentStatus()
    }
  });

  call?.on('disconnect', call => {
    disconnectTwilioVoice()
  })

  const showExtendMeetingModal = async () => {
    clearTimeout(window.prdgm.timeoutCall);
    window.prdgm.lastDuration = (window.prdgm.elapsedTime / 60) + 1;
    window.prdgm.timeoutCall = setTimeout(() => disconnectTwilioVoice(), 60000);

    if (appointmentDuration != -1 && (appointmentDuration <= window.prdgm.lastDuration)) {
      hideExtendMeetingModal();
      return;
    }

    window.prdgm.showingExtendModal = true;
    setMute(true);
    setShowExtendModal(true);

    if (audio) { try { audio.play(); } catch (error) { } }

    setLoadingPaymentMethods(true)

    try {
      const res = await paymentApi.getPaymentMethods()
      setPaymentMethods(res?.payment_methods || [])
    } catch (error) { }

    setLoadingPaymentMethods(false)
  }

  const hideExtendMeetingModal = () => {
    setShowExtendModal(false);
    window.prdgm.showingExtendModal = false;
  }

  const extendMeeting = async (values, actions) => {
    setExtendingSession(true)

    if (window.prdgm.extendingCallHandled) return;
    window.prdgm.extendingCallHandled = true;

    const data = {
      credits: values.credits,
      payment_method: values.method
    }

    try {
      const lastDuration = window.prdgm.lastDuration;
      const res = await paymentApi.requestPaymentIntent(data);

      const timeToExtend = values.credits / expert.price_phonecall;
      const secondsExtended = Math.floor(timeToExtend * 60) % 60;
      const minutesExtended = Math.floor(timeToExtend);

      let timeExtended = timeToExtend + (lastDuration - (window.prdgm.elapsedTime / 60));

      clearTimeout(window.prdgm.timeoutCall);

      if (appointmentDuration != -1) {
        const leftTimeToAppt = appointmentDuration - lastDuration;

        if (leftTimeToAppt < timeExtended) {
          timeExtended = leftTimeToAppt;
          window.prdgm.timeoutCall = setTimeout(() => disconnectTwilioVoice(), timeExtended);
        } else {
          window.prdgm.timeoutCall = setTimeout(() => {
            ToastWarn("Tu sesión terminará en un 1 minuto.");
            showExtendMeetingModal();
          }, (timeExtended - 1) * 60000);
        }
      } else {
        window.prdgm.timeoutCall = setTimeout(() => {
          ToastWarn("Tu sesión terminará en un 1 minuto.");
          showExtendMeetingModal();
        }, (timeExtended - 1) * 60000);
      }

      ToastSuccess(`Tu sesión se ha extendido por ${minutesExtended} minutos, ${secondsExtended} segundos`);
    } catch (error) {
      ToastError("Ocurrió un error agregando el crédito. Inténtalo en un momento.")
      actions.setSubmitting(false)
    }

    actions.setSubmitting(false)
    setShowExtendModal(false)
    setExtendingSession(false)
    window.prdgm.showingExtendModal = false;
    window.prdgm.extendingCallHandled = false;
  }

  const timerSetter = () => {
    if (window?.prdgm?.timeoutCall !== null) { clearTimeout(window.prdgm.timeoutCall) }

    if (duration != -1) {
      if (duration >= 1) {
        if (duration < 3) {
          window.prdgm.timeoutCall = setTimeout(() => {
            ToastWarn("Tu sesión terminará en un 1 minuto.");
            showExtendMeetingModal();
          }, (duration - 1) * 60000);
        }
        else {
          window.prdgm.timeoutCall = setTimeout(() => {
            ToastWarn("Tu sesión terminará en 3 minutos.");
            setTimeout(() => {
              ToastWarn("Tu sesión terminará en 1 minuto.");
              showExtendMeetingModal();
            }, 120000);
          }, (duration - 3) * 60000);
        }
      }
      else window.prdgm.timeoutCall = setTimeout(() => disconnectTwilioVoice(), duration * 60000);
    }
  }

  const handleRatingAfterCalling = async () => {
    let mustShowReview = false

    if (answerCall) {
      if (disconnectionFired) return
      disconnectionFired = true;

      if (window.prdgm.session) {
        if (window.prdgm?.elapsedTime && window.prdgm.elapsedTime % 10 != 0) {
          updateSessionData(window.prdgm.elapsedTime % 10, window.prdgm.elapsedTime)
        }

        mustShowReview = true;
        disconnectionFired = false;
        answerCall = false;
        handleSessionUpdated(mustShowReview, window.prdgm.session);
      }
    }

    removeCall();
  }

  const disconnectTwilioVoice = async () => {
    clearTimeout(timeoutCall);
    clearTimeout(timeoutCalling);
    try { setVisible(false) } catch (error) { }
    await handleRatingAfterCalling()
  }

  const sendReplyCall = (status = false) => {
    const data = {
      action: 'wsVideocallReply',
      data: {
        token: globalState.token,
        idReceiver: expert._id,
        userType: 'user',
        accept: status,
        portal: "100_tutores"
      }
    }

    globalState.ws.send(JSON.stringify(data))
  }

  const handleDisconnection = () => {
    call.disconnect()
  }

  return (
    <>
      {
        visible && (
          <div style={{ width: "100%", height: "100%", zIndex: 8, position: "fixed", top: 0, backgroundColor: "rgb(244, 246, 253)", right: 0 }}
            onClick={(e) => { e.stopPropagation() }}>
            <div style={{
              width: "100%",
              height: "100%",
              display: "flex",
              justifyContent: "center"
            }}>
              <div style={{
                position: "absolute",
                top: "15%",
                zIndex: 8,
                width: 300,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}>
                {
                  expert.image != null && expert.image != 'null' ?
                    <img alt={expert.image} src={expert.image} style={{ width: 120, height: 120, objectFit: "cover", borderRadius: "50%" }} /> :
                    <div style={{display:'inline-flex', justifyContent:'center', width: 120, height: 120, objectFit: "cover", borderRadius: "50%" }}><UserProfileIcon /></div>
                }
                {expert.username && <h3 className="text-xl mt-4">{expert.username}</h3>}
              </div>
              <div style={{
                position: "absolute",
                bottom: "15%",
                zIndex: 9,
                width: 300
              }}>
                <div>
                  <h2 className="text-center" ref={timerRef}>00:00:00</h2>
                </div>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <div
                    onClick={handleMute}
                    className="px-2 py-2 mx-4 text-xs bg-superLight rounded-full cursor-pointer relative" >
                    <IconMicrophone width="40" height="40" />
                    {mute && <div className="absolute w-12 h-1 bg-primary transform rotate-135 top-7 left-1"></div>}
                  </div>
                  <div className="px-2 py-2 text-xs bg-red-500 rounded-full mx-4 cursor-pointer transform rotate-135"
                    onClick={() => { disconnectedByClient = true; sendReplyCall(); handleDisconnection() }}>
                    <IconPhone width="40" height="40" />
                  </div>
                </div>
              </div>
            </div>
            {
              showExtendModal && (
                <ModalDialog visible={showExtendModal} close={hideExtendMeetingModal} >
                  <div className="p-8">
                    {
                      loadingPaymentMethods ? (<Loader />) : (
                        <>
                          <h2 className="text-3xl">
                            Tu sesión terminará en un minuto
                          </h2>
                          <h2 className="text-2xl text-primary">
                            ¿ Deseas extender esta sesión ?
                          </h2>
                          <div>
                            Puedes agregar crédito a tu cuenta
                          </div>
                          <div className="mt-10">
                            <Formik
                              initialValues={{ method: -1, credits: null }}
                              validationSchema={
                                yup.object().shape({
                                  method: yup.string("No válido").min(3, 'No válido').required("Debe seleccionar un método de pago"),
                                  credits: yup.number("Debe agregar una cantidad").min(100, 'Debe ser mayor o igual a $100 pesos').required("Debe agregar una cantidad")
                                })
                              }
                              onSubmit={extendMeeting}>
                              {
                                ({ errors, isSubmitting, touched, submitForm }) => (
                                  <Form>
                                    <div>
                                      <div>¿Cuánto deseas agregar a tu saldo?</div>
                                      <Field
                                        name="credits"
                                        placeholder="$100"
                                        className={`text-sm text-right rounded-md w-full px-3 py-2 h-10 ${errors.credits && touched.credits ? 'border-red-500' : 'border-secondary'} border focus:outline-none`}
                                      ></Field>
                                      {
                                        (errors?.credits && touched?.credits) && (
                                          <span className="h-px text-xs text-red-500">Debe agregar una cantidad mayor o igual a $100 pesos</span>
                                        )
                                      }
                                    </div>
                                    <div className="mt-4">
                                      <div>Selecciona el método de pago</div>
                                      <Field name="method" as="select"
                                        className={`text-sm text-right rounded-md w-full px-3 py-2 h-10 ${errors.method && touched.method ? 'border-red-500' : 'border-secondary'} border focus:outline-none`} >
                                        <option value="">Seleccionar</option>
                                        {
                                          paymentMethods.map((method, idx) => (
                                            <option key={`method-${idx}`} value={method.id}>
                                              {method?.name} | ***{method?.last4}
                                            </option>
                                          ))
                                        }
                                      </Field>
                                      <ErrorMessage name="method" render={(msg) => <span className="h-px text-xs text-red-500">{msg}</span>} />
                                    </div>
                                    <div className="flex items-center justify-center mt-4">
                                      <button type="button" onClick={hideExtendMeetingModal}
                                        className="mr-1 flex items-center justify-center px-6 py-3 text-xs font-bold tracking-wider text-secondary rounded-md bg-secondary-lightest focus:outline-none">
                                        Cancelar
                                      </button>
                                      {extendingSession
                                        ? <button disabled={extendingSession}
                                          className="ml-1 flex items-center justify-center px-6 py-3 text-xs font-bold tracking-wider text-white rounded-md bg-primary-light focus:outline-none">
                                          <IconLoader
                                            className="text-white fill-current animate-spin"
                                            style={{ height: 20 }}
                                          />
                                        </button>
                                        : <button type="submit" className="ml-1 flex items-center justify-center px-6 py-3 text-xs font-bold tracking-wider text-white rounded-md bg-primary-light focus:outline-none"
                                          onClick={submitForm}
                                          disabled={extendingSession}>
                                          Agregar
                                        </button>
                                      }
                                    </div>
                                  </Form>
                                )
                              }
                            </Formik>
                          </div>
                        </>
                      )
                    }
                  </div>
                </ModalDialog>
              )
            }
          </div>
        )
      }
    </>
  )
};

export default VoiceChat;