import React, { useEffect } from "react"
import { Link } from 'react-router-dom'
import EmailSuscribe from "../Horoscopes/Components/Emailsuscribe"
import assets from "../../Assets"
import { IconChevronRight, IconCheck2 } from "../../Components/Icons"

const Request = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])
    return <>
        <div className="w-full py-20 max-w-md px-6 pt-10 mx-auto sm:max-w-3xl lg:max-w-7xl px-6 lg:px-0">
            <section className="grid grid-cols-1 md:grid-cols-5 text-primary">
                <div className="col-span-2 lg:pr-10 pb-3">
                    <div className="flex mb-8">
                        <Link to='/' className="font-semibold text-sm inline-flex items-center"
                            style={{ color: '#6E92C9' }}>
                            <small className="inline-block" style={{ transform: 'rotate(180deg)' }}>
                                <IconChevronRight fill="currentColor" />
                            </small>
                            <span className="mx-3 inline-block -mt-1">Regresar</span>
                        </Link>
                    </div>
                    <div className="text-3xl font-semibold mb-3">Nuestro proceso de solicitud</div>
                    <sub className="block text-base mb-3">¿Eres tutor, maestro o profesor?</sub>
                    <sub className="block text-base mb-3">Únete a nuestra comunidad de profesionales que ofrecen soluciones personalizadas de aprendizaje.</sub>
                    <sub className="block text-base mb-3">Usa tus experiencia y habilidades para ayudar a más gente a lograr sus metas de aprendizaje.</sub>
                    <sub className="block text-base mb-3">Nuestros tutores están comprometidos a ofrecer sesiones profesionales por videollamada en línea.</sub>
                    <sub className="block text-base mb-3">Comparte tus especialidades con otros en un ambiente de respeto y comprensión.</sub>
                </div>
                <div className="col-span-3 relative">
                    <div className="absolute hidden md:inline-flex items-end right-0 top-40 -mr-10 z-10">
                        <IconCheck2 fill="#D2544A" width={58} height={46} />
                        <IconCheck2 fill="#14336C" width={37} height={29} />
                    </div>
                    <img className="rounded mx-auto" src={assets.RequestHeader} />
                    <did className="block mt-5">
                        <div className="font-semibold">¿Cómo puedes ser parte?</div>
                        <p className="my-6">
                            Escríbenos un email con tus datos a  hola@paradigmanetworks.com.
                            Nosotros te enviaremos un cuestionario y estaremos en contacto contigo para decirte los siguientes pasos. Revisaremos tu historial de trabajo, experiencia y tus dones, y te daremos la oportunidad de hacernos preguntas también. Es importante para nosotros que te sientas cómodo asociándote a 100 Tutores y que nosotros seamos el “hogar” correcto para ti.
                            Si eres seleccionado, te contactaremos con una oferta, y entonces comenzaremos con la sesión de inducción y resolveremos todas las dudas sobre cómo comenzar.
                        </p>
                        <div className="font-semibold">¿Por qué unirte a 100 Tutores?</div>
                        <p className="my-6">
                            100 Tutores ofrece una plataforma sólida, herramientas innovadoras y una comunidad de apoyo a profesionales solidarios y expertos que buscan conectar con clientes en línea. Nosotros nos encargamos de la logística: marketing, soporte técnico, gestión financiera y todo lo demás, para que te puedas dedicar tu tiempo a ofrecer tutorías, clases y sesiones educativas.
                        </p>
                        <p className="my-6">
                            Esta es la oportunidad que estabas esperando.
                        </p>
                        <div className="font-semibold">¡Bienvenido!</div>
                    </did>
                </div>
            </section>
        </div>
        <EmailSuscribe />
    </>
}

export default Request