import React from "react"

const Privacy = () => {
    return <div className="px-3 max-w-md px-6 pt-3 mx-auto lg:max-w-7xl sm:max-w-3xl lg:px-0 text-primary">
        <div className="my-10 font-semibold text-3xl text-center">Políticas de privacidad</div>
        <p className="my-5">
            Esta Política de privacidad se aplica a todos los sitios web, aplicaciones móviles y otras plataformas digitales que enlazan o publican esta Política de privacidad (cada "Plataforma") y operadas por Paradigma Networks SAS de CV (colectivamente, "Paradigma Networks", "nuestro", o "nosotros"). Esta Política de privacidad describe cómo usamos, almacenamos, divulgamos y procesamos la información que obtenemos sobre usted a través de cualquiera de nuestras Plataformas, incluida la información que obtenemos a través de cualquier oferta u otro servicio que brindamos, en su totalidad o en parte, a través de cualquiera de nuestras Plataformas.
        </p>
        <p className="my-5">
            A los efectos del Reglamento general de protección de datos de la UE de 2016 (el "RGPD"), el controlador de datos es Paradigma Networks SAS de CV con una oficina en Calle José María Vigil 3150, Interior G, Col. Lomas de Providencia, CP 44647, Guadalajara, Jalisco, México.
        </p>
        <p className="my-5">
            TENGA EN CUENTA QUE AL UTILIZAR O ACCEDER A CUALQUIER PLATAFORMA EN LA QUE SE PUBLIQUE O ENLACE ESTA POLÍTICA DE PRIVACIDAD, USTED ACEPTA LOS TÉRMINOS DE ESTA POLÍTICA DE PRIVACIDAD.
        </p>
        <p className="my-5">
            1. ¿QUÉ TIPO DE INFORMACIÓN RECOPILAMOS Y CÓMO LA RECOPILAMOS?
        </p>
        <p className="my-5">
            Cuando usa o accede a cualquiera de nuestras Plataformas, podemos recopilar una variedad de información sobre usted y otros, como se describe a continuación. Dicha información incluye, pero no se limita a, información sobre usted que se encuentra en una forma que nos permite identificarlo (sus "datos personales").
        </p>
        <p className="my-5">
            A. Datos personales sobre usted que proporciona.
        </p>
        <p className="my-5">
            Cuando usa o accede a cualquiera de nuestras Plataformas, podemos pedirle que nos proporcione ciertos datos personales, incluidos, entre otros, algunos o todos los siguientes: su nombre, nombre de usuario, dirección de correo electrónico, número de teléfono, direcciones de correo actuales y anteriores. , tarjeta de crédito o débito o información de la cuenta de PayPal, información de la cuenta de OxxoPay, dirección de pago, otro número de cuenta financiera, número de seguro social o número de identificación fiscal, número de licencia de conducir u otro número de identificación emitido por el gobierno (o una copia de su licencia de conducir u otra tarjeta de identificación emitida por el gobierno) , fecha de nacimiento, hora de nacimiento, lugar de nacimiento, inicio de sesión, PIN, contraseña, preferencias de cuenta y zona horaria. Es posible que se le solicite esta información en distintos momentos al interactuar con nuestras Plataformas, como al registrarse para ser usuario de cualquiera de nuestros servicios, al realizar una compra a través de cualquiera de nuestras Plataformas, o al completar un formulario o encuesta o al participar en una promoción a través de nuestras Plataformas.
        </p>
        <p className="my-5">
            B. Datos personales sobre usted que recopilamos a través de la tecnología.
        </p>
        <p className="my-5">
            Cuando usa o accede a cualquiera de nuestras Plataformas, también podemos recopilar ciertos datos personales a través de medios automatizados, incluidos, entre otros, algunos o todos los siguientes: su número de teléfono móvil, ID de dispositivo único, tipo de dispositivo, ID de máquina, información de geolocalización , información de la computadora y la conexión, tipo de navegador, dirección de Protocolo de Internet ("IP") (un número que se asigna automáticamente a su computadora cuando usa Internet, que puede variar de una sesión a otra), nombre de dominio y marcas de fecha y hora para sus visitas. También podemos recopilar cualquier número de teléfono desde el que se comunique con nosotros o desde el que realice una llamada a otro usuario a través de nuestra función de llamadas en cualquiera de nuestras Plataformas. (Si llama a cualquier usuario u otra persona directamente, es decir, no a través de la función de llamada en cualquiera de nuestras Plataformas, la persona llamada puede obtener su número de teléfono).
        </p>
        <p className="my-5">
            Podemos monitorear o grabar sus conversaciones telefónicas en vivo con otros usuarios a través de la Plataforma con fines operativos, incluso con fines de garantía de calidad y para promover la puesta a su disposición de ciertos servicios.
        </p>
        <p className="my-5">
            C. Información sobre usted que recopilamos de otros.
        </p>
        <p className="my-5">
            Cuando usa o accede a cualquiera de nuestras Plataformas, también podemos recopilar su nombre y dirección de correo electrónico de sus amigos que nos sugieren que tal vez desee utilizar una de nuestras Plataformas. Si recibimos estos datos personales sobre usted de esta manera, nos comunicaremos con usted para informarle que nos lo han recomendado. Si no responde a nuestro acercamiento o nos solicita que eliminemos sus datos personales, lo haremos.
        </p>
        <p className="my-5">
            Cuando usa o accede a algunas de nuestras Plataformas, también podemos recopilar su nombre, dirección, número de teléfono e información de geolocalización de otros terceros, como los proveedores de verificación de identidad con los que contratamos.
        </p>
        <p className="my-5">
            D. Información sobre otras personas que recopilamos de usted.
        </p>
        <p className="my-5">
            Cuando utiliza o accede a cualquiera de nuestras Plataformas, puede decidir proporcionarnos información sobre un tercero, como los nombres y direcciones de correo electrónico de sus amigos en relación con nuestras funciones "Cuéntale a un amigo".
        </p>
        <p className="my-5">
            E. Información proporcionada a sitios de redes sociales.
        </p>
        <p className="my-5">
            Algunas de nuestras Plataformas pueden incluir la posibilidad de que usted y otros usuarios conecten su perfil en nuestra Plataforma con Facebook u otros sitios de redes sociales (cada uno, un "SNS"), como a través de la funcionalidad "Conectarse a Facebook", ya sea que inicialmente conectarse desde nuestra Plataforma al SNS o en la otra dirección desde el SNS a nuestra Plataforma. Podemos recibir o acceder a información sobre usted a través del SNS cuando elige conectar su perfil de Plataforma a cualquier SNS, o publicar o compartir información en cualquier SNS al que haya conectado su perfil de Plataforma. Tenga en cuenta que cualquier usuario de dicho SNS podrá acceder a cualquier información que usted u otros publiquen en un SNS, sujeto a sus términos de servicio y política de privacidad.
        </p>
        <p className="my-5">
            F. Otra información que recopilamos a través de la tecnología.
        </p>
        <p className="my-5">
            Cuando usa o accede a nuestras Plataformas, también podemos recopilar cierta información a través de medios automatizados asociados con sus actividades en nuestras Plataformas, incluida información sobre la forma en que interactúa con cualquiera de nuestras Plataformas o cualquier contenido de las mismas, estadísticas sobre las visitas a su página y el tráfico a y de nuestras Plataformas, y la cantidad de bytes transferidos, hipervínculos en los que se hace clic y otras acciones que realiza en nuestras Plataformas. Cada una de nuestras Plataformas también puede rastrear información como la URL que visitó antes de llegar a la Plataforma y la URL a la que se dirigirá a continuación. Al igual que con la mayoría de los sitios web y otras plataformas digitales, también empleamos cookies, etiquetas de píxeles, balizas web y tecnologías similares en nuestras Plataformas para recopilar y almacenar cierta información sobre los visitantes de nuestras Plataformas, para mejorar nuestros servicios y para ayudarnos a recordarlo a usted y sus preferencias cuando vuelva a visitar nuestras Plataformas.
        </p>
        <p className="my-5">
            G. Información en sus consultas y publicaciones.
        </p>
        <p className="my-5">
            También recopilamos cualquier información que nos envíe a nosotros o a otros usuarios a través de cualquiera de nuestras Plataformas, incluida la información sobre usted en la correspondencia, el correo y las llamadas o videollamadas realizadas a través de una Plataforma, y ​​las respuestas a nuestras encuestas de satisfacción del cliente o comunicaciones de servicio al cliente. También recopilamos información del contenido que publica en reseñas, comentarios, foros o blogs en línea en nuestras Plataformas.
        </p>
        <p className="my-5">
            2. ¿CÓMO UTILIZAMOS LA INFORMACIÓN QUE RECOPILAMOS SOBRE USTED?
        </p>
        <p className="my-5">
            Nuestro propósito principal al recopilar información sobre usted en relación con su uso de nuestras Plataformas es proporcionarle o conectarlo con nuestros productos, servicios, información y usuarios que coincidan con sus intereses y preferencias. Sin embargo, podemos utilizar dicha información recopilada sobre usted para una variedad de propósitos comerciales. Sin limitación, podemos utilizar dicha información para:
        </p>
        <ul className="my-5 list-disc" style={{
            listStyleType: 'none',
            marginLeft: '0px',
            paddingLeft: '0px'
        }}>
            <li>permitir su participación en actividades en nuestras Plataformas;</li>
            <li>proporcionarle nuestras ofertas y servicios;</li>
            <li>confirmar su identidad;</li>
            <li>hacer cumplir las políticas y los términos de servicio de nuestra Plataforma, e investigar posibles violaciones de dichas políticas o términos;</li>
            <li>cumplimentar un pedido que realice a través de cualquiera de nuestras Plataformas, incluso para procesar el pago de cualquier compra realizada a través de cualquiera de nuestras Plataformas;</li>
            <li>responder a las consultas que pueda tener sobre cualquiera de nuestras Plataformas o servicios;</li>
            <li>administrar su cuenta en cualquiera de nuestras Plataformas;</li>
            <li>mostrar nuestro contenido en función de sus intereses;</li>
            <li>permitirle buscar información en nuestras Plataformas;</li>
            <li>enumerar en nuestros servicios de búsqueda y directorio cualquier usuario que brinde servicios a través de una de nuestras Plataformas;</li>
            <li>contactarlo con respecto al servicio al cliente y las encuestas a los clientes;</li>
            <li>evaluar sus necesidades e intereses en nuestros productos y servicios para adaptar mejor nuestras ofertas y / o publicidad;</li>
            <li>mejorar nuestras Plataformas;</li>
            <li>informarle de los cambios realizados en nuestras Plataformas;</li>
            <li>organizar o realizar actividades promocionales, juegos o eventos promocionales;</li>
            <li>informarle sobre productos, servicios, promociones, ofertas y concursos que le puedan resultar interesantes, siempre que no haya optado por no recibir dicha información o, en su caso, tengamos su consentimiento expreso;</li>
            <li>hacer una investigación;</li>
            <li>comprender las tendencias y patrones generales de los clientes para que podamos desarrollar y respaldar las estrategias de marketing existentes y en curso para nuestros productos y servicios; y</li>
            <li>realizar otras actividades comerciales y de marketing.</li>
        </ul>
        <p className="my-5">
            También podemos utilizar la información sobre usted que recopilamos en relación con su uso de nuestras Plataformas con fines de seguridad, administrativos y legales (incluso para detectar, prevenir o abordar de otro modo el fraude, problemas técnicos o de seguridad).
        </p>
        <p className="my-5">
            Además, podemos combinar información sobre usted que recopilamos en relación con su uso de nuestras Plataformas con otros registros (como los obtenidos de terceros) para desarrollar un perfil de cliente relacionado con usted.
        </p>
        <p className="my-5">
            3. ¿CUÁNTO TIEMPO MANTENEMOS SUS DATOS PERSONALES?
        </p>
        <p className="my-5">
            No almacenaremos sus datos personales durante más tiempo del necesario para el propósito para el que se procesan dichos datos personales. Sin embargo, tenga en cuenta que podemos retener y utilizar sus datos personales según sea necesario para cumplir con nuestras obligaciones legales, resolver disputas y hacer cumplir nuestros acuerdos y derechos, o si no es técnica y razonablemente factible eliminar dicha información de nuestras bases de datos, lo que significa que podemos retener y usar sus datos personales según sea necesario después de que deje de usar nuestras Plataformas. Sujeto a lo anterior, nos esforzaremos por eliminar sus datos personales de inmediato si lo solicita por escrito.
        </p>
        <p className="my-5">
            4. ¿A QUIÉN DIVULGAMOS LA INFORMACIÓN QUE RECOPILAMOS SOBRE USTED?
        </p>
        <p className="my-5">
            Podemos compartir o divulgar información sobre usted a terceros en las siguientes situaciones:
        </p>
        <p className="my-5">
            A. Otros usuarios.
        </p>
        <p className="my-5">
            En relación con poner las Plataformas a disposición de nuestros usuarios, podemos divulgar cierta información sobre usted (incluido su nombre, nombre de usuario y fecha de nacimiento) a otros usuarios de las Plataformas, únicamente según lo indique usted o según lo autorizado por nuestros términos de servicio para la Plataforma correspondiente.
        </p>
        <p className="my-5">
            B. Nuestros afiliados.
        </p>
        <p className="my-5">
            Podemos divulgar la información recopilada sobre usted a nuestros afiliados 
            que actúan como proveedores de servicios para nosotros. Por "afiliados", 
            nos referimos a cualquier entidad que controle, esté controlada por, o esté 
            bajo control común con Paradigma Networks SAS de CV, incluidas, entre otras, 
            las empresas que operan bajo cualquiera de las siguientes marcas: ParadigmaExperts.com, 
            PortalMistico.com,  Mipazmental.com, 100tutores.com Nuestros afiliados nos brindan 
            varios servicios, incluidos los de detección de fraude y servicios de marketing. 
            Los datos personales recopilados sobre usted por una entidad de nuestra familia 
            corporativa no se divulgarán a ningún afiliado (es decir, a ninguna otra entidad 
            de nuestra familia corporativa) para sus fines de marketing directo, sin su 
            consentimiento.
        </p>
        <p className="my-5">
            C. Proveedores de servicios de terceros.
        </p>
        <p className="my-5">
            También divulgamos la información recopilada sobre usted a nuestros proveedores de servicios externos y otros socios que prestan servicios en nuestro nombre en relación con cualquiera de las Plataformas. Los servicios proporcionados por dichos terceros y socios incluyen servicios en las siguientes categorías:
        </p>
        <ul className="my-5 list-disc" style={{
            listStyleType: 'none',
            marginLeft: '0px',
            paddingLeft: '0px'
        }}>
            <li>procesar pagos en nuestro nombre;</li>
            <li>ayudarnos a proporcionar los productos o servicios que solicite;</li>
            <li>enviar comunicaciones de marketing en nuestro nombre;</li>
            <li>autenticar identidades en nuestro nombre;</li>
            <li>ayudarnos a crear o mantener nuestras bases de datos;</li>
            <li>ayudándonos a investigar o analizar a las personas que utilizan nuestras Plataformas; y</li>
            <li>probando nuestras Plataformas.</li>
        </ul>
        <p className="my-5">
            A menos que usted dé su consentimiento, no permitimos que nuestros proveedores de servicios utilicen dicha información para comercializar sus propios productos o servicios para usted directamente. Cuando transferimos su información de identificación personal a dicho proveedor de servicios o socio, esa entidad estará obligada por contrato, entre otras cosas, a (a) utilizar dicha información solo para llevar a cabo los servicios que hemos solicitado, (b) para cumplir con esta Política de privacidad y (c) proporcionar la protección adecuada para dicha información (según lo requieran los requisitos legales aplicables).
        </p>
        <p className="my-5">
            D. Sitios de redes sociales.
        </p>
        <p className="my-5">
            Como se señaló anteriormente, algunas de nuestras Plataformas pueden permitirle conectarse y / o publicar contenido en los SNS (Social Networks Sites/ Sitios de Redes Sociales). Y nuestras Plataformas también integran tecnologías facilitadas por varios SNS, como los píxeles de Facebook. Usted reconoce y acepta que es el único responsable del uso de cualquier SNS y que es su responsabilidad revisar los términos de uso y la política de privacidad del proveedor externo de dicho SNS. No somos responsables de: (i) la disponibilidad o precisión de cualquier SNS; (ii) el contenido, productos o servicios o disponibilidad de cualquier SNS; o (iii) su uso de cualquier SNS.
        </p>
        <p className="my-5">
            E. Proveedores de servicios de marca compartida.
        </p>
        <p className="my-5">
            Podemos celebrar acuerdos con determinadas empresas de terceros en virtud de los cuales proporcionamos a dicho tercero una URL y una página de registro de cliente con la marca compartida o etiquetada de forma privada por el tercero, que distribuye y promociona la URL a sus clientes. . Si se registra en un sitio web con un tercero con el que tenemos un acuerdo de marca compartida, podemos proporcionar la información que hemos recopilado sobre usted a través de nuestras Plataformas a dicho tercero que actúa como proveedor de servicios de dicho tercero.
        </p>
        <p className="my-5">
            F. Público en general.
        </p>
        <p className="my-5">
            Podemos compartir con el público en general a través de nuestras Plataformas, o volver a publicar en un sitio web de terceros, cualquier información que publique como usuario de una de nuestras Plataformas, como información en un perfil, calificación en línea, comentario, foro, o publicación de blogs en cualquiera de nuestras Plataformas. Esto puede incluir información que lo identifique (por nombre de usuario o de otro modo).
        </p>
        <p className="my-5">
            G. Terceros involucrados en la transición comercial.
        </p>
        <p className="my-5">
            Podemos compartir información sobre usted recopilada a través de las Plataformas con uno o más terceros en relación con o durante la negociación de cualquier fusión, financiamiento, adquisición o disolución, transición o procedimiento que involucre la venta, transferencia, desinversión o divulgación de todo o un parte de nuestro negocio o activos. En caso de insolvencia, quiebra o administración judicial, dicha información también puede transferirse como un activo comercial. Si otra empresa adquiere cualquiera de nuestras empresas, negocios o activos, esa empresa adquirente puede adquirir la información sobre usted recopilada por nosotros a través de nuestras Plataformas. No garantizamos que cualquier entidad que reciba dicha información en relación con una de estas transacciones cumplirá con todos los términos de esta Política de privacidad después de dicha transacción.
        </p>
        <p className="my-5">
            H. Nuestros asesores.
        </p>
        <p className="my-5">
            También podemos compartir la información recopilada sobre usted con nuestros auditores, asesores legales y terceros similares en relación con la recepción de sus servicios profesionales, sujeto a las obligaciones estándar de confidencialidad.
        </p>
        <p className="my-5">
            I. Aplicación de la ley.
        </p>
        <p className="my-5">
            Podemos divulgar información sobre usted al gobierno o a terceros bajo ciertas circunstancias cuando esté legalmente obligado a hacerlo, como en relación con una actividad ilegal sospechosa en relación con las Plataformas, o para responder a una citación, orden judicial u otro proceso legal. , o que creemos que puede ayudar a una investigación policial. Nos reservamos el derecho de divulgar la información recopilada a través de las Plataformas a las fuerzas del orden u otros funcionarios gubernamentales, según lo consideremos, a nuestro exclusivo criterio, necesario o apropiado.
        </p>
        <p className="my-5">
            J. Procesos legales.
        </p>
        <p className="my-5">
            Podemos compartir todo tipo de información con otros según lo requiera o permita la ley. Esto puede incluir compartir todo tipo de información con entidades gubernamentales o terceros en respuesta a citaciones, órdenes judiciales, otros procesos legales, o cuando creamos que es necesario para ejercer nuestros derechos legales, para defendernos de reclamos legales que se hayan presentado contra nosotros. , o para defenderse de posibles reclamos legales que determinemos a nuestro exclusivo criterio que puedan presentarse contra nosotros.
        </p>
        <p className="my-5">
            K. Otros terceros.
        </p>
        <p className="my-5">
            Podemos compartir información con otros terceros que no se describen anteriormente. Cuando lo hagamos, nos esforzaremos por agregar o anular la identificación de la información para que no sea probable que un tercero vincule datos con usted, su computadora o su dispositivo. La agregación significa que combinamos la información de numerosas personas para que los datos no se relacionen con ninguna persona. Desidentificar significa que eliminamos o cambiamos cierta información que podría usarse para vincular datos a una persona en particular.
        </p>
        <p className="my-5">
            También podemos compartir cualquier información con otros terceros que no se describen anteriormente si nos da su consentimiento para hacerlo.
        </p>
        <p className="my-5">
            5. ¿CÓMO GARANTIZAMOS LA INFORMACIÓN QUE RECOPILAMOS SOBRE USTED?
        </p>
        <p className="my-5">
            Hemos implementado y seguimos medidas técnicas y de procedimiento (incluido el cifrado de cierta información) destinadas a proteger contra el acceso no autorizado y el uso de la información recopilada en relación con el uso de nuestras Plataformas. SIN EMBARGO, TENGA EN CUENTA QUE NO PODEMOS ELIMINAR TOTALMENTE ESTOS RIESGOS YA QUE, A PESAR DE NUESTRAS MEJORES INTENCIONES, NINGUNA TECNOLOGÍA ES COMPLETAMENTE SEGURA. NUESTRAS PLATAFORMAS SE OPERAN EN SOFTWARE, HARDWARE Y REDES, CUALQUIER COMPONENTE DE LOS CUALES PUEDE, DE VEZ EN TIEMPO, EXPERIMENTAR INCUMPLIMIENTOS DE SEGURIDAD U OTROS PROBLEMAS FUERA DE NUESTRO CONTROL.
        </p>
        <p className="my-5">
            En consecuencia, no podemos garantizar que cualquier información recopilada sobre usted, ya sea durante la transmisión o mientras está almacenada en nuestros sistemas o bajo nuestro cuidado, estará absolutamente a salvo de la intrusión de otras personas que no tengan su autorización. Cualquier transmisión o intercambio de esa información se realiza bajo su propio riesgo.
        </p>
        <p className="my-5">
            También juega un papel en la protección de su información personal. No comparta su nombre de usuario o contraseña con otras personas.
        </p>
        <p className="my-5">
            6. ¿NUESTRAS PLATAFORMAS SOPORTAN SEÑALES DE "NO RASTREAR"?
        </p>
        <p className="my-5">
            En este momento, nuestros sitios web no admiten las preferencias de "No rastrear" que pueden estar disponibles en su navegador para que los sitios web sepan que usted no desea que recopilen ciertos tipos de información. Si activa la configuración "No rastrear" en su navegador, nuestros sitios web no son capaces de seguir las preferencias de No rastrear que establezca.
        </p>
        <p className="my-5">
            7. ¿COMPARTIMOS INFORMACIÓN SOBRE USTED CON TERCEROS PARA SUS PROPIOS PROPÓSITOS DE COMERCIALIZACIÓN DIRECTA?
        </p>
        <p className="my-5">
            No. No divulgaremos sus datos personales a terceros para sus propios fines de marketing directo sin su consentimiento.
        </p>
        <p className="my-5">
            8. ¿CUÁLES SON LAS POLÍTICAS DE LOS SITIOS WEB VINCULADOS Y OTROS TERCEROS?
        </p>
        <p className="my-5">
            Nuestras Plataformas pueden contener enlaces a otros sitios web que no están cubiertos por esta Política de Privacidad y donde las prácticas de información pueden ser diferentes a las nuestras. Nuestra provisión de un enlace a cualquier otro sitio web o ubicación es para su conveniencia y no significa nuestro respaldo a dicho otro sitio web o ubicación o su contenido. Cuando haga clic en dicho enlace, abandonará nuestra Plataforma e irá a otro sitio web. Durante este proceso, otra entidad puede recopilar información sobre usted. No tenemos control, no revisamos y no somos responsables de las prácticas de privacidad o el contenido de esos otros sitios web. Tenga en cuenta que los términos de esta Política de privacidad no se aplican a estos sitios web o contenido externo, ni a ninguna recopilación de datos después de hacer clic en los enlaces a dichos sitios web externos.
        </p>
        <p className="my-5">
            9. ¿CUÁL ES NUESTRA POLÍTICA SOBRE EL USO DE NUESTRAS PLATAFORMAS POR NIÑOS?
        </p>
        <p className="my-5">
            Ninguna de nuestras Plataformas está diseñada o destinada a ser atractiva para niños menores de 18 años, y no recopilamos a sabiendas información de identificación personal de niños menores de 18 años. SI TIENE MENOS DE 18 AÑOS, NO UTILICE NINGUNA DE NUESTRAS PLATAFORMAS EN NINGÚN MOMENTO O DE NINGUNA FORMA.
        </p>
        <p className="my-5">
            10. ¿CÓMO PUEDE CORREGIR, ACTUALIZAR O TOMAR OPCIONES SOBRE LA INFORMACIÓN RECOPILADA SOBRE USTED QUE ESTAMOS ALMACENANDO?
        </p>
        <p className="my-5">
            A. General.
        </p>
        <p className="my-5">
            Si alguno de sus datos personales que estamos almacenando cambia, o si ya no desea nuestros servicios, puede corregir, actualizar o eliminar información sobre usted, o solicitar que se elimine de foros públicos (como directorios, blogs o testimonios) en nuestras Plataformas, llamándonos al +52 33 11421316 o enviándonos un correo electrónico a hola@paradigmanetworks.com, o (para obtener más información) iniciando sesión en su perfil en la Plataforma correspondiente y haciendo cambios en la configuración de su cuenta. Intentaremos responder a su solicitud dentro de los 30 días. En algunos casos, es posible que no podamos eliminar sus datos personales, en cuyo caso nos esforzaremos por informarle que no podemos hacerlo y por qué. Por ejemplo, es posible que se nos exija (por ley o de otro modo) que conservemos cierta información y no la eliminemos (o que conservemos esta información durante un tiempo determinado, en cuyo caso cumpliremos con su solicitud de eliminación solo después de haber cumplido con dichos requisitos).
        </p>
        <p className="my-5">
            B. Desconexión de un SNS.
        </p>
        <p className="my-5">
            Si decide en cualquier momento que ya no desea tener su cuenta de Facebook, Instagram u otra cuenta SNS vinculada a su cuenta de la Plataforma, debe desvincular el SNS de la Plataforma correspondiente iniciando sesión en su perfil para dicho SNS y cambiando sus preferencias. También puede administrar el intercambio de cierta información personal con nosotros cuando se conecta con nosotros a través de un SNS, como a través de Facebook Connect. Consulte la configuración de privacidad del SNS para determinar cómo puede ajustar nuestros permisos y administrar la interactividad entre nuestras Plataformas y su cuenta de redes sociales.
        </p>
        <p className="my-5">
            C. Detención de determinadas comunicaciones de nuestra parte.
        </p>
        <p className="my-5">
            1. Correos electrónicos. Es posible que nos comuniquemos con usted por correo electrónico en varios momentos sobre su cuenta y transacciones, así como sobre diversos asuntos de servicio al cliente, encuestas a clientes y promociones, incluidas ofertas especiales y actualizaciones sobre nuevos productos y servicios. Puede optar por dejar de recibir nuestros correos electrónicos promocionales en cualquier momento simplemente siguiendo el enlace "Cancelar suscripción" o "Administrar preferencias de correo electrónico" en la parte inferior de cualquier correo electrónico promocional, o iniciando sesión en su perfil en la Plataforma correspondiente y cambiando las preferencias de su cuenta. Tenga en cuenta que no puede optar por no recibir correos electrónicos no promocionales relacionados con su cuenta o sus transacciones con nosotros.
        </p>
        <p className="my-5">
            2. Llamadas telefónicas o mensajes de texto exclusivos para el servicio. Si nos proporciona voluntariamente su número de teléfono, estará de acuerdo en que nosotros (incluidos nuestros afiliados y agentes) podamos llamarlo o enviarle un mensaje de texto a ese número (incluso a través de un sistema de marcación automática o mensaje pregrabado) para responder a su solicitud de nuestros servicios y para comunicarnos con usted con respecto a su cuenta o una transacción con nosotros. Tenga en cuenta que pueden aplicarse tarifas estándar de mensajes y datos.
        </p>
        <p className="my-5">
            3. Llamadas telefónicas de marketing. Si le hemos brindado la opción de dar su consentimiento para recibir nuestras llamadas telefónicas de marketing (incluso a través de un sistema de marcación automática o mensajes pregrabados), tenga en cuenta que su decisión de dar su consentimiento para recibir dichas llamadas o mensajes promocionales es voluntaria y no está obligada a comprar o utilizar cualquiera de nuestros productos o servicios. Si ha dado su consentimiento para recibir nuestras llamadas telefónicas de marketing, pero desea revocar dicho consentimiento, puede hacerlo por cualquier medio razonable, incluso llamándonos al +52 33 1142 1316 o enviándonos un correo electrónico a hola@paradigmanetworks.com y declarando que desea revocar dicho consentimiento.
        </p>
        <p className="my-5">
            4. Marketing de mensajes de texto. Si le hemos brindado la opción de dar su consentimiento para recibir mensajes de texto promocionales de nuestra parte (incluso a través de un sistema de marcación automática), tenga en cuenta que su decisión de dar su consentimiento para recibir dichos mensajes de texto promocionales es voluntaria y no es necesario que compre o use ninguno de nuestros productos o servicios, y es posible que se apliquen tarifas estándar de mensajes y datos. Si ha dado su consentimiento para recibir mensajes de texto promocionales de nuestra parte, pero desea revocar dicho consentimiento, puede hacerlo por cualquier medio razonable, incluso llamándonos al +52 33 1142 1316o enviándonos un correo electrónico a hola@paradigmanetworks.com y declarando que desea revocar dicho consentimiento, o respondiendo "STOP" a dicho mensaje.
        </p>
        <p className="my-5">
            11. DIVULGACIONES ADICIONALES PARA USUARIOS CON SEDE EN LA UNIÓN EUROPEA.
        </p>
        <p className="my-5">
            A. General.
        </p>
        <p className="my-5">
            Nuestra sede se encuentra en México. Al utilizar cualquiera de nuestras Plataformas desde fuera de México, usted reconoce que podemos acceder a sus datos personales o transferirlos a nosotros en México y a nuestros afiliados, socios y proveedores de servicios que se encuentran en todo el mundo; y que sus datos personales serán transferidos, almacenados y procesados ​​en México, Estados Unidos o en cualquier otro lugar del mundo donde se encuentran nuestros servidores.
        </p>
        <p className="my-5">
            Si reside en la UE, solo procesaremos sus datos personales para un propósito descrito en esta Política de privacidad si (1) ha brindado su consentimiento (que puede retirarse en cualquier momento), (2) el procesamiento es necesario para la ejecución de un contrato que estamos a punto de celebrar o que hemos celebrado con usted, (3) la ley nos exige, (4) el procesamiento es necesario para proteger sus intereses vitales o los de otra persona, o (5) el procesamiento es necesario para los fines de nuestros intereses comerciales legítimos (excepto cuando dichos intereses sean anulados por sus derechos e intereses).
        </p>
        <p className="my-5">
            B. Derechos bajo el GDPR.
        </p>
        <p className="my-5">
            Si tiene su sede en la UE, en determinadas circunstancias tiene derechos en virtud de las leyes de protección de datos en relación con los datos personales que tenemos sobre usted, específicamente:
        </p>
        <p className="my-5">
            1. Solicitar el acceso a sus datos personales. Puede tener derecho a solicitar acceso a cualquier dato personal que tengamos sobre usted, así como a la información relacionada, incluidos los fines para el procesamiento de los datos personales, los destinatarios o las categorías de destinatarios con los que se han compartido los datos personales, cuando sea posible, el período durante el cual se almacenarán los datos personales, la fuente de los datos personales y la existencia de cualquier toma de decisiones automatizada.
        </p>
        <p className="my-5">
            2. Solicitar la corrección de sus datos personales. Puede tener derecho a obtener sin demora indebida la rectificación de cualquier dato personal inexacto que tengamos sobre usted.
        </p>
        <p className="my-5">
            3. Solicitar el borrado de sus datos personales. Puede tener derecho a solicitar que se eliminen los datos personales que tenemos sobre usted.
        </p>
        <p className="my-5">
            4. Solicitar la restricción del procesamiento de sus datos personales. Puede tener derecho a prevenir o restringir el procesamiento de sus datos personales.
        </p>
        <p className="my-5">
            5. Solicitar la transferencia de sus datos personales. Puede tener derecho a solicitar la transferencia de sus datos personales directamente a un tercero cuando sea técnicamente factible.
        </p>
        <p className="my-5">
            Además, cuando crea que no hemos cumplido con nuestra obligación en virtud de esta Política de privacidad o la ley europea de protección de datos, tiene derecho a presentar una queja ante una Autoridad de Protección de Datos de la UE.
        </p>
        <p className="my-5">
            Puede ejercer cualquiera de estos derechos llamándonos al +52 33 11421 316 o enviándonos un correo electrónico a hola@paradigmanetworks.com
        </p>
        <p className="my-5">
            C. Restricciones sobre el uso de sus datos personales para promocionar productos y servicios de terceros.
        </p>
        <p className="my-5">
            Si reside en la UE, solo le enviaremos comunicaciones de marketing directo relacionadas con productos y servicios de terceros, si ha dado su consentimiento para recibir dicho marketing. Por lo general, le pediremos su consentimiento para recibir dicho marketing cuando proporcione por primera vez sus datos personales.
        </p>
        <p className="my-5">
            Puede optar por dejar de recibir nuestras comunicaciones de marketing directo en cualquier momento simplemente siguiendo el enlace "Cancelar suscripción" o "Administrar preferencias de correo electrónico" en la parte inferior de cualquier correo electrónico promocional, o iniciando sesión en su perfil en la Plataforma correspondiente y haciendo cambios en la configuración de su cuenta. Tenga en cuenta que no puede optar por no recibir correos electrónicos no promocionales relacionados con su cuenta o sus transacciones con nosotros.
        </p>
        <p className="my-5">
            12. ¿CUÁLES SON LOS TÉRMINOS CON RESPECTO A CUALQUIER CAMBIO FUTURO A ESTA POLÍTICA DE PRIVACIDAD?
        </p>
        <p className="my-5">
            Nos reservamos el derecho de actualizar o modificar esta Política de privacidad de vez en cuando, y dichos cambios entrarán en vigor de manera prospectiva. Visite esta página web periódicamente para estar informado de dichos cambios. Su uso continuo de cualquiera de nuestras Plataformas después de cualquier modificación a esta Política de Privacidad constituirá su aceptación de dicha modificación.
        </p>
        <p className="my-5">
            13. ¿QUÉ LEY RIGE CUALQUIER DISPUTAS CON RESPECTO A ESTA POLÍTICA DE PRIVACIDAD?
        </p>
        <p className="my-5">
            Usted acepta que cualquier disputa sobre la privacidad o los términos contenidos en esta Política de privacidad se regirá por la ley del estado Jalisco, México, sin referencia a la elección de la ley o los conflictos de principios legales de la misma, y ​​estará sujeta a los términos y limitaciones del Acuerdo de usuario o cualquier otro acuerdo que podamos tener con usted.
        </p>
        <p className="my-5">
            Si alguna disposición de esta Política de privacidad se considera ilegal, nula o inaplicable debido a la ley aplicable o por orden de un tribunal de jurisdicción competente, se considerará eliminada y la continuación en pleno vigor y efecto del resto de las disposiciones no tener prejuicios.
        </p>
        <p className="my-5">
            Si celebra un contrato con nosotros que requiere, o contempla, recopilar, usar, compartir o asegurar información sobre usted de una manera diferente a la que se describe en esta Política de privacidad, se aplicarán los términos de ese contrato.
        </p>
        <p className="my-5">
            14. ¿CÓMO PUEDE CONTACTARNOS?
        </p>
        <p className="my-5">
            Si tiene alguna pregunta o inquietud con respecto a esta Política de privacidad, comuníquese con nosotros a:
        </p>
        <ul style={{
            listStyleType: 'none',
            marginLeft: '0px',
            paddingLeft: '0px'
        }}>
            <li>Dirección de correo electrónico: hola@paradigmanetworks.com</li>
            <li>Número de teléfono: +52 3311421316</li>
            <li>Dirección postal: Paradigma Networks – Política de privacidad</li>
            <li>Calle José María Vigil 3150</li>
            <li>Interior G</li>
            <li>Lomas de Providencia</li>
            <li>CP 44647</li>
            <li>Guadalajara, Jal</li>
            <li>México</li>
        </ul>
    </div>
}

export default Privacy