import { Formik, Field, Form, ErrorMessage } from "formik"
import { PseudoLink, SecondaryButton } from "../../Components/Button"
import { ToastSuccess, ToastError } from "../../Components/Toast"
import payment from "../../integrations/payment"
import { IconArrowLeft, IconOxxoPay, IconRadioChecked, IconRadioUnChecked, IconLoader, IconCardVisa, IconCardMasterCard, IconCardAMEX, AlertIcon } from "../../Components/Icons"
import { useEffect, useState, useContext } from "react"
import DataGrid from "../Profile/partials/DataGrid"
import assets from "../../Assets"
import ModalDialog from "../../Components/ModalDialog"
import { Context } from '../../store/useGlobalState'
import * as yup from 'yup'

const schema = yup.object().shape({
  cardNumber: yup.string().required('Campo requerido').matches(/^[0-9]{16,16}$/, 'No válido'),
  cardHolder: yup.string().required('Campo requerido'),
  expMonth: yup.number().required('Campo requerido').min(1).max(12),
  expYear: yup.number().required('Campo requerido').min(2000).max(2030),
  cvc: yup.string().required('Campo requerido').matches(/^[0-9]{3,3}$/, 'No válido')
})

const MyErrMsg = msg => <span className="h-px text-xs text-red-500">{msg}</span>

const Payment = ({ toPrevious, toNext, hidden, setPaymentMetod, paymentMetod, isUser }) => {
  const [processingPayment, setProcessingPayment] = useState(false)
  const [paymentList, setPaymentList] = useState([])
  const [showOxxoErr, setShowOxxoErr] = useState(false)
  const { globalState } = useContext(Context)

  const headings = [
    '',
    '',
    'NÚMERO DE TARJETA',
    'TITULAR DE TARJETA',
    'FECHA DE EXPIRACIÓN',
  ]

  useEffect(() => {
    if (isUser) {
      payment.getPaymentMethods()
        .then(({ payment_methods }) => {
          setPaymentList(Array.isArray(payment_methods) ? payment_methods : [])
        }).catch((err) => {
          setPaymentList([])
        });
    }
  }, [])

  const setCardIcon = (cardissuer) => {
    switch (cardissuer) {
      case 'visa': return <IconCardVisa className="h-8 mx-auto" />

      case 'mastercard': return <IconCardMasterCard className="h-8 mx-auto" />

      case 'amex': return <IconCardAMEX className="h-8 mx-auto" />

      default: return cardissuer
    }
  }
  const CustomRow = ({ data }) => (
    <tr className="cursor-pointer" onClick={() => { setPaymentMetod(data) }}>
      <td className="py-4 text-xs tracking-wide text-center"> {paymentMetod === data
        ? <IconRadioChecked className="h-5 mr-3" />
        : <IconRadioUnChecked className="h-5 mr-3" />
      }</td>
      <td className="py-4 text-xs tracking-wide text-center">{setCardIcon(data.brand)}</td>
      <td className="py-4 text-xs tracking-wide text-center">**** **** **** {data.last4}</td>
      <td className="py-4 text-xs tracking-wide text-center">
        {data.name}
      </td>
      <td className="py-4 text-xs tracking-wide text-center">
        {data.exp_month > 9 ? '' : 0 + `${data.exp_month} / ${data.exp_year}`
        }
      </td>

    </tr>
  )

  const OxxoDataValidation = () => {
    const { user } = globalState
    let err = false
    if (user && !user.name && !user.lastname) {
      err = true
    }
    setShowOxxoErr(err)
    return err

  }

  const handleSubmit = async () => {
    setProcessingPayment(true)

    if (paymentMetod?.id) {
      ToastSuccess("tarjeta" + ' seleccionada')
      setProcessingPayment(false)
      toNext()
      return
    }
    else if (paymentMetod) {
      if (paymentMetod === "oxxo") {
        if (!OxxoDataValidation()) {
          setProcessingPayment(false)
          toNext()
        }
      }
      else if (paymentMetod === "paypal") {
        setProcessingPayment(false)
        toNext()
      }
      return
    }
    else {
      ToastError("Debe seleccionar un método de pago")
    }

    setProcessingPayment(false)
  }

  const handleAddPaymentMethodSubmit = async (data, { setSubmitting }) => {
    try {
      let { cardNumber: card, expMonth: exp_month, expYear: exp_year, cvc, cardHolder: name } = data
      exp_month = Number(exp_month)
      exp_year = Number(exp_year)
      card = card.replaceAll(' ', '')
      let { payload } = await payment.addMethod(card, exp_month, exp_year, cvc, name)
      setPaymentMetod(payload)
      ToastSuccess('Se guardó el método de pago correctamente')
      setSubmitting(false)
      toNext()
    } catch (error) {
      ToastError(("Revise la información de la tarjeta"))
    }
  }

  const handleClickMetod = (metodName) => {

    setPaymentMetod(metodName === paymentMetod
      ? null
      : metodName
    )
  }

  const INITIAL_FORM_DATA = {
    cardNumber: '',
    expMonth: '',
    expYear: '',
    cvc: '',
    cardHolder: '',
  }


  let monthsList = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]

  const currentYear = new Date().getFullYear();
  let selectableYears = []
  for (let i = 0; i < 7; i++) {
    selectableYears.push(currentYear + i)
  }

  return <div className={`${hidden && 'hidden'}`}>
    <Formik
      initialValues={INITIAL_FORM_DATA}
      className="flex flex-col mx-auto mb-6 mt-14"
      onSubmit={handleAddPaymentMethodSubmit}
      validationSchema={schema}
    >
      {({ isSubmitting, errors, touched }) => (
        <Form>
          <div className="text-xl leading-loose font-bold text-primary">Agregar método de pago</div>
          <p className="text-primary mt-4">Ingresa la información solicitada a continuación</p>

          <div className="flex flex-col gap-y-4 mt-10">
            <div>
              <label
                className="mb-1 text-xs text-primary"
                htmlFor="cardNumber">
                Número de tarjeta
              </label>
              <Field
                onFocus={() => { setPaymentMetod(null) }}
                maxlength="16"
                className={`text-sm rounded-md w-full text-primary px-3 py-2 border border-primary-light ${errors.cardNumber && touched.cardNumber && 'border-red-500'} h-10 focus:outline-none`}
                name="cardNumber"
                placeholder=""
              />
              <ErrorMessage name="cardNumber" render={MyErrMsg} />
            </div>

            <div>
              <label className="mb-1 text-xs text-primary">
                Nombre en la tarjeta
              </label>
              <Field
                onFocus={() => { setPaymentMetod(null) }}
                className={`text-sm rounded-md w-full text-primary px-3 py-2 border border-primary-light ${errors.cardHolder && touched.cardHolder && 'border-red-500'} h-10 focus:outline-none`}
                name="cardHolder"
              />
              <ErrorMessage name="cardHolder" render={MyErrMsg} />
            </div>

            <div className="grid grid-cols-2 gap-4 ">
              <div>
                <label className="mb-1 text-xs text-primary">
                  Fecha de expiración
                </label>
                <div className="grid grid-cols-2 gap-2">
                  <div>
                    <Field name="expMonth">
                      {({ field }) => (
                        <select
                          onFocus={() => { setPaymentMetod(null) }}
                          {...field}
                          className={`text-sm rounded-md w-full text-primary px-3 py-2 border border-primary-light ${errors.expMonth && touched.expMonth && 'border-red-500'} h-10 focus:outline-none`}
                        >
                          <option value='' />
                          {monthsList.map(m => <option value={m} key={m}>{m}</option>)}
                        </select>
                      )}
                    </Field>
                    <ErrorMessage name="expMonth" render={MyErrMsg} />
                  </div>

                  <div>
                    <Field name="expYear">
                      {({ field }) => (
                        <select
                          onFocus={() => { setPaymentMetod(null) }}
                          {...field}
                          className={`text-sm rounded-md w-full text-primary px-3 py-2 border border-primary-light ${errors.expYear && touched.expYear && 'border-red-500'} h-10 focus:outline-none`}
                        >
                          <option value='' />
                          {selectableYears.map(y => <option value={y} key={y}>{y}</option>)}
                        </select>
                      )}
                    </Field>
                    <ErrorMessage name="expYear" render={MyErrMsg} />
                  </div>
                </div>
              </div>

              <div>
                <label className="mb-1 text-xs text-primary">
                  CVV
                </label>
                <Field
                  onFocus={() => { setPaymentMetod(null) }}
                  className={`text-sm rounded-md w-full text-primary px-3 py-2 border border-primary-light ${errors.cvc && touched.cvc && 'border-red-500'} h-10 focus:outline-none`}
                  name="cvc"
                  maxlength="3"
                />
                <ErrorMessage name="cvc" render={MyErrMsg} />
              </div>
            </div>
          </div>
          <div className="my-4 flex flex-row-reverse">
            {(isSubmitting)
              ? <button className="bg-primary text-white rounded-md px-5 py-3 text-sm" 
              type="button" >
                <IconLoader
                  className="text-white fill-current animate-spin"
                  style={{ height: 20 }}
                />
              </button>
              : <button className="bg-primary text-white rounded-md px-5 py-3 text-sm"
                type="submit"
              >Agregar método de pago</button>
            }
          </div>
        </Form>
      )}
    </Formik>
    <div>
      {isUser && <div className="grid space-y-2 mt-10">
        <span className="mb-1 text-sm">
          Formas de pago:
        </span>
        <div className="overflow-x-auto">
          <DataGrid data={paymentList} headings={headings} row={CustomRow} />
        </div>
      </div>}
      <div className="grid space-y-2 mt-10">
        <span className="mb-1 text-sm text-primary">
          Otros métodos de pago:
        </span>
        <button
          className="border rounded-lg px-4 py-3 flex justify-between items-center text-primary"
          type='button'
          onClick={() => { handleClickMetod('paypal') }}
        >
          {paymentMetod === 'paypal'
            ? <IconRadioChecked className="h-5 mr-3" />
            : <IconRadioUnChecked className="h-5 mr-3" />
          }
          Paypal
          <img style={{ width: 58 }}
            className="h-8 ml-auto mr-2"
            src={assets.PayPal_Logo} />
        </button>

        <button
          className="border rounded-lg px-4 py-3 flex justify-between items-center text-primary"
          type='button'
          onClick={() => { handleClickMetod('oxxo') }}
        >
          {paymentMetod === 'oxxo'
            ? <IconRadioChecked className="h-5 mr-3" />
            : <IconRadioUnChecked className="h-5 mr-3" />
          }
          Oxxo Pay
          <IconOxxoPay className="h-8 ml-auto mr-1" />
        </button>
      </div>

      {/* actions */}
      <div className="flex flex-row justify-between w-full mt-10">
        <PseudoLink className="font-semibold" color="text-primary-light" onClick={() => !isUser ? toPrevious() : {}}>
          {!isUser && <IconArrowLeft
            className="mr-7"
            strokeWidth="1.0"
            style={{ height: 16 }}
          />}
          {!isUser && <span className="-mt-1">Regresar</span>}
        </PseudoLink>

        {processingPayment
          ? <PseudoLink className="bg-secondary text-white rounded-md px-5 py-3 text-sm" 
            type="button" >
            <IconLoader
              className="text-white fill-current animate-spin"
              style={{ height: 20 }}
            />
          </PseudoLink>
          : <PseudoLink
            onClick={handleSubmit}
            className="bg-secondary text-white rounded-md px-5 py-3 text-sm" color="text-white"
            label='Continuar'
          />
        }
      </div>
    </div>
    {
      showOxxoErr && <ModalDialog visible={showOxxoErr} close={() => setShowOxxoErr(prev => !prev)}>
        <div className="flex items-center mb-5">
          <AlertIcon className="w-10 m" />
          <h2 className="ml-3 font-bold">Datos incompletos</h2>
        </div>
        <p>Para realizar un pago en OXXO, es necesario completar sus datos personales en perfil de usuario</p>
      </ModalDialog>
    }
  </div>
}
export default Payment
