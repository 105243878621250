import React from 'react';
import { Field, useFormikContext } from 'formik';
import { PHOE_CODES } from '../../utils/misc';

export const PHONE_INITIAL_VALUES = {
  country_code: '',
  phone: '',
}

const PhoneInput = ({disabled}) => {
  const {errors, touched} = useFormikContext();

  return (
    <div className="flex">
      <Field name="country_code">
        {({field}) => (
          <select
            {...field}
            disabled={disabled}
            className={`flex-1 text-sm text-primary bg-white rounded-l-md border-r-0 w-full h-9 py-2 border border-primary-light ${errors.country_code && touched.country_code ? 'border-red-500' : ''} h-10 focus:outline-none focus:border-secondary`}
          >
            <option value='' />
            {PHOE_CODES.sort((a, b) => a.name.toLocaleLowerCase().localeCompare(b.name.toLocaleLowerCase())).map( ({name, dial_code}) =>
              <option key={name} value={dial_code}>{`(${dial_code}) ${name}`}</option>
            )}
          </select>
        )}
      </Field>
      <Field
        disabled={disabled}
        name="phone"
        className={`flex-4 text-sm text-primary rounded-r-md w-full px-3 py-2 border border-primary-light ${errors.phone && touched.phone ? 'border-red-500' : ''} h-10 focus:outline-none focus:border-secondary`}
        placeholder="0000000000"
        maxlength="10"
      />
    </div>
  );
}
export default PhoneInput