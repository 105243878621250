
const DataRow = ({values,bold}) => {
   return <tr>
      {values.map((value, index) => (
         <td
            key={index}
            className={`px-2 py-4 text-xs whitespace-nowrap text-primary text-center ${bold?"font-semibold":''}`}
            >
            {value}
         </td>
      ))}
   </tr>
}

export const DataGrid = ({ headings, data, className="w-full overflow-x-auto", rowParserer, bold}) => (
   <div className={className}>
      <table className="w-full divide-y divide-gray-200 border-b border-gray-200 text-xs">
         {headings && <thead>
            <tr>
               {headings.map((heading, i) => 
                  <th key={i}
                     scope="col"
                     className="px-6 py-3 font-semibold text-primary uppercase"
                     >
                     {heading}
                  </th>
               )}
            </tr>
         </thead>}
         <tbody>
            {data.map((rowData) =>
               <DataRow key={rowData._id} values={rowParserer(rowData)} />
            )}
         </tbody>
      </table>
   </div>
)
export default DataGrid
