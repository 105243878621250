import React, { useState } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik"
import * as yup from 'yup'
import qs from "qs";
import authIntegration from "../integrations/auth"
import { parseErrorMsg } from "../utils/misc";
import { IconEyeOff, IconEye, IconLoader, IconRoundCheck } from "../Components/Icons"
import { useHistory } from 'react-router-dom'
import Dialog from "../Layouts/Dialog"
import Recommended from "../Components/Recommended";

const MyErrMsg = msg => <span className="text-xs text-red-500 h-px">{msg}</span>

const SuccesDialog = () => {
    const history = useHistory();

    return <Dialog
        onClose={() => { history.push('/') }}
        darkOverlay
        className=""
        dismisable
        render={({ closeDialog }) => {
            return (
                <div className="flex flex-col z-10 max-w-2xl bg-white py-9 px-20 rounded-2xl">
                    {/* title */}
                    <div className="flex flex-col w-full text-lg text-center text-primary">
                        <h1 className="flex-col text-2xl font-bold">Contraseña restablecida</h1>
                        <p className="flex-col pt-2 text-base whitespace-normal">Ya puedes iniciar sesión y continuar explorando 100 Tutores.</p>
                    </div>

                    {/* content */}
                    <div className="flex flex-row justify-center mt-6 mb-12 text-primary">
                        <IconRoundCheck />
                    </div>
                    {/* actions */}
                    <div className="flex flex-row justify-center">
                        <button
                            onClick={closeDialog}
                            className="w-48 py-3 text-sm rounded-md bg-primary text-white text-center tracking-wider focus:outline-none"
                        >
                            Continuar
                        </button>
                    </div>
                </div>
            )
        }}
    />
}

const ResetPassword = ({ location }) => {
    const [succes, setSucces] = useState(false)
    const [visiblePass, setVisiblePass] = useState(false)
    const [reqError, setReqError] = useState(null)

    const INITIAL_FORM_DATA = {
        newPass: '',
        confirmPass: '',
    }

    let schema = yup.object().shape({
        newPass: yup.string().min(6, "Mínimo 6 caracteres").required("Campo requerido"),
        confirmPass: yup.string().required("Campo requerido"),
    })

    const { token } = qs.parse(location.search, { ignoreQueryPrefix: true })

    const handleSubmit = async (data, { setSubmitting, setFieldError }) => {
        setReqError(null)
        // data has been validated
        if (data.newPass !== data.confirmPass) {
            setFieldError('confirmPass', 'los valores no coinciden')
            setSubmitting(false)
            return
        }
        try {
            // throws error if data is duplicated 
            await authIntegration.changePassword({ token, password: data.newPass })
            setSucces(true)
        } catch (error) {
            const errStr = parseErrorMsg(error)
            console.log(errStr);
            setReqError(errStr)
        }
        setSubmitting(false)
    }

    return <div className="pt-4 flex flex-col w-full items-center">
        <h1 className="text-xl mb-2 text-primary">Restablece tu contraseña</h1>
        <div>Para conectar con alguno de nuestros tutores, ingresa una nueva contraseña e inicia sesión</div>
        {reqError && <span className="text-md text-red-500 h-px">{reqError}</span>}

        {succes && <SuccesDialog />}

        <Formik
            initialValues={INITIAL_FORM_DATA}
            validationSchema={schema}
            onSubmit={handleSubmit}
        >
            {({ isSubmitting }) => (
                <Form className="mt-11 mb-8 w-116 text-black">
                    { /* inputs */}
                    <div>
                        <label
                            className="block text-xs font-semibold mb-1 text-primary"
                            htmlFor="pasword">
                            Nueva contraseña
                        </label>
                        <Field name="newPass">
                            {({ field, meta: { error, touched } }) => (
                                <div className="relative">
                                    <input
                                        {...field}
                                        className={`text-sm text-primary rounded-md w-full px-3 py-2 border ${error && touched ? 'border-red-500' : 'border-primary-light'} h-10 focus:outline-none`}
                                        type={visiblePass ? 'text' : 'password'}
                                        value={field.value}
                                    />
                                    <div className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5 text-primary">
                                        {visiblePass
                                            ? <IconEyeOff
                                                onClick={() => { setVisiblePass(false) }}
                                                style={{ height: 20 }}
                                            />
                                            : <IconEye
                                                onClick={() => { setVisiblePass(true) }}
                                                style={{ height: 20 }}
                                            />
                                        }
                                    </div>
                                </div>
                            )}
                        </Field>
                        <ErrorMessage name="newPass" render={MyErrMsg} />
                    </div>
                    <div className="mt-5">
                        <label
                            className="block text-xs font-semibold mb-1 text-primary"
                            htmlFor="pasword">
                            Confirmar nueva contraseña
                        </label>
                        <Field name="confirmPass">
                            {({ field, meta: { error, touched } }) => (
                                <div className="relative z-0">
                                    <input
                                        {...field}
                                        className={`text-sm text-primary rounded-md w-full px-3 py-2 border ${error && touched ? 'border-red-500' : 'border-primary-light'} h-10 focus:outline-none`}
                                        type={visiblePass ? 'text' : 'password'}
                                        value={field.value}
                                    />
                                    <div className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5 text-primary">
                                        {visiblePass
                                            ? <IconEyeOff
                                                onClick={() => { setVisiblePass(false) }}
                                                style={{ height: 20 }}
                                            />
                                            : <IconEye
                                                onClick={() => { setVisiblePass(true) }}
                                                style={{ height: 20 }}
                                            />
                                        }
                                    </div>
                                </div>
                            )}
                        </Field>
                        <ErrorMessage name="confirmPass" render={MyErrMsg} />
                    </div>

                    {/* actions */}
                    <div className="w-full flex flex-1 flex-row justify-end mt-10 text-xs">
                        <button className="px-6 py-3 mr-5 tracking-wider focus:outline-none rounded-md bg-primary-lightest text-primary"
                        >Cancelar</button>
                        {isSubmitting
                            ? <button
                                type="button"
                                className="w-50 px-3 py-3 rounded-md bg-primary text-white flex justify-center">
                                <IconLoader
                                    className="text-white fill-current animate-spin"
                                    style={{ height: 20 }}
                                />
                            </button>
                            : <button className="w-50 px-3 py-3 rounded-md bg-primary text-white">
                                Restablecer contraseña
                            </button>
                        }
                    </div>
                </Form>
            )}
        </Formik>

        <Recommended />
    </div>
}
export default ResetPassword
