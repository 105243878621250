import React, { useEffect, useState, useContext } from "react";
import { IconLoader } from "../Icons";
import timezoneList from '../../utils/timezone'
import { ToastError } from "../Toast"
import moment from 'moment'
import hoursList from "../../utils/hoursList"
import appointmentsApi from "../../integrations/appointments"
import Loader from "../Loader"
import { Context } from '../../store/useGlobalState'
import experts from "../../integrations/expert";
import { Listbox } from "@headlessui/react";

const timeFormat = "HH:mm"

const weekDays = {
    '0': 'Sun',
    '1': 'Mon',
    '2': 'Tue',
    '3': 'Wed',
    '4': 'Thu',
    '5': 'Fri',
    '6': 'Sat'
}

const durationOptions = [
    { value: -1, label: 'Seleccionar' },
    { value: 10, label: "10 minutos" },
    { value: 15, label: "15 minutos" },
    { value: 30, label: "30 minutos" },
    { value: 45, label: "45 minutos" },
    { value: 60, label: "60 minutos" },
    { value: 90, label: "90 minutos" },
    { value: 120, label: "120 minutos" }
]

const expertMethodsMap = {
    'phone_call': 'LLamada telefónica',
    'video_call': 'Videollamada',
    'internet_call': 'Llamada vía internet',
    '': 'Seleccionar'
}

// is between
const isBetween = function (a, b, c, closed = true) {
    const a_h = a.hour() * 100 + a.minute()
    const b_h = b.hour() * 100 + b.minute()
    const c_h = c.hour() * 100 + c.minute()
    if (closed) return (a_h >= b_h && a_h <= c_h)
    return (a_h > b_h && a_h < c_h)
}

const Schedule = ({ hide, selectDate, setTime, setTimezone, timezone,
    time, expert, duration, setDuration, onConfirm, sessionType,
    setSessionType, saving, calculatingInfo, setCalculatingInfo,
    expertIsFull, setExpertIsFull }) => {
    const [hours, setHours] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const [expertTimeZoneString, setExpertTimeZone] = useState(undefined)
    const [localTimeZoneString, setLocalTimeZone] = useState(undefined)
    const [expertMethods, setExpertMethods] = useState([])
    const [loadingExpertMethods, setLoadingExpertMethods] = useState(false)

    const { globalState } = useContext(Context)
    const { user } = globalState
    const month = moment(selectDate).format("MMMM")
    const dayYear = moment(selectDate).format("dddd DD, YYYY")

    // expert appointments flow
    const DATES_APPOINTMENTS_DATA = {}
    let USER_APPOINTMENTS_DATA = [];


    const timeValidation = (start, now=moment()) => {
        // check if the select date is after the current date
        if (now.isBefore(selectDate)) return true
        const s_n = start.hour() * 100 + start.minute()
        const n_n = now.hour() * 100 + now.minute()

        return n_n < s_n
    }

    useEffect(() => {
        if (expert) {
            setTimezone(expert?.schedule?.timezone)
        }
    }, [])

    useEffect(async () => {
        if (expert) {
            setCalculatingInfo(true)
            setTime(null)
            setHours([])

            const expertTimeZone = timezoneList.find(x => x.value === expert?.schedule?.timezone)
            // expert timezone cannot be null
            if (!expertTimeZone) throw Error("Error setting the expert timezone")
            setExpertTimeZone(expertTimeZone.utc[0])

            const localTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
            // local timezone cannot be null
            if (!localTimeZone) throw Error("Error setting the local timezone")
            setLocalTimeZone(localTimeZone)

            await updateAppointmentsList(localTimeZone, expertTimeZone.utc[0], selectDate)

            const morning_shift = expert?.schedule?.morning_shift
            const afternoon_shift = expert?.schedule?.afternoon_shift
            let workDays = []
            if (expert.schedule && expert.schedule.days)
                workDays = expert.schedule.days

            const morning_shift_start = morning_shift.start != "na" ? moment(morning_shift.start, timeFormat) : moment('00:00', timeFormat)
            const morning_shift_end = morning_shift.end != "na" ? moment(morning_shift.end, timeFormat) : moment('00:00', timeFormat)

            const afternoon_shift_start = afternoon_shift.start != "na" ? moment(afternoon_shift.start, timeFormat) : moment('00:00', timeFormat)
            const afternoon_shift_end = afternoon_shift.end != "na" ? moment(afternoon_shift.end, timeFormat) : moment('00:00', timeFormat)

            const hoursArray = hoursList().filter(x => {
                const hourTime = moment.tz(x, timeFormat, localTimeZone)

                // check if the user already has an appointment at the time
                const sch = checkScheduleAtTime(hourTime, localTimeZone);
                if (sch) return false;

                const currentTime = moment.tz(selectDate, localTimeZone)
                currentTime.set("hours", hourTime.hour())
                    .set("minutes", hourTime.minute())
                    .set("seconds", 0)
                const currentEndTime = currentTime.clone().add(duration - 1, "minutes")

                // check if the meeting extends to the next day
                if (currentTime.format("YYYY-MM-DD") !== currentEndTime.format("YYYY-MM-DD")) return false

                const expertCurrentTime = currentTime.clone().tz(expertTimeZone.utc[0])
                const expertCurrentEndTime = expertCurrentTime.clone().add(duration, "minutes")

                const day = weekDays[Number.parseInt(expertCurrentTime.format('d'))]

                // check if the current time is in the work days of the expert
                if (!workDays.includes(day)) return false

                // check if the current time is in the schedule of the expert
                if (morning_shift_start && morning_shift_end && afternoon_shift_start && afternoon_shift_end) {
                    if (!(isBetween(expertCurrentTime, morning_shift_start, morning_shift_end, true) ||
                        isBetween(expertCurrentTime, afternoon_shift_start, afternoon_shift_end, true)))
                        return false

                    if (!(isBetween(expertCurrentEndTime, morning_shift_start, morning_shift_end, true) ||
                        isBetween(expertCurrentEndTime, afternoon_shift_start, afternoon_shift_end, true)))
                        return false
                }

                // check if there are not appointments at the time
                const app = checkAppointsmentsAtTime(expertCurrentTime, expertTimeZone.utc[0])
                //if (app !== undefined) console.log("app: ", app, ", for: ", currentTime.format())
                return app === undefined
            })

            setHours(duration > 0 ? hoursArray : [])
            setIsLoading(false)
            setCalculatingInfo(false);

            (async ()=>{
                const validHours = hoursArray.filter(item => timeValidation(moment.tz(item, timeFormat, localTimeZoneString)))

                if (validHours?.length == 0 && duration>0) {
                    setExpertIsFull(true)
                    ToastError("Lo sentimos. El experto ya no tiene espacio en su agenda para una cita con la duración seleccionada.")
                } else {
                    setExpertIsFull(false)
                }
            })();
        }
    }, [selectDate, timezone, duration])

    const updateAppointmentsList = async (localTimeZone, expertTimeZone, selectDate) => {
        const selectDateMoment = moment(selectDate, localTimeZone)
        const init = moment.tz("00:00", timeFormat, localTimeZone)
        init.set("year", selectDateMoment.year()).set("month", selectDateMoment.month()).set("date", selectDateMoment.date())
        const end = moment.tz("23:55", timeFormat, localTimeZone)
        end.set("year", selectDateMoment.year()).set("month", selectDateMoment.month()).set("date", selectDateMoment.date())
        const exInit = init.clone().tz(expertTimeZone)
        const exEnd = end.clone().tz(expertTimeZone)
        const exInitDate = exInit.format("YYYY/MM/DD")
        const exEndDate = exEnd.format("YYYY/MM/DD")

        if (!DATES_APPOINTMENTS_DATA[exInitDate]) {
            try {
                DATES_APPOINTMENTS_DATA[exInitDate] = await appointmentsApi.expertSchedule(expert._id, exInitDate)
            } catch (error) {
                DATES_APPOINTMENTS_DATA[exInitDate] = []
            }
        } if (!DATES_APPOINTMENTS_DATA[exEndDate]) {
            try {
                DATES_APPOINTMENTS_DATA[exEndDate] = await appointmentsApi.expertSchedule(expert._id, exEndDate)
            } catch (error) {
                DATES_APPOINTMENTS_DATA[exEndDate] = []
            }
        }

        // get the user appointments
        try {
            USER_APPOINTMENTS_DATA = await appointmentsApi.userSchedule({
                startDate: selectDateMoment.format("YYYY/MM/DD")
            });
        } catch (error) {
            USER_APPOINTMENTS_DATA = []
        }
    }

    const checkScheduleAtTime = (time, localTimeZone) => {
        if (USER_APPOINTMENTS_DATA.length == 0) return undefined

        const endTime = time.clone().add(duration, "minutes")
        const notAvailable = USER_APPOINTMENTS_DATA.find(appointment => {
            const appointmentStart = moment.tz(appointment.time, timeFormat, appointment.timeZone).tz(localTimeZone)
            const appointmentEnd = appointmentStart.clone().add(appointment.duration, "minutes")
            //console.log("checking: ", appointmentStart.format(), ", time: ", time.format())
            if (time.format(timeFormat) === appointmentStart.format(timeFormat)) {
                return appointment
            }
            else if (isBetween(time, appointmentStart, appointmentEnd, false) || isBetween(endTime, appointmentStart, appointmentEnd, false)) {
                return appointment
            }
            else if (isBetween(appointmentStart, time, endTime, false) || isBetween(appointmentEnd, time, endTime, false)) {
                return appointment
            }
        })

        if (notAvailable && notAvailable?.status && notAvailable?.status === 'cancelled') return undefined

        return notAvailable
    }

    const checkAppointsmentsAtTime = (time, expertTimeZone) => {
        const date = time.format("YYYY/MM/DD")
        const endTime = time.clone().add(duration, "minutes")

        if (!DATES_APPOINTMENTS_DATA[date]) return false

        const notAvailable = DATES_APPOINTMENTS_DATA[date].find(appointment => {
            const appointmentStart = moment.tz(appointment.time, timeFormat, expertTimeZone)
            const appointmentEnd = appointmentStart.clone().add(appointment.duration, "minutes")
            //console.log("checking: ", appointmentStart.format(), ", time: ", time.format())
            if (time.format(timeFormat) === appointmentStart.format(timeFormat)) {
                return appointment
            }
            else if (isBetween(time, appointmentStart, appointmentEnd, false) || isBetween(endTime, appointmentStart, appointmentEnd, false)) {
                return appointment
            }
            else if (isBetween(appointmentStart, time, endTime, false) || isBetween(appointmentEnd, time, endTime, false)) {
                return appointment
            }
        })

        if (notAvailable && notAvailable?.status && notAvailable?.status === 'cancelled') return undefined

        return notAvailable
    }

    const handleDurationOnChange = (value) => {
        setDuration(value)
        setTime("")
    }

    const loadExpertMethods = async (e) => {
        setLoadingExpertMethods(true)

        try {
            const availability = await experts.checkAvailable2(expert._id)
            setExpertMethods(Object.keys(availability.methods).filter(method => availability.methods[method]))
        } catch (error) { }

        setLoadingExpertMethods(false)
    }

    if (isLoading) {
        return <Loader />
    }

    return <div className="flex flex-col w-full justify-between  relative">
        {!hide && <div className="w-10 overflow-hidden   absolute z-10  left-20" style={{
            top: "-0.95rem"
        }}>
            <div className="h-6 w-6 rotate-45 bg-white transform origin-bottom-left border border-gray-300"></div>
        </div>}

        <div className={hide ? "rounded-md border-gray-300 border w-full px-6 py-5 " :
            " rounded-md border-gray-300 border w-full px-6 py-5 mt-2"}>
            <div className="text-left text-gray-400 text-lg text-center pt-2 capitalize">{month}</div>
            <div className="text-left text-secondary text-xl text-center pt-2 pb-2 capitalize">{dayYear}</div>
            <div className="self-start flex flex-col">
                <label className="mb-1 text-xs text-left text-primary mt-2">
                    Tipo de sesión
                </label>
                <Listbox onChange={(e) => {
                    setSessionType(e)
                }} value={sessionType}>
                    <div className="relative mt-1" >
                        <Listbox.Button className='w-full h-9 bg-white border border-primary-light rounded-md text-sm '>
                            <div className="w-full py-2 text-left text-primary px-2 flex justify-between"
                                onClick={loadExpertMethods}>
                                <span>{sessionType ? expertMethodsMap[sessionType] : 'Seleccionar'}</span>
                                <span>&#9662;</span>
                            </div>
                        </Listbox.Button>
                        <Listbox.Options className="absolute w-full py-1 mt-1 overflow-auto text-base bg-white rounded-md border max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
                            style={{ zIndex: '10' }}>
                            {
                                loadingExpertMethods ? (
                                    <Listbox.Option
                                        className={({ active }) =>
                                            `${active ? 'bg-gray-200' : 'text-gray-900'}
                          cursor-default select-none relative p-2 text-left`
                                        }
                                        value={''}
                                    >
                                        {({ selected, active }) => (
                                            <>
                                                <span
                                                    className={`${selected ? 'font-medium' : 'font-normal'
                                                        } block truncate text-primary`}
                                                >
                                                    Actualizando disponibilidad...
                                                </span>
                                            </>
                                        )}
                                    </Listbox.Option>
                                ) : (
                                    <>
                                        {
                                            ([...[''], ...expertMethods].filter(it => it=='video_call'))?.map((method, idx) => (
                                                <Listbox.Option
                                                    key={`expert-method-${method}-${idx}`}
                                                    className={({ active }) =>
                                                        `${active ? 'bg-gray-200' : 'text-primary'}
                          cursor-default select-none relative p-2 text-left`
                                                    }
                                                    value={method}
                                                >
                                                    {({ selected, active }) => (
                                                        <>
                                                            <span
                                                                className={`${selected ? 'font-medium' : 'font-normal'
                                                                    } block truncate`}
                                                            >
                                                                {expertMethodsMap[method]}
                                                            </span>
                                                        </>
                                                    )}
                                                </Listbox.Option>
                                            ))
                                        }
                                    </>
                                )
                            }
                        </Listbox.Options>
                    </div>
                </Listbox>
                <label className="mb-1 text-xs text-left text-primary mt-4">
                    Duración
                </label>
                <Listbox onChange={(e) => {
                    setDuration(e)
                }} value={sessionType}>
                    <div className="relative mt-1" >
                        <Listbox.Button className='w-full h-9 bg-white border border-primary-light rounded-md text-sm '>
                            <div className="w-full py-2 text-left text-primary px-2 flex justify-between">
                                <span>{duration > 0 ? duration : 'Seleccionar'}</span>
                                <span>&#9662;</span>
                            </div>
                        </Listbox.Button>
                        <Listbox.Options className="absolute w-full py-1 mt-1 overflow-auto text-base bg-white rounded-md border max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
                            style={{ zIndex: '10', marginTop:'-17.25rem' }}>
                            {
                                durationOptions?.map((x, i) => (
                                    <Listbox.Option
                                        key={`duration-key-opt-${i}`}
                                        className={({ active }) =>
                                            `${active ? 'bg-gray-200' : 'text-primary'}
              cursor-default select-none relative p-2 text-left`
                                        }
                                        value={x.value}
                                    >
                                        {({ selected, active }) => (
                                            <>
                                                <span
                                                    className={`${selected ? 'font-medium' : 'font-normal'
                                                        } block truncate`}
                                                >
                                                    {x.label}
                                                </span>
                                            </>
                                        )}
                                    </Listbox.Option>
                                ))
                            }
                        </Listbox.Options>
                    </div>
                </Listbox>
                <label className="mb-1 text-xs text-left text-primary mt-4">
                    Horario de atención
                </label>
                <Listbox disabled={hours?.length==0} onChange={(e) => {
                    setTime(e)
                }} value={time}>
                    <div className="relative mt-1" >
                        <Listbox.Button className='w-full h-9 bg-white border border-primary-light rounded-md text-sm '>
                            <div className="w-full py-2 text-left text-primary px-2 flex justify-between">
                                <span>{time ? moment(time, timeFormat).format("h:mm A") : 'Seleccionar'}</span>
                                <span>&#9662;</span>
                            </div>
                        </Listbox.Button>
                        <Listbox.Options className="absolute w-full py-1 mt-1 overflow-auto text-base bg-white rounded-md border h-60 max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
                            style={{ zIndex: '10', marginTop:hours.length>0?'-17.25rem':'0px' }}>
                            {
                                ([...[''], ...hours])?.map((schedule, i) => {
                                    if (schedule==='') return null;

                                    const time = moment.tz(schedule, timeFormat, localTimeZoneString)
                                    const finishAt = time.clone().add(duration, "minutes")
                                    
                                    return (timeValidation(time, moment()) &&
                                        <Listbox.Option
                                            key={`time-key-${schedule}-${i}`}
                                            className={({ active }) =>
                                                `${active ? 'bg-gray-200' : 'text-primary'}
              cursor-default select-none relative p-2 text-left`
                                            }
                                            value={time.format(timeFormat)}
                                        >
                                            {({ selected, active }) => (
                                                <>
                                                    <span
                                                        className={`${selected ? 'font-medium' : 'font-normal'
                                                            } block truncate`}
                                                    >
                                                        {`${time.format("h:mm A")} - ${finishAt.format("h:mm A")}`}
                                                    </span>
                                                </>
                                            )}
                                        </Listbox.Option>
                                    )
                                })
                            }
                        </Listbox.Options>
                    </div>
                </Listbox>
                {!hide && <button
                    onClick={() => {
                        if (!user) {
                            ToastError("Debe de iniciar sesión para poder agendar")
                        }
                        else if (time && timezone)
                            onConfirm(duration)
                        else {
                            ToastError("Por favor completa la información")
                        }
                    }}
                    disabled={saving || calculatingInfo}
                    className={`${(saving || calculatingInfo) ? 'bg-riptideGray text-primary' : 'bg-secondary text-white'}
                            px-2 py-3 mx-auto w-full rounded mt-6 flex items-center justify-center`} >
                    {
                        saving ? (
                            <IconLoader
                                className="text-primary fill-current animate-spin"
                                style={{ height: 20 }}
                            />
                        ) : ('Agendar cita')
                    }
                </button>}
            </div>
        </div>
    </div>
}
export default Schedule