import { useEffect, useState } from "react"
import { IconRadioChecked, IconRadioUnChecked } from "../Icons"

const RadioButton = ({ onClick, checked, className }) => {
   const [isChecked, setIsChecked] = useState(checked)

   useEffect(()=>{
      setIsChecked(checked)
   }, [checked])

   const handleCheck = () => {
      setIsChecked(true)
      if (typeof onClick === 'function') {
         onClick(true)
      }
   }

   const handleUnCheck = () => {
      setIsChecked(false)
      if (typeof onClick === 'function') {
         onClick(false)
      }
   }

   // Radio unchecked
   if (!isChecked) {
      return <IconRadioUnChecked className={className} onClick={handleCheck} />
   }

   // Radio checked
   return <IconRadioChecked className={className} onClick={handleUnCheck} />
}

export default RadioButton