import { useEffect, useState } from "react";
import payment from "../../integrations/payment";
import DataGrid from "../../Pages/Profile/partials/DataGrid";
import { IconCardAMEX, IconCardMasterCard, IconCardVisa } from "../Icons";
import RadioButton from "../RadioButton";

const CardTypeMap = {
   'credit': 'Tarjeta de crédito',
   'debid': 'Tarjeta de débito',
   'prepaid': 'Tarjeta bancaria',
   'unknown': 'Tarjeta bancaria',
   'undefined': 'Tarjeta bancaria'
}

export const ListCards = ({ onChange, selectedid, updated }) => {
   const [isLoading, setIsLoading] = useState(true)
   const [paymentId, setPaymentId] = useState(selectedid)
   const [paymentList, setPaymentList] = useState([])
   const [lastUpdate, setLastUpdate] = useState(updated)


   const loadPaymentList = () => {
      payment.getPaymentMethods()
         .then(({ payment_methods }) => {
            setPaymentList(Array.isArray(payment_methods) ? payment_methods : [])
            setIsLoading(false)
         }).catch((err) => {
            setPaymentList([])
            setIsLoading(false)
         });
   }

   useEffect(() => {
      setPaymentId(selectedid)
   }, [selectedid])

   useEffect(() => {
      setLastUpdate(updated)
      loadPaymentList()
   }, [updated])


   const setCardIcon = (cardissuer) => {
      switch (cardissuer) {
         case 'visa': return <IconCardVisa className="h-8" />

         case 'mastercard': return <IconCardMasterCard className="h-8" />

         case 'amex': return <IconCardAMEX className="h-8" />

         default: return cardissuer
      }
   }


   const CustomRow = ({ data }) => (
      <tr className="cursor-pointer" onClick={() => {
         if (typeof onChange === 'function') {
            setPaymentId(data.id)
            onChange({
               id: data.id,
               name: CardTypeMap[data?.funding],
               number: data.last4
            })
         }
      }}>
         <td className="py-3 pl-6 lg:pl-8"><RadioButton checked={paymentId === data.id} className="h-6" /> </td>
         <td className="py-3 text-xs text-center">{setCardIcon(data.brand)}</td>
         <td className="py-3 text-xs">{data.name}</td>
         <td className="py-3 pr-6 text-xs text-right lg:pr-8">**** {data.last4}</td>
      </tr >
   )

   return (
      <section className="mt-1">
         {isLoading === true ? 'Espera un momento...'
            :
            paymentList.length === 0
               ?
               <div className="py-2 mt-2 text-center text-riptideGray2">
                  <h1 className="mb-6 text-2xl">No tienes formas de pago</h1>
                  <p className="mx-auto lg:max-w-lg">Te sugerimos agregar una forma de pago para poder realizar tus transacciones en el portal de forma más rápida.</p>
               </div>
               : <DataGrid data={paymentList} row={CustomRow} lastUpdate={lastUpdate} />
         }
      </section>
   )
}

export default ListCards