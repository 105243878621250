import { useEffect, useState } from "react"
import DataGrid from "../../Pages/Profile/partials/DataGrid"
import { IconOxxoPay } from "../Icons"
import RadioButton from "../RadioButton"
import assets from "../../Assets"

const paymethods = [
   {
      id: "paypal",
      name: "Paypal",
      icon: <img style={{ width: 58 }}
         className="h-8 ml-auto mr-2"
         src={assets.PayPal_Logo} />
   },
   {
      id: "oxxopay",
      name: "Oxxo Pay",
      icon: <IconOxxoPay className="h-8 ml-auto mr-0" />,
      caption: 'La transacción puede tardar hasta 48 hrs'
   }
]

const OtherMethods = ({ onChange, selectedid }) => {
   return (
      <>
         <section className="flex flex-row mt-8 mb-4" >
            <div className="flex-col flex-grow">
               <p className="text-sm text-primary">Otros métodos de pago</p>
            </div>
         </section>
         <section>
            {
               paymethods?.map(data => (
                  <div className="flex items-center justify-between cursor-pointer my-2 rounded-md border border-riptideGray2"
                     key={data?.id}
                     onClick={() => {
                        if (typeof onChange === 'function') {
                           //setPaymentId(data.id)
                           onChange({
                              id: data.id,
                              name: data.name,
                              number: ''
                           })
                        }
                     }}>
                     <div className="inline-flex items-center">
                        <div className="w-10 py-3 pl-6 lg:pl-8">
                           <RadioButton checked={data?.id == selectedid} className="h-6" />
                        </div>
                        <div className="py-3 pl-6 text-primary">
                           <div>{data.name}</div>
                           {
                              (data?.caption) && (
                                 <div>
                                    <small className="text-primary">
                                       {data.caption}
                                    </small>
                                 </div>
                              )
                           }
                        </div>
                     </div>
                     <div className="py-3 pr-6 text-xs text-gray-600 lg:pr-8">{data.icon}</div>
                  </div>
               ))
            }
         </section>
      </>
   )
}

export default OtherMethods