import React, { useState, useContext } from 'react'
import * as yup from 'yup'
import { Formik, Field, ErrorMessage } from "formik"
import { IconFacebook, IconLoader, IconEye, IconEyeOff } from "../../Icons"
import authIntegration from "../../../integrations/auth"
import { parseErrorMsg } from "../../../utils/misc";
import { Context, StoreActions } from '../../../store/useGlobalState'
import { setAxiosToken } from "../../../utils/axiosConfig"
import MyLocalStorage, { lsKeys } from "../../../utils/localStorage"
import { ToastError, ToastSuccess } from '../../Toast'
import ReactFacebookLogin from 'react-facebook-login'

const INITIAL_VALUES = {
    username: '',
    email: '',
    password: ''
}

let schema = yup.object().shape({
    username: yup.string().min(3, "Mínimo 3 caracteres").required("Campo requerido"),
    email: yup.string().email('Email no válido').required("Campo requerido"),
    password: yup.string().min(6, "Mínimo 6 caracteres").required("Campo requerido"),
})

export const RegisterPanel = ({ hideSelf }) => {
    let [visiblePass, setvisiblePass] = useState(false)
    const [iconIndex, setIconIdex] = useState(0)
    const { globalDispatch } = useContext(Context)

    const facebookIcons = [
        <IconFacebook className="mr-4 text-white fill-current" style={{ height: 20 }} />,
        <IconLoader className="text-white fill-current animate-spin" style={{ height: 20 }} />
    ]

    const handleSubmit = async (data, { setSubmitting, setFieldError }) => {
        try {
            const response = await authIntegration.shortRegister(data)
            ToastSuccess("El registro del usuario se realizó correctamente")
            globalDispatch({ type: StoreActions.LOGIN, payload: response })
            setAxiosToken(response.token)
            MyLocalStorage.save(lsKeys.sesion, response)
        } catch (err) {
            console.log('error at register:', parseErrorMsg(err))

            if (err.response?.status === 409) {
                const response = err.response.data
                if (response.fields?.email)
                    setFieldError('email', 'Este correo ya existe')
                if (response.fields?.username)
                    setFieldError('username', 'Este usuario ya existe')
            }
            else {
                setFieldError('general', parseErrorMsg(err))
            }
        }
        setSubmitting(false)
    }

    const responseFacebook = async (response) => {
        const { id, name, email, first_name, last_name } = response

        if (!(id && name && email)) {
            ToastError("Ocurrió un error realizando el registro, por favor intente más tarde")
            setIconIdex(0)
            return
        }

        try {
            const response = await authIntegration.shortRegister({
                username: email,
                email: email,
                facebook_id: id,
                name: first_name,
                lastname: last_name
            })

            ToastSuccess("El registro del usuario se realizó correctamente")
            setAxiosToken(response.token)
            MyLocalStorage.save(lsKeys.sesion, response)

            globalDispatch({ type: StoreActions.LOGIN, payload: response })
        } catch (err) {
            if (err.response?.status === 409) {
                const response = err.response.data
                if (response.fields?.email) {
                    ToastError('Este nombre de usuario o cuenta de Facebook ya existe, por favor intenta con uno nuevo')
                    return
                } if (response.fields?.username)
                    ToastError('Este nombre de usuario o cuenta de Facebook ya existe, por favor intenta con uno nuevo')
            }
            else {
                ToastError(parseErrorMsg(err))
            }
        }

        setIconIdex(0)
    }

    const MyErrMsg = msg => <span className="text-xs text-red-500">{msg}</span>

    return <>
        <Formik
            initialValues={INITIAL_VALUES}
            validationSchema={schema}
            onSubmit={handleSubmit}
        >
            {({ errors, isSubmitting, touched, submitForm }) => (<>
                <div className="mt-10 text-center text-primary">Formulario de registro</div>
                <div className="justify-center text-center flex-column">
                    {errors.general &&
                        <div className="mx-auto text-red-500">
                            {errors.general}
                        </div>
                    }
                </div>
                <div className="flex items-center mt-10">
                    <span className="text-primary">
                        ¿ Ya estás registrado ?,
                    </span>
                    <button className="btn mx-2 text-primary-light" onClick={hideSelf}>
                        Inicia sesión
                    </button>
                </div>
                <div className="flex flex-col my-3 text-black">
                    <div className="flex justify-start">
                        <label
                            className="block mb-1 text-xs text-primary"
                            htmlFor="username">
                            Usuario
                        </label>
                    </div>
                    <Field
                        className={`text-sm rounded-md w-full px-3 py-2 h-10 ${errors.username && touched.username ? 'border-red-500' : 'border-primary-light'} border focus:outline-none`}
                        name="username"
                    />
                    <ErrorMessage name='username' render={MyErrMsg} />
                </div>
                <div className="flex flex-col my-3 text-black">
                    <div className="flex justify-start">
                        <label
                            className="block mb-1 text-xs text-primary"
                            htmlFor="email">
                            Correo electronico
                        </label>
                    </div>
                    <Field
                        className={`text-sm rounded-md w-full px-3 py-2 h-10 ${errors.email && touched.email ? 'border-red-500' : 'border-primary-light'} border focus:outline-none`}
                        name="email"
                    />
                    <ErrorMessage name='email' render={MyErrMsg} />
                </div>
                <div className="my-3 text-black">
                    <div className="flex justify-start">
                        <label
                            className="block mb-1 text-xs text-primary"
                            htmlFor="passWord">
                            Contraseña
                        </label>
                    </div>
                    <div className="relative flex flex-col">
                        <Field
                            className={`text-sm rounded-md w-full px-3 py-2 h-10 ${errors.password && touched.password ? 'border-red-500' : 'border-primary-light'} border focus:outline-none`}
                            name="password"
                            type={visiblePass ? 'text' : 'password'}
                        />
                        <div className="absolute top-2.5 right-0 flex items-center pr-3 text-sm leading-5">
                            {visiblePass
                                ? <IconEyeOff
                                    onClick={() => { setvisiblePass(false) }}
                                    className="stroke-current text-primary"
                                    style={{ height: 20 }}
                                />
                                : <IconEye
                                    onClick={() => { setvisiblePass(true) }}
                                    className="stroke-current text-primary"
                                    style={{ height: 20 }}
                                />
                            }
                        </div>
                        <ErrorMessage name='password' render={MyErrMsg} />
                    </div>
                </div>

                {isSubmitting
                    ? <button className="flex items-center justify-center w-full px-6 py-2.5 tracking-wider text-white rounded-md bg-secondary focus:outline-none">
                        <IconLoader
                            className="text-white fill-current animate-spin"
                            style={{ height: 20 }}
                        />
                    </button>
                    : <button className="flex items-center justify-center w-full px-6 py-2.5 tracking-wider text-white rounded-md bg-secondary focus:outline-none"
                        onClick={submitForm}>
                        Registro
                    </button>
                }
            </>)}
        </Formik>

        <div className="flex flex-row justify-center my-4">
            <span className="mx-2 text-black">ó</span>
        </div>
        <ReactFacebookLogin
            appId={process.env.REACT_APP_FB_APP_ID}
            autoLoad={false}
            fields="id,name,first_name,last_name,email,picture"
            scope="public_profile,email"
            callback={responseFacebook}
            icon={facebookIcons[iconIndex]}
            onClick={() => { setIconIdex(1) }}
            isDisabled={iconIndex == 1}
            redirectUri={`${process.env.REACT_APP_URL_FRONT}/facebooklogin/registro`}
            responseType="token"
            textButton="Registro con Facebook"
            cssClass="flex items-center justify-center w-full px-6 py-2.5 tracking-wider text-white rounded-md bg-primary-dark focus:outline-none"
        />
    </>
}

export default RegisterPanel
