import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom";
import Loader from "../Loader";
import articles from "../../integrations/article"
import WavesShape from '../../Assets/Images/waves-shape.png'
import { IconCheck2 } from "../Icons";

const categories = {
    'temas_educativos': 'Temas educativos',
    'historias_de_exito': 'Historias de éxito',
    'inspiracion_y_motivacion': 'Inspiración y motivación',
    'tendencias': 'Tendencias',
    'cultura': 'Cultura',
    'juegos_mentales': 'Juegos mentales',
    'libros': 'Libros',
    'tips': 'Tips'
}

const categoriesClassMap = {
    'temas_educativos': 'py-1 px-2 rounded text-sm bg-primary text-white',
    'historias_de_exito': 'py-1 px-2 rounded text-sm bg-secondary text-white',
    'inspiracion_y_motivacion': 'py-1 px-2 rounded text-sm bg-primary text-white',
    'tendencias': 'py-1 px-2 rounded text-sm bg-primary text-white',
    'cultura': 'py-1 px-2 rounded text-sm bg-secondary text-white',
    'juegos_mentales': 'py-1 px-2 rounded text-sm bg-primary text-white',
    'libros': 'py-1 px-2 rounded text-sm bg-secondary text-white',
    'tips': 'py-1 px-2 rounded text-sm bg-primary text-white'
}

const RecomendedLectures = ({ template = "vertical", ...props }) => {
    const [lastArticles, setlastArticles] = useState([])
    const [loading, setLoading] = useState(true)

    useEffect(async () => {
        try {
            const res = await articles.getArticles(4, 1)

            if (res.data) {
                res.data.forEach(element => {
                    if (element && element.name) {
                        try {
                            const str = element.name.trim();
                            element.name = str.charAt(0).toLocaleUpperCase() + str.slice(1)
                        } catch (error) { }
                    }
                });
            }

            setlastArticles(res?.data)
            setLoading(false)
        } catch (error) {
            console.log(error)
            setlastArticles([])
            setLoading(false)
        }
    }, [])

    if (loading) {
        return <Loader />
    }
    return <>
        {
            template === "vertical" ? <Vertical src={lastArticles} /> : <Horizontal src={lastArticles} />
        }
    </>
}

const Vertical = ({ src }) => {
    return <>
        <div className="font-semibold text-xl text-primary mb-3 mt-6 md:mt-0 mb-5">Lecturas recomendadas</div>
        {
            src.map((x, i) => {
                return (
                    <Link to={`/articulo/${x._id}`} key={`recomended-lectures-vertical-${i}`} className="grid grid-cols-1 md:grid-cols-3 mb-5 items-center">
                        <div className="col-span-1">
                            <img src={x.photo} className="rounded-md w-full md:w-30 md:h-20 mb-2 md:mb-0" />
                        </div>
                        <div className="col-span-1 md:col-span-2 ml-3">
                            <div className="text-gray-500 flex flex-wrap items-center">
                                <span className={categoriesClassMap[x.category]}>
                                    <small>
                                        {categories[x.category]}
                                    </small>
                                </span>
                            </div>
                            <div>
                                <small className="text-primary-light">{x.postedBy.name}</small>
                            </div>
                            <p className="text-primary text-sm mt-1"
                                style={{ wordBreak: 'break-word', hyphens: 'auto' }}
                                dangerouslySetInnerHTML={{ __html: x.name }}></p>
                        </div>
                    </Link>
                )
            })
        }
    </>
}

const Horizontal = ({ src }) => {
    return <div className="lg:overflow-hidden py-10 px-3 relative">
        <div className="mb-9">
            <div className="bg-primary hidden lg:block" style={{
                position: 'absolute',
                minHeight: '140px',
                zIndex: -2,
                top: '40%',
                left: '0px',
                width: '100%'
            }}>
                <div className="w-full mx-auto mt-6">
                    <img className="hidden lg:block xl:z-0 -mt-2" src={WavesShape} style={{
                        height: '140px',
                        width: '100%',
                        zIndex: -1
                    }} />
                </div>
            </div>
            <div className="max-w-7xl mx-auto">
                <div>
                    <h3 className="text-base text-center font-semibold text-2xl text-primary px-3">
                        Nuestras lecturas recomendadas
                    </h3>
                    <div className="grid grid-cols-2 md:grid-cols-4 gap-4 lg:gap-x-30 relative">
                        {
                            src.map((x, i) => {
                                return <Link to={`/articulo/${x._id}`} key={`recomended-lectures-vertical-${i}`} className="ml-0 md:ml-3">
                                    <div className="col-span-1 md:col-span-2 relative">
                                        <div className="flex flex-col justify-between" style={{ height: '320px' }}>
                                            <div className="relative">
                                                <img src={x.photo} className="rounded w-full h-60 mb-2 md:mb-0"
                                                    style={{ minHeight: '255px' }} />
                                                <div className="absolute z-10 right-8 -mt-6 inline-flex items-end">
                                                    <IconCheck2 fill="#D2544A" width="41" height="33" />
                                                    <IconCheck2 fill="#14336C" width="16" height="13" />
                                                </div>
                                            </div>
                                            <div className="mt-10">
                                                <p className="text-primary text-xl font-semibold mt-1"
                                                    style={{ wordBreak: 'break-word', hyphens: 'auto' }}
                                                    dangerouslySetInnerHTML={{ __html: x.name }}>
                                                </p>
                                                <span className="text-md flex flex-wrap items-center my-2 truncate">
                                                    Por {x.postedBy.name}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                            })
                        }
                    </div>
                </div>
            </div>
        </div>

    </div>
}

export default RecomendedLectures