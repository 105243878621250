import { Link } from "react-router-dom"
import moment from "moment"
import { IconFacebook, IconInstagramSVG } from "../Icons"
import LogoTransparent from '../../Assets/Brand/LogoTransparent.svg'
import { Context } from '../../store/useGlobalState'
import { useContext } from "react"

const Footer = () => {
    const { globalState } = useContext(Context)

    return (
        <footer aria-labelledby="footerHeading">
            <div className="bg-primary-strong">
                <h2 id="footerHeading" className="sr-only">Footer</h2>
                <div className="max-w-6xl px-5 py-8 mx-auto">
                    <div className="text-gray-700 xl:gap-8 xl:grid xl:grid-cols-4">
                        <div className="grid gap-8 xl:col-span-2">
                            <div className="md:grid md:grid-cols-2 md:gap-8">
                                <div>
                                    <div className="text-secondary font-bold">Explora 100 tutores</div>
                                    <ul className="flex flex-col mt-3 text-xs gap-y-3"
                                        style={{
                                            listStyleType: 'none',
                                            marginLeft: '0px',
                                            paddingLeft: '0px'
                                        }}>
                                        <FooterLink label="Mapa del sitio" url="/mapa-del-sitio" />
                                        <FooterLink label="Tutores" url="/tutores" />
                                        <FooterLink label="Servicios" url="/servicios" />
                                        <FooterLink label="Sesiones" url="/sesiones" />
                                        <FooterLink label="Artículos" url="/articulos" />
                                        <FooterLink label="Comienza gratis" 
                                            url={globalState?.user?'/ofertas':'/registrarse'} />
                                    </ul>
                                </div>
                                <div className="mt-12 md:mt-0">
                                    <div className="text-secondary tracking-wider font-bold">Servicio al cliente</div>
                                    <ul className="flex flex-col mt-3 text-xs gap-y-3"
                                        style={{
                                            listStyleType: 'none',
                                            marginLeft: '0px',
                                            paddingLeft: '0px'
                                        }}>
                                        <FooterLink label="Ayuda" url="/servicio-al-cliente" />
                                        <FooterLink label="Contacto" url="/contacto" />
                                        <FooterLink label="Satisfacción garantizada" url="/satisfaccion-garantizada" />
                                        <FooterLink label="Recomendaciones técnicas" url="/recomendaciones-tecnicas" />
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-col justify-between mt-8 xl:col-span-2 lg:mt-0">
                            <div className="flex-row text-right">
                                <img className="ml-auto mr-0 h-15 w-auto" src={LogoTransparent} alt="Paradigma" />
                            </div>
                            <div>
                                <div className="flex-col pt-6 pb-3">
                                    <p className="text-right text-white">¿Eres tutor? <Link to="/peticion-de-expertos" className="ml-2">Realiza tu solicitud ahora</Link> </p>
                                </div>
                                <div className="flex-col grid-cols-3 space-x-6 md:order-2">
                                    <div className="flex flex-row-reverse items-center text-center">
                                        <a href="https://www.facebook.com/100tutores" className="inline-flex items-center justify-center h-8 w-8 rounded-full bg-white ml-4 text-right text-primary">
                                            <span className="sr-only">Facebook</span>
                                            <IconFacebook />
                                        </a>
                                        <a href="https://www.instagram.com/100_tutores/" className="inline-flex items-center justify-center h-8 w-8 rounded-full bg-white ml-4 text-right text-primary">
                                            <span className="sr-only">Instagram</span>
                                            <IconInstagramSVG />
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <div className="bg-white max-w-6xl px-5 mx-auto">
                <div className="pt-4 pb-6 md:flex md:items-center md:justify-between">
                    <div className="flex space-x-3 md:order-2">
                        <Link to="/terminos-y-condiciones" className="text-primary hover:text-primary" >Términos y condiciones</Link>
                        <Link to="/politicas-de-privacidad" className="text-primary hover:text-primary" >Política de privacidad</Link>
                    </div>
                    <p className="mt-8 md:mt-0 md:order-1 text-primary">
                        Copyright 100tutores, {moment().format("YYYY")}
                    </p>
                </div>
            </div>
        </footer>
    )
}

//#region Subcomponents
const FooterLink = ({ label, url, className }) => (
    <li className="flex-col">
        <a href={url} className={"text-sm text-white"}>
            {label}
        </a>
    </li>
)
//#endregion

export default Footer