import React, { useState, useContext, useEffect } from 'react'
import { LinkButton } from "../../Components/Button"
import { Context } from '../../store/useGlobalState'
import VoiceCall from '../../Components/VoiceContainer'
import assets from '../../Assets'
import credit from '../../integrations/credit'
import appointmentsClient from '../../integrations/appointments';
import experts from "../../integrations/expert";
import moment from 'moment';
import { ToastError } from '../Toast'
import { Device } from '@twilio/voice-sdk'
import RateAnExpert from '../RateAnExpert'
import { IconLoader, IconChevronRight } from '../Icons'

let rejectedMessageShowed = false

export const ModalCall = ({ visible, hideSelf, expert, serviceType }) => {
    const { globalState } = useContext(Context)
    const [amount, setAmount] = useState(0)
    const [call, setCall] = useState(null)
    const [showRate, setShowRate] = useState(false)
    const [session, setSession] = useState(null)
    const [loading, setLoading] = useState(true)
    const [duration, setDuration] = useState(-1)

    useEffect(() => {
        setLoading(true);
        if (visible)
            credit.getCredit()
                .then(async ({ data: credits }) => {
                    let now = moment().tz("America/Mexico_City");
                    let nearAppointments = await appointmentsClient.expertSchedule(expert._id, now.format('YYYY/MM/DD'));
                    if ((nearAppointments || []).length > 0) {
                        const nearDate = (moment(`${now.format('YYYY-MM-DD')} ${nearAppointments[0].time}`).tz(nearAppointments[0].timeZone, true)).tz("America/Mexico_City");
                        setDuration(nearDate.diff(now, 'minutes'));
                    }
                    setAmount(Math.floor(credits));
                    setLoading(false)
                    globalState.user.credit = Math.floor(credits);
                })
                .catch((err) => {
                    console.log(err);
                    ToastError('No se pudo obtener tu crédito actual')
                    setAmount(0);
                    setLoading(false);
                })
    }, [visible])

    const onNotifyCall = () => {
        const data = {
            action: 'wsVideochatCall',
            data: {
                token: globalState.token,
                idReceiver: expert._id,
                userType: 'user',
                portal: "100_tutores",
                metadata: { duration, credits: amount }
            }
        }

        globalState.ws.send(JSON.stringify(data))
    }

    const initVoiceCall = async () => {
        try {
            const device = new Device(globalState?.tokenVoice?.token, {
                enableRingingState: true
            })

            const call = await device.connect({
                params: {
                    To: expert?._id,
                    name: globalState.user.username,
                    image: globalState.user.image,
                    duration,
                    credits: amount,
                },
                name: globalState.user.username,
                image: globalState.user.image
            })

            setCall(call)
            rejectedMessageShowed = false

            device.audio.incoming(true);
            device.audio.outgoing(true);
            device.audio.disconnect(true);
            device.register()

            device.on("accept", connection => {
                console.log("accept CALL", connection);
                connection.on("onDisconnected", connection => {
                    console.log("DISCONNECTED", connection)
                })
            })

            device.on("destroyed", connection => {
                console.log("END CALL");
                // dispatch(rejectCall());
            });
            device.on("cancel", connection => {
                setCall(null)
                console.log("cancel CALL", connection);
            });
            device.on("error", err => {
                setCall(null)
                console.log("ERR CALL", err);
            });
            device.on("registered", connection => {
                console.log("registered CALL", connection);
            });
            device.on("registering", connection => {
                console.log("registering CALL", connection);
            });

            device.on("unregistered", connection => {
                console.log("unregistered CALL", connection);
            });


        } catch (error) {
            console.log('[call error]', error)
        }
    }

    const handleRemoveCall = () => {
        setCall(null)
    }

    const handleSessionUpdated = (showReview = false, sessionStored = null) => {
        if (showReview && sessionStored) {
            setShowRate(true)
            setSession(sessionStored)
        }

        setCall(null)
    }

    const handleRateClose = () => {
        setShowRate(false)
        hideSelf()
    }

    if (call) {
        console.log("Return voicecall")

        return <VoiceCall
            call={call}
            expert={expert}
            removeCall={handleRemoveCall}
            handleSessionUpdated={handleSessionUpdated}
            appointmentDuration={duration}
        />
    }

    if (showRate) return <RateAnExpert session={session?._id} expert={{ ...expert, id: expert._id }} handleClose={handleRateClose} call={true} />

    return (visible &&
        <div className="fixed w-full cursor-auto top-0 left-0 z-10 overflow-y-auto py-10"
        style={{backgroundColor:'rgba(10,11,12,0.7)', height:'100vh'}}  onClick={hideSelf}>
            <div className="m-auto bg-white border rounded-md shadow-lg w-3/4 lg:w-1/2 p-3 relative"
                onClick={(e)=>{e.stopPropagation()}}>
                <div className="flex flex-col justify-center align-center max-w-md px-6 pt-3 mx-auto text-center lg:max-w-7xl sm:max-w-3xl sm:px-0">
                    <div className='flex flex-row-reverse'>
                        <button className='text-primary text-xl -mt-4 px-4' onClick={hideSelf}>
                            x
                        </button>
                    </div>
                    <div className="mt-2 text-xl font-semibold tracking-tight text-primary sm:text-2xl">
                        {serviceType === 'internet_call' ? 'Inicia tu llamada' : 'Inicia tu videollamada'}
                    </div>
                    <p className="max-w-prose mt-3.5 mx-auto text-gray-600 text-1xl tracking-normal pb-3">
                        Gracias por confiar en nosotros
                    </p>
                    <img src={assets.videoCall} className="w-60 self-center" />
                    <p className="max-w-prose mt-3.5 mx-auto text-primary-light text-1xl tracking-normal pb-1">
                        {
                            loading ? (
                                <div className="flex items-center">
                                    <IconLoader className="h-6 text-secondary animate-spin" />
                                </div>
                            ) :
                                <>
                                    Tu saldo actual es de ${Number(amount).toFixed(2)}
                                </>
                        }
                    </p>
                    <p className="max-w-prose mx-auto text-1xl tracking-normal pb-3 text-primary">
                        {!loading ? ((serviceType === 'internet_call' ? expert?.price_phonecall : expert?.price_videocall) <= amount ? 'Cuentas con crédito suficiente para iniciar una conversación' :
                            'No cuentas con crédito suficiente para iniciar una conversación') : 'Obteniendo tu crédito actual...'}
                    </p>
                    <div className="md:w-100 mt-3 grid grid-cols-1  sm:grid-cols-2 gap-1 px-2 pb-4 self-center	">
                        <LinkButton url="/perfil/credits" label="Agregar crédito" className="px-2 py-3 mx-auto text-sm bg-superLight text-primary-light w-full rounded font-semibold" />
                        {
                            loading ? (
                                <button className="mt-2 md:m-0 px-2 py-3 text-center text-sm w-full tex rounded bg-gray-500 text-white cursor-default" disabled>
                                    {serviceType === 'internet_call' ? "Unirse a llamada" : "Unirse a videollamada"}
                                </button>
                            ) : (
                                <button disabled={loading}
                                    onClick={async () => {
                                        const available = await experts.checkAvailable(expert?._id)
                                        if (available) {
                                            if ((serviceType === 'internet_call' ? expert?.price_phonecall : expert?.price_videocall) < amount) {
                                                if (serviceType === 'internet_call') initVoiceCall();
                                                else onNotifyCall();
                                                hideSelf();
                                            }
                                            else ToastError('Credito insuficiente');
                                        }
                                        else ToastError('El experto no está disponible por el momento');
                                    }}
                                    className={`mt-2 md:m-0 px-2 py-3 text-center text-sm text-white flex items-center justify-evenly
                        ${!expert?.price_videocall <= amount ? "bg-secondary" : "bg-gray-500"} w-full rounded`} >
                                {serviceType === 'internet_call' ? "Unirse a llamada" : "Unirse a videollamada"}
                        </button>
                            )
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ModalCall
