import { Link } from "react-router-dom"
import { ServicesThemesList, MattersThemesList } from "../Home/HoroscopeOfDay"

const SitemapComponent = () => {
    return (
        <div className="p-4 max-w-7xl mx-auto">
            <div className="text-xl font-bold text-primary">Mapa del sitio</div>
            <div className="mt-10 grid grid-cols-3">
                <div>
                    <div>
                        <div className="font-semibold text-primary">Inicio</div>
                        <div className="flex flex-col">
                            <Link to="" className="text-primary-light">Página inicial</Link>
                            <Link to="/registrarse" className="text-primary-light">Regístrate</Link>
                            <Link to="/peticion-de-expertos" className="text-primary-light">Solicitudes de expertos</Link>
                        </div>
                    </div>
                    <div className="mt-4">
                        <div className="font-semibold text-primary">Páginas</div>
                        <div className="flex flex-col">
                            <Link to="/articulos" className="text-primary-light">Artículos</Link>
                            <Link to="/sesiones" className="text-primary-light">Sesiones</Link>
                            <Link to="/tutores" className="text-primary-light">Tutores</Link>
                            <Link to="/ofertas" className="text-primary-light">Ofertas</Link>
                        </div>
                    </div>
                </div>
                <div>
                    <div>
                        <div className="font-semibold text-primary">Servicios</div>
                        <div className="flex flex-col">
                            <Link to="/servicios" className="text-primary-light">Ver todos</Link>
                            {
                                Object.keys(MattersThemesList).map((key, idx) => (
                                    <Link key={`service-${idx}`} 
                                    to={`/servicios/${key}`} 
                                    className="text-primary-light">{MattersThemesList[key].text}</Link>
                                ))
                            }
                        </div>
                    </div>
                </div>
                <div>
                    <div>
                        <div className="font-semibold text-primary">Otros</div>
                        <div className="flex flex-col">
                            <Link to="/como-funciona" className="text-primary-light">¿Cómo funciona?</Link>
                            <Link to="/codigo-de-etica" className="text-primary-light">Código de ética</Link>
                            <Link to="/contacto" className="text-primary-light">Contacto</Link>
                            <Link to="/manifiesto" className="text-primary-light">Manifiesto</Link>
                            <Link to="/politicas-de-privacidad" className="text-primary-light">Políticas de privacidad</Link>
                            <Link to="/precios" className="text-primary-light">Precios</Link>
                            <Link to="/preguntas-frecuentes" className="text-primary-light">Preguntas frecuentes</Link>
                            <Link to="/recomendaciones-tecnicas" className="text-primary-light">Recomendaciones técnicas</Link>
                            <Link to="/servicio-al-cliente" className="text-primary-light">Servicio al cliente</Link>
                            <Link to="/satisfaccion-garantizada" className="text-primary-light">Satisfacción garantizada</Link>
                            <Link to="/terminos-y-condiciones" className="text-primary-light">Términos y condiciones</Link>
                            <Link to="/testimonios" className="text-primary-light">Testimonios</Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SitemapComponent