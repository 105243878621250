import React, { useState } from "react"
import { Formik, Field, Form, ErrorMessage } from "formik"
import * as yup from 'yup'
import { Heading, Sub } from "../../../Components/Typography";
import { SecondaryButton, LinkButton } from "../../../Components/Button";
import { IconCheck2, IconCheckRedWhite, IconChevronRight, IconLoader } from "../../../Components/Icons";
import suscription from "../../../integrations/suscription";
import { ToastError, ToastSuccess } from "../../../Components/Toast";
import moment from "moment"

let schema = yup.object().shape({
    fullName: yup.string().min(2, "Mínimo dos caracteres").required("Campo requerido"),
    email: yup.string().email('Correo no válido').required("Campo requerido"),
    birthDate: yup.mixed().transform((value, originalValue) => new Date(originalValue)).required("Campo requerido")
})

const EmailSuscribe = () => {
    const INITIAL_FORM_DATA = {
        fullName: '',
        birthDate: "",
        email: '',
    }

    const handleSubmit = async (values, actions) => {
        const data = { ...values, birthDate: moment(values.birthDate).toDate() }
        try {
            const res = await suscription.suscribre(data)
            actions.resetForm({ values: INITIAL_FORM_DATA })
            ToastSuccess('Suscripción exitosa.')
        } catch (error) {
            if (error && error.response && error.response.status && error.response.status === 409) {
                ToastError("El correo ya está suscrito")
            } else {
                ToastError('Se produjo un error, intente más tarde.')
            }
        }
        actions.setSubmitting(false);
    }

    const isDate = (value) => {
        let temp = new Date(value);
        if (isNaN(temp.getTime()))
            return 'fecha no valida'
    }

    const MyErrMsg = msg => <span className="h-px text-xs text-red-500">{msg}</span>

    return <section className="py-10 bg-riptideGray relative">
        <div className="max-w-7xl relative mx-auto">
            <div className="absolute top-0 left-0 flex w-full z-10 justify-center -mt-16">
                <IconCheckRedWhite fill2="#14336C" />
            </div>
            <div className="absolute hidden lg:inline-flex bottom-0 lg:left-20 xl:left-36 z-10 -mb-16">
                <IconCheck2 fill="#EBEBEB" />
            </div>
            <div className="absolute hidden lg:inline-flex items-center bottom-0 right-10 -mb-16 z-10">
                <IconCheck2 fill="#6F8197" />
                <IconCheck2 fill="#D2544A" width={40} height={30} />
            </div>
            <div className="text-2xl text-center font-bold text-primary">
                Recibe tips de educación y promociones exclusivas
            </div>
            <div className="lg:px-fullScreen">
                <Formik
                    initialValues={INITIAL_FORM_DATA}
                    onSubmit={(values, actions) => { handleSubmit(values, actions) }}
                    validationSchema={schema}
                >
                    {({ isSubmitting, errors, touched, resetForm, dirty, values }) => (
                        <Form className="text-black md:flex md:flex-row px-5 justify-between items-end	my-10">
                            <div className="mb-3 md:mb-0 md:min-w-1/4 md:mr-5 text-primary">
                                <label className="block mb-1 text-xs"
                                    htmlFor="fullName">Nombre(s)</label>
                                <ErrorMessage name="fullName" render={MyErrMsg} />
                                <Field
                                    className={`relative text-sm rounded-md w-full px-3 py-2 border border-primary ${errors.fullName && touched.fullName ? 'border-red-500' : ''} h-10 focus:outline-none`}
                                    name="fullName"
                                    maxLength={19}
                                    disabled={isSubmitting}
                                />
                            </div>
                            <div className="mb-3 md:mb-0 md:min-w-1/4 md:mr-5 text-primary">
                                <label className="block mb-1 text-xs"
                                    htmlFor="email">Correo</label>
                                <ErrorMessage name="email" render={MyErrMsg} />
                                <Field
                                    className={`relative text-sm rounded-md w-full px-3 py-2 border border-primary ${errors.email && touched.email ? 'border-red-500' : ''} h-10 focus:outline-none`}
                                    name="email"
                                    disabled={isSubmitting}
                                />
                            </div>
                            <div className="mb-3 md:mb-0 md:min-w-1/4 md:mr-5 text-primary">
                                <label
                                    className="block mb-1 text-xs"
                                    htmlFor="birthDate">Fecha de nacimiento </label>
                                <ErrorMessage name="birthDate" render={MyErrMsg} />
                                <Field
                                    className={`relative text-sm rounded-md w-full px-3 py-2 border border-primary ${errors.birthDate && touched.birthDate ? 'border-red-500' : ''} h-10 focus:outline-none`}
                                    type='date'
                                    name="birthDate"
                                    max={new Date().toISOString().substring(0, 10)}
                                    disabled={isSubmitting}
                                    validate={isDate}
                                />
                            </div>
                            <div>
                                {isSubmitting
                                    ? <button
                                        type="button"
                                        className="px-5 py-3 text-sm text-white bg-secondary rounded-md flex items-center justify-evenly">
                                        <IconLoader
                                            className="text-white fill-current animate-spin"
                                            style={{ height: 20 }}
                                        />
                                    </button>
                                    : <button
                                        className="px-5 py-3 text-sm text-white bg-secondary rounded-md flex items-center justify-evenly">
                                        <span>Suscribirse</span>
                                    </button>
                                }
                            </div>
                        </Form>
                    )}
                </Formik>
                <p className="md:text-center text-xs text-primary p-1 md:p-0">Como parte del servicio, también recibirás promociones exclusivas de 100 Tutores. Puedes eliminar tu suscripción cuando lo desees.</p>
                <p className="md:text-center text-xs text-primary p-1 md:p-0">Al presionar sobre el botón de Suscribirse, aceptas haber leído y estar de acuerdo con nuestros <LinkButton className="ml-1 contents text-primary-light" to="/terminos-y-condiciones" label="términos y políticas de privacidad" /></p>
            </div>
        </div>
    </section>
}

export default EmailSuscribe