import React, { useState, useCallback, useEffect, useContext } from "react"
import expertsIntegration from "../../integrations/expert"
import { Context } from "../../store/useGlobalState"
import Video from "twilio-video"
import Room from "./Room";
import myLocalStorage, { lsKeys } from '../../utils/localStorage'

const VideoChat = ({ roomName, token, removeRoom, calling, answerState }) => {
  const [username, setUsername] = useState("");
  const [room, setRoom] = useState(null);
  const [duration, setDuration] = useState(0);
  const [connecting, setConnecting] = useState(false);
  const [expert, setExpert] = useState(null);
  const [appointmentDuration, setAppointmentDuration] = useState(-1);

  const { globalDispatch, globalState } = useContext(Context)
  
  const handleUsernameChange = useCallback((event) => {
    setUsername(event.target.value);
  }, []);

  const handleSubmit = useCallback(
    async (event) => {
      try {
        var expert = await expertsIntegration.getById(calling.id);
        setExpert(expert);

        const maxDuration = (globalState.user.credit / expert.price_videocall);
        setDuration(calling.metadata.duration != -1 ? (maxDuration < calling.metadata.duration ? maxDuration : calling.metadata.duration) : maxDuration);
        setAppointmentDuration(calling.metadata.duration);
        setConnecting(true);

        Video.connect(token, {
          name: roomName,
        }).then((room) => {
            setConnecting(false);
            setRoom(room);
        }).catch((err) => {
            console.error(err);
            setConnecting(false);
        });        
      } catch (error) {}

    },
    [roomName, username]
  );

  const sendReplyCall = status => {
    const data = {
      action: 'wsVideocallReply',
      data: {
        token: globalState.token,
        idReceiver: calling?.id,
        userType: 'user',
        accept: status,
        portal: "100_tutores"
      }
    }

    globalState.ws.send(JSON.stringify(data));
  }

  const handleLogout = useCallback((firedByClient) => {
    setRoom((prevRoom) => {
      if (prevRoom) {
        prevRoom.localParticipant.tracks.forEach((trackPub) => {
          trackPub.track.stop();
        });
        prevRoom.disconnect();
        
        if (window?.prdgm?.session && window?.prdgm?.session?._id) {
          try {
            myLocalStorage.save(lsKeys.videoToken, token)
          } catch (error) {}
        }
        
        removeRoom(firedByClient)
      } else if (room) {
        room.disconnect();
      }
      return null;
    });
  }, []);

  useEffect(() => {
    if (room) {
      const tidyUp = (event) => {
        if (event.persisted) {
          return;
        }
        if (room) {
          handleLogout();
        }
      };
      window.addEventListener("pagehide", tidyUp);
      window.addEventListener("beforeunload", tidyUp);
      return () => {
        window.removeEventListener("pagehide", tidyUp);
        window.removeEventListener("beforeunload", tidyUp);
      };
    }
  }, [room, handleLogout]);

  useEffect(() => {
    handleSubmit()

    return () => {
      handleLogout()
    }
  }, [])
  let render = null;
  if (room) {
    render = (<div style={{ width: "100%", height: "100%", zIndex: 7, position: "fixed", top: 0, backgroundColor: "black", right: 0 }}>
      <Room roomName={roomName} 
        room={room} 
        calling={calling} 
        handleLogout={handleLogout} 
        replyCallRequest={sendReplyCall} 
        answerState={answerState} 
        duration={duration}
        expert={expert}
        appointmentDuration={appointmentDuration} />
    </div>
    );
  }
  return render;
};

export default VideoChat;
