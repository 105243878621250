import React, { useEffect, useState } from "react"
import { PseudoLink } from "../../Components/Button"
import CardExpert from "../../Components/CardExpert"
import { Formik, Field, Form } from "formik"
import { IconChevronDown, IconChevronLeft, IconChevronRight, IconArrowLeft, IconClose, IconSearch } from "../../Components/Icons"
import Checkbox from "../../Components/Checkbox"
import expert from "../../integrations/expert"
import Loader from "../../Components/Loader"
import { preferenceLabels, studentsTypeLabels, themeOptionsData, themeOptionsLabels } from "../../utils/expertConfigOptions"
import storage from "../../utils/localStorage"

const newObject = (label, value) => {
    const obj = {}
    obj[label] = value
    return obj
}

const orderValueMap = {
    'rating': 'rating',
    'available': 'available',
    'created_at': 'created_at',
    'username': 'username',
    'price_videocall': 'price_videocall',
    'price_videocall_2': 'price_videocall'
}

const RenderThemesOptions = ({ onChangeSelect, onChangeSelectAll, filterSelectValues, type="themes", labels=themeOptionsLabels }) => {
    const [themesData, setThemesData] = useState(labels)

    const toggleShowThemeLabelOptions = (themeLabel) => {
        setThemesData(prev => ({
            ...prev,
            ...newObject(themeLabel, !prev[themeLabel])
        }))
    }

    return (
        <>
            <Checkbox onChangeSelect={onChangeSelectAll} type={type} listChecked={filterSelectValues} label={"Seleccionar Todos"} />
            {
                Object.keys(themesData).map(themeLabel => (
                    <>
                        <div className="cursor-pointer flex items-center justify-between py-3 border-b"
                            key={`${type}-label-${themeLabel}`}>
                            <Checkbox onChangeSelect={onChangeSelectAll} type={themeLabel} listChecked={filterSelectValues} label={themeOptionsData[themeLabel]?.label} />
                            <span className="px-3" onClick={() => toggleShowThemeLabelOptions(themeLabel)}>
                                <IconArrowLeft className={`w-2 transform ${themesData[themeLabel] ? "rotate-90" : "-rotate-90"} cursor-pointer text-primary`} />
                            </span>
                        </div>
                        {
                            themesData[themeLabel] ?
                                <div>
                                    {
                                        Object.keys(themeOptionsData[themeLabel]['options']).map(option => (
                                            <>
                                                <Checkbox onChangeSelect={onChangeSelect}
                                                    type={themeLabel}
                                                    listChecked={filterSelectValues}
                                                    value={option}
                                                    key={`${type}-${themeLabel}-${option}`}
                                                    label={themeOptionsData[themeLabel]['options'][option].label} />
                                            </>
                                        ))
                                    }
                                </div> : ''
                        }
                    </>
                ))
            }
        </>
    )
}

const ExpertsPage = () => {
    const INITIAL_FORM_DATA = {}
    const [openSelect, setOpenSelect] = useState({ open: false, select: null })
    const [lastSearchText, setLastSearchText] = useState('')
    const [searchText, setSearchText] = useState('')
    const [orderBy, setOrderBy] = useState('available')
    const [order, setOrder] = useState('desc')
    const [pagination, setPagination] = useState({})
    const [loading, setLoading] = useState(true)
    const [filterSelectValues, setFilterSelectValues] = useState([])
    const [experts, setExperts] = useState([])

    useEffect(() => {
        const expertFlagData = storage.read("_app_experts_flag")

        if (expertFlagData && expertFlagData.horoscopesLinkedToExperts) {
            storage.delete("_app_experts_flag")
            setFilterSelectValues(prev => [...prev, { value: 'Astrology', type: 'preferences' }])
            return
        }

        filtersSearch()
    }, [filterSelectValues, orderBy, order])

    /*useEffect(() => {
        if (searchText) filtersSearch()
    }, [searchText])*/

    const findAvailable = (a, b) => {
        let ret = 1

        if (a?.available && (!b?.available)) ret = -1
        if (!(a?.available || b?.available)) ret = 0

        return ret
    }

    const sortAnSetExperts = (experts) => {
        //Rating,Available,Name,New,Price_High,Price_Low
        let finalExperts = experts || []

        try {
            if (orderBy === 'rating') {
                finalExperts = finalExperts.sort((a, b) => (b?.rating - a?.rating))
            } if (orderBy === 'available') {
                finalExperts = finalExperts.sort((a, b) => findAvailable(a, b))
            } if (orderBy === 'username') {
                finalExperts = finalExperts.sort((a, b) => b?.username?.toString().toLocaleLowerCase().localeCompare(a?.username?.toString().toLocaleLowerCase()))
            } if (orderBy === 'price_videocall') {
                finalExperts = finalExperts.sort((a, b) => (b?.price_videocall - a?.price_videocall))
            } if (orderBy === 'New') {
                finalExperts = finalExperts.sort((a, b) => (-1))
            }
        } catch (error) { }


        setExperts(finalExperts)
    }

    const getPagination = (experts, page) => {
        const { totalPages, currentPage } = experts?.data?.payload;
        let pages = []

        for (let i = 0; i < totalPages; i++) {
            pages.push(i + 1)
        }

        setPagination({
            prev: experts?.data?.payload?.prev,
            next: experts?.data?.payload?.next,
            pages: pages,
            current: currentPage
        })
    }

    const filtersSearch = async () => {
        setLoading(true)
        try {
            const experts = await expert.getExperts(12, 1, searchText?.toLocaleLowerCase(), filterSelectValues, orderBy, order)
            setExperts(experts?.data?.payload?.data || []);
            getPagination(experts)
            setLoading(false)
        } catch (error) {
            sortAnSetExperts([])
            setLoading(false)
        }
    }

    const goToPage = async (page) => {
        setLoading(true)
        try {
            const experts = await expert.getExperts(12, page, searchText?.toLocaleLowerCase(), filterSelectValues, orderBy, order)
            setExperts(experts?.data?.payload?.data || []);
            getPagination(experts, page)
            setLoading(false)
            window.scrollTo(0, 0);
        } catch (error) {
            setLoading(false)
        }
    }


    const handleSubmit = async (data, { setSubmitting, setFieldError }) => {
    }

    const handlePagination = async (url, next, page) => {
        if (next) {
            if (pagination.pages.length < pagination.current + 1)
                return
        }
        const experts = await expert.pagination(url)
        setExperts(experts?.data?.payload?.data || [])
        const { totalPages, currentPage } = experts?.data?.payload;
        let pages = []
        for (let i = 0; i < totalPages; i++) {
            pages.push(i + 1)
        }

        console.log(pages);
        setPagination(prevData => ({
            prev: experts?.data?.payload?.prev,
            next: experts?.data?.payload?.next,
            pages: pages,
            current: currentPage
        }))
    }

    const handleSearch = async () => {
        filtersSearch()
    }

    const handleCleanSearch = async () => {
        setSearchText('')
        setLoading(true)
        try {
            const experts = await expert.getExperts(12, 1, '', filterSelectValues, orderBy, order)
            setExperts(experts?.data?.payload?.data || []);
            getPagination(experts)
            setLoading(false)
        } catch (error) {
            sortAnSetExperts([])
            setLoading(false)
        }
    }

    const onChangeSearch = ({ target }) => {
        setLastSearchText(searchText)

        if (searchText && (!target?.value)) return handleCleanSearch()
        setSearchText(target?.value)
    }

    const onChangeSelect = (id, type) => {
        if (filterSelectValues.find(selectValue => selectValue.value === id && selectValue.type === type))
            setFilterSelectValues(prev => [...prev.filter(selectValue => selectValue.value !== id)])
        else {
            setFilterSelectValues(prev => [...prev, { value: id, type }])
        }
    }

    const onChangeSelectAll = (id, type) => {
        if (filterSelectValues.find(selectValue => selectValue.value === id && selectValue.type == type)) {
            let filter = filterSelectValues
            let currentList = []

            if (type=="sessionTypes") {
                currentList = arraySessionsType
            } else if (type == "preferences") {
                const themeList = []
                currentList = Object.keys(preferenceLabels).forEach(key => {
                    themeList.push(Object.keys(themeOptionsData[key].options))
                })
                currentList = themeList
            } else if (type == "themes") {
                const themeList = []
                currentList = Object.keys(themeOptionsLabels).forEach(key => {
                    themeList.push(Object.keys(themeOptionsData[key].options))
                })
                currentList = themeList
            } else if (type == "studentsType") {
                const themeList = []
                currentList = Object.keys(studentsTypeLabels).forEach(key => {
                    themeList.push(Object.keys(themeOptionsData[key].options))
                })
                currentList = themeList
            } else {
                for (let key of Object.keys(themeOptionsData)) {
                    if (type == key) {
                        currentList = Object.keys(themeOptionsData[key].options)
                        break
                    }
                }
            }

            currentList.map(esp => {
                filter = filter.filter(select => select.value !== esp)
            })

            filter = filter.filter(select => select.value !== id && select.type == type)
            setFilterSelectValues([...filter])
        }
        else {
            let arraySelect = []
            if (type=="sessionTypes") {
                arraySessionsType.map(esp => {
                    arraySelect.push({ value: esp, type })
                })
            }  else if (type == "preferences") {
                Object.keys(preferenceLabels).forEach(label => {
                    Object.keys(themeOptionsData[label].options).map(esp => {
                        arraySelect.push({ value: esp, type: label })
                    })

                    arraySelect.push({ value: undefined, type: label })
                })
            } else if (type == "studentsType") {
                Object.keys(studentsTypeLabels).forEach(label => {
                    Object.keys(themeOptionsData[label].options).map(esp => {
                        arraySelect.push({ value: esp, type: label })
                    })

                    arraySelect.push({ value: undefined, type: label })
                })
            } else if (type == "themes") {
                Object.keys(themeOptionsLabels).forEach(label => {
                    Object.keys(themeOptionsData[label].options).map(esp => {
                        arraySelect.push({ value: esp, type: label })
                    })

                    arraySelect.push({ value: undefined, type: label })
                })
            } else {
                for (let key of Object.keys(themeOptionsData)) {
                    if (type == key) {
                        Object.keys(themeOptionsData[type].options).map(esp => {
                            arraySelect.push({ value: esp, type })
                        })

                        break
                    }
                }
            }

            arraySelect.push({ value: id, type })
            setFilterSelectValues(prev => [...prev, ...arraySelect])
        }
    }

    const handleClickSelect = (id) => {
        if (openSelect.open && openSelect.select === id)
            handleCloseSelect()
        else
            setOpenSelect({ open: true, select: id })
    }

    const handleCloseSelect = () => {
        setOpenSelect({ open: false, select: null })
    }

    const arraySessionsType2 = ["Maestros", "Profesores", "Catedráticos", "Especialistas", "Profesionistas", "Entrenadores", "Coaches"]
    const arraySessionsType = ["maestros", "profesores", "catedraticos", "especialistas", "profesionalistas", "entrenadores", "coaches"]

    return (
        <>
            <div className="pb-20 bg-white max-w-md px-1 pt-10 mx-auto lg:max-w-7xl sm:max-w-3xl sm:px-0">
                <div className="text-center">
                    <div className="text-3xl font-bold tracking-tight text-primary py-0 my-0">
                        Los mejores expertos
                    </div>
                    <p className="max-w-prose my-6 mx-auto text-primary text-xl tracking-normal pb-3">
                        Conoce a todos nuestros expertos que pueden ayudarte
                    </p>
                    <div>
                        <div>
                            <Formik
                                initialValues={INITIAL_FORM_DATA}
                                onSubmit={handleSubmit}
                            >
                                <Form name="searchBar">

                                    <div className="relative flex-row flex  align-center justify-center mb-10">
                                        <div className="relative" style={{ width: "100%", maxWidth: "25rem" }}>
                                            <input
                                                value={searchText}
                                                onChange={onChangeSearch}
                                                style={{ width: "100%", maxWidth: "25rem" }}
                                                className={`text-sm text-primary-light rounded-md  px-3 pl-2 pr-5 border border-primary h-10 focus:outline-none rounded-r-none`}
                                            />
                                            {searchText && <div className="absolute right-1 top-3" onClick={handleCleanSearch}>
                                                <IconClose stroke="rgba(113, 113, 122,0.5)" />
                                            </div>
                                            }
                                        </div>
                                        <PseudoLink onClick={handleSearch} className="px-3 py-2 bg-primary rounded rounded-l-none border-primary">
                                            <IconSearch className="h-5 " />
                                        </PseudoLink>
                                    </div>


                                </Form>
                            </Formik>
                        </div>
                        <Formik
                            initialValues={INITIAL_FORM_DATA}
                            onSubmit={handleSubmit}
                        >

                            <Form className="mt-2 md:grid md:grid-cols-2 lg:grid-cols-4 xl:grid-cols-5  mb-2 md:gap-4">
                                { /* inputs */}
                                <div className="my-1 md:m-0">
                                    <Field name="sessionTypes">
                                        {({ field, meta: { error, touched } }) => (
                                            <>
                                                <div className="relative">
                                                    <div
                                                        onClick={() => handleClickSelect("sessionTypes")}
                                                        className="flex flex-row items-center justify-between pl-2 bg-white border rounded-md text-sm text-primary h-9 pr-2">
                                                        <label className="m-none whitespace-nowrap overflow-hidden	overflow-ellipsis">{`Tipos de Tutores${filterSelectValues.find(select => select.type === "sessionTypes") ?
                                                            `: Seleccionados (${filterSelectValues.filter(select => select.type === "sessionTypes" && select.value != "Seleccionar Todos").length == arraySessionsType.length ?
                                                                "Todos" :
                                                                filterSelectValues.filter(select => select.type === "sessionTypes" && select.value != "Seleccionar Todos").length
                                                            })` : ''
                                                            }`}</label>
                                                        <span>
                                                            <IconChevronDown className="text-primary" style={{ width: '13px', height: '13px' }} fill="red" />
                                                        </span>
                                                    </div>
                                                    {openSelect?.open && openSelect?.select === "sessionTypes" &&
                                                        <div className="absolute p-3 top-10 bg-white border rounded-md m"
                                                            style={{ minWidth: 220, zIndex: 100, maxHeight: '360px', overflowY: 'auto' }}>
                                                            <Checkbox onChangeSelect={onChangeSelectAll} type="sessionTypes" listChecked={filterSelectValues} label={"Seleccionar Todos"} />
                                                            {
                                                                arraySessionsType.map((especialidad, index) => (
                                                                    <Checkbox onChangeSelect={onChangeSelect} type="sessionTypes" listChecked={filterSelectValues} value={especialidad} label={arraySessionsType2[index]} />
                                                                )
                                                                )
                                                            }
                                                        </div>}
                                                </div>

                                            </>
                                        )}
                                    </Field>
                                </div>
                                <div className="my-1 md:m-0">
                                    <Field name="themes">
                                        {({ field, meta: { error, touched } }) => (
                                            <>
                                                <div className="relative">
                                                    <div
                                                        onClick={() => handleClickSelect("themes")}
                                                        className="flex flex-row items-center justify-between pl-2 bg-white border rounded-md text-sm text-primary h-9 pr-2">
                                                        <label className="m-none whitespace-nowrap overflow-hidden	overflow-ellipsis">{`Materias y Temas${filterSelectValues.find(select =>
                                                        {
                                                            let flag = false
                                                            for (let key of ["themes", ...Object.keys(themeOptionsLabels)]) {
                                                                if (select.type == key) flag = true
                                                            }
                                                            
                                                            return flag
                                                        }) ?
                                                            `: Varios seleccionados ` : ''}`}</label>
                                                        <span>
                                                            <IconChevronDown className="text-primary" style={{ width: '13px', height: '13px' }} fill="red" />
                                                        </span>
                                                    </div>
                                                    {openSelect?.open && openSelect?.select === "themes" &&
                                                        <div className="absolute p-3 top-10 bg-white border rounded-md m"
                                                            style={{ minWidth: 270, zIndex: 100, maxHeight: '360px', overflowY: 'auto' }}>
                                                            <RenderThemesOptions onChangeSelect={onChangeSelect}
                                                                onChangeSelectAll={onChangeSelectAll}
                                                                filterSelectValues={filterSelectValues} />
                                                        </div>
                                                    }
                                                </div>
                                            </>
                                        )}
                                    </Field>
                                </div>
                                <div className="my-1 md:m-0">
                                    <Field name="studentsType">
                                        {({ field, meta: { error, touched } }) => (
                                            <>
                                                <div className="relative">
                                                    <div
                                                        onClick={() => handleClickSelect("studentsType")}
                                                        className="flex flex-row items-center justify-between pl-2 bg-white border rounded-md text-sm text-primary h-9 pr-2">
                                                        <label className="m-none whitespace-nowrap overflow-hidden	overflow-ellipsis">{`Tipos de Estudiantes${filterSelectValues.find(select =>
                                                        {
                                                            let flag = false
                                                            for (let key of ["studentsType", ...Object.keys(studentsTypeLabels)]) {
                                                                if (select.type == key) flag = true
                                                            }
                                                            
                                                            return flag
                                                        }) ?
                                                            `: Varios seleccionados ` : ''}`}</label>
                                                        <span>
                                                            <IconChevronDown className="text-primary" style={{ width: '13px', height: '13px' }} fill="red" />
                                                        </span>
                                                    </div>
                                                    {openSelect?.open && openSelect?.select === "studentsType" &&
                                                        <div className="absolute p-3 top-10 bg-white border rounded-md m"
                                                            style={{ minWidth: 270, zIndex: 100, maxHeight: '360px', overflowY: 'auto' }}>
                                                            <RenderThemesOptions onChangeSelect={onChangeSelect}
                                                                onChangeSelectAll={onChangeSelectAll}
                                                                filterSelectValues={filterSelectValues}
                                                                labels={studentsTypeLabels}
                                                                type="studentsType" />
                                                        </div>
                                                    }
                                                </div>
                                            </>
                                        )}
                                    </Field>
                                </div>
                                <div className="my-1 md:m-0">
                                    <Field name="preferences">
                                        {({ field, meta: { error, touched } }) => (
                                            <>
                                                <div className="relative">
                                                    <div
                                                        onClick={() => handleClickSelect("preferences")}
                                                        className="flex flex-row items-center justify-between pl-2 bg-white border rounded-md text-sm text-primary h-9 pr-2">
                                                        <label className="m-none whitespace-nowrap overflow-hidden	overflow-ellipsis">{`Preferencia${filterSelectValues.find(select =>
                                                        {
                                                            let flag = false
                                                            for (let key of ["preferences", ...Object.keys(preferenceLabels)]) {
                                                                if (select.type == key) flag = true
                                                            }
                                                            
                                                            return flag
                                                        }) ?
                                                            `: Varios seleccionados ` : ''}`}</label>
                                                        <span>
                                                            <IconChevronDown className="text-primary" style={{ width: '13px', height: '13px' }} fill="red" />
                                                        </span>
                                                    </div>
                                                    {openSelect?.open && openSelect?.select === "preferences" &&
                                                        <div className="absolute p-3 top-10 bg-white border rounded-md m"
                                                            style={{ minWidth: 270, zIndex: 100, maxHeight: '360px', overflowY: 'auto' }}>
                                                            <RenderThemesOptions onChangeSelect={onChangeSelect}
                                                                onChangeSelectAll={onChangeSelectAll}
                                                                filterSelectValues={filterSelectValues}
                                                                labels={preferenceLabels}
                                                                type="preferences" />
                                                        </div>
                                                    }
                                                </div>
                                            </>
                                        )}
                                    </Field>
                                </div>
                                <div className="col-span-2 xl:col-span-1 flex flex-row xl:justify-center items-center">
                                    <label
                                        className="block text-xs mx-0"
                                        htmlFor="sort">
                                        Ordenar:
                                    </label>
                                    <Field name="sort">
                                        {({ field, meta: { error, touched } }) => (
                                            <select
                                                onChange={e => {
                                                    setOrderBy(orderValueMap[e.target.value])
                                                    switch (e.target.value) {
                                                        case 'rating': case 'available': case 'created_at': case 'price_videocall': setOrder('desc'); break;
                                                        case 'username': case 'price_videocall_2': setOrder('asc');
                                                    }
                                                }}
                                                className={`h-9 bg-white lg:mx-2 rounded-md text-xs pl-2 truncate text-primary-light font-semibold`}
                                            >
                                                <option value={"available"}>{"Disponibilidad"}</option>
                                                <option value={"rating"}>{"Rating"}</option>
                                                <option value={"username"}>{"Orden alfabético"}</option>
                                                <option value={"created_at"}>{"Nuevos"}</option>
                                                {/* <option value={"price_videocall"}>{"Tarifa (Baja a alta)"}</option> */}
                                                <option value={"price_videocall"}>{"Tarifa (Alta a baja)"}</option>
                                                <option value={"price_videocall_2"}>{"Tarifa (Baja a alta)"}</option>
                                            </select>
                                        )}
                                    </Field>
                                </div>
                            </Form></Formik>
                    </div>
                    <div className="mt-4" onClick={handleCloseSelect}>
                        {
                            loading ? <Loader /> : <div className="items-stretch grid grid-cols-1 lg:grid-cols-3 xl:grid-cols-4 gap-x-2 gap-y-8 sm:grid-cols-2">

                                {experts.map((expert, index) => (
                                    <CardExpert
                                        id={expert._id}
                                        expert={expert}
                                        available={expert.available}
                                        photo={expert.image}
                                        stars={expert.rating}
                                        name={expert.username}
                                        key={`expert-key-${expert._id}`}
                                    />
                                ))}
                            </div>
                        }
                    </div>
                    <div className="flex text-gray-700 justify-end self-end mt-10">
                        {
                            (pagination?.pages?.length > 1) && (
                                <>
                                    {pagination?.prev && <div onClick={() => handlePagination(pagination.prev, false)}
                                        className="h-12 w-12 mr-1 flex justify-center items-center cursor-pointer">
                                        <IconChevronLeft className="h-5 text-white ml-2 " style={{ width: 8, height: 12 }} />

                                    </div>}
                                    <div className="flex flex-row justify-center items-center ">
                                        {pagination?.pages?.map(page => {
                                            return <div
                                                className="m-1 text-gray-500 cursor-pointer font-semibold mx-4"
                                                style={{ color: pagination?.current == page ? "#505DD5" : "rgba(113, 113, 122, var(--tw-text-opacity))" }}
                                                onClick={() => {
                                                    goToPage(page)
                                                }
                                                }
                                            >
                                                {page}
                                            </div>
                                        })}
                                    </div>
                                    {pagination?.next && <div onClick={() => handlePagination(pagination.next, true)}
                                        className="h-12 w-12 mr-1 flex justify-center items-center cursor-pointer">
                                        <IconChevronRight className="h-5 text-white ml-2 " style={{ width: 8, height: 12 }} />
                                    </div>}
                                </>
                            )
                        }
                    </div>
                </div>
            </div>
        </>
    )
}

export default ExpertsPage