import React, { useEffect, useState } from "react"
import { useParams, Link } from "react-router-dom"
import articles from "../../../integrations/article"
import Recommended from "../../../Components/Recommended"
import moment from 'moment'
import { IconFacebook, IconEnvelopeSVG, UserProfileIcon, IconChevronRight, IconCheck2 } from "./../../../Components/Icons"
import { FacebookShareButton, EmailShareButton } from "react-share"

const ArticlePage = () => {
    const [article, setArticle] = useState(null)
    const [stars, setStarts] = useState([])
    let { id } = useParams()

    useEffect(async () => {
        window.scrollTo(0, 0);
        const response = await articles.getById(id)
        setArticle(response)
        const starsIcon = new Array(typeof response?.ranking === 'number' ? Math.ceil(response?.ranking) : 0).fill('star')
        setStarts(starsIcon)
    }, [id])

    const renderBody = () => (<div className="mt-10">
        {article?.topics?.map((topic, i) => {
            return (<div className="grid grid-cols-1 lg:grid-cols-3 xl:grid-cols-3" key={`topic-key-${topic._id}`}>
                <div>
                    <h1 className="text-2xl" style={{ wordBreak: 'break-word', hyphens: 'auto' }}>{topic?.title}</h1>
                    <p className="text-left text-base font-normal text-base text-gray-500 my-4 formatted-text-container"
                        dangerouslySetInnerHTML={{ __html: topic.description }}>
                    </p>
                </div>
                <div className="w-full col-span-2 pl-0 lg:pl-4">
                    <img className="w-full object-cover rounded-sm bg-black h-100 mt-4 " src={topic?.image} title="" alt="" />
                    <p className="text-left text-base font-normal text-base text-gray-500 my-4 formatted-text-container"
                        dangerouslySetInnerHTML={{ __html: topic.summary }}>
                    </p>
                </div>
            </div>)
        })
        }
    </div>)

    return (
        <>
            <div className="bg-white pt-10">
                <div className="max-w-lg lg:max-w-md px-6 pt-3 mx-auto lg:max-w-7xl  sm:px-0 xs:max-w-3xl md:max-w-5x1 xs:px-0 relative">
                    <div className="absolute hidden md:inline-flex items-end right-20 top-32 z-10">
                        <IconCheck2 fill="#EBEBEB" width={113} height={80} />
                        <span className="-ml-10">
                            <IconCheck2 fill="#D2544A" width={58} height={46} />
                        </span>
                    </div>
                    <div className="flex items-center mb-8">
                        <Link to='/articulos' className="inline-block text-sm text-primary-light">Artículos</Link>
                        <p className="mx-2 text-primary-light" style={{paddingTop:'1px'}}>
                            <IconChevronRight fill="currentColor" />
                        </p>
                        <div className="text-primary-light"
                            style={{ wordBreak: 'break-word', hyphens: 'auto', fontWeight:700 }}>
                            {article?.name}
                        </div>
                    </div>
                    <h1 className="text-xl text-primary-light font-semibold" style={{ wordBreak: 'break-word', hyphens: 'auto' }}>{article?.name}</h1>
                    <div className="flex flex-row self-start mt-4 items-center">
                        {
                            article?.postedBy?.image !== null ?
                                <img className="w-10 h-10 object-cover rounded-full" src={article?.postedBy?.image} title="" alt="" /> :
                                <UserProfileIcon className="w-10 h-10" />
                        }

                        <span className="text-left text-base text-primary mx-4">Por {article?.postedBy?.name}</span>
                        <span className="text-left text-base text-primary font-bold">{"·"}</span>
                        <span className="text-left text-base text-riptideGray2 mx-4 capitalize">{moment(article?.updatedAt).format("MMMM DD, YYYY")}</span>
                    </div>
                    <img className="w-full object-cover rounded-sm bg-black h-100 mt-4" src={article?.photo} title="no image" alt="no image" />
                    <div className="absolute hidden md:inline-flex items-start right-20 -mt-24 z-10">
                        <IconCheck2 fill="#F5F5F5" width={134} height={108} />
                        <span className="pt-6">
                            <IconCheck2 fill="#F5F5F5" width={27} height={21} />
                        </span>
                    </div>
                    <div className="grid grid-cols-1 lg:grid-cols-6 my-8 gap-2">
                        <div>
                            <div className="flex items-start text-primary-light">
                                <FacebookShareButton quote={article?.name} hashtag="100tutoresOnline"
                                    url={`${process.env.REACT_APP_ARTICLES_URL_BACK}/articles/share/${article?._id}`}>
                                    <IconFacebook width="22" height="22" />
                                </FacebookShareButton>
                                <EmailShareButton quote={article?.name} hashtag="100tutoresoOnline" className="mx-3"
                                    url={`${process.env.REACT_APP_ARTICLES_URL_BACK}/articles/share/${article?._id}`}>
                                    <IconEnvelopeSVG width="22" height="22" />
                                </EmailShareButton>
                            </div>
                            <div className="flex justify-center mt-10">
                                <div className="hidden md:inline-flex items-end right-0 top-40 -mr-10 z-10">
                                    <IconCheck2 fill="#14336C" width={134} height={108} />
                                    <IconCheck2 fill="#D2544A" width={27} height={21} />
                                </div>
                            </div>
                        </div>
                        <div className="lg:col-start-3 lg:col-span-4 formatted-text-container"
                            dangerouslySetInnerHTML={{ __html: article?.content }}>
                        </div>
                    </div>
                    {renderBody()}
                    <div className="flex flex-row-reverse mt-10">
                        <Link to='/articulos' className="font-semibold flex items-center text-primary-light">
                            <span className="-mt-2">
                                Ver todos los artículos
                            </span>
                            <span className="mx-3  text-primary-light"><IconChevronRight fill="currentColor" /></span>
                        </Link>
                    </div>
                    <Recommended />
                </div>
            </div>

        </>
    )
}

export default ArticlePage