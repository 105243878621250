import "./technicalRecommendationsStyle.css"
import { IconCheck2 } from "../../Components/Icons"

const TechnicalRecommendations = () => {
    return (
        <div className="p-3 text-primary">
            <div className="grid grid-cols-1 md:grid-cols-5 mt-10">
                <div className="col-start-1 md:col-start-2 md:col-span-3 style-container relative">
                    <div className="text-2xl font-bold">
                        Recomendaciones técnicas para una mejor experiencia con 100 Tutores
                    </div>
                    <div className="absolute hidden md:inline-flex items-start right-0 top-10 -mr-40 z-10">
                        <IconCheck2 fill="#6F8197" width={58} height={46} />
                        <IconCheck2 fill="#14336C" width={110} height={86} />
                    </div>
                    <div className="mt-10">
                        <div className="font-bold text-2xl my-2">
                            ¿Qué navegador utilizar?
                        </div>
                        <ul>
                            <li>
                                Se recomienda utilizar un dispositivo que
                                soporte el navegador Google Chrome.
                            </li>
                            <li>
                                Dada sus características técnicas, 100 Tutores
                                funciona mejor con el navegador Google Chrome, sin
                                embargo, soportamos otros navegadores también.
                            </li>
                            <li>
                                Se recomienda utilizar las versiones más recientes
                                siempre que sea posible.
                            </li>
                        </ul>
                    </div>
                    <div className="mt-10">
                        <div className="font-bold text-2xl my-2">
                            Conexión a Internet
                        </div>
                        <div className="absolute hidden md:inline-flex items-center left-0 -ml-60 mt-10 z-10">
                            <IconCheck2 fill="#D2544A" width={132} height={106} />
                            <IconCheck2 fill="#14336C" width={27} height={21} />
                        </div>
                        <ul>
                            <li>
                                El servicio de conexión a internet es un factor clave en la velocidad de respuesta del sistema. Revisar la capacidad de carga/descarga de datos (no siempre es la misma y no siempre es igual a la contratada).
                            </li>
                            <li>
                                Preferir conexiones alámbricas vs. inalámbricas.
                            </li>
                            <li>
                                Si estas por wifi, acércate lo más posible al router/módem.
                            </li>
                            <li>
                                Considerar que en algunos países la red celular es más potente que las redes domésticas.
                            </li>
                            <li>
                                Al momento de realizar tus sesiones evita tener otros dispositivos conectados a la red, no uses plataformas como Netflix, YouTube , Spotify, P2P e incluso Web WhatsApp ya que disminuyen la capacidad de tu conexión.
                            </li>
                        </ul>
                    </div>
                    <div className="mt-10">
                        <div className="font-bold text-2xl my-2">
                            Comprueba tu ancho de banda
                        </div>
                        <ul>
                            <li>
                                La característica más importante de este servicio es el ancho de banda, que determina la velocidad de comunicación. Para utilizar las herramientas del sistema en forma adecuada, se recomienda una conexión de 512 kbps o superior. Un servicio con un ancho de banda reducido o entrecortado puede llegar a dificultar el uso del sistema, hacerlo más lento e incluso inoperable.
                            </li>
                            <li>
                                Accede a sitios como <a target="_blank">https://www.speedtest.net/es</a> comprueba cuál es tu capacidad real de conexión, tanto para descargar como para cargar información.
                            </li>
                            <li>
                                Si vas a compartir contenidos, requieres de una buena capacidad de carga, no solo de descarga.
                            </li>
                            <li>
                                Muévete al sitio de tu casa u oficina en el que los resultados sean más favorables, o conéctate vía alámbrica a la red (módem) si esto es posible.
                            </li>
                        </ul>
                    </div>
                    <div className="mt-10">
                        <div className="font-bold text-2xl my-2">
                            Zona horaria
                        </div>
                        <ul>
                            <li>
                                La zona horaria se establece en base a la zona horaria que tenga el navegador que estén usando.
                            </li>
                            <li>
                                Si por alguna razón es necesario actualizar la zona horaria de tu navegador, recomendamos que vuelva a cargar el sitio web para que se tome la nueva zona horaria.
                            </li>
                        </ul>
                    </div>
                    <div className="mt-10">
                        <div className="font-bold text-2xl my-2">
                            Configuraciones recomendadas para el navegador
                        </div>
                        <ul>
                            <li>
                                Aceptar el uso de cookies
                            </li>
                            <li>
                                Habilitar el uso de micrófono y cámara por parte del navegador.
                            </li>
                            <li>
                                Borrar las cookies(archivos temporales) y el cache del navegador si se tiene problemas.
                            </li>
                            <li>
                                Es recomendable no tener desactivado el uso de ventanas emergente (Popups) en tu navegador.
                            </li>
                        </ul>
                        <div className="absolute hidden md:inline-flex items-end right-0 -mr-20 mt-10 z-10">
                            <IconCheck2 fill="#D2544A" width={58} height={46} />
                            <IconCheck2 fill="#14336C" width={37} height={29} />
                        </div>
                    </div>
                    <div className="mt-10">
                        <div className="font-bold text-2xl my-2">
                            Configuración de la computadora
                        </div>
                        <ul>
                            <li>
                                Seleccionar la entrada/salida del audio/video adecuada (¿audífonos?, ¿bocinas?, ¿micrófono auxiliar?)
                            </li>
                            <li>
                                Para una visualización óptima se aconseja una resolución gráfica de 800 x 600 pixeles.
                            </li>
                        </ul>
                    </div>
                    <div className="mt-10">
                        <div className="font-bold text-2xl my-2">
                            ¿Problemas con el audio/video?
                        </div>
                        <ul>
                            <li>
                                Comprobar que otros sonidos si se escuchan en la computadora
                            </li>
                            <li>
                                Cerrar otras aplicaciones o páginas que no sean necesarias para la sesión y pudieran estar usando el micrófono, los audífonos, el video o demandando recursos de procesamiento en la computadora.
                            </li>
                            <li>
                                Usar Google Chrome en su versión más reciente o la versión más reciente de otros navegadores soportados.
                            </li>
                            <li>
                                Probar conectarse con una nueva ventana privada o incógnito para identificar si hay algún bloqueo establecido.
                            </li>
                            <li>
                                Revisar que el navegador no haya bloqueado el uso del micrófono y la cámara para 100 Tutores.
                            </li>
                            <li>
                                Revisar que en la computadora está seleccionada la entrada/salida correcta para audio/video.
                            </li>
                            <li>
                                Utilizar alguna diadema o audífonos con micrófono para mejorar la calidad del audio.
                            </li>
                            <li>
                                Reiniciar el navegador después de revisar y corregir configuraciones.
                            </li>
                        </ul>
                    </div>
                    <div className="mt-10">
                        <div className="font-bold text-2xl my-2">
                            Algunas opciones adicionales para la solución de problemas:
                        </div>
                        <div className="absolute hidden md:inline-flex items-end left-0 -ml-40 z-10">
                            <IconCheck2 fill="#D2544A" width={58} height={46} />
                            <IconCheck2 fill="#14336C" width={37} height={29} />
                        </div>
                        <ul>
                            <li>
                                Reiniciar la computadora después de revisar la entrada/salida de audio/video.
                            </li>
                            <li>
                                Cerrar las aplicaciones que pueden estar consumiendo recursos de memoria de la computadora.
                            </li>
                            <li>
                                Cambiar de red (Wifi, Alámbrica, Celular).
                            </li>
                            <li>
                                Cambiar de computadora o dispositivo móvil.
                            </li>
                        </ul>
                    </div>
                    <div className="mt-10">
                        <div className="font-bold text-2xl my-2">
                            La página no carga
                        </div>
                        <ul>
                            <li>
                                Revisar la configuración de cookies en tu navegador. Deben estar habilitadas.
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TechnicalRecommendations