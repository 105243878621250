import React, { useEffect, useState } from "react";
import { IconArrowLeft, IconCalendar } from "../Icons";
import moment from 'moment'
import 'moment/locale/es-mx'
moment.locale('es');

const daysHeader = [
    { es: "Lun", en: "Mon", full: "Lunes" },
    { es: "Mar", en: "Tue", full: "Martes" },
    { es: "Mie", en: "Wed", full: "Miercoles" },
    { es: "Jue", en: "Thu", full: "Jueves" },
    { es: "Vie", en: "Fri", full: "Viernes" },
    { es: "Sab", en: "Sat", full: "Sabado" },
    { es: "Dom", en: "Sun", full: "Domingo" }
]

const Monb = 0b1000000
const Tueb = 0b0100000
const Wedb = 0b0010000
const Thub = 0b0001000
const Frib = 0b0000100
const Satb = 0b0000010
const Sunb = 0b0000001

const daysBinary = {
    "Mon": Monb,
    "Tue": Tueb,
    "Wed": Wedb,
    "Thu": Thub,
    "Fri": Frib,
    "Sat": Satb,
    "Sun": Sunb
}

const daysMap = {
    "Mon": "Lun",
    "Tue": "Mar",
    "Wed": "Mié",
    "Thu": "Jue",
    "Fri": "Vie",
    "Sat": "Sáb",
    "Sun": "Dom"
}

const daysArray = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"]

// 0b1 | 0b01 | 0b001 | 0b0001 | 0b00001 | 0b000001 | 0b0000001
const daysMatch = {
    [Monb | Tueb]: "De lunes a martes",
    [Monb | Tueb | Wedb]: "De lunes a miércoles",
    [Monb | Tueb | Wedb | Thub]: "De lunes a jueves",
    [Monb | Tueb | Wedb | Thub | Frib]: "De lunes a viernes",
    [Monb | Tueb | Wedb | Thub | Frib | Satb]: "De lunes a sábado",
    [Monb | Tueb | Wedb | Thub | Frib | Satb | Sunb]: "De lunes a domingo",
    [Tueb | Wedb]: "De martes a miércoles",
    [Tueb | Wedb | Thub]: "De martes a jueves",
    [Tueb | Wedb | Thub | Frib]: "De martes a viernes",
    [Tueb | Wedb | Thub | Frib | Satb]: "De martes a sábado",
    [Tueb | Wedb | Thub | Frib | Satb | Sunb]: "De martes a domingo",
    [Wedb | Thub]: "De miércoles a jueves",
    [Wedb | Thub | Frib]: "De miércoles a viernes",
    [Wedb | Thub | Frib | Satb]: "De miércoles a sábado",
    [Wedb | Thub | Frib | Satb | Sunb]: "De miércoles a domingo",
    [Thub | Frib]: "De jueves a viernes",
    [Thub | Frib | Satb]: "De jueves a sábado",
    [Thub | Frib | Satb | Sunb]: "De jueves a domingo",
    [Frib | Satb]: "De viernes a sábado",
    [Frib | Satb | Sunb]: "De viernes a domingo",
}

const Schedule = ({ setSelectedDate, selectDate, expert, backColor="bg-secondary", textColor="text-primary" }) => {
    const [current, setCurrent] = useState(new Date())

    useEffect(async () => {
    }, [expert])

    const addRemoveDays = (add) => {
        if (add) {
            setCurrent(prev => moment(prev).add(14, 'days').toDate())
        } else {
            setCurrent(prev => moment(prev).subtract(14, 'days').toDate())

        }
    }

    const formatCalendar = () => {
        const days = expert?.schedule?.days

        if (!days) return null

        let weekBinary = 0b0
        days?.forEach(day => {
            if (daysBinary[day]) {
                weekBinary |= daysBinary[day]
            }
        })

        if (daysMatch[weekBinary]) return (
            <div className="text-md text-white">
                {
                    daysMatch[weekBinary]
                }
            </div>
        )

        return (
            <div className="text-md text-white">
                {
                    days?.sort((a, b) => (daysArray.indexOf(a) - daysArray.indexOf(b))).map(day => daysMap[day]).join(', ')
                }
            </div>
        )
    }

    const renderHeader = () => {
        return daysHeader.map(({ es }) => (
            <span className="text-left font-normal text-xs text-center pt-2 pb-2 text-gray-400">{es}</span>
        ))
    }

    const renderCalendar = () => {
        var currentDate = moment(current);
        const selected = moment(selectDate)
        var weekStart = currentDate.clone().startOf('isoWeek');
        var days = [];
        for (var i = 0; i <= 13; i++) {
            days.push(moment(weekStart).add(i, 'days'));
        }

        return days.map(day => {
            const dayName = day.format("dddd").normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(".", '')

            const occuped = false// appointments.find(appointment => moment(appointment.datetime).format("DD-MM-YYYY") === day.format("DD-MM-YYYY"))
            const arrayDay = daysHeader.find(days => days.full.toUpperCase() === dayName.toUpperCase())
            const available = expert?.schedule?.days.find(dayS => dayS.toUpperCase() === arrayDay?.en.toUpperCase())
            let classN = `text-left ${textColor} rounded-full w-8 h-8 m-auto text-center text-sm pt-2 cursor-pointer `
            if (selected.format("DD MM YYYY") === day.format("DD MM YYYY"))
                classN = `${classN.replace(`${textColor}`, `${backColor} text-white`)} `
            if (moment(new Date()).subtract(1, 'days').toDate() >= day.toDate()) {
                classN = `${classN.replace(`${textColor}`, 'text-gray-400')} `
            }
            else if (occuped) {
                classN = `${classN.replace(`${textColor}`, 'text-gray-400')} `
            }
            else if (!available) {
                classN = `${classN.replace(`${textColor}`, 'text-gray-400')} `
            }
            return <span
                onClick={() => {
                    if (moment(new Date()).subtract(1, 'days').toDate() < day.toDate() && !occuped && available)
                        setSelectedDate(day.toDate())
                }}
                className={classN}>{day.format("DD")}</span>

        })
    }

    return <div className="flex flex-col w-full justify-between">
        <div className={`flex flex-row justify-between items-center rounded-md px-4 py-2 ${backColor}`}>
            <div>
                <div>
                    <div className="text-left text-md text-white mb-2">Horario semanal</div>
                    {formatCalendar()}
                </div>
            </div>
            <IconCalendar className="text-white w-6" fill="currentColor" />
        </div>
        <div className="rounded-md border-gray-300 border w-full px-6 py-5 mt-2">
            <div className="flex flex-row justify-between items-center">
                <div
                    className="cursor-pointer"
                    onClick={() => addRemoveDays(false)}
                >
                    <IconArrowLeft strokeWidth="1" className="text-gray-400"
                        style={{ height: 12 }}
                    />
                </div>
                <h3 className="text-left text-primary font-semibold capitalize">{moment(current).format("MMMM YYYY")}</h3>

                <div
                    className="cursor-pointer"
                    onClick={() => addRemoveDays(true)}
                >
                    <IconArrowLeft strokeWidth="1"
                        className="transform rotate-180 text-gray-400"
                        style={{ height: 12 }}
                    />
                </div>
            </div>
            <div className="grid grid-cols-7  ">
                {renderHeader()}
                {renderCalendar(current, setCurrent)}
            </div>
        </div>

    </div>
}
export default Schedule