import React, { useEffect, useState } from "react"
import Loader from "../../../Components/Loader";
import { useParams, useHistory, Link } from "react-router-dom";
import assets from "../../../Assets"
import RecomendedLectures from "../../../Components/RecomendedLectures"
import SubConsultations from "./SubConsultations"
import consultationMethods from "../../../integrations/consultation"
import { IconChevronRight, IconCheck2 } from "../../../Components/Icons"

const ConsultationInfo = () => {
    const { consultationId, subConsultationId } = useParams()
    const [parentConsultation, setParentConsultation] = useState(null)
    const [consultationData, setConsultationData] = useState(null)
    const [loading, setLoading] = useState(true)
    const [dataNotFound, setDataNotFound] = useState(false)

    useEffect(async () => {
        setLoading(true)
        try {
            const res = await consultationMethods.getById(consultationId, subConsultationId)

            if (subConsultationId) {
                const parentRes = await consultationMethods.getById(consultationId, null)
                setParentConsultation(parentRes)
            } if (res) {
                setConsultationData(res)
            }
            setDataNotFound(res ? false : true)
            setLoading(false)
        } catch (error) {
            setDataNotFound(true)
            setLoading(false)
        }
    }, [consultationId, subConsultationId])
    return <>
        <div className="w-full max-w-md pt-10 mx-auto sm:max-w-3xl lg:max-w-7xl lg:px-0">
            {
                loading ? <Loader /> :
                    <section className="grid grid-cols-1 md:grid-cols-5">
                        {
                            dataNotFound ? <DataNotFound /> : <>
                                <div className="md:col-span-5">
                                    <div className="block lg:flex text-primary items-center mb-8">
                                        <Link to='/sesiones' className="text-primary-light">
                                            <span>Acerca de las sesiones</span>
                                        </Link>
                                        <span className="mx-3 text-primary-light" style={{paddingTop:'1px'}}><IconChevronRight fill="currentColor" /></span>
                                        {
                                            subConsultationId ?
                                                <>
                                                    <Link to={`/sesiones/${consultationId}`} className=" text-primary-light">
                                                        <span>
                                                            {
                                                                parentConsultation?.title
                                                            }
                                                        </span>
                                                    </Link>
                                                    <span className="mx-3" style={{paddingTop:'1px'}}><IconChevronRight fill="currentColor" /></span>
                                                </> : null
                                        }
                                        <p className="text-primary-light"
                                            style={{ wordBreak: 'break-word', hyphens: 'auto', paddingBottom: '0px', fontWeight: 700 }}>
                                            {consultationData.title}
                                        </p>
                                    </div>
                                </div>
                                <div className="md:col-span-2 sm:pr-10 pb-3">
                                    <h1 className="text-2xl font-bold mb-3 text-primary"
                                        style={{ wordBreak: 'break-word', hyphens: 'auto' }}>
                                        {consultationData.title}
                                    </h1>
                                    <sub className="block text-base formatted-text-container text-primary"
                                        dangerouslySetInnerHTML={{ __html: consultationData.description }}></sub>
                                    {subConsultationId ? <SubConsultationSideContent /> : <SubConsultations src={consultationData?.subrequests} />}
                                </div>
                                <div className="md:col-span-3 mb-4 relative">
                                    {
                                        subConsultationId ? (
                                            <div className="relative">
                                                <div className="absolute hidden md:inline-flex items-end right-0 top-0 z-10">
                                                    <span>
                                                        <IconCheck2 fill="#14336C" width={111} height={89} />
                                                    </span>
                                                    <span>
                                                        <IconCheck2 fill="#BCBCBC" width={41} height={32} />
                                                    </span>
                                                </div>
                                            </div>
                                        ) : (
                                            <div className="relative">
                                                <div className="absolute hidden md:inline-flex items-center right-0 top-40 -mr-24 z-10">
                                                    <span className="-mt-10">
                                                        <IconCheck2 fill="#D2544A" width={112} height={90} />
                                                    </span>
                                                    <span className="-ml-10 mt-10 inline-block">
                                                        <IconCheck2 fill="#14336C" width={71} height={56} />
                                                    </span>
                                                    <span className="-ml-10 -mt-10 inline-block">
                                                        <IconCheck2 fill="#EBEBEB" width={15} height={12} />
                                                    </span>
                                                </div>
                                            </div>
                                        )
                                    }
                                    <img className="rounded mx-auto" src={consultationData.image} />
                                    {
                                        subConsultationId ? (
                                            <div className="absolute hidden md:inline-flex items-start left-10 -mt-10 z-10">
                                                <span>
                                                    <IconCheck2 fill="#BCBCBC" width={42} height={34} />
                                                </span>
                                                <span className="pt-2">
                                                    <IconCheck2 fill="#D2544A" width={15} height={11} />
                                                </span>
                                            </div>
                                        ) : (
                                            <div className="absolute hidden md:inline-flex items-center left-10 -mt-10 z-10">
                                                <span className="-mt-10">
                                                    <IconCheck2 fill="#D2544A" width={42} height={34} />
                                                </span>
                                                <span className="-mt-4">
                                                    <IconCheck2 fill="white" width={27} height={21} />
                                                </span>
                                            </div>
                                        )
                                    }
                                    <div className="block mt-5 formatted-text-container text-primary" dangerouslySetInnerHTML={{ __html: consultationData.resume }}>
                                    </div>
                                </div>
                            </>
                        }
                    </section>
            }
        </div>
        <RecomendedLectures template="horizontal" />
    </>
}

const SubConsultationSideContent = () => {
    let history = useHistory()

    const handleScheduleConsultationClick = () => {
        history.push("/tutores");
    }
    return <div className="mt-8">
        <button className="px-5 py-3 text-sm mb-3 text-white bg-secondary rounded-md"
            style={{ fontWeight: '600' }}
            onClick={handleScheduleConsultationClick}>Agendar una sesión</button>
        <div className="mt-4">
            <img src={assets.SatisfactionGuaranteed} />
        </div>
    </div>
}

const DataNotFound = () => {
    return <div className="col-span-2 lg:pr-10 pb-3">
        <h1 className="text-3xl font-bold mb-3">No se encontraron resultados</h1>
        <sub className="block text-base">Intente más tarde</sub>
    </div>
}

export default ConsultationInfo