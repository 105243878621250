import NavItem from "../NavItem"

const categories = {
    'temas_educativos': 'Temas educativos',
    'historias_de_exito': 'Historias de éxito',
    'inspiracion_y_motivacion': 'Inspiración y motivación',
    'tendencias': 'Tendencias',
    'cultura': 'Cultura',
    'juegos_mentales': 'Juegos mentales',
    'libros': 'Libros',
    'tips': 'Tips'
}

const categoriesTexts = {
    'temas_educativos': 'temas-educativos',
    'historias_de_exito': 'historias-de-exito',
    'inspiracion_y_motivacion': 'inspiracion-y-motivacion',
    'tendencias': 'tendencias',
    'cultura': 'cultura',
    'juegos_mentales': 'juegos-mentales',
    'libros': 'libros',
    'tips': 'tips'
}

const MenuArticles = ({ mobile }) => {
    if (mobile === true) {
        return <NavItem mobile={true} url="/articulos" label="Artículos" />
    }

    return (
        <NavItem className="flex-grow" url="/articulos" label="Artículos">
            {
                Object.keys(categories).map((x) => {
                    return <NavItem url={`/articulos/${categoriesTexts[x]}`} label={categories[x]} key={`nav-article-${x}`} />
                })
            }
        </NavItem>
    )
    //#endregion
}

export default MenuArticles