import React from "react"
import { Link, useLocation } from "react-router-dom"

const SubConsultations = ({ src, ...props }) => {
    const location = useLocation()
    return <div className="mt-8 pr-10 lg:pr-0">
        {
            src && src.map((x, i) => {
                return <div className="mb-6" key={`sub-consultation-${i}`}>
                    <Link to={`${location.pathname}/${x._id}`} className="pb-3 border-b border-gray-200 block max-w-xs text-primary"
                        style={{wordBreak:'break-word', hyphens:'auto'}}>
                        {x.title}
                    </Link>
                </div>
            })
        }
    </div>
}

export default SubConsultations
