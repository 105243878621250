import { Link } from "react-router-dom";
import Matter_Primaria from '../../Assets/Images/matters/icon-primaria.svg'
import Matter_Secundaria from '../../Assets/Images/matters/icon-secundaria.svg'
import Matter_Preparatoria from '../../Assets/Images/matters/icon-preparatoria.svg'
import Matter_Examanes from '../../Assets/Images/matters/icon-examenes.svg'
import Matter_Idiomas from '../../Assets/Images/matters/icon-idiomas.svg'
import Matter_Negocios from '../../Assets/Images/matters/icon-negocios.svg'
import Matter_Ciencias from '../../Assets/Images/matters/icon-ciencias.svg'
import Matter_Musica from '../../Assets/Images/matters/icon-musica.svg'
import Matter_Deportes from '../../Assets/Images/matters/icon-deportes.svg'
import Matter_Computacion from '../../Assets/Images/matters/icon-computacion.svg'
import Matter_Programacion from '../../Assets/Images/matters/icon-programacion.svg'
import Matter_Especial from '../../Assets/Images/matters/icon-especial.svg'

export const ServicesThemesList = {};

export const MattersThemesList = {
    "primaria": { text:"Tutores de materias de primaria", icon:Matter_Primaria },
    "secundaria": { text:"Tutores de materias de secundaria", icon:Matter_Secundaria },
    "preparatoria": { text:"Tutores de materias de preparatoria", icon:Matter_Preparatoria },
    "examenes": { text:"Tutores de preparación de exámenes", icon:Matter_Examanes },
    "idiomas": { text:"Tutores de idiomas", icon:Matter_Idiomas },
    "negocios": { text:"Tutores de negocios", icon:Matter_Negocios },
    "ciencias": { text:"Tutores de ciencias", icon:Matter_Ciencias },
    "musica": { text:"Tutores de música", icon:Matter_Musica },
    "deportes": { text:"Tutores de deportes", icon:Matter_Deportes },
    "computacion": { text:"Tutores de computación", icon:Matter_Computacion },
    "programacion": { text:"Tutores de programación", icon:Matter_Programacion },
    "especial": { text:"Tutores de habilidades de aprendizaje diferentes", icon:Matter_Especial }
};

const HoroscopeOfDay = () => {
    const styles = {
        backgroundColor: "#F5F5F5"
    }
    
    return (
        <div style={styles} className="mt-24">
            <section className="flex flex-col w-full max-w-7xl gap-4 px-10 mx-auto text-primary">
                <div className="flex flex-row text-3xl justify-center text-center nunito-font-bold mt-10">
                    Encuentra a un tutor en cualquier habilidad o tema
                </div>
                <div className="grid gap-y-14 mb-20 mt-11 grid-cols-2 xl:grid-cols-6">
                    {
                        Object.keys(MattersThemesList).map((key, idx) => (
                            <Link to={`/servicios/${key}`} key={`service-idx-${idx}`}
                                className="flex flex-col items-center">
                                <span className="flex items-center justify-center p-10 home-service-item">
                                    <img src={MattersThemesList[key].icon} className="h-16 w-16" />
                                </span>
                                <div className="mt-4 text-center">
                                    {MattersThemesList[key].text}
                                </div>
                            </Link>
                        ))
                    }
                </div>
            </section>
        </div>
    )
}

export default HoroscopeOfDay