import { useEffect, useState } from "react"
import { Link, useParams } from "react-router-dom"
import { MattersThemesList } from "../../Home/HoroscopeOfDay"
import Recommended from "../../../Components/Recommended"
import RecomendedLectures from "../../../Components/RecomendedLectures"
import { IconChevronRight, IconLoader } from "../../../Components/Icons"
import servicesIntegration from '../../../integrations/services'
import './../../Home/home.css'
import './index.css'

const ServicesDetailComponet = () => {
    const [selectedService, setSelectedService] = useState(null)
    const [serviceContent, setServiceContent] = useState(null)
    const [loadingServiceContent, setLoadingServiceContent] = useState(false)
    const [carrouselList, setCarrouselList] = useState([])
    const [carrouselIndex, setCarrouselIndex] = useState(0)
    const { service } = useParams()

    useEffect(() => {
        const serviceKey = Object.keys(MattersThemesList).find(item => item == service)
        if (serviceKey) {
            setSelectedService(MattersThemesList[serviceKey])
        }
    }, [service])

    useEffect(()=>{
        if (selectedService) {
            loadServiceContent()
            loadCarrouselList()
            window.scrollTo(0,0)
        }
    }, [selectedService])

    const loadServiceContent = async () => {
        setLoadingServiceContent(true)

        try {
            const data = await servicesIntegration.getByLabel(service)
            setServiceContent(data?.content)
        } catch (error) { }

        setLoadingServiceContent(false)
    }

    const loadCarrouselList = () => {
        const themesList = Object.keys(MattersThemesList).map(item => ({
            ...MattersThemesList[item],
            link:item
        }));

        const itemsList = Object.values(themesList)
        let index = itemsList.findIndex(item => item.link === service)

        if (index>=0) {
            while (index!=4) {
                const item = itemsList[0]
                itemsList.splice(0,1)
                itemsList.push(item)

                index = itemsList.findIndex(item => item.link === service)
            }

            for(let i=0; i<6; ++i) {
                const item2 = itemsList[i]
                itemsList.push(item2)
            }
        }
        
        setCarrouselList(itemsList)
        setCarrouselIndex(index >= 2 ? index: 2)
    }

    const updateIndex = (value) => {
        let nIndex = carrouselIndex + value
        nIndex = nIndex<2?12: (nIndex>12?2: nIndex)
        setCarrouselIndex(nIndex)
    }

    return (
        <div className="mt-8 max-w-7xl mx-auto">
            <div className="flex items-center text-primary-light px-2 md:px-6">
                <Link to="/servicios">Servicios</Link>
                <span className="mx-2" style={{paddingTop:'1px'}}>
                    <IconChevronRight fill="currentColor" />
                </span>
                <span style={{fontWeight:700, paddingBottom:'0px'}}>
                    { selectedService?.text }
                </span>
            </div>
            <div>
                <div className="text-3xl font-bold text-center text-primary">Nuestros servicios</div>
                <div className="text-lg mt-10 text-center text-primary">
                    Soluciones personalizadas para tus necesidades de 100 tutores
                </div>
            </div>
            <div className="my-10 px-2 md:px-6 flex items-center justify-between">
                <div>
                    <button className="text-primary inline-block px-2 py-10" onClick={()=>{updateIndex(-1)}}>
                        <span className="inline-block" style={{transform:'rotate(180deg)'}}>
                            <IconChevronRight fill="currentColor" />
                        </span>
                    </button>
                </div>
                <div className="flex-grow flex items-stretch overflow-hidden">
                    {
                        carrouselList.map((key, idx) => (
                            <Link to={`/servicios/${key?.link}`} key={`service-idx-${idx}`}
                                className={`flex flex-col items-center service-element index-${carrouselIndex}`}>
                                <span className={`flex items-center justify-center p-8 ${key?.link==service?'bg-secondary-lightest':'bg-riptideGray'} rounded-xl home-service-item`}>
                                    <img src={key?.icon} className="h-20 w-20 text-black" />
                                </span>
                                <span className="mt-4 text-center text-primary">{key?.text}</span>
                            </Link>
                        ))
                    }
                </div>
                <div>
                    <button className="text-primary inline-block px-2 py-10" onClick={()=>{updateIndex(1)}}>
                        <IconChevronRight fill="currentColor" />
                    </button>
                </div>
            </div>
            <div className="grid grid-cols-1 lg:grid-cols-3 px-4">
                <div className="lg:col-span-2 p-2">
                    {
                        (selectedService) && (
                            <div>
                                <div className="text-lg font-bold text-primary">{selectedService?.text}</div>
                                <div className="mt-4">
                                    {
                                        loadingServiceContent ? (
                                            <div className="flex items-center justify-center">
                                                <IconLoader className="h-6 text-primary animate-spin" />
                                            </div>
                                        ) : (
                                            <div>
                                                {
                                                    serviceContent ? (
                                                        <div className="text-primary" dangerouslySetInnerHTML={{__html:serviceContent}}></div>
                                                    ): (
                                                        <div className="text-primary">
                                                            No hay contenido para este servicio por el momento
                                                        </div>
                                                    )
                                                }
                                            </div>
                                        )
                                    }
                                </div>
                                <div className="flex flex-row-reverse mt-2">
                                    <Link to="/tutores" className="inline-flex items-center text-primary-light text-sm font-semibold">
                                        <span className="mr-3 inline-block -mt-1">Elige un tutor</span>
                                        <IconChevronRight fill="currentColor" />
                                    </Link>
                                </div>
                            </div>
                        )
                    }
                </div>
                <div className="p-2">
                    <RecomendedLectures template="vertical" />
                </div>
            </div>
            <div>
                <Recommended />
            </div>
        </div>
    )
}

export default ServicesDetailComponet