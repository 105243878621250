import React, { useContext, useState } from 'react'
import LoginModal from './LoginModal'
import RegisterModal from './RegisterModal'
import UserProfile from '../UserProfile'
import { Context } from '../../../store/useGlobalState'
import { PrimaryButton, PseudoLink } from '../../Button'
import { Link } from 'react-router-dom'
import Icon from '../../../Assets/Icon'
import { IconChevronRight } from '../../Icons'

/**
 * Sección con interacciones de usuario
 * Registro, Inicio de sesión, Información de usuario
 */
const UserAccountSection = ({ mobile }) => {
    const { globalState } = useContext(Context)
    const [LoginMenuVisible, setLoginMenuVisible] = useState(false)
    const [registerMenuVisible, setRegisterMenuVisible] = useState(false)

    //#region Mobile item menu
    if (mobile === true) {
        if (globalState.user) {
            return <>
                <div className="flex border-t pt-2 mt-2">
                    <UserProfile />
                </div>
                <div className="grid items-center col-span-12 grid-rows-2 p-3 mt-2 -m-3 text-right rounded-md hover:bg-gray-50">
                    <Link className="font-semibold whitespace-nowrap text-xs text-blueStrong" to="/servicio-al-cliente">Servicio al cliente</Link>
                    <div to="#" className="whitespace-nowrap text-primary text-xs">
                        <Icon name="phone" className="inline-block w-8 h-8 px-2 leading-normal" />
                        {globalState.contactNumber}
                    </div>
                </div>
            </>
        }
        return (<>
            <nav className="grid grid-cols-2 pt-2 mt-5 border-t">
                <a onClick={() => { setRegisterMenuVisible(true) }}
                    className="flex items-center p-3 -m-3 rounded-md hover:bg-gray-50">
                    <span className="ml-3 text-base text-blueStrong font-semibold">Registrarse</span>
                </a>
                <a onClick={() => { setLoginMenuVisible(true) }}
                    className="flex items-center p-3 -m-3 rounded-md hover:bg-gray-50">
                    <span className="ml-3 text-base text-blueStrong font-semibold">Iniciar sesión</span>
                </a>
                <div className="grid items-center col-span-12 grid-rows-2 p-3 mt-2 -m-3 text-right rounded-md hover:bg-gray-50" href="/">
                    <Link className="whitespace-nowrap text-blueStrong" to="/servicio-al-cliente">Servicio al cliente</Link>
                    <div to="#" className="whitespace-nowrap text-blueStrong">
                        <Icon name="phone" className="inline-block w-8 h-8 px-2 leading-normal" />
                        {globalState.contactNumber}
                    </div>
                </div>
            </nav>
            {LoginMenuVisible && <LoginModal
                hideSelf={() => { setLoginMenuVisible(false) }}
            />}
            <RegisterModal
                visible={registerMenuVisible}
                hideSelf={() => { setRegisterMenuVisible(false) }}
            />
        </>
        )
    }
    //#endregion

    //#region Desktop item menu
    if (globalState.user) {
        return <UserProfile />
    }

    return <>
        <PseudoLink
            onClick={() => { setRegisterMenuVisible(true) }}
            className="lg:px-2 text-sm font-bold whitespace-nowrap text-blueStrong" >
            Registrarse
            <RegisterModal
                visible={registerMenuVisible}
                hideSelf={() => { setRegisterMenuVisible(false) }}
            />
        </PseudoLink>

        <PrimaryButton
            onClick={() => { setLoginMenuVisible(true) }}
            className="lg:mr-3 mx-2 px-5 py-2.5 text-sm tracking-wider whitespace-nowrap bg-secondary"
        >
            {LoginMenuVisible && <LoginModal
                hideSelf={() => { setLoginMenuVisible(false) }}
            />}
            <span>Iniciar sesión</span>
        </PrimaryButton>
    </>
    //#endregion
}

export default UserAccountSection