import React from "react"
import ImagePaymentProcessed from "../../Pages/Profile/ImagePaymentProcessed"
import { Link } from "react-router-dom"

const MessageSuccess = ({ orderId }) => {
    return <section className="flex flex-col gap-y-6">
        <div className="flex-row text-2xl font-semibold text-center justify-center">¡Pago procesado!</div>
        <span className="text-center">Gracias por continuar con nosotros</span>
        <ImagePaymentProcessed className="h-96" />
        <p className="text-center">Tu orden #{orderId} ha sido confirmada exitosamente</p>
        <p className="text-center">Ya puedes platicar y consultar a los mejores tutores</p>
        <div className="flex flex-row justify-center mb-3">
            <Link to="/tutores" className="text-primary font-bold">
                Continuar navegando
            </Link>
        </div>
    </section>
}

export default MessageSuccess