
const TextField = ({ label, onChange, placeholder, maxLength, textClass, defaultValue, ...props }) => {
   const handleChange = (e) => {
      if (typeof onChange === 'function') {
         onChange(e.target.value)
      }
   }

   return (
      <label className="flex-row outline-none">
         <span className="font-semibold outline-none">{label}</span>
         <input
            maxLength={maxLength}
            defaultValue={defaultValue || ''}
            placeholder={placeholder}
            onChange={handleChange}
            className={"input-pattern-validator text-primary block w-full px-4 py-2.5 border border-primary-light rounded outline-none " + (textClass || "")}
            {...props}
         />
      </label>
   )
}

export default TextField