import React from "react"
import Recommended from "../../Components/Recommended"
import { Link } from "react-router-dom"
import assets from "../../Assets"
import { IconCheck2 } from "../../Components/Icons"

const ClientService = () => {
    const sideMenuItems = [
        {
            title: "Manifiesto",
            url: "manifiesto"
        },
        {
            title: "Código de ética",
            url: "codigo-de-etica"
        },
        {
            title: "Satisfacción garantizada",
            url: "satisfaccion-garantizada"
        },
        {
            title: "Testimonios",
            url: "testimonios"
        },
        {
            title: "Contáctanos",
            url: "contacto"
        },
        {
            title: "Preguntas frecuentes",
            url: "preguntas-frecuentes"
        },
        {
            title: "Cómo funciona",
            url: "como-funciona"
        },
        {
            title: "Precios",
            url: "precios"
        }

    ]
    return <>
        <div className="px-3 max-w-md px-6 pt-10 mx-auto lg:max-w-7xl sm:max-w-3xl lg:px-0">
            <section className="grid grid-cols-1 md:grid-cols-5 gap-4 text-primary">
                <div className="col-span-2 lg:pr-10 pb-3">
                    <div className="text-3xl font-semibold mb-3">Centro de servicio al cliente</div>
                    <sub className="block text-base">
                        En 100 Tutores estamos dedicados a ofrecerte la mejor atención y servicio, creemos de todo corazón que cada experiencia debe ser impecable, y estamos comprometidos a lograrlo.
                    </sub>
                    <SideMenu src={sideMenuItems} />
                </div>
                <div className="col-span-3 relative">
                    <div className="absolute hidden md:inline-flex items-end right-0 top-40 -mr-40 -mt-10 z-10">
                        <IconCheck2 fill="#D2544A" width={80} height={64} />
                        <IconCheck2 fill="#6F8197" width={37} height={27} />
                    </div>
                    <img className="rounded" src={assets.ClientServiceHeader} />
                    <div className="absolute hidden md:inline-flex flex-col items-center z-10 -mt-20 -ml-8">
                        <span>
                            <IconCheck2 fill="white" width={27} height={21} />
                        </span>
                        <span className="-mt-8">
                            <IconCheck2 fill="#D2544A" width={117} height={94} />
                        </span>
                    </div>
                    <div className="block mt-5 relative">
                        <div className="mb-5">
                            <div className="block mb-5 font-semibold">Gracias por darnos la oportunidad de servirte</div>
                            <p>
                                100 Tutores es una comunidad confiable de tutores, maestros y profesores que se dedican a empoderar vidas impulsados por la misión de ayudar a más personas a lograr sus metas de aprendizaje. No hay dos tutores en nuestra comunidad iguales, ya que cada uno tiene su propio toque personal cuando se trata de brindar servicios profesionales personalizados.
                            </p>
                        </div>
                        <div className="absolute hidden md:inline-flex items-center right-0 -mr-40 z-10">
                            <span className="pb-8 -mr-4">
                                <IconCheck2 fill="#EBEBEB" width={42} height={33} />
                            </span>
                            <IconCheck2 fill="#EBEBEB" width={70} height={56} />
                        </div>
                        <div className="mb-5">
                            <p>
                                Nuestro equipo de servicio al cliente está disponible los siete días de la semana, las 24 horas del día, para hacer de tu experiencia en nuestra plataforma, simple, directa y satisfactoria siempre.
                            </p>
                        </div>
                        <div className="mb-5">
                            <div className="block mb-5 font-semibold">Seguridad y confianza</div>
                            <p>
                                Nuestra prioridad es conectarte con los mejores tutores, maestros y profesores en la industria mientras protegemos tu seguridad y garantizamos una experiencia de cliente de primer nivel. 100 Tutores actúa como barrera entre tú y tu tutor, maestro o profesor. Nos aseguramos de que tu número de teléfono, correo electrónico y cualquier otra información personal nunca sean revelados porque administramos la conexión entre tu y cualquier persona con la que contactes en la plataforma de tutores, maestros y profesores  de 100 Tutores. Cada transacción es anónima y segura. No importa con cuántos  tutores, maestros y profesores te conectes, ten la confianza de que tu cuenta es privada y segura. Cuando te registras solo necesitas ingresar tu información personal una sola vez y listo.
                            </p>
                        </div>
                        <div className="mb-5">
                            <p>
                                ¡Contáctanos cuando quieras! Estamos para servirte. Llámanos al 3311421316 o envíanos un correo electrónico a hola@100tutores.com.
                            </p>
                        </div>
                    </div>
                    <div>
                        <img className="h-40" src={assets.SatisfactionGuaranteed} />
                    </div>
                </div>
            </section>
        </div>
        <Recommended />
    </>
}

const SideMenu = ({ src, ...props }) => {
    return <div className="mt-30 pr-10 lg:pr-0">
        {
            src.map((x, i) => {
                return <div className="mb-6" key={`sub-consultation-${i}`}>
                    <Link to={`/${x.url}`} className="pb-3 border-b block max-w-xs">{x.title}</Link>
                </div>
            })
        }
    </div>
}


export default ClientService