import React from 'react'
import { Link } from 'react-router-dom'
import assets from '../../Assets/'
import { IconCalendarTutores, IconCheck2, IconCheckRedBlue, IconCheckRedWhite, IconStrog, IconTutor } from '../../Components/Icons'
import ImageWhy1 from '../../Assets/Images/why-our-platform-3.png'
import ImageWhy2 from '../../Assets/Images/why-our-platform-4.png'
import ImageWhy3 from '../../Assets/Images/why-our-platform-5.png'
import ImageWhy4 from '../../Assets/Images/why-our-platform-6.png'
import WavesShape from '../../Assets/Images/waves-shape.png'

const WhyUs = () => {
    return (
        <section>
            <div className="p-4">
                <div className="relative">
                    <div className="text-center text-3xl">
                        <span className="mx-2 font-bold text-primary">Obtener ayuda es más fácil</span>
                        <span className="text-primary-light font-bold">de lo que piensas</span>
                    </div>
                    <div className="mt-10 md:mt-20 lg:mt-10 rounded-md bg-primary-strong p-4 pt-10 max-w-6xl mx-auto relative grid md:grid-cols-2">
                        <img src={assets.Home_WhyOurPlatform} className="hidden md:inline-block" alt="100tutores"
                            style={{
                                position: 'absolute',
                                left: '0px',
                                bottom: '0px',
                                marginLeft: '-24px'
                            }} />
                        <span className="hidden lg:inline-block" style={{
                            position: 'absolute',
                            right: '2rem',
                            top: '2rem'
                        }}>
                            <IconCheckRedWhite />
                        </span>
                        <div className="md:col-start-2 lg:-ml-20 md:-ml-4">
                            <HowItWorksRow
                                icon={<IconStrog />}
                                label="Dinos con qué estás batallando"
                                text="Conéctate con expertos en más de 300 habilidades y materias" />
                            <HowItWorksRow
                                icon={<IconTutor />}
                                label="Elige al tutor que tú quieras"
                                text="Encuentra a un tutor calificado que necesitas a las tarifas adecuadas" />
                            <HowItWorksRow
                                icon={<IconCalendarTutores />}
                                label="Haz o agenda una sesión"
                                text="Toma tu lección y sólo paga por el tiempo que necesites" />
                        </div>
                        <div className="absolute hidden md:inline-flex flex-col items-center bottom-0 -mb-4 left-0 z-10">
                            <IconCheck2 fill="white" width={41} height={33} />
                            <span className="-ml-8 mt-2">
                                <IconCheck2 fill="#6F8197" width={83} height={66} />
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="mt-10">
                <div>
                    <div>
                        <div className="text-2xl text-center text-primary font-bold px-2">
                            <div>¿Por qué nuestra comunidad de tutores es la mejor?</div>
                        </div>
                        <div className="text-black text-center max-w-5xl mx-auto mt-4 px-2">
                            Conocimiento, habilidades y experiencia. Cada uno de nuestros
                            tutores cuenta con capacidades y estilos diferentes. Encuentra el
                            que se adapte a tus necesidades y estilo de aprendizaje.
                        </div>
                        <div className=" mt-8 relative">
                            <div className="bg-primary hidden lg:block" style={{
                                position: 'absolute',
                                minHeight: '140px',
                                width: '100%',
                                zIndex: -1,
                                top: '25%'
                            }}></div>
                            <img className="hidden lg:block xl:z-0" src={WavesShape} style={{
                                position: 'absolute',
                                minHeight: '140px',
                                width: '100%',
                                zIndex: -1,
                                top: '130px'
                            }} />
                            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 max-w-7xl mx-auto px-2">
                                <ReasonRow
                                    icon={ImageWhy1}
                                    title="Tutores investigados"
                                    text="Instructores calificados, listos para ayudar."
                                />
                                <ReasonRow
                                    icon={ImageWhy2}
                                    title="Resultados reales"
                                    text="Logra tus metas más rápido con lecciones privadas, 1 a 1."
                                />
                                <ReasonRow
                                    icon={ImageWhy3}
                                    zIndex={3}
                                    title="Código de ética"
                                    text="Nuestros tutores se comprometen a tratar a los clientes con respeto, compasión y profesionalismo."
                                />
                                <ReasonRow
                                    icon={ImageWhy4}
                                    zIndex={4}
                                    title="Flexibilidad"
                                    text="Haz tu consulta desde donde quieras y cuando quieras. Fácil de usar y directo."
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-span-2 mt-24 p-2">
                <div className="flex flex-row w-full mx-auto max-w-6xl rounded-2xl bg-primary-strong text-white overflow-hidden"
                    style={{ boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.25)' }}>
                    <div className="flex flex-col px-10 pb-12">
                        <div className="flex-row mt-10 mb-4 tracking-wide text-3xl nunito-font-bold">
                            Satisfacción garantizada
                        </div>
                        <p className="flex-row leading-normal mt-2">
                            Mantenemos tu información segura con diferentes medidas de seguridad. Así
                            mismo, garantizamos nuestro servicio contigo, si no estás satisfecho
                            con tu última lección pagada, !Te devolvemos tu dinero!
                        </p>
                        <div className="mt-2">
                            Encuentra el adecuado o es gratis.
                        </div>
                        <div className="flex-row pt-10 xl:py-0 mt-6 pb-20 text-sm">
                            <Link to="/satisfaccion-garantizada" className="bg-secondary text-white rounded-md px-4 py-3 inline-flex items-center">
                                <span>Conoce más</span>
                            </Link>
                        </div>
                    </div>
                    <div className="hidden xl:flex-col xl:inline-block">
                        <div className="flex-col items-center grid-cols-1 align-middle diagonal-fractions justify-items-center md:h-80" style={{ width: '30em', overflow: 'hidden' }}>
                            <div style={{ width: '25em', position: 'absolute', height:'20em'}} className="flex flex-col items-center content-end justify-center">
                                <div className="grid flex-col col-span-2 align-middle absolute -mt-4" style={{right: '3rem', zIndex: 1 }}>
                                    <span className=""><img src={assets.Home_ShieldCheck} width="130" height="130" alt="Satisfacción garantizada" /></span>
                                </div>
                                <div className="absolute inline-flex items-end bottom-4 right-0 -mr-12 z-10">
                                    <IconCheck2 fill="#D2544A" width="102" height="81" />
                                    <span className="-ml-8 -mb-2">
                                        <IconCheck2 fill="#FFFFFF" width="39" height="32" />
                                    </span>
                                </div>
                                <div className="absolute top-20 z-10" style={{ right: '16rem' }}>
                                    <IconCheck2 width="102" height="81" />
                                </div>
                            </div>
                            <div style={{ height: '100%' }}>
                                <div style={{ background: 'rgb(29,59,112)', borderRadius: '50%', transform: 'translate(1em, -5.1em)', height: '30em', width: '30em' }}>
                                    <div style={{ background: 'rgb(37,66,117)', borderRadius: '50%', transform: 'translate(7.5em, 1em)', height: '27em', width: '27em' }}>
                                        &amp;nbsp;
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

// #region Subcomponentes
const ReasonRow = ({ title, icon, text, zIndex = 0 }) => (
    <article className="relative">
        <span className="absolute top-56 right-30 md:top-56 md:right-30 lg:right-20 z-10">
            <IconCheckRedBlue />
        </span>
        <div className="flex justify-center">
            <img src={icon} alt={title} style={{ zIndex }} />
        </div>
        <div className="text-center mt-4">
            <div className="text-primary font-bold text-xl">{title}</div>
            <p className="text-black text-sm mt-2">{text}</p>
        </div>
    </article>
)

const HowItWorksRow = ({ label, text, icon }) => (
    <div className="flex flex-row items-stretch my-8">
        <div style={{ minWidth: '80px' }} className="inline-flex items-center justify-start">
            {icon}
        </div>
        <div className="ml-2 text-white font-light">
            <div className="text-lg">
                {label}
            </div>
            <p className="text-sm mt-4">{text}</p>
        </div>
    </div>
)

// #endregion

export default WhyUs