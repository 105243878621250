import React, { useEffect, useState, useContext } from "react"
import { Link, useHistory } from 'react-router-dom'
import moment from "moment"
import { Heading, Sub } from "../../../Components/Typography";
import assets from "../../../Assets"
import ZodiacCarousel from "./ZodiacCarousel"
import RecomendedLectures from "../../../Components/RecomendedLectures"
import zodiacs from "../../../integrations/zodiac"
import Loader from "../../../Components/Loader"
import storage from "../../../utils/localStorage"

moment.locale('es');

const ZodiacInfo = ({ sign }) => {
    const zodiacList = {
        "aquarius": "Acuario",
        "pisces": "Piscis",
        "aries": "Aries",
        "taurus": "Tauro",
        "gemini": "Géminis",
        "cancer": "Cáncer",
        "leo": "Leo",
        "virgo": "Virgo",
        "libra": "Libra",
        "scorpio": "Escorpión",
        "sagittarius": "Sagitario",
        "capricorn": "Capricornio"
    }

    const [zodiacDate, setZodiacDate] = useState(moment())
    const [selectedTab, setSelectedTab] = useState(1)
    const [zodiacInfo, setZodiacInfo] = useState(null)
    const [horoscopeData, setHoroscopeData] = useState(null)
    const [loading, setLoading] = useState(true)
    const [notFound, setNotFound] = useState(false)
    const history = useHistory()

    const demoData = {
        content: <>
            <p className="text-gray-500 mb-5">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut eget pellentesque est. Phasellus viverra urna ultricies posuere sagittis. Duis malesuada mattis sem, in aliquet lacus porttitor ut. Sed non augue a felis eleifend auctor sed in arcu. Vivamus mollis dolor erat. Nullam viverra magna id volutpat commodo. Pellentesque vitae justo quis sem efficitur sagittis quis et tellus. Fusce sodales pellentesque massa sed gravida. Nulla facilisi. Ut iaculis lorem eget lectus rutrum lobortis. Suspendisse potenti. Vivamus convallis cursus nisl.
            </p>
            <p className="text-gray-500 mb-5">
                Nam a velit justo. Aliquam hendrerit, orci vitae pulvinar sodales, ex odio rhoncus tellus, at fermentum arcu nunc vitae ante. In in orci sit amet nibh egestas rutrum eget efficitur lectus. Vestibulum sit amet neque eu tortor ullamcorper pretium vel vitae diam. Quisque gravida est lectus, et vestibulum nulla bibendum id. Integer lacinia nisi eros, sed elementum lectus pretium a. Suspendisse eu vestibulum felis. Pellentesque mauris nisi, auctor feugiat eros gravida, cursus suscipit diam. Morbi vel hendrerit ex. Phasellus placerat consectetur pharetra. Duis aliquam sapien sed ex malesuada molestie. Vestibulum purus ligula, varius id auctor non, tempus at orci. Donec efficitur est enim, eu ullamcorper tellus porta a.
            </p>
        </>,
        astrology: <>
            <p className="text-gray-500">
                Cras volutpat felis vel porttitor blandit. Quisque posuere ligula vel dignissim tristique. Suspendisse vestibulum tempor velit, ut sagittis mauris commodo sed. Sed rhoncus nisi sit amet nisl ornare tristique. Nullam vitae varius lacus. Aenean placerat ipsum id sapien tempus, et interdum sem finibus. Suspendisse sed eleifend odio. Ut sit amet finibus odio. In hac habitasse platea dictumst. Praesent tristique luctus sapien vel interdum. Vestibulum nunc lorem, placerat quis dignissim nec, aliquet id felis. Pellentesque venenatis suscipit nisl, vitae luctus arcu. Suspendisse vitae tellus mi. Mauris ultricies ex velit, vitae suscipit ligula bibendum nec. Cras volutpat turpis quis dolor ultrices, vitae venenatis enim viverra. Nullam fringilla, mauris in posuere dignissim, purus nulla placerat neque, nec tincidunt enim libero ut sem.
            </p>
        </>
    }

    useEffect(async () => {
        setLoading(true)
        setNotFound(false)
        let dateObject = {
            year: zodiacDate.format("YYYY")
        }
        if (selectedTab !== 3) {
            dateObject = {
                ...dateObject,
                day: zodiacDate.format("DD"),
                month: zodiacDate.format("MM")
            }
        }
        try {
            const zodiacInfo = await zodiacs.getZodiagInfo(sign)
            setZodiacInfo(zodiacInfo)
            const res = await zodiacs.getInfo(sign, dateObject)
            if (!res) {
                setNotFound(true)
                setLoading(false)
                return
            }
            setHoroscopeData(res)
            setLoading(false)
        } catch (error) {
            console.log(error)
            setHoroscopeData(demoData)
            setLoading(false)
            setNotFound(true)
        }
    }, [zodiacDate, sign])

    useEffect(() => {
        calcCurrentDay()
    }, [selectedTab])

    const handleNavClick = (tab) => {
        setSelectedTab(tab)
    }

    const calcCurrentDay = () => {
        let addDays = 0
        switch (selectedTab) {
            case 0:
                addDays = -1
                break;
            case 2:
                addDays = 1
                break;
        }
        setZodiacDate(moment().add(addDays, "days"))
    }

    const onExpertCallClick = () => {
        storage.save("_app_experts_flag", {horoscopesLinkedToExperts:true})
        history.push('/tutores')
    }

    return <div className="px-3 max-w-md px-6 pt-3 mx-auto lg:max-w-7xl sm:max-w-3xl lg:px-0">
        <div className="flex">
            <Link to='/horoscopos' className="font-semibold" style={{ color: '#C0C9FF' }}>Horóscopos</Link>
            <p className="mx-2 font-semibold" style={{ color: '#C0C9FF' }}>&gt;</p>
            <p className="font-semibold" style={{ color: '#505DD4' }}>{zodiacList[sign]}</p>
        </div>
        <Heading>Tu horóscopo del día</Heading>
        <div className="mb-8 mt-4 font-semibold text-lg text-gray-400 text-center">Esto es lo que tu zodiaco dice para ti hoy</div>
        <ZodiacCarousel />
        <div className="grid grid-cols-1 md:gap-2 md:grid-cols-5 xl:grid-cols-12">
            <div className="col-span-3 xl:col-span-8">
                <section>
                    <div className="capitalize text-base font-semibold text-xl text-black-400 mb-4">{zodiacList[sign]}</div>
                    <label className="capitalize text-gray-500 text-md">{zodiacDate.format(selectedTab === 3 ? "YYYY" : "MMMM DD, YYYY")}</label>
                    <ZodiacNav selectedTab={selectedTab} onClick={handleNavClick} />
                    {
                        loading ? <Loader /> : notFound ? <NotFound /> : <>
                            <div className="text-gray-500 mb-5 text-xs formatted-text-container"
                                dangerouslySetInnerHTML={{ __html: horoscopeData.horoscope }}></div>
                        </>
                    }
                </section>
                <section className="mt-10">
                    <div className="text-base font-semibold text-lg text-black-400 mb-3">El signo astrológico</div>
                    <div className="text-gray-500 mb-5 formatted-text-container text-xs"
                        dangerouslySetInnerHTML={{ __html: zodiacInfo?.astrology }}></div>
                </section>
                <div className="flex flex-row-reverse mt-5 items-center text-secondary font-semibold text-sm cursor-pointer"
                    onClick={onExpertCallClick}>
                    <img className="ml-2 md:ml-3 h-4" src={assets.Icon_ChevronRight} alt="Right" />
                    <span>Agenda una consulta de astrología</span>
                </div>
            </div>
            <div className="col-span-2 xl:col-span-4">
                <RecomendedLectures />
            </div>
        </div>

    </div>
}

const NotFound = () => {
    return <div className="text-base font-semibold text-md text-gray-500">
        Por el momento este contenido no está disponible
    </div>
}

const TabItem = ({ label, selected, onClick }) => (
    <div className="items-center cursor-pointer text-center" onClick={onClick} style={{ minWidth: "5em" }}>
        <div className={`text-center ${selected ? 'text-secondary' : 'text-trueGray-500'}`}>
            <span className="text-xs" >{label}</span>
        </div>
    </div>
)

const ZodiacNav = ({ selectedTab, onClick }) => {
    return <div className="flex flex-row justify-end items-center mb-5">
        <TabItem label={"Ayer"} selected={selectedTab === 0} onClick={() => onClick(0)} />
        <TabItem label={"Hoy"} selected={selectedTab === 1} onClick={() => onClick(1)} />
        <TabItem label={"Mañana"} selected={selectedTab === 2} onClick={() => onClick(2)} />
        <TabItem label={"Año"} selected={selectedTab === 3} onClick={() => onClick(3)} />
    </div>
}

export default ZodiacInfo