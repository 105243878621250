import React, { useState, useEffect, useContext, useRef } from "react"
import Loader from "../../Components/Loader"
import { IconArrowLeft } from "../../Components/Icons"
import { ToastError, ToastInfo } from "../../Components/Toast"
import { parseErrorMsg } from "../../utils/misc"
import Dialog from "../../Layouts/Dialog"
import { IconLoader, AlertIcon } from "../../Components/Icons"
import { SecondaryButton } from "../../Components/Button"
import { IconClose } from "../../Components/Icons"
import appointmentsApi from "../../integrations/appointments"
import credit from '../../integrations/credit'
import 'moment/locale/es'
import moment from 'moment-timezone'
import ExpertAvatar from "../../Components/ExpertAvatar"
import RateAnExpert from "../../Components/RateAnExpert"
import VideoCall from "../../Components/VideoContainer"
import VoiceCall from "../../Components/VoiceContainer"
import { Context } from "../../store/useGlobalState"
import { Device } from "@twilio/voice-sdk"

moment.locale('es');
const timeFormat = "HH:mm";
const dateFormat = "YYYY/MM/DD";

let expertRejectedShowed = false
var nearAppointmentDiff = -1;

const sessionTypeMap = {
    "video_call": "Videollamada",
    "phone_call": "Llamada telefónica",
    "internet_call": "Llamada vía internet",
    "undefined": "Sin definir"
}

const sessionTypeMapMd = {
    "video_call": "videollam.",
    "phone_call": "teléfonic.",
    "internet_call": "llama por.",
    "undefined": "Sin definir"
}

const sessionTypeSm = {
    "video_call": "vide.",
    "phone_call": "telé.",
    "internet_call": "llam.",
    "undefined": "Sin definir"
}

const statusTypeMap = {
    "finalized": "Finalizada",
    "cancelled": "Cancelada",
    "confirmed": "Confirmada",
    "undefined": "Sin definir"
}

const SessionHistory = () => {
    const [isLoading, setIsLoading] = useState(true)
    const [sessions, setSessions] = useState([])
    const [monthSessions, setMonthSessions] = useState([])
    const [showAppointment, setShowAppointment] = useState(false)
    const [showRate, setShowRate] = useState(false)
    const [session, setSession] = useState(null)
    const [selectedAppointment, setSelectedAppointment] = useState(null)
    const [selectedDate, setSelectedDate] = useState(new Date())
    const [roomName, setRoomName] = useState("")
    const [token, setToken] = useState("")
    const [currentExpert, setCurrentExpert] = useState(null)
    const [call, setCall] = useState(null)
    const [calendarMode, setCalendarMode] = useState(0)
    const trHourRef = useRef(null)

    const { globalState } = useContext(Context)

    useEffect(() => {
        setIsLoading(true)
        setShowAppointment(false)
        setSelectedAppointment(null)
        fetchData()
        fetchMonthSessions()
    }, [selectedDate])

    useEffect(() => {
        scrollToCurrentHour()
    }, [calendarMode])

    const scrollToCurrentHour = () => {
        window.setTimeout(() => {
            if (trHourRef && trHourRef.current && trHourRef.current.scrollIntoView) {
                trHourRef.current.scrollIntoView({
                    behavior: 'smooth',
                    block: 'center',
                    inline: 'nearest'
                })
            }
        }, 500)
    }

    const fetchData = async () => {
        setSessions([])

        try {
            const nDate = moment(selectedDate)
            let res = []

            try {
                res = await appointmentsApi.userSchedule({
                    startDate: nDate.clone().startOf('isoWeek').subtract(1, 'days').format(dateFormat),
                    endDate: nDate.clone().endOf('isoWeek').add(1, 'days').format(dateFormat),
                })
            } catch (error) {
                ToastError("Ocurrió un error obteniendo las citas. Inténtelo de nuevo más tarde.")
                return
            }

            const localTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
            if (!localTimeZone) throw Error("Error setting the local timezone")

            let sessionMap = {};

            const sessionsToExtend = [];

            Array.from(res).forEach(session => {
                if (session.duration > 60) {
                    sessionsToExtend.push({
                        ...session,
                        duration: session.duration - 60,
                        time: moment(session.time, timeFormat).add(60, 'minutes').format(timeFormat),
                        isExtension: true
                    })
                }
            })

            const resSessions = [...res, ...sessionsToExtend];

            Array.from(resSessions).forEach(session => {
                if (!session.timeZone) return
                const timeMoment = moment(session.time, timeFormat, session.timeZone)
                let date = moment.tz(session.date, dateFormat, session.timeZone)
                date.set("hours", timeMoment.hour()).set("minutes", timeMoment.minute())

                session.time = moment.tz(session.time, timeFormat, session.timeZone).tz(localTimeZone).format(timeFormat)

                const dateTimeString = date.format("YYYY/MM/DD HH:mm")

                if (sessionMap[dateTimeString]) {
                    const settedSession = sessionMap[dateTimeString]

                    if (settedSession?.status === "cancelled" && session?.status !== "cancelled") {
                        sessionMap[dateTimeString] = session
                    }
                } else {
                    sessionMap[dateTimeString] = session
                }
            })

            setSessions(Object.values(sessionMap))
            sessionMap = null
        } catch (error) {
            console.log(error)
            setSessions([])
            ToastError(parseErrorMsg(error))
        }

        setIsLoading(false)
        scrollToCurrentHour()
    }

    const fetchMonthSessions = async () => {
        try {
            const res = await appointmentsApi.userScheduleMonth({
                startDate: moment(selectedDate).format(dateFormat)
            })

            const currMoment = moment()
            const datesOfInterest = [currMoment.format(dateFormat),
            currMoment.clone().subtract(1, 'days').format(dateFormat),
            currMoment.clone().add(1, 'days').format(dateFormat)
            ]

            const currDateSessions = res.filter(item => datesOfInterest.includes(item.date))

            if (currDateSessions?.length > 0) {
                const localTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
                if (!localTimeZone) throw Error("Error setting the local timezone")

                const currDateSession = currDateSessions.find(item => item.date === currMoment.format(dateFormat))
                if (!currDateSession) throw "Error getting the current date session"

                currDateSession.isCurrent = true
                currDateSession.expiredAppointments = 0
                currDateSession.toTakeAppointments = 0

                currDateSessions.forEach(dateSession => {
                    if (dateSession?.appointments) {
                        dateSession.appointments.forEach(session => {
                            if (!session.timeZone) return
                            const timeMoment = moment(session.time, timeFormat, session.timeZone)
                            const date = moment.tz(session.date, dateFormat, session.timeZone)
                            date.set("hours", timeMoment.hour()).set("minutes", timeMoment.minute())

                            if (date.tz(localTimeZone).format(dateFormat) !== currMoment.format(dateFormat)) return

                            session.time = moment.tz(session.time, timeFormat, session.timeZone).tz(localTimeZone).format(timeFormat)

                            if (moment(session.time, timeFormat).diff(currMoment, 'minutes') >= 0) {
                                currDateSession.toTakeAppointments = currDateSession.toTakeAppointments + 1
                            } else {
                                currDateSession.expiredAppointments = currDateSession.expiredAppointments + 1
                            }
                        })
                    }
                })
            }

            setMonthSessions(res)
        } catch (error) {
            ToastError("Ocurrió un error obteniendo las citas del mes. Inténtelo de nuevo más tarde.")
        }
    }

    const appointmentToggle = (item = null) => {
        setShowAppointment(item ? true : false)

        if (item && item.time && item.duration) {
            item.leftTime = moment(item.time, timeFormat).add(item.duration, 'minutes').diff(moment(), 'minutes')
        }

        setSelectedAppointment(item)
    }

    const appointments = () => {
        var hours = (new Array(24)).fill(0).map((it, ix) => moment({ hour: ix }))
        const currentTime = new Date()
        const currentTimeDate = moment(currentTime)

        const currentMomentDate = moment(selectedDate)
        currentMomentDate.hours(0).minutes(0).seconds(0)

        if (calendarMode === 2) {
            const endDate = currentMomentDate.clone().endOf('month').endOf('isoWeek')
                .hour(0).minute(0).second(0).toDate()
            const startDate = currentMomentDate.clone().startOf('month').startOf('isoWeek')
                .hour(0).minute(0).second(0)
            const datesList = []

            while (startDate.toDate() < endDate) {
                const startWeek = startDate.clone().startOf('isoWeek')

                const weekDays = []
                for (let i = 0; i <= 6; ++i) {
                    const next = startWeek.clone().add(i, 'days')
                    weekDays.push({
                        currentMonth: next.month() === currentMomentDate.month(),
                        dateObject: next.toDate(),
                        date: next.date(),
                        left: next.isBefore(currentTimeDate),
                        same: next.isSame(currentTimeDate),
                        right: next.isAfter(currentTimeDate),
                        dateString: next.format(dateFormat)
                    })
                }

                datesList.push(weekDays)
                startDate.add(1, 'week')
            }

            return (
                <div>
                    <table className="w-full" style={{ tableLayout: 'fixed', borderCollapse: 'collapse' }}>
                        <tbody>
                            {
                                datesList.map((tr, trx) => (
                                    <tr key={`month-mo"de-tr-${trx}`} style={{ borderBottom: '1px solid rgb(222, 226, 255)' }}>
                                        <td className="w-4 md:w-16"></td>
                                        {
                                            tr.map((td, tdx) => (
                                                <td key={`month-mode-td-${tdx}`}>
                                                    <div className="h-20 flex flex-col cursor-pointer" onClick={
                                                        () => {
                                                            setSelectedDate(td.dateObject)
                                                            setCalendarMode(0)
                                                        }
                                                    }>
                                                        <div>
                                                            <small className={td.currentMonth ? 'text-primary' : 'text-gray-300'}>
                                                                {
                                                                    td.date
                                                                }
                                                            </small>
                                                        </div>
                                                        <div>
                                                            {
                                                                (() => {
                                                                    const dateInfo = monthSessions.find(item => item.date === td.dateString)

                                                                    if (dateInfo && dateInfo?.confirmedAppointments > 0) {
                                                                        if (dateInfo?.isCurrent) {
                                                                            return (
                                                                                <>
                                                                                    {
                                                                                        (dateInfo?.expiredAppointments > 0) && (
                                                                                            <span className="m-1 inline-block p-0 md:p-2 rounded-sm md:rounded-md text-xs text-white bg-gray-300">
                                                                                                {dateInfo?.expiredAppointments}
                                                                                            </span>
                                                                                        )
                                                                                    }
                                                                                    {
                                                                                        (dateInfo?.toTakeAppointments > 0) && (
                                                                                            <span className="m-1 inline-block p-0 md:p-2 rounded-sm md:rounded-md text-xs text-white bg-primary">
                                                                                                {dateInfo?.toTakeAppointments}
                                                                                            </span>
                                                                                        )
                                                                                    }
                                                                                </>
                                                                            )
                                                                        }
                                                                        return (
                                                                            <span className={`m-1 inline-block p-0 md:p-2 rounded-sm md:rounded-md text-xs text-white ${(td.same || td.right) ? 'bg-primary' : 'bg-gray-300'}`}>
                                                                                {dateInfo?.confirmedAppointments}
                                                                            </span>
                                                                        )
                                                                    }

                                                                    return null
                                                                })()
                                                            }
                                                        </div>
                                                    </div>
                                                </td>
                                            ))
                                        }
                                        <td className="w-4 md:w-16"></td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                </div>
            )
        }

        return (
            <table className="w-full" style={{ tableLayout: 'fixed', borderCollapse: 'collapse' }}>
                <tbody>
                    {
                        hours.map((hour, i) => {
                            return (
                                <tr ref={hour.hours() === currentTime.getHours() ? trHourRef : null}>
                                    <td className="w-8 md:w-16" style={{ height: '100px' }}>
                                        <small className="text-gray-400 block" style={{ marginTop: '-55px' }}>
                                            {hour.format("h A")}
                                        </small>
                                    </td>
                                    {
                                        (new Array(calendarMode === 0 ? 1 : 7)).fill(0).map((it, ix) => (
                                            <td key={`week-item-td-${ix}`} className="border-b">
                                                <div className="relative" style={{ height: '100px' }}>
                                                    {
                                                        (() => {
                                                            const hourAppointments = sessions.filter(item => {
                                                                let apptTime = moment(item.time, timeFormat)
                                                                let wkTime = currentMomentDate

                                                                if (calendarMode === 1) {
                                                                    wkTime = currentMomentDate.clone().startOf('isoWeek').add(ix, 'days')
                                                                }

                                                                if (wkTime.format(dateFormat) !== item.date) return false

                                                                return (apptTime.hours() === hour.hours())
                                                            })

                                                            if (hourAppointments.length > 0) {
                                                                return hourAppointments.map((appointment, idx) => {
                                                                    const apptTime = moment(appointment?.time, timeFormat);

                                                                    const apptDate = moment(appointment?.date, dateFormat);
                                                                    apptDate.hour(apptTime.hour()).minute(apptTime.minute())

                                                                    const expired = (currentTimeDate.diff(apptDate.clone().add(appointment?.duration, 'minutes'), 'millisecond') > 0)
                                                                        || (appointment?.status === 'cancelled') || (appointment?.status === 'finalized');

                                                                    const momentDate = apptDate.clone()

                                                                    if (appointment?.isExtension) {
                                                                        momentDate.subtract(60, 'minutes')
                                                                    }

                                                                    const appointmentStartDate = momentDate.toDate()
                                                                    const appointmentEndDate = new Date(appointmentStartDate.getTime() + Number(appointment?.duration) * 60000)
                                                                    const currentDate = new Date()
                                                                    const isOnTime = appointmentStartDate <= currentDate && currentDate <= appointmentEndDate

                                                                    let durDec = appointment?.duration / 60;
                                                                    if (durDec > 1) durDec = 1;
                                                                    const marginTop = (moment(appointment.time, timeFormat).minutes()) * (100 / 60);

                                                                    return (
                                                                        <div className={`${expired ? 'bg-riptideGray4 text-gray-500' : (isOnTime?'agenda blink' : 'bg-primary-light3 text-black')} 
                                                                            w-full cursor-pointer absolute rounded-sm`}
                                                                            style={{
                                                                                height: (durDec * 100) + '%',
                                                                                padding: (durDec) > 0.17 ? '4px' : '0px 4px',
                                                                                display: (durDec > 0.5) ? 'block' : 'flex',
                                                                                fontSize: '12px',
                                                                                lineHeight: '15px',
                                                                                top: (marginTop + 'px'),
                                                                                zIndex: appointment?.isExtension ? 0 : (idx + (expired ? -1 : 1)) * 2
                                                                            }}

                                                                            onClick={() => {
                                                                                appointmentToggle(appointment)
                                                                            }}>
                                                                            {
                                                                                (appointment?.isExtension) ? (
                                                                                    <div className={`py-3 ${expired ? 'bg-riptideGray4' : (isOnTime?'agenda blink' : 'bg-primary-light3 text-black')} 
                                                                                    absolute w-full`} style={{ left: '0px', top: '-4px' }}></div>
                                                                                ) : (
                                                                                    <div className="flex items-start">
                                                                                        {
                                                                                            (calendarMode === 0) && (
                                                                                                <div className="flex flex-col mr-2" style={{ paddingTop: (appointment?.duration == 10 ? '2px' : '') }}>
                                                                                                    {
                                                                                                        (new Array(({
                                                                                                            '10': 2, '15': 3, '30': 7, '45': 11, '60': 16, '90': 24, '120': 32
                                                                                                        })[appointment?.duration?.toString()])).fill(0).map(item => (
                                                                                                            <span className={`${expired ? 'text-gray-500' : 'text-white'} font-bold`}
                                                                                                                style={{ fontSize: '6px', lineHeight: '6px', transform: 'rotate(-10deg)' }}>
                                                                                                                \
                                                                                                            </span>
                                                                                                        ))
                                                                                                    }
                                                                                                </div>
                                                                                            )
                                                                                        }
                                                                                        <div>
                                                                                            {
                                                                                                (calendarMode === 0) ? (
                                                                                                    <div>
                                                                                                        {moment(appointment?.time, timeFormat).format("h:mm A")}
                                                                                                        {
                                                                                                            (() => {
                                                                                                                const hoursDec = appointment?.duration / 60;
                                                                                                                if (hoursDec > 1) {
                                                                                                                    return ` ${Math.floor(appointment?.duration / 60)} Hr, ${appointment?.duration % 60} min `;
                                                                                                                } else if (hoursDec == 1) {
                                                                                                                    return ' 1 Hr '
                                                                                                                } else {
                                                                                                                    return ` ${appointment?.duration % 60} min `;
                                                                                                                }
                                                                                                            })()
                                                                                                        }
                                                                                                        {
                                                                                                            sessionTypeMap[appointment?.sessionType]
                                                                                                        }
                                                                                                    </div>
                                                                                                ) : (
                                                                                                    <div>
                                                                                                        <div className="md:hidden" style={{ fontSize: '9px' }}>
                                                                                                            <div>{moment(appointment?.time, timeFormat).format("h:mm")}</div>
                                                                                                            <div>
                                                                                                                {
                                                                                                                    (appointment?.duration / 60) >= 0.5 &&
                                                                                                                    (() => {
                                                                                                                        const hoursDec = appointment?.duration / 60;
                                                                                                                        if (hoursDec > 1) {
                                                                                                                            return ` ${Math.floor(appointment?.duration / 60)} h, ${appointment?.duration % 60} m `;
                                                                                                                        } else if (hoursDec == 1) {
                                                                                                                            return ' 1 h '
                                                                                                                        } else {
                                                                                                                            return ` ${appointment?.duration % 60} m `;
                                                                                                                        }
                                                                                                                    })()
                                                                                                                }
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <div className="hidden md:block">
                                                                                                            <div>{moment(appointment?.time, timeFormat).format("h:mm A")}</div>
                                                                                                            <div>
                                                                                                                {
                                                                                                                    (appointment?.duration / 60) >= 0.5 &&
                                                                                                                    (() => {
                                                                                                                        const hoursDec = appointment?.duration / 60;
                                                                                                                        if (hoursDec > 1) {
                                                                                                                            return ` ${Math.floor(appointment?.duration / 60)} Hr, ${appointment?.duration % 60} min `;
                                                                                                                        } else if (hoursDec == 1) {
                                                                                                                            return ' 1 Hr '
                                                                                                                        } else {
                                                                                                                            return ` ${appointment?.duration % 60} min `;
                                                                                                                        }
                                                                                                                    })()
                                                                                                                }
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <div>
                                                                                                            {
                                                                                                                (appointment?.duration / 60) >= 0.5 && (
                                                                                                                    <>
                                                                                                                        <div className="md:hidden" style={{ fontSize: '9px' }}>{sessionTypeSm[appointment?.sessionType]}</div>
                                                                                                                        <div className="hidden md:block lg:hidden">{sessionTypeMapMd[appointment?.sessionType]}</div>
                                                                                                                        <div className="hidden lg:block">{sessionTypeMap[appointment?.sessionType]}</div>
                                                                                                                    </>
                                                                                                                )
                                                                                                            }

                                                                                                        </div>
                                                                                                    </div>
                                                                                                )
                                                                                            }
                                                                                        </div>

                                                                                    </div>
                                                                                )
                                                                            }
                                                                        </div>
                                                                    )
                                                                })
                                                            }

                                                            return (<div className="p-3"></div>)
                                                        })()
                                                    }
                                                </div>
                                            </td>
                                        ))
                                    }
                                    <td className="w-8 md:w-16"></td>
                                </tr >
                            )
                        })
                    }
                </tbody >
            </table >
        )
    }

    const handleRateClose = () => {
        setShowRate(false)
    }

    const handleSessionUpdated = (showReview = false, sessionStored = null) => {
        console.log("must show review: ", showReview, ", session data: ", sessionStored, ", expert: ", currentExpert)

        if (showReview && sessionStored) {
            setShowRate(true)
            setSession(sessionStored)
        }

        setCall(null)
    }

    const handleRemoveCall = (duration, disconnectedByClient) => {
        // Launch appointments reload
        setSelectedDate(new Date());
        setCall(null)
    }

    const updateAppointmentStatus = async () => {
        try {
            if (selectedAppointment && selectedAppointment._id) {
                await appointmentsApi.setAppointmentStatus(selectedAppointment._id, 'finalized')
            }
        } catch (error) { }
    }

    const content = () => {
        const currentDate = moment(selectedDate);
        const weekStart = currentDate.clone().startOf('isoWeek');

        const days = [];
        for (var i = 0; i <= 6; i++) {
            days.push(moment(weekStart).add(i, 'days'));
        }

        return (
            <div className="py-2 w-full" style={{ borderBottom: '1px solid rgb(222, 226, 255)' }}>
                {
                    (calendarMode === 0) ? '' : (
                        <div>
                            <div className="flex flex-row justify-between items-center w-40 m-auto py-4">
                                <div className="cursor-pointer" >
                                    <button onClick={() => setSelectedDate(moment(selectedDate).add(-1, 'month').toDate())}>
                                        <IconArrowLeft className="font-bold text-primary" style={{ height: 12 }} />
                                    </button>
                                </div>
                                <div className="text-left font-semibold capitalize text-primary">{moment(selectedDate).format("MMMM YYYY")}</div>
                                <div className="cursor-pointer" >
                                    <button onClick={() => setSelectedDate(moment(selectedDate).add(1, 'month').toDate())}>
                                        <IconArrowLeft className="transform rotate-180 text-primary" style={{ height: 12 }} />
                                    </button>
                                </div>
                            </div>
                            <table className="w-full">
                                <tr>
                                    <td className="w-8 md:w-16">
                                        <div className="cursor-pointer">
                                            <button onClick={() => {
                                                setSelectedDate(moment(selectedDate).add(-1, 'week').toDate())
                                            }} disabled={calendarMode !== 1}>
                                                <IconArrowLeft className=" text-secondary-light"
                                                    style={{ height: 18 }}
                                                />
                                            </button>
                                        </div>
                                    </td>
                                    {
                                        days.map(day => {

                                            let classN = "capitalize text-gray-400 text-center text-black font-semibold  m-auto text-center text-xs md:text-sm pt-2 cursor-pointer "
                                            if (day.format(dateFormat) === moment(selectedDate).format(dateFormat) && calendarMode > 0) {
                                                classN += "text-secondary"
                                            }

                                            return (
                                                <td>
                                                    <div key={`day-key-${day.format("DD/MM/YYYY")}`} className="flex flex-col justify-center items-center" onClick={
                                                        () => { if (!isLoading) setSelectedDate(day.toDate()) }} >
                                                        <h3 className={classN}>{day.format("ddd")}</h3>
                                                        <h3 className={classN}>{calendarMode === 1 ? day.format("DD") : ''}</h3>
                                                    </div>
                                                </td>
                                            )
                                        })
                                    }
                                    <td className="w-8 md:w-16">
                                        <div className="cursor-pointer flex flex-row-reverse">
                                            <button onClick={() => setSelectedDate(moment(selectedDate).add(1, 'week').toDate())}
                                                disabled={calendarMode !== 1}>
                                                <IconArrowLeft
                                                    className="transform rotate-180  text-secondary-light"
                                                    style={{ height: 18 }}
                                                />
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                            </table>
                        </div>

                    )
                }
            </div>
        )
    }

    const modeTitle = () => {
        const currentDate = moment(selectedDate);
        const weekStart = currentDate.clone().startOf('isoWeek');

        if (calendarMode === 0) {
            return (
                <div className="text-lg capitalize font-semibold text-primary">
                    {moment(selectedDate).format("MMMM DD, YYYY")}
                </div>
            )
        } else if (calendarMode === 2) {
            return (
                <div className="text-lg capitalize font-semibold text-primary">
                    {moment(selectedDate).format("MMMM, YYYY")}
                </div>
            )
        } else if (calendarMode === 1) {
            return (
                <div className="text-lg capitalize font-semibold text-primary">
                    {
                        `${currentDate.format("MMMM")} ` +
                        `${weekStart.format("D")} - ${weekStart.add(6, 'days').format("D")}, ` +
                        `${currentDate.format("YYYY")}`
                    }
                </div>
            )
        } else {
            return ''
        }
    }

    if (roomName && token) {
        console.log('Inica videollamada desde agenda');
        return <VideoCall
            roomName={roomName}
            token={token}
            calling={currentExpert}

            removeRoom={() => {
                setToken(null)
                setRoomName(null)
                setCurrentExpert(null)
            }} />
    }

    if (call) {
        return <VoiceCall
            call={call}
            handleSessionUpdated={handleSessionUpdated}
            expert={currentExpert}
            removeCall={handleRemoveCall}
            updateAppointmentStatus={updateAppointmentStatus}
            appointmentDuration={nearAppointmentDiff != -1 ? (nearAppointmentDiff < selectedAppointment?.leftTime ? nearAppointmentDiff : selectedAppointment?.leftTime) : selectedAppointment?.leftTime} />
    }

    if (showRate) return <RateAnExpert session={session?._id} expert={{ ...currentExpert, id: currentExpert._id }} handleClose={handleRateClose} call={true} />

    return (<div className="p-10 rounded rounded-xl border-2 border-riptideGray3">
        <div className="lg:flex justify-between items-center">
            <div>
                <h2 className="text-2xl leading-loose font-semibold text-primary">Mis sesiones</h2>
                <p className="font-semibold text-primary">Tus sesiones registradas</p>
            </div>
            <div className="flex items-center">
                {
                    modeTitle()
                }
                <div className="flex items-center ml-3">
                    <button onClick={() => { setCalendarMode(0) }}>
                        <span className={`${calendarMode === 0 ? 'text-secondary' : 'text-primary'}`}>Día</span>
                    </button>
                    <button className="mx-3" onClick={() => { setCalendarMode(1) }}>
                        <span className={`${calendarMode === 1 ? 'text-secondary' : 'text-primary'}`}>Semana</span>
                    </button>
                    <button onClick={() => { setCalendarMode(2) }}>
                        <span className={`${calendarMode === 2 ? 'text-secondary' : 'text-primary'}`}>Mes</span>
                    </button>
                </div>
                <div className="ml-8 hidden">
                    <small>Ver:</small>
                    <button className="mx-3 py-1 px-3 rounded bg-primary text-white">Paradigma</button>
                    <button className="py-1 px-3 rounded bg-secondary text-white">Paradigma</button>
                </div>
            </div>
        </div>
        <div>
            {
                content()
            }
        </div>
        <div className="overflow-y-auto mt-4" style={{ height: '520px' }}>
            {isLoading ? <Loader /> : appointments()}
        </div>
        {showAppointment && <AppointmentDetail close={() => appointmentToggle()}
            setCurrentExpert={setCurrentExpert}
            globalState={globalState}
            setRoomName={setRoomName}
            setToken={setToken}
            setCall={setCall}
            appointment={selectedAppointment}
            setSelectedDate={setSelectedDate} />}
    </div>
    )
}

const AppointmentDetail = ({ appointment, close, setRoomName, setToken, setCurrentExpert, setCall, globalState, setSelectedDate }) => {
    const { status, expert, date, time, sessionType, code } = appointment
    const [showCancelConfirmation, setShowCancelConfirmation] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [appointmentStatus, setAppointmentStatus] = useState(appointment.status);

    const duration = "duration" in appointment ? appointment.duration : 0

    const momentDate = moment(date, "YYYY/MM/DD")
    const momentTime = moment(time, "HH:mm")

    if (appointment?.isExtension) momentTime.subtract(60, 'minutes');

    momentDate.add(momentTime.get("hours"), "hours").add(momentTime.get("minutes"), "minutes")

    const appointmentStartDate = momentDate.toDate()
    const appointmentEndDate = new Date(appointmentStartDate.getTime() + Number(duration) * 60000)
    const currentDate = new Date()
    const isOnTime = appointmentStartDate <= currentDate && currentDate <= appointmentEndDate
    const isLate = currentDate > appointmentEndDate
    const expired = isLate && appointment?.status === 'confirmed'

    const appointmentDate = moment(date).format("dddd D, MMMM")

    const initCall = async (isWeb) => {
        credit.getCredit().then(async ({ data: credits }) => {
            credits = Math.floor(credits);
            globalState.user.credit = credits;
            if (credits > appointment.expert.price_phonecall) {
                await setNearAppointmentDifference();

                try {
                    const device = new Device(globalState?.tokenVoice?.token, {
                        enableRingingState: true
                    });

                    //console.log(appointment.expert)
                    const call = await device.connect({
                        params: {
                            To: isWeb ? appointment.expert._id : `${appointment.expert.country_code}${appointment.expert.phone}`,
                            name: `${globalState.user.username}`,
                            image: globalState.user.image,
                            duration: nearAppointmentDiff != -1 ? (nearAppointmentDiff < appointment.duration ? nearAppointmentDiff : appointment.duration) : appointment.duration,
                            credits

                        },
                        name: `${globalState.user.username}`,
                        image: globalState.user.image,
                    });
                    setCurrentExpert(appointment.expert)

                    setCall(call)
                    expertRejectedShowed = false
                    /*setTimeout(() => {
                        call.disconnect()
                        device.disconnectAll()
                    }, 60000);
                    */
                    device.audio.incoming(true);
                    device.audio.outgoing(true);
                    device.audio.disconnect(true);
                    device.register();
                    device.on("accept", connection => {
                        console.log("accept CALL", connection);
                        connection.on("onDisconnected", connection => {
                            console.log("DISCONNECTED", connection)
                        })
                    });
                    device.on("destroyed", connection => {
                        console.log("END CALL");
                        // dispatch(rejectCall());
                    });
                    device.on("cancel", connection => {
                        setCall(null)
                        console.log("cancel CALL", connection);
                    });
                    device.on("error", err => {
                        setCall(null)
                        console.log("ERR CALL", err);
                    });
                    device.on("registered", connection => {
                        console.log("registered CALL", connection);
                    });
                    device.on("registering", connection => {
                        console.log("registering CALL", connection);
                    });

                    device.on("unregistered", connection => {
                        console.log("unregistered CALL", connection);
                    });
                    //  dispatch(setTwilioClientVoice(device));
                } catch (ex) {
                    console.log("EX VOICE", ex);
                }
            }
            else ToastError('Credito insuficiente.');
        }).catch((err) => {
            console.log(err);
            ToastError('No se pudo obtener tu crédito actual');
        });
    }

    const setNearAppointmentDifference = async () => {
        let now = moment().tz("America/Mexico_City");
        nearAppointmentDiff = -1;
        let nearAppointments = await appointmentsApi.expertSchedule(appointment.expert._id, now.format('YYYY/MM/DD'));
        if ((nearAppointments || []).length > 1) {
            const nearDate = (moment(`${now.format('YYYY-MM-DD')} ${nearAppointments[1].time}`).tz(nearAppointments[1].timeZone, true)).tz("America/Mexico_City");
            nearAppointmentDiff = nearDate.diff(now, 'minutes');
            console.log(nearDate.format(), now.format(), nearAppointmentDiff);
        }
    }

    const initVideo = async () => {
        credit.getCredit().then(async ({ data: credits }) => {
            credits = Math.floor(credits);
            globalState.user.credit = credits;
            if (credits > appointment.expert.price_videocall) {
                await setNearAppointmentDifference();
                //await appointmentsApi.setAppointmentStatus(appointment._id, 'finalized');

                const data = {
                    action: "wsVideochatCall",
                    data: {
                        token: globalState.token,
                        idReceiver: appointment.expert._id,
                        userType: "user",
                        portal: "100_tutores",
                        metadata: {
                            duration: nearAppointmentDiff != -1 ? (nearAppointmentDiff < appointment.duration ? nearAppointmentDiff : appointment.duration) : appointment.duration,
                            credits
                        },
                        appointment: appointment
                    }
                }

                localStorage.setItem(process.env.REACT_APP_CURRENT_APNT_KEY, appointment._id)
                globalState.ws.send(JSON.stringify(data))
            }
            else ToastError('Crédito insuficiente');
        }).catch(() => {
            ToastError('No se pudo obtener tu crédito actual');
        });
    }

    const renderButton = () => {
        return (
            <>
                {
                    status === "confirmed" && sessionType === 'video_call' && (
                        <button disabled={!isOnTime} onClick={initVideo}
                            className="mt-10 p-3 mx-auto w-25 rounded w-full bg-secondary text-white rounded-md"
                            style={{ opacity: isOnTime ? 1 : 0.4 }}>
                            Iniciar videollamada
                        </button>
                    )
                }
                {
                    status === "confirmed" && (sessionType === 'internet_call') && (
                        <button disabled={!isOnTime} onClick={() => initCall(sessionType === 'internet_call' ? true : false)}
                            className="mt-10 p-3 mx-auto w-25 rounded w-full bg-secondary text-white rounded-md"
                            style={{ opacity: isOnTime ? 1 : 0.4 }}>
                            Iniciar llamada
                        </button>
                    )
                }
            </>
        );
    }

    const handleCancelAppointment = async () => {
        appointmentsApi.setAppointmentStatus(appointment._id, 'cancelled')
            .then(res => {
                setAppointmentStatus('cancelled');
                setShowCancelConfirmation(prev => !prev);

                // Launch appointments reload
                setSelectedDate(new Date());
            })
            .catch(err => {
                console.log(err);
                setShowCancelConfirmation(prev => !prev);
            });
    }

    return <div className="fixed md:right-0 lg:right-0 top-0 lg:bottom-0 bg-white w-10/12 md:w-96 p-10 border-t-8 border-primary-light"
        style={{ boxShadow: '-5px 0px 4px rgba(10,11,12,0.2)', zIndex: 1000 }}>
        <div className={`${expired ? 'opacity-80' : ''}`}>
            <div className="relative">
                <IconClose onClick={close}
                    className="w-4 h-4 absolute -top-4 -right-4 cursor-pointer" stroke="black" />
                <h2 className="font-semibold text-xl text-primary">Sesión agendada</h2>
                <p className="text-gray-400" style={{ color: '#6773DF' }}>
                    {
                        expired ? 'Expirada' : statusTypeMap[status]
                    }
                </p>
                <div className="pt-10 flex items-center">
                    <ExpertAvatar src={expert.image} className="w-12 h-12" />
                    <div className="ml-5 text-primary">{`${expert.username}`}</div>
                </div>
                {
                    appointment.status === 'confirmed' && appointment?.sessionType === 'phone_call' ?
                        <div className="flex items-center justify-between text-sm mt-5">
                            <div>
                                <div className="text-gray-400 text-xs mb-2">Teléfono</div>
                                <p className="text-sm capitalize">{
                                    appointment?.telephoneNumber ? appointment.telephoneNumber : '+xx xx'
                                }</p>
                            </div>
                            <p className="border-r block" style={{ padding: '18px 2px', width: '6px' }}></p>
                            <div className="mx-4">
                                <div className="text-gray-400 text-xs mb-2">Código</div>
                                <p className="text-sm capitalize">{appointment?.code}</p>
                            </div>
                        </div> : null
                }
                <div className="py-5 border-b border-primary-light">
                    <div className="text-gray-600 font-semibold text-xs mb-2">Fecha y hora</div>
                    <div className="flex items-center justify-between text-sm">
                        <p className="capitalize text-primary">{appointmentDate}</p>
                        <p className="border-r block" style={{ padding: '18px 2px', width: '6px' }}></p>
                        <p className="text-right text-primary">
                            {
                                (() => {
                                    if (appointment?.isExtension) {
                                        return (
                                            `${moment(appointment.time, timeFormat).subtract(60, 'minutes').format("h:mm A")} - 
                                                ${moment(appointment.time, timeFormat).add(appointment.duration, "minute").format("h:mm A")}`
                                        )
                                    }

                                    return (
                                        `${moment(appointment.time, timeFormat).format("h:mm A")} - 
                                            ${moment(appointment.time, timeFormat).add(appointment.duration, "minute").format("h:mm A")}`
                                    )
                                })()
                            }
                        </p>
                    </div>
                </div>
                <div className="py-5 border-b border-primary-light">
                    <div className="text-gray-600 font-semibold text-xs mb-2">Servicios</div>
                    <div className="text-sm text-primary">
                        {sessionTypeMap[appointment?.sessionType]}
                    </div>
                </div>
                <div className="py-5">
                    {renderButton()}
                </div>
            </div>
            {
                (!isLate) &&
                <>
                    {
                        appointment.status == 'confirmed' && <div style={{ cursor: 'pointer' }} onClick={() => setShowCancelConfirmation(prev => !prev)}>
                            <div className="py-5">
                                <p className="text-primary p-3 text-center border border-primary rounded-md">Cancelar sesión</p>
                            </div>
                        </div>
                    }
                </>
            }
            {
                showCancelConfirmation && <Dialog
                    darkOverlay
                    dismisable
                    onClose={() => setShowCancelConfirmation(prev => !prev)}
                    render={({ closeDialog }) =>
                        <div className="border-l-8 border-secondary rounded-tl-xl rounded-bl-xl p-5">
                            <div className="flex items-center mb-5">
                                <AlertIcon className="w-10 m" />
                                <h2 className="ml-3 font-bold">Cancelar sesión</h2>
                            </div>
                            <p>¿Estás seguro que deseas cancelar esta sesión?</p>
                            <div className="flex justify-end mt-8">
                                <button
                                    onClick={closeDialog}
                                    className="px-6 py-3 mx-2 text-xs text-primary tracking-wider focus:outline-none rounded-md bg-gray-200 font-bold"
                                >No</button>
                                {
                                    isSubmitting ? <button
                                        type="button"
                                        className="px-6 py-3 text-xs tracking-wider focus:outline-none rounded-md bg-secondary text-white font-bold">
                                        <IconLoader
                                            className="text-white fill-current animate-spin"
                                            style={{ height: 20 }}
                                        /></button> : <button
                                            type="button"
                                            onClick={handleCancelAppointment}
                                            className="px-6 py-3 text-xs tracking-wider focus:outline-none rounded-md bg-secondary text-white font-bold"
                                        >Sí</button>
                                }

                            </div>
                        </div>
                    }
                />
            }
        </div>
    </div>
}

export default SessionHistory