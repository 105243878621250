import React from "react"
import Recommended from "../../Components/Recommended"
import { Link } from "react-router-dom"
import assets from "../../Assets"
import { IconCheck2 } from "../../Components/Icons"

const Manifest = () => {
  const sideMenuItems = [
    {
      title: "Manifiesto",
      url: "manifiesto"
    },
    {
      title: "Código de ética",
      url: "codigo-de-etica"
    },
    {
      title: "Satisfacción garantizada",
      url: "satisfaccion-garantizada"
    },
    {
      title: "Testimonios",
      url: "testimonios"
    },
    {
      title: "Contáctanos",
      url: "contacto"
    },
    {
      title: "Preguntas frecuentes",
      url: "preguntas-frecuentes"
    },
    {
      title: "Cómo funciona",
      url: "como-funciona"
    },
    {
      title: "Precios",
      url: "precios"
    }

  ]
  return <>
    <div className="px-3 max-w-md px-6 pt-10 mx-auto lg:max-w-7xl sm:max-w-3xl lg:px-0">
      <section className="grid grid-cols-1 md:grid-cols-5 text-primary">
        <div className="col-span-2 lg:pr-10 pb-3">
          <div className="text-3xl font-semibold mb-3">Manifiesto</div>
          <sub className="block text-base"></sub>
          <SideMenu src={sideMenuItems} />
        </div>
        <div className="col-span-3 relative">
          <div className="absolute hidden md:inline-flex items-end right-0 top-40 -mr-10 z-10">
            <IconCheck2 fill="#D2544A" width={80} height={64} />
            <IconCheck2 fill="#14336C" width={37} height={27} />
          </div>
          <img className="rounded" src={assets.Manifest} alt="Manifest" />
          <div className="absolute hidden md:inline-flex flex-col items-center right-0 z-10 -mt-24">
            <span>
              <IconCheck2 fill="white" width={27} height={21} />
            </span>
            <span className="-mt-8">
              <IconCheck2 fill="white" width={117} height={94} />
            </span>
          </div>
          <div className="block mt-5 relative">
            <div className="mb-5 relative">
              <div className="block mb-5 font-semibold">
                Estamos muy contentos de que estés aquí y conozcas a nuestros tutores de confianza.
              </div>
              <div className="absolute hidden md:inline-flex items-end right-0 -mr-52 z-10">
                <IconCheck2 fill="#EBEBEB" width={109} height={86} />
                <IconCheck2 fill="#6F8197" width={32} height={25} />
              </div>
              <p className="mb-2">
                En 100 Tutores estamos dedicados a ofrecer soluciones personalizadas, seguras,
                confiables y profesionales para que logres tus metas aprendizaje. Los tutores
                pueden ofrecerte ayuda en tareas, prepararte para exámenes, enseñarte,
                guiarte, trabajar contigo y apoyarte a que logres lo que te propongas.
                Cuando tomas una sesión en 100 Tutores, creas una conexión con uno de nuestros
                tutores, maestros o profesores quien te ayudará a que logres tus metas de
                aprendizaje. Nuestros tutores crean una atmósfera cálida y enriquecedora en
                la cual podrás aprender de manera segura.
              </p>
              <ol className="mb-5 pl-4">
                <li className="list-disc">Seguro</li>
                <li className="list-disc">Profesional</li>
                <li className="list-disc">Confiable</li>
                <li className="list-disc">Escuchar</li>
                <li className="list-disc">Apoyo</li>
                <li className="list-disc">Conveniente</li>
                <li className="list-disc">Personal</li>
                <li className="list-disc">Auténtico</li>
                <li className="list-disc">Comprensivo</li>
                <li className="list-disc">Amigable</li>
                <li className="list-disc">Orientación</li>
                <li className="list-disc">Confidencialidad</li>
                <li className="list-disc">Transparencia</li>
                <li className="list-disc">Compromiso</li>
                <li className="list-disc">Accesible</li>
                <li className="list-disc">Atento</li>
                <li className="list-disc">Compasivo</li>
                <li className="list-disc">Privacidad</li>
                <li className="list-disc">Comodidad</li>
                <li className="list-disc">Validación</li>
                <li className="list-disc">Respeto</li>
                <li className="list-disc">Cortesía</li>
                <li className="list-disc">Comunidad mundial</li>
                <li className="list-disc">Servicio</li>
                <li className="list-disc">Motivación</li>
                <li className="list-disc">Positivismo</li>
                <li className="list-disc">Amable</li>
              </ol>
            </div>
            <div className="mb-5">
              <p>
                Nos enorgullece nuestra comunidad de  tutores, maestros y
                profesores  atentos, compasivos, con gran experiencia y
                conocimiento. Nuestros tutores, maestros y profesores están
                aquí para apoyarte, enseñarte y motivarte a lograr tus metas
                de aprendizaje..
              </p>
            </div>
            <div className="mb-5">
              <div className="block mb-5 font-semibold">
                Estudia, aprende y haz que las cosas sucedan.
              </div>
              <p>
                Sigue adelante con confianza. Haz la prueba y toma una sesión ahora mismo.
              </p>
            </div>
          </div>
          <div>
            <img className="h-40" src={assets.SatisfactionGuaranteed} />
          </div>
        </div>
      </section>
    </div>
    <Recommended />
  </>
}

const SideMenu = ({ src, ...props }) => {
  return <ul className="mt-30 pr-10 lg:pr-0">
    {
      src.map((x, i) => {
        return <li className="mb-6" key={`sub-consultation-${i}`}>
          <Link to={`/${x.url}`} className="pb-3 border-b block max-w-xs">{x.title}</Link>
        </li>
      })
    }
  </ul>
}


export default Manifest