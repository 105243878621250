import React, { useState, useContext, useEffect, useRef } from 'react'
import { Link, useHistory } from 'react-router-dom'
import Icon from '../../Assets/Icon'
import MenuArticles from './Menus/MenuArticles'
import MenuConsultations from './Menus/MenuConsultations'
import MenuExperts from './Menus/MenuExperts'
import MenuOffers from './Menus/MenuOffers'
import MobileMenu from './MobileMenu'
import UserAccountSection from './UserAccountSection'
import { w3cwebsocket as W3CWebSocket } from "websocket";
import { Context, StoreActions } from "../../store/useGlobalState";
import VideoCall from "../../Components/VideoContainer"
import RateAnExpert from '../RateAnExpert'
import { ToastInfo } from '../Toast'

import LogoPrimary from '../../Assets/Brand/LogoPrimary.jpeg'
import MenuServices from './Menus/MenuServices'
import { IconPhoneO } from '../Icons'

export const Navbar = () => {
    const [roomName, setRoomName] = useState("")
    const [token, setToken] = useState("")
    const [currentExpert, setCurrentExpert] = useState(null)
    const [showRate, setShowRate] = useState(false)
    const [client, setClient] = useState(null)
    const { globalDispatch, globalState } = useContext(Context)
    const history = useHistory()

    useEffect(() => {
        if (globalState.user) initSocketConection();

        return () => {
            if (client && client.close) {
                try {
                    client.close()
                } catch (error) {
                    console.error("Error closing the socket connection")
                }
            }

            setClient(null);
            globalDispatch({ type: StoreActions.SET_WS, payload: { ws: null } })
            globalDispatch({ type: StoreActions.SET_TOKEN_VOICE, payload: { tokenVoice: null } })
        }
    }, [globalState.user])

    const [mobileMenuVisible, setMobileMenuVisible] = useState(false)

    const initSocketConection = () => {
        const client = new W3CWebSocket(process.env.REACT_APP_WSS_URL);

        client.onopen = (resp) => {
            globalDispatch({ type: StoreActions.SET_WS, payload: { ws: client } })
            setClient(client);

            const data = {
                action: "socketRegister",
                data: {
                    token: globalState.token,
                    userType: "user",
                    portal: "100_tutores",
                }
            }

            client.send(JSON.stringify(data))
            setInterval(() => {
                const data = {
                    action: "socketRegister",
                    data: {
                        token: globalState.token,
                        userType: "user",
                        portal: "100_tutores"
                    }
                }

                client.send(JSON.stringify(data))

            }, 60000 * 6);
        };

        client.onmessage = (message) => {
            let data = null;

            try {
                data = JSON.parse(message.data);
            } catch (error) { }

            if (!data) return;

            if (data.message === 'Register successful') {
                globalDispatch({ type: StoreActions.SET_TOKEN_VOICE, payload: { tokenVoice: data?.payload.voiceData } })
            }
            if (data.message === 'Videocall sent') {
                console.log('[videocall daata]', data);
                history.push({ pathname: '/sala-de-llamada', state: { call: { ...data?.payload, lastName: data?.payload.lastname } } })
            }
            if (data.message === 'Your call was answered') {
                if (!data.payload.accept) {
                    ToastInfo("El experto no puede atender la sesión en este momento, por favor intenta más tarde")
                    history.goBack()
                    //window.location.reload(false)
                }
            }
        };
    }

    const openMobileMenu = () => {
        setMobileMenuVisible(true)
    }
    const closeMobileMenu = () => {
        setMobileMenuVisible(false)
    }

    const handleClose = () => {
        setCurrentExpert(null)
        setShowRate(false)
    }
    if (roomName && token) return <VideoCall
        roomName={roomName}
        token={token}
        calling={currentExpert}
        removeRoom={() => {
            setToken(null)
            setRoomName(null)
            setShowRate(true)
            setCurrentExpert({ username: `${currentExpert.userName}` })
        }} />
    return (
        <div className="relative bg-white sm:pb-5 sm:pt-6 lg:py-1 border-b"
            style={{ boxShadow: '0px 2px 3px rgba(0, 0, 0, 0.2)' }}>
            <div className="max-w-7xl mx-auto">
                {showRate && <RateAnExpert expert={currentExpert} call={true} handleClose={handleClose} />}
                {/* desktop and tablets layout */}
                <div className="hidden md:block lg:flex lg:flex-row lg:flex-nowrap items-center w-full px-4">
                    <div className="hidden md:flex md:flex-row md:justify-between lg:hidden px-2">
                        <div className="mb-4">
                            <Link to="/" className="px-4 sm:px-0">
                                <span className="sr-only">100tutores</span>
                                <img className="w-auto h-20 lg:w-4/5 sm:w-9/12 xl:max-h-16 xl:max-w-md xl:px-2 object-contain" src={LogoPrimary} alt="Paradigma" />
                            </Link>
                        </div>
                        <div className="flex items-center">
                            <UserAccountSection />
                            <div className="mx-1 grid gap-2 lg:ml-2 text-right border-l lg:gap-02 lg:pl-5 xl:gap-1 pl-3 lg:pl-0">
                                <Link className="whitespace-nowrap text-xs text-blueStrong" to="/servicio-al-cliente">Servicio al cliente</Link>
                                <div to="#" className="whitespace-nowrap text-blueStrong text-xs">
                                    <Icon name="phone" className="inline-block w-8 h-8 px-2 leading-normal" />
                                    {globalState.contactNumber}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="hidden lg:block">
                        <Link to="/">
                            <span className="sr-only">100tutores</span>
                            <img className="xl:max-h-20" src={LogoPrimary} alt="Paradigma" />
                        </Link>
                    </div>
                    <div className="flex flex-row items-center content-center flex-grow">
                        <nav className="px-4 h-4 ml-2 lg:ml-0 hidden md:flex flex-row flex-grow items-end lg:justify-center gap-x-2 xl:gap-x-8 xl:mr-16">
                            <MenuOffers />
                            <MenuExperts />
                            <MenuServices />
                            <MenuConsultations />
                            <MenuArticles />
                        </nav>
                        <div className="hidden lg:flex items-center sm:ml-2">
                            <UserAccountSection />
                            <div className="mx-1 grid gap-2 lg:ml-3 text-right border-l border-secondary lg:gap-02 lg:pl-5 xl:gap-1 pl-3 lg:pl-0">
                                <Link className="whitespace-nowrap text-xs text-blueStrong" to="/servicio-al-cliente">Servicio al cliente</Link>
                                <div to="#" className="whitespace-nowrap text-blueStrong text-xs">
                                    <IconPhoneO className="inline-block w-8 h-8 px-2 leading-normal" stroke="currentColor" />
                                    {globalState.contactNumber}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* mobile layout */}
                <div className="relative flex flex-row md:hidden items-center justify-between py-1">
                    <Link to="/" className="px-4 sm:px-0">
                        <span className="sr-only">100tutores</span>
                        <img className="w-auto h-16 lg:w-4/5 md:w-auto sm:w-9/12 xl:max-h-16 xl:max-w-md xl:px-2 object-contain" src={LogoPrimary} alt="Paradigma" />
                    </Link>
                    <MobileMenu onOpen={openMobileMenu} />
                    <MobileMenu open={mobileMenuVisible} onClose={closeMobileMenu} />
                </div>
            </div>
        </div>
    )
}

export default Navbar
