import { MattersThemesList } from "../Home/HoroscopeOfDay"
import { Link } from "react-router-dom"
import Recommended from '../../Components/Recommended'
import EmailSuscribe from "../Horoscopes/Components/Emailsuscribe"
import './../Home/home.css'

const ServicesComponent = () => {
    return (
        <div className="mt-10">
            <div>
                <div className="text-2xl font-bold text-primary text-center">
                    Encuentra a un tutor en cualquier habilidad o tema
                </div>
            </div>
            <div className="mt-10 lg:px-20 max-w-7xl mx-auto">
                <div className="grid gap-y-14 mb-20 mt-11 grid-cols-2 xl:grid-cols-6">
                    {
                        Object.keys(MattersThemesList).map((key, idx) => (
                            <Link to={`/servicios/${key}`} key={`service-idx-${idx}`}
                                className="flex flex-col items-center">
                                <span className="flex items-center justify-center p-8 home-service-item bg-riptideGray">
                                    <img src={MattersThemesList[key].icon} className="h-20 w-20" />
                                </span>
                                <div className="mt-4 text-center text-primary">{MattersThemesList[key].text}</div>
                            </Link>
                        ))
                    }
                </div>
            </div>
            <div>
                <EmailSuscribe />
            </div>
            <div className="max-w-6xl mx-auto">
                <Recommended />
            </div>
        </div>
    )
}

export default ServicesComponent