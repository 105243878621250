export const misticoConfigOptions = {
    preferencesOptions: {
        label: "Preferencia",
        options: {
            male: {label:'Terapeuta masculino', selected:false}, 
            female: {label:'Terapeuta femenino', selected:false}, 
            christianity: {label:'Terapia basada en cristianidad', selected:false}, 
            lgbt: {label:'Terapeuta de la comunidad LGBTQ+', selected:false}, 
            old: {label:'Terapeuta mayor (+40)', selected:false}, 
            non_religious: {label:'Terapeuta no religioso', selected:false}
        } 
    },
    specialitiesOptions: {
        label: "Especialidad",
        options: {
            stress_anxiety: 'Stress y Ansiedad', 
            depress: 'Depresión', 
            motivation: 'Motivación, autoestima y confianza',
            family_problems: 'Conflictos familiares',
            anger: 'Manejo de la ira',
            loss_grief: 'Hacer frente a la pérdida y duelo',
            addictions: 'Hacer frente a las adicciones',
            relationships: 'Problemas de relaciones', 
            life_changes: 'Hacer frente a los cambios de la vida', 
            trauma_abuses: 'Trauma y abuso', 
            focus: 'Concentración, memoria y enfoque (TDAH)', 
            privacy: 'Problemas relacionados con la imtimidad', 
            professional_difficulties: 'Dificultades profesionales', 
            sleep_disorders: 'Desordenes de sueño', 
            eating_disorders: 'Desordenes alimenticios', 
            raising_problems: 'Problemas de crianza', 
            fatigue: 'Fatiga de la compasión', 
            bipolar_disorder: 'Desorden bipolar', 
            lgbt: 'Problemas relacionados con LGBTQ+', 
            coaching: 'Coaching ejecutivo y profesional'
        }
    },
    themeOptions: {
        love_relation_family: {
            label: "Amor, relaciones y familia",
            options: {
                divorce: "Divorcio / Ruptura",
                infidelity: "Infidelidad / Indecisión",
                find_love: "Encontrar el amor / Almas gemelas",
                lgbt_relationships: "Relaciones LGBT",
                sexual_life: "Vida Sexual / Intimidad",
                family_problems: "Hermanos / Problemas familiares",
                socialmedia_influence: "Influencia de los medios sociales",
                toxic_relationships: "Relaciones tóxicas",
            }
        },
        career_finances: {
            label: "Carrera profesional y finanzas",
            options: {
                professional_changes: "Cambios en la Carrera profesional",
                coworkers_problems: "Problemas con compañeros de trabajo",
                financial_stress: "Tensión financiera",
                bullying: "Acoso",
                wealth_manifestation: "Manifestación de riquezas",
                new_job: "Trabajo nuevo",
                office_romance: "Romance en la oficina",
                work_life_balance: "Balance trabajo / vida",
            }
        },
        life_destiny_meaning: {
            label: "Vida, destino y significado",
            options: {
                auras: "Auras",
                cakras: "Chakras",
                dreams_interpretation: "Interpretación de sueños",
                acceptance_change: "Aceptación del cambio / Salir adelante",
                law_attraction: "Ley de atracción",
                life_coach: "Coach de vida",
                lost_objects: "Objetos perdidos",
                meditation: "Meditación",
                past_life_interpretations: "Interpretación de vidas pasadas",
                spiritual_guide: "Guías espirituales",
                spirituality: "Espiritualidad",
                discovery_psychic_abilities: "Descubrimiento de habilidades psíquicas",
                transcendental_reprogramming: "Reprogramación transcendental",
                biomagnetism_therapy: "Terapia de Biomagnetismo",
                decoding_trapped_emotions: "Descodificación de emociones atrapadas",
            }
        },
        loss_grief: {
            label: "Pérdida y duelo",
            options: {
                face_death: "Enfrentar a la muerte",
                pending_goodbyes: "Despedidas pendientes",
                pet_lost: "Duelo de mascotas",
                overcoming_past: "Superando el pasado",
                connection_signals: "Señales de conexión",
                bond_healing: "Sanación de lazos"
            }
        },
    },
    instrumentsOptions: {
        label: "Instrumentos",
        options: {
            angels: "Ángeles",
            astrology: "Astrología",
            consultation_without_instruments: "Consulta sin instrumentos",
            cartomancy: "Cartomancia o lectura de cartas",
            crystals: "Cristales",
            dowsing: "Radiestesia",
            numerology: "Numerología",
            reiki: "Reiki / Sanación",
            tarot: "Tarot",
            magnets: "Imanes",
        }
    },
    lectureStyleOptions: {
        label: "Estilo de lectura",
        options: {
            compassionate: "Compasivo",
            direct: "Directo",
            expressive: "Expresivo",
            reflexive: "Reflexivo",
            wise: "Sabio",
        }
    }
}

export const themeOptionsData = {
	"materias_de_preescolar_100_tutores": {
		"showed": false,
		"selectedAll": false,
		"label": "Materias de Preescolar",
		"options": {
			"educacion_socioemocionals": {
				"label": "Educación Socioemocional",
				"selected": false
			},
			"matematicas_de_preescolar": {
				"label": "Matemáticas de Preescolar",
				"selected": false
			},
			"conocimiento_del_medio": {
				"label": "Conocimiento del Medio",
				"selected": false
			},
			"lengua_materna": {
				"label": "Lengua Materna",
				"selected": false
			},
			"artes": {
				"label": "Artes",
				"selected": false
			},
			"educacion_fisica": {
				"label": "Educación Física ",
				"selected": false
			},
			"ingles": {
				"label": "Inglés ",
				"selected": false
			},
			"formacion_civica_y_etica": {
				"label": "Formación Cívica y Ética ",
				"selected": false
			},
			"vida_saludable": {
				"label": "Vida Saludable",
				"selected": false
			},
			"lenguaje_y_comunicacion": {
				"label": "Lenguaje y Comunicación",
				"selected": false
			},
			"pensamiento_matematico": {
				"label": "Pensamiento Matemático",
				"selected": false
			}
		}
	},
	"materias_de_primaria_100_tutores": {
		"showed": false,
		"selectedAll": false,
		"label": "Materias de Primaria",
		"options": {
			"ciencias_naturales_de_primaria": {
				"label": "Ciencias Naturales de Primaria",
				"selected": false
			},
			"educacion_artistica_de_primaria": {
				"label": "Educación Artística de Primaria",
				"selected": false
			},
			"educacion_fisica_de_primaria": {
				"label": "Educación Física de Primaria",
				"selected": false
			},
			"espanol_de_primaria": {
				"label": "Español de Primaria",
				"selected": false
			},
			"exploracion_de_la_naturaleza_y_la_sociedad_de_primaria": {
				"label": "Exploración de la Naturaleza y la Sociedad de Primaria",
				"selected": false
			},
			"fonetica_de_primaria": {
				"label": "Fonética de Primaria",
				"selected": false
			},
			"formacion_civica_y_etica_de_primaria": {
				"label": "Formación Cívica y Ética de Primaria",
				"selected": false
			},
			"geografia_de_primaria": {
				"label": "Geografía de Primaria",
				"selected": false
			},
			"habilidades_de_estudio_de_primaria": {
				"label": "Habilidades de Estudio de Primaria",
				"selected": false
			},
			"historia_de_primaria": {
				"label": "Historia de Primaria",
				"selected": false
			},
			"ingles_de_primaria": {
				"label": "Inglés de Primaria",
				"selected": false
			},
			"la_entidad_donde_vivo_de_primaria": {
				"label": "La Entidad Donde Vivo de Primaria",
				"selected": false
			},
			"lectura_de_primaria": {
				"label": "Lectura de Primaria",
				"selected": false
			},
			"matematicas_de_primaria": {
				"label": "Matemáticas de Primaria",
				"selected": false
			},
			"ortografia_de_primaria": {
				"label": "Ortografía de Primaria",
				"selected": false
			},
			"redaccion_de_primaria": {
				"label": "Redacción de Primaria",
				"selected": false
			},
			"1ero_de_primaria": {
				"label": "1ero de Primaria",
				"selected": false
			},
			"2ndo_de_primaria": {
				"label": "2ndo de Primaria",
				"selected": false
			},
			"3ero_de_primaria": {
				"label": "3ero de Primaria",
				"selected": false
			},
			"4to_de_primaria": {
				"label": "4to de Primaria",
				"selected": false
			},
			"5to_de_primaria": {
				"label": "5to de Primaria",
				"selected": false
			},
			"6to_de_primaria": {
				"label": "6to de Primaria",
				"selected": false
			},
			"lectura_de_1ero_de_primaria": {
				"label": "Lectura de 1ero de Primaria",
				"selected": false
			},
			"escritura_de_1ero_de_primaria": {
				"label": "Escritura de 1ero de Primaria",
				"selected": false
			},
			"matematicas_de_1ero_de_primaria": {
				"label": "Matemáticas de 1ero de Primaria",
				"selected": false
			},
			"lectura_de_2ndo_de_primaria": {
				"label": "Lectura de 2ndo de Primaria",
				"selected": false
			},
			"escritura_de_2ndo_de_primaria": {
				"label": "Escritura de 2ndo de Primaria",
				"selected": false
			},
			"matematicas_de_2ndo_de_primaria": {
				"label": "Matemáticas de 2ndo de Primaria",
				"selected": false
			},
			"lectura_de_3ero_de_primaria": {
				"label": "Lectura de 3ero de Primaria",
				"selected": false
			},
			"escritura_de_3ero_de_primaria": {
				"label": "Escritura de 3ero de Primaria",
				"selected": false
			},
			"matematicas_de_3ero_de_primaria": {
				"label": "Matemáticas de 3ero de Primaria",
				"selected": false
			},
			"lectura_de_4to_de_primaria": {
				"label": "Lectura de 4to de Primaria",
				"selected": false
			},
			"escritura_de_4to_de_primaria": {
				"label": "Escritura de 4to de Primaria",
				"selected": false
			},
			"matematicas_4to_de_primaria": {
				"label": "Matemáticas 4to de Primaria",
				"selected": false
			},
			"lectura_de_5to_de_primaria": {
				"label": "Lectura de 5to de Primaria",
				"selected": false
			},
			"escritura_de_5to_de_primaria": {
				"label": "Escritura de 5to de Primaria",
				"selected": false
			},
			"matematicas_5to_de_primaria": {
				"label": "Matemáticas 5to de Primaria",
				"selected": false
			},
			"lectura_de_6to_de_primaria": {
				"label": "Lectura de 6to de Primaria",
				"selected": false
			},
			"escritura_de_6to_de_primaria": {
				"label": "Escritura de 6to de Primaria",
				"selected": false
			},
			"matematicas_6to_de_primaria": {
				"label": "Matemáticas 6to de Primaria",
				"selected": false
			},
			"espanol_de_1ero_de_primaria": {
				"label": "Español de 1ero de Primaria",
				"selected": false
			},
			"espanol_de_2ndo_de_primaria": {
				"label": "Español de 2ndo de Primaria",
				"selected": false
			},
			"espanol_de_3ero_de_primaria": {
				"label": "Español de 3ero de Primaria",
				"selected": false
			},
			"espanol_de_4to_de_primaria": {
				"label": "Español de 4to de Primaria",
				"selected": false
			},
			"espanol_de_5to_de_primaria": {
				"label": "Español de 5to de Primaria",
				"selected": false
			},
			"espanol_de_6to_de_primaria": {
				"label": "Español de 6to de Primaria",
				"selected": false
			}
		}
	},
	"materias_de_secundaria_100_tutores": {
		"showed": false,
		"selectedAll": false,
		"label": "Materias de Secundaria",
		"options": {
			"artes_de_secundaria": {
				"label": "Artes de Secundaria",
				"selected": false
			},
			"artes_visuales_de_secundaria": {
				"label": "Artes Visuales de Secundaria",
				"selected": false
			},
			"ciencias_de_secundaria": {
				"label": "Ciencias de Secundaria",
				"selected": false
			},
			"ciencias_i_enfasis_en_biologia_de_secundaria": {
				"label": "Ciencias I (Énfasis en Biología) de Secundaria",
				"selected": false
			},
			"ciencias_ii_enfasis_en_fisica_de_secundaria": {
				"label": "Ciencias II (Énfasis en Física) de Secundaria",
				"selected": false
			},
			"ciencias_iii_enfasis_en_quimica_de_secundaria": {
				"label": "Ciencias III (Énfasis en Química) de Secundaria",
				"selected": false
			},
			"danza_de_secundaria": {
				"label": "Danza de Secundaria",
				"selected": false
			},
			"educacion_fisica_de_secundaria": {
				"label": "Educación Física de Secundaria",
				"selected": false
			},
			"espanol_de_secundaria": {
				"label": "Español de Secundaria",
				"selected": false
			},
			"formacion_civica_y_etica_de_secundaria": {
				"label": "Formación Cívica y Ética de Secundaria",
				"selected": false
			},
			"geografia_de_mexico_y_del_mundo_de_secundaria": {
				"label": "Geografía de México y del Mundo de Secundaria",
				"selected": false
			},
			"habilidades_de_estudio_de_secundaria": {
				"label": "Habilidades de Estudio de Secundaria",
				"selected": false
			},
			"historia_de_secundaria": {
				"label": "Historia de Secundaria",
				"selected": false
			},
			"ingles_de_secundaria": {
				"label": "Inglés de Secundaria",
				"selected": false
			},
			"lectura_de_secundaria": {
				"label": "Lectura de Secundaria",
				"selected": false
			},
			"matematicas_de_secundaria": {
				"label": "Matemáticas de Secundaria",
				"selected": false
			},
			"musica_de_secundaria": {
				"label": "Música de Secundaria",
				"selected": false
			},
			"redaccion_de_secundaria": {
				"label": "Redacción de Secundaria",
				"selected": false
			},
			"teatro_de_secundaria": {
				"label": "Teatro de Secundaria",
				"selected": false
			},
			"tecnologia_de_secundaria": {
				"label": "Tecnología de Secundaria",
				"selected": false
			},
			"matematicas_de_1ero_de_secundaria": {
				"label": "Matemáticas de 1ero de Secundaria",
				"selected": false
			},
			"matematicas_de_2ndo_de_secundaria": {
				"label": "Matemáticas de 2ndo de Secundaria",
				"selected": false
			},
			"matematicas_de_3ero_de_secundaria": {
				"label": "Matemáticas de 3ero de Secundaria",
				"selected": false
			},
			"espanol_de_1er_de_secundaria": {
				"label": "Español de 1er de Secundaria",
				"selected": false
			},
			"espanol_de_2ndo_de_secundaria": {
				"label": "Español de 2ndo de Secundaria",
				"selected": false
			},
			"espanol_de_3ero_de_secundaria": {
				"label": "Español de 3ero de Secundaria",
				"selected": false
			},
			"ingles_de_1ero_de_secundaria": {
				"label": "Inglés de 1ero de Secundaria",
				"selected": false
			},
			"ingles_de_2ndo_de_secundaria": {
				"label": "Inglés de 2ndo de Secundaria",
				"selected": false
			},
			"ingles_de_3ero_de_secundaria": {
				"label": "Inglés de 3ero de Secundaria",
				"selected": false
			},
			"ciencias_de_1ero_de_secundaria": {
				"label": "Ciencias de 1ero de Secundaria",
				"selected": false
			},
			"ciencias_de_2ndo_de_secundaria": {
				"label": "Ciencias de 2ndo de Secundaria",
				"selected": false
			},
			"ciencias_de_3ero_de_secundaria": {
				"label": "Ciencias de 3ero de Secundaria",
				"selected": false
			},
			"geografia_de_mexico_y_del_mundo_de_1ero_de_secundaria": {
				"label": "Geografía de México y del Mundo de 1ero de Secundaria",
				"selected": false
			},
			"historia_de_1ero_de_secundaria": {
				"label": "Historia de 1ero de Secundaria",
				"selected": false
			},
			"historia_de_2ndo_de_secundaria": {
				"label": "Historia de 2ndo de Secundaria",
				"selected": false
			},
			"historia_de_3ero_de_secundaria": {
				"label": "Historia de 3ero de Secundaria",
				"selected": false
			}
		}
	},
	"materias_de_preparatoria_100_tutores": {
		"showed": false,
		"selectedAll": false,
		"label": "Materias de Preparatoria",
		"options": {
			"analisis_fisicos_y_quimicos": {
				"label": "Análisis Físicos y Químicos",
				"selected": false
			},
			"analisis_instrumental": {
				"label": "Análisis Instrumental",
				"selected": false
			},
			"apreciacion_artistica_i_de_preparatoria": {
				"label": "Apreciación Artística I de Preparatoria",
				"selected": false
			},
			"apreciacion_artistica_ii_de_preparatoria": {
				"label": "Apreciación Artística II de Preparatoria",
				"selected": false
			},
			"auditoria_nocturna": {
				"label": "Auditoría Nocturna",
				"selected": false
			},
			"biologia_i_de_preparatoria": {
				"label": "Biología I de Preparatoria",
				"selected": false
			},
			"biologia_ii_de_preparatoria": {
				"label": "Biología II de Preparatoria",
				"selected": false
			},
			"caja_de_restaurante_y_caja_de_recepcion": {
				"label": "Caja de Restaurante y Caja de Recepción",
				"selected": false
			},
			"ciencia_y_tecnologia_i_de_preparatoria": {
				"label": "Ciencia y Tecnología I de Preparatoria",
				"selected": false
			},
			"ciencia_y_tecnologia_ii_de_preparatoria": {
				"label": "Ciencia y Tecnología II de Preparatoria",
				"selected": false
			},
			"ciencias_sociales_i_de_preparatoria": {
				"label": "Ciencias Sociales I de Preparatoria",
				"selected": false
			},
			"ciencias_sociales_ii": {
				"label": "Ciencias Sociales II",
				"selected": false
			},
			"ciencias_sociales_ii_de_preparatoria": {
				"label": "Ciencias Sociales II de Preparatoria",
				"selected": false
			},
			"conservacion_de_documentos": {
				"label": "Conservación de Documentos",
				"selected": false
			},
			"contribuciones_de_personas_fisicas_y_morales": {
				"label": "Contribuciones de Personas Físicas y Morales",
				"selected": false
			},
			"control_de_efectivo": {
				"label": "Control de Efectivo",
				"selected": false
			},
			"correccion_y_edicion_fotografica": {
				"label": "Corrección y Edición Fotográfica",
				"selected": false
			},
			"crear_y_administrar_base_de_datos": {
				"label": "Crear y Administrar Base de Datos",
				"selected": false
			},
			"dibujo_de_planos_arquitectonicos_y_estructurales": {
				"label": "Dibujo de Planos Arquitectónicos y Estructurales",
				"selected": false
			},
			"dibujo_de_planos_de_instalaciones": {
				"label": "Dibujo de Planos de Instalaciones",
				"selected": false
			},
			"diseno_editorial": {
				"label": "Diseño Editorial",
				"selected": false
			},
			"diseno_en_2d_para_web": {
				"label": "Diseño en 2D para Web",
				"selected": false
			},
			"ecologia_de_preparatoria": {
				"label": "Ecología de Preparatoria",
				"selected": false
			},
			"elaboracion_de_estados_financieros": {
				"label": "Elaboración de Estados Financieros",
				"selected": false
			},
			"elaboracion_de_pago_de_personal": {
				"label": "Elaboración de Pago de Personal",
				"selected": false
			},
			"esem_i": {
				"label": "ESEM I",
				"selected": false
			},
			"esem_ii": {
				"label": "ESEM II",
				"selected": false
			},
			"etica_de_preparatoria": {
				"label": "Ética de Preparatoria",
				"selected": false
			},
			"fisica_i_de_preparatoria": {
				"label": "Física I de Preparatoria",
				"selected": false
			},
			"fisica_ii_de_preparatoria": {
				"label": "Física II de Preparatoria",
				"selected": false
			},
			"fisica_iii_de_preparatoria": {
				"label": "Física III de Preparatoria",
				"selected": false
			},
			"geografia_i_de_preparatoria": {
				"label": "Geografía I de Preparatoria",
				"selected": false
			},
			"geografia_ii_de_preparatoria": {
				"label": "Geografía II de Preparatoria",
				"selected": false
			},
			"gestion_de_calidad_en_el_laboratorio": {
				"label": "Gestión de Calidad en el Laboratorio",
				"selected": false
			},
			"gestion_de_personal": {
				"label": "Gestión de Personal",
				"selected": false
			},
			"historia_de_mexico_i_de_preparatoria": {
				"label": "Historia de México I de Preparatoria",
				"selected": false
			},
			"historia_de_mexico_ii_de_preparatoria": {
				"label": "Historia de México II de Preparatoria",
				"selected": false
			},
			"humanidades_i_de_preparatoria": {
				"label": "Humanidades I de Preparatoria",
				"selected": false
			},
			"humanidades_ii_de_preparatoria": {
				"label": "Humanidades II de Preparatoria",
				"selected": false
			},
			"ingenieria_fisica_i_de_preparatoria": {
				"label": "Ingeniería Física I de Preparatoria",
				"selected": false
			},
			"ingenieria_fisica_ii_de_preparatoria": {
				"label": "Ingeniería Física II de Preparatoria",
				"selected": false
			},
			"ingles_i_de_preparatoria": {
				"label": "Inglés I de Preparatoria",
				"selected": false
			},
			"ingles_ii_de_preparatoria": {
				"label": "Inglés II de Preparatoria",
				"selected": false
			},
			"ingles_iii_de_preparatoria": {
				"label": "Inglés III de Preparatoria",
				"selected": false
			},
			"ingles_iv_de_preparatoria": {
				"label": "Inglés IV de Preparatoria",
				"selected": false
			},
			"ingles_v_de_preparatoria": {
				"label": "Inglés V de Preparatoria",
				"selected": false
			},
			"ingles_vi_de_preparatoria": {
				"label": "Inglés VI de Preparatoria",
				"selected": false
			},
			"integracion_de_proyectos": {
				"label": "Integración de Proyectos",
				"selected": false
			},
			"interdisciplina_artistica_i_de_preparatoria": {
				"label": "Interdisciplina Artística I de Preparatoria",
				"selected": false
			},
			"interdisciplina_artistica_ii_de_preparatoria": {
				"label": "Interdisciplina artística II de Preparatoria",
				"selected": false
			},
			"introduccion_a_la_filosofia_de_preparatoria": {
				"label": "Introducción a la Filosofía de Preparatoria",
				"selected": false
			},
			"introduccion_al_trabajo": {
				"label": "Introducción al Trabajo",
				"selected": false
			},
			"lengua_y_literatura_ii_de_preparatoria": {
				"label": "Lengua y Literatura II de Preparatoria",
				"selected": false
			},
			"lenguaje_y_comunicacion_ii_de_preparatoria": {
				"label": "Lenguaje y Comunicación II de Preparatoria",
				"selected": false
			},
			"lenguaje_y_comunicacion_iii_de_preparatoria": {
				"label": "Lenguaje y Comunicación III de Preparatoria",
				"selected": false
			},
			"logica_y_argumentacion": {
				"label": "Lógica y Argumentación",
				"selected": false
			},
			"matematicas_i_de_preparatoria": {
				"label": "Matemáticas I de Preparatoria",
				"selected": false
			},
			"matematicas_ii_de_preparatoria": {
				"label": "Matemáticas II de Preparatoria",
				"selected": false
			},
			"matematicas_iii_de_preparatoria": {
				"label": "Matemáticas III de Preparatoria",
				"selected": false
			},
			"matematicas_iv_de_preparatoria": {
				"label": "Matemáticas IV de Preparatoria",
				"selected": false
			},
			"matematicas_v_de_preparatoria": {
				"label": "Matemáticas V de Preparatoria",
				"selected": false
			},
			"matematicas_vi_de_preparatoria": {
				"label": "Matemáticas VI de Preparatoria",
				"selected": false
			},
			"orientacion_i_de_preparatoria": {
				"label": "Orientación I de Preparatoria",
				"selected": false
			},
			"orientacion_ii_de_preparatoria": {
				"label": "Orientación II de Preparatoria",
				"selected": false
			},
			"preparacion_de_alimentos_de_preparatoria": {
				"label": "Preparación de Alimentos de Preparatoria ",
				"selected": false
			},
			"prevencion_de_riesgos_de_trabajo": {
				"label": "Prevención de Riesgos de Trabajo",
				"selected": false
			},
			"problemas_filosoficos": {
				"label": "Problemas Filosóficos",
				"selected": false
			},
			"procesos_industriales": {
				"label": "Procesos Industriales",
				"selected": false
			},
			"programacion_de_paginas_web": {
				"label": "Programación de Páginas Web",
				"selected": false
			},
			"programacion_en_java": {
				"label": "Programación en Java",
				"selected": false
			},
			"proyecto_integrador": {
				"label": "Proyecto Integrador",
				"selected": false
			},
			"proyectos_de_gestion_social_i": {
				"label": "Proyectos de Gestión Social I",
				"selected": false
			},
			"proyectos_de_gestion_social_ii": {
				"label": "Proyectos de Gestión Social II",
				"selected": false
			},
			"proyectos_de_inversion_y_finanzas_personales_i": {
				"label": "Proyectos de Inversión y Finanzas Personales I",
				"selected": false
			},
			"proyectos_de_inversion_y_finanzas_personales_ii": {
				"label": "Proyectos de Inversión y Finanzas Personales II",
				"selected": false
			},
			"quimica_del_carbono_de_preparatoria": {
				"label": "Química del Carbono de Preparatoria",
				"selected": false
			},
			"quimica_i_de_preparatoria": {
				"label": "Química I de Preparatoria",
				"selected": false
			},
			"quimica_ii_de_preparatoria": {
				"label": "Química II de Preparatoria",
				"selected": false
			},
			"quimica_iii_de_preparatoria": {
				"label": "Química III de Preparatoria",
				"selected": false
			},
			"salud_humana_i_de_preparatoria": {
				"label": "Salud Humana I de Preparatoria",
				"selected": false
			},
			"salud_humana_ii_de_preparatoria": {
				"label": "Salud humana II de Preparatoria",
				"selected": false
			},
			"servicio_de_restaurante": {
				"label": "Servicio de Restaurante",
				"selected": false
			},
			"servicios_a_usuarios": {
				"label": "Servicios a Usuarios",
				"selected": false
			},
			"sistematizacion": {
				"label": "Sistematización, Búsqueda y Recuperación de Información",
				"selected": false
			},
			"taller_de_analisis_y_produccion_de_textos_i_de_preparatoria": {
				"label": "Taller de Análisis y Producción de Textos I de Preparatoria",
				"selected": false
			},
			"taller_de_analisis_y_produccion_de_textos_ii_de_preparatoria": {
				"label": "Taller de Análisis y Producción de Textos II de Preparatoria",
				"selected": false
			},
			"tic_i_de_preparatoria": {
				"label": "TIC I de Preparatoria",
				"selected": false
			},
			"tic_ii_de_preparatoria": {
				"label": "TIC II de Preparatoria",
				"selected": false
			},
			"tic_iii_de_preparatoria": {
				"label": "TIC III de Preparatoria",
				"selected": false
			},
			"tic_iv_de_preparatoria": {
				"label": "TIC IV de Preparatoria",
				"selected": false
			}
		}
	},
	"preparacion_para_examenes_100_tutores": {
		"showed": false,
		"selectedAll": false,
		"label": "Preparación para Exámenes",
		"options": {
			"act": {
				"label": "ACT",
				"selected": false
			},
			"examen_de_admision_a_la_universidad": {
				"label": "Examen de Admisión a la Universidad",
				"selected": false
			},
			"examen_de_ingreso_a_la_universidad": {
				"label": "Examen de Ingreso a la Universidad",
				"selected": false
			},
			"exani_ii": {
				"label": "EXANI_II",
				"selected": false
			},
			"gmat": {
				"label": "GMAT",
				"selected": false
			},
			"paa_prueba_de_aptitud_academica": {
				"label": "PAA Prueba de Aptitud Académica",
				"selected": false
			},
			"sat": {
				"label": "SAT",
				"selected": false
			},
			"toefl": {
				"label": "TOEFL",
				"selected": false
			}
		}
	},
	"idiomas_100_tutores": {
		"showed": false,
		"selectedAll": false,
		"label": "Idiomas",
		"options": {
			"ingles": {
				"label": "Inglés ",
				"selected": false
			},
			"frances": {
				"label": "Francés",
				"selected": false
			},
			"aleman": {
				"label": "Alemán",
				"selected": false
			},
			"italiano": {
				"label": "Italiano",
				"selected": false
			},
			"japones": {
				"label": "Japonés",
				"selected": false
			},
			"chino_mandarin": {
				"label": "Chino Mandarín",
				"selected": false
			},
			"portugues": {
				"label": "Portugués",
				"selected": false
			},
			"lenguaje_de_senas": {
				"label": "Lenguaje de Señas",
				"selected": false
			},
			"arabe": {
				"label": "Arabe",
				"selected": false
			},
			"hindi": {
				"label": "Hindi",
				"selected": false
			},
			"coreano": {
				"label": "Coreano",
				"selected": false
			},
			"ingles_conversacional": {
				"label": "Inglés Conversacional",
				"selected": false
			},
			"hebreo": {
				"label": "Hebreo",
				"selected": false
			},
			"ruso": {
				"label": "Ruso",
				"selected": false
			}
		}
	},
	"diferencias_en_aprendizaje_100_tutores": {
		"showed": false,
		"selectedAll": false,
		"label": "Diferencias en Aprendizaje",
		"options": {
			"autismo": {
				"label": "Autismo",
				"selected": false
			},
			"dificultades_de_aprendizaje": {
				"label": "Dificultades de Aprendizaje",
				"selected": false
			},
			"dislexia": {
				"label": "Dislexia",
				"selected": false
			},
			"educacion_especial": {
				"label": "Educación Especial",
				"selected": false
			},
			"lenguaje_de_senas": {
				"label": "Lenguaje de Señas",
				"selected": false
			},
			"tda_y_tdah": {
				"label": "TDA y TDAH",
				"selected": false
			}
		}
	},
	"matematicas_100_tutores": {
		"showed": false,
		"selectedAll": false,
		"label": "Matemáticas",
		"options": {
			"algebra_i": {
				"label": "Álgebra I",
				"selected": false
			},
			"algebra_ii": {
				"label": "Álgebra II",
				"selected": false
			},
			"algebra_linear": {
				"label": "Álgebra Lineal",
				"selected": false
			},
			"bioestadistica": {
				"label": "Bioestadística",
				"selected": false
			},
			"calculo": {
				"label": "Cálculo",
				"selected": false
			},
			"ciencias_naturales": {
				"label": "Ciencias Naturales ",
				"selected": false
			},
			"contenido_basico_de_matematicas": {
				"label": "Contenido Básico de Matemáticas",
				"selected": false
			},
			"estadistica": {
				"label": "Estadística",
				"selected": false
			},
			"fisica_basada_en_algebra": {
				"label": "Física Basada en Álgebra",
				"selected": false
			},
			"fisica_basada_en_calculo": {
				"label": "Física Basada en Cálculo",
				"selected": false
			},
			"geometria": {
				"label": "Geometría",
				"selected": false
			},
			"matematicas_de_preparatoria": {
				"label": "Matemáticas de Preparatoria",
				"selected": false
			},
			"matematicas_de_primaria": {
				"label": "Matemáticas de Primaria",
				"selected": false
			},
			"matematicas_de_secundaria": {
				"label": "Matemáticas de Secundaria",
				"selected": false
			},
			"matematicas_de_universidad": {
				"label": "Matemáticas de Universidad",
				"selected": false
			},
			"pre-algebra": {
				"label": "Pre-Álgebra",
				"selected": false
			},
			"pre-calculo": {
				"label": "Pre-Cálculo",
				"selected": false
			},
			"trigonometria": {
				"label": "Trigonometría",
				"selected": false
			},
			"aritmetica": {
				"label": "Aritmetica",
				"selected": false
			},
			"probabilidad": {
				"label": "Probabilidad",
				"selected": false
			},
			"calculo_multivariable": {
				"label": "Cálculo Multivariable",
				"selected": false
			}
		}
	},
	"ciencias_100_tutores": {
		"showed": false,
		"selectedAll": false,
		"label": "Ciencias",
		"options": {
			"anatomia": {
				"label": "Anatomía",
				"selected": false
			},
			"bioestadistica": {
				"label": "Bioestadística",
				"selected": false
			},
			"biologia": {
				"label": "Biología ",
				"selected": false
			},
			"ciencias_de_la_tierra": {
				"label": "Ciencias de la Tierra",
				"selected": false
			},
			"ciencias_e_ingenieria_en_materiales": {
				"label": "Ciencias e Ingeniería en Materiales",
				"selected": false
			},
			"ciencias_naturales_de_primaria": {
				"label": "Ciencias Naturales de Primaria",
				"selected": false
			},
			"civismo_y_gobierno": {
				"label": "Civismo y Gobierno",
				"selected": false
			},
			"edicion_de_ensayos": {
				"label": "Edición de Ensayos",
				"selected": false
			},
			"fisica": {
				"label": "Física ",
				"selected": false
			},
			"fisica_basada_en_algebra": {
				"label": "Física Basada en Álgebra",
				"selected": false
			},
			"fisica_basada_en_calculo": {
				"label": "Física Basada en Cálculo",
				"selected": false
			},
			"fisiologia": {
				"label": "Fisiología",
				"selected": false
			},
			"psicologia": {
				"label": "Psicología",
				"selected": false
			},
			"quimica": {
				"label": "Química ",
				"selected": false
			},
			"quimica_fisica": {
				"label": "Química Física",
				"selected": false
			},
			"quimica_organica": {
				"label": "Química Orgánica",
				"selected": false
			},
			"ecologia": {
				"label": "Ecología",
				"selected": false
			},
			"ciencias_del_medio_ambiente": {
				"label": "Ciencias del Medio Ambiente",
				"selected": false
			},
			"ciencias_de_la_vida": {
				"label": "Ciencias de la Vida",
				"selected": false
			}
		}
	},
	"espanol_100_tutores": {
		"showed": false,
		"selectedAll": false,
		"label": "Español",
		"options": {
			"edicion_de_ensayos": {
				"label": "Edición de Ensayos",
				"selected": false
			},
			"espanol": {
				"label": "Español",
				"selected": false
			},
			"espanol_1er_semestre_a_6to_semestre_de_preparatoria_": {
				"label": "Español (1er Orgánica a 6to Semestre de Preparatoria)",
				"selected": false
			},
			"espanol_1er_a_3er_grado_de_secundaria_": {
				"label": "Español (1er a 3er Grado de Secundaria)",
				"selected": false
			},
			"espanol_4to_a_6to_grado_": {
				"label": "Español (4to a 6to Grado)",
				"selected": false
			},
			"espanol_de_universidad": {
				"label": "Español de Universidad",
				"selected": false
			},
			"fonetica": {
				"label": "Fonética ",
				"selected": false
			},
			"gramatica": {
				"label": "Gramática",
				"selected": false
			},
			"habilidades_de_estudio": {
				"label": "Habilidades de Estudio",
				"selected": false
			},
			"lectura": {
				"label": "Lectura ",
				"selected": false
			},
			"lectura_de_preparatoria_y_universidad": {
				"label": "Lectura de Preparatoria y Universidad",
				"selected": false
			},
			"lectura_de_primaria_y_secundaria": {
				"label": "Lectura de Primaria y Secundaria",
				"selected": false
			},
			"literatura": {
				"label": "Literatura",
				"selected": false
			},
			"ortografia": {
				"label": "Ortografía",
				"selected": false
			},
			"redaccion": {
				"label": "Redacción",
				"selected": false
			},
			"redaccion_de_ensayos_de_preparatoria": {
				"label": "Redacción de Ensayos de Preparatoria",
				"selected": false
			},
			"redaccion_de_ensayos_de_secundaria": {
				"label": "Redacción de Ensayos de Secundaria",
				"selected": false
			},
			"redaccion_de_ensayos_de_universidad": {
				"label": "Redacción de Ensayos de Universidad",
				"selected": false
			},
			"vocabulario": {
				"label": "Vocabulario",
				"selected": false
			},
			"concurso_de_ortografia_spelling_bee_": {
				"label": "Concurso de Ortografía (Spelling Bee)",
				"selected": false
			},
			"lectura_critica": {
				"label": "Lectura Crítica",
				"selected": false
			}
		}
	},
	"humanidades_100_tutores": {
		"showed": false,
		"selectedAll": false,
		"label": "Humanidades",
		"options": {
			"correccion_de_textos_y_edicion": {
				"label": "Corrección de Textos y Edición",
				"selected": false
			},
			"edicion_de_ensayos": {
				"label": "Edición de Ensayos",
				"selected": false
			},
			"espanol": {
				"label": "Español ",
				"selected": false
			},
			"espanol_1er_semestre_a_6to_semestre_de_preparatoria_": {
				"label": "Español (1er Semestre a 6to Semestre de Preparatoria)",
				"selected": false
			},
			"espanol_1er_a_3er_grado_de_secundaria_": {
				"label": "Español (1er a 3er Grado de Secundaria)",
				"selected": false
			},
			"espanol_4to_a_6to_grado_": {
				"label": "Español (4to a 6to Grado)",
				"selected": false
			},
			"espanol_de_universidad": {
				"label": "Español de Universidad",
				"selected": false
			},
			"fonetica": {
				"label": "Fonética ",
				"selected": false
			},
			"gramatica": {
				"label": "Gramática",
				"selected": false
			},
			"habilidades_de_estudio": {
				"label": "Habilidades de Estudio",
				"selected": false
			},
			"historia": {
				"label": "Historia",
				"selected": false
			},
			"lectura": {
				"label": "Lectura",
				"selected": false
			},
			"lectura_de_preparatoria_y_universidad": {
				"label": "Lectura de Preparatoria y Universidad",
				"selected": false
			},
			"lectura_de_primaria_y_secundaria": {
				"label": "Lectura de Primaria y Secundaria",
				"selected": false
			},
			"leyes": {
				"label": "Leyes",
				"selected": false
			},
			"literatura": {
				"label": "Literatura",
				"selected": false
			},
			"ortografia": {
				"label": "Ortografía ",
				"selected": false
			},
			"redaccion": {
				"label": "Redacción ",
				"selected": false
			},
			"redaccion_de_ensayos_de_preparatoria": {
				"label": "Redacción de Ensayos de Preparatoria",
				"selected": false
			},
			"redaccion_de_ensayos_de_secundaria": {
				"label": "Redacción de Ensayos de Secundaria",
				"selected": false
			},
			"redaccion_de_ensayos_de_universidad": {
				"label": "Redacción de Ensayos de Universidad",
				"selected": false
			},
			"teologia": {
				"label": "Teología",
				"selected": false
			},
			"derecho": {
				"label": "Derecho",
				"selected": false
			},
			"introduccion_a_psicologia": {
				"label": "Introducción a Psicología",
				"selected": false
			},
			"introduccion_a_sociologia": {
				"label": "Introducción a Sociología",
				"selected": false
			},
			"estudios_sociales": {
				"label": "Estudios Sociales",
				"selected": false
			},
			"historia_de_mexico": {
				"label": "Historia de México",
				"selected": false
			},
			"historia_mundial": {
				"label": "Historia Mundial",
				"selected": false
			},
			"historia_de_europa": {
				"label": "Historia de Europa",
				"selected": false
			}
		}
	},
	"ciencias_sociales_100_tutores": {
		"showed": false,
		"selectedAll": false,
		"label": "Ciencias Sociales",
		"options": {
			"historia": {
				"label": "Historia ",
				"selected": false
			},
			"introduccion_a_psicologia": {
				"label": "Introducción a Psicología",
				"selected": false
			},
			"introduccion_a_sociologia": {
				"label": "Introducción a Sociología",
				"selected": false
			},
			"estudios_sociales": {
				"label": "Estudios Sociales",
				"selected": false
			},
			"historia_de_mexico": {
				"label": "Historia de México",
				"selected": false
			},
			"historia_mundial": {
				"label": "Historia Mundial",
				"selected": false
			},
			"historia_de_europa": {
				"label": "Historia de Europa",
				"selected": false
			}
		}
	},
	"tecnologia_e_ingenieria_100_tutores": {
		"showed": false,
		"selectedAll": false,
		"label": "Tecnología e Ingeniería",
		"options": {
			"autocad": {
				"label": "AutoCAD",
				"selected": false
			},
			"c++": {
				"label": "C++",
				"selected": false
			},
			"ingenieria_quimica": {
				"label": "Ingeniería Química",
				"selected": false
			},
			"ingenieria_civil": {
				"label": "Ingeniería Civil",
				"selected": false
			},
			"ciencias_computacionales": {
				"label": "Ciencias Computacionales",
				"selected": false
			},
			"ingenieria_electrica": {
				"label": "Ingeniería Eléctrica",
				"selected": false
			},
			"java": {
				"label": "Java",
				"selected": false
			},
			"python": {
				"label": "Python",
				"selected": false
			},
			"ruby": {
				"label": "Ruby",
				"selected": false
			},
			"sql": {
				"label": "SQL",
				"selected": false
			},
			"termodinamica": {
				"label": "Termodinámica",
				"selected": false
			},
			"ingenieria_mecanica": {
				"label": "Ingeniería Mecánica",
				"selected": false
			}
		}
	},
	"programacion_100_tutores": {
		"showed": false,
		"selectedAll": false,
		"label": "Programación ",
		"options": {
			"programacion_para_principiantes": {
				"label": "Programación para Principiantes",
				"selected": false
			},
			"minecraft": {
				"label": "Minecraft",
				"selected": false
			},
			"roblox": {
				"label": "Roblox",
				"selected": false
			},
			"unity": {
				"label": "Unity",
				"selected": false
			},
			"programacion": {
				"label": "Programación",
				"selected": false
			}
		}
	},
	"negocios_100_tutores": {
		"showed": false,
		"selectedAll": false,
		"label": "Negocios",
		"options": {
			"contabilidad": {
				"label": "Contabilidad",
				"selected": false
			},
			"calculo_para_negocios": {
				"label": "Cálculo para Negocios",
				"selected": false
			},
			"economia": {
				"label": "Economía",
				"selected": false
			},
			"finanzas": {
				"label": "Finanzas",
				"selected": false
			},
			"marketing": {
				"label": "Marketing",
				"selected": false
			},
			"mba": {
				"label": "MBA",
				"selected": false
			},
			"microsoft_excel": {
				"label": "Microsoft Excel",
				"selected": false
			},
			"microsoft_powerpoint": {
				"label": "Microsoft PowerPoint",
				"selected": false
			},
			"microsoft_word": {
				"label": "Microsoft Word",
				"selected": false
			},
			"windows": {
				"label": "Windows",
				"selected": false
			},
			"cadena_de_suministro": {
				"label": "Cadena de Suministro",
				"selected": false
			},
			"introduccion_a_contabilidad": {
				"label": "Introducción a Contabilidad",
				"selected": false
			},
			"introduccion_a_economia": {
				"label": "Introducción a Economía",
				"selected": false
			}
		}
	},
	"enriquecimiento_100_tutores": {
		"showed": false,
		"selectedAll": false,
		"label": "Enriquecimiento",
		"options": {
			"ajedrez": {
				"label": "Ajedrez",
				"selected": false
			},
			"arte": {
				"label": "Arte",
				"selected": false
			},
			"musica": {
				"label": "Música",
				"selected": false
			},
			"historia_de_la_musica": {
				"label": "Historia de la Música",
				"selected": false
			},
			"teoria_de_la_musica": {
				"label": "Teoría de la Música",
				"selected": false
			},
			"expresion_oral": {
				"label": "Expresion Oral",
				"selected": false
			},
			"razonamiento_cuantitativo": {
				"label": "Razonamiento Cuantitativo",
				"selected": false
			},
			"canto": {
				"label": "Canto",
				"selected": false
			},
			"fotografia": {
				"label": "Fotografía",
				"selected": false
			},
			"photoshop": {
				"label": "Photoshop",
				"selected": false
			},
			"medios_sociales": {
				"label": "Medios Sociales",
				"selected": false
			},
			"facebook": {
				"label": "Facebook",
				"selected": false
			},
			"instagram": {
				"label": "Instagram",
				"selected": false
			},
			"snapchat": {
				"label": "Snapchat",
				"selected": false
			},
			"tiktok": {
				"label": "TikTok",
				"selected": false
			}
		}
	},
	"materias_populares_100_tutores": {
		"showed": false,
		"selectedAll": false,
		"label": "Materias Populares",
		"options": {
			"bioestadistica": {
				"label": "Bioestadística",
				"selected": false
			},
			"minecraft": {
				"label": "Minecraft",
				"selected": false
			},
			"preescolar": {
				"label": "Preescolar",
				"selected": false
			},
			"programacion": {
				"label": "Programación",
				"selected": false
			},
			"apoyo_en_tareas": {
				"label": "Apoyo en Tareas",
				"selected": false
			},
			"superdotados": {
				"label": "Superdotados",
				"selected": false
			}
		}
	},
	"todas_las_materias_100_tutores": {
		"showed": false,
		"selectedAll": false,
		"label": "Todas las Materias ",
		"options": {
			"analisis_fisicos_y_quimicos": {
				"label": "Análisis Físicos y Químicos",
				"selected": false
			},
			"analisis_instrumental": {
				"label": "Análisis Instrumental",
				"selected": false
			},
			"apreciacion_artistica": {
				"label": "Apreciación Artística",
				"selected": false
			},
			"artes": {
				"label": "Artes",
				"selected": false
			},
			"artes_visuales": {
				"label": "Artes Visuales",
				"selected": false
			},
			"auditoria_nocturna": {
				"label": "Auditoría Nocturna",
				"selected": false
			},
			"biologia": {
				"label": "Biología ",
				"selected": false
			},
			"caja_de_restaurante_y_caja_de_recepcion": {
				"label": "Caja de Restaurante y Caja de Recepción",
				"selected": false
			},
			"ciencia_y_tecnologia": {
				"label": "Ciencia y Tecnología",
				"selected": false
			},
			"ciencias_naturales": {
				"label": "Ciencias Naturales",
				"selected": false
			},
			"ciencias_sociales": {
				"label": "Ciencias Sociales",
				"selected": false
			},
			"conservacion_de_documentos": {
				"label": "Conservación de Documentos",
				"selected": false
			},
			"contribuciones_de_personas_fisicas_y_morales": {
				"label": "Contribuciones de Personas Físicas y Morales",
				"selected": false
			},
			"control_de_efectivo": {
				"label": "Control de Efectivo",
				"selected": false
			},
			"correccion_y_edicion_fotografica": {
				"label": "Corrección y Edición Fotográfica",
				"selected": false
			},
			"crear_y_administrar_base_de_datos": {
				"label": "Crear y Administrar Base de Datos",
				"selected": false
			},
			"danza": {
				"label": "Danza ",
				"selected": false
			},
			"dibujo_de_planos_arquitectonicos_y_estructurales": {
				"label": "Dibujo de Planos Arquitectónicos y Estructurales",
				"selected": false
			},
			"dibujo_de_planos_de_instalaciones": {
				"label": "Dibujo de Planos de Instalaciones",
				"selected": false
			},
			"diseno_editorial": {
				"label": "Diseño Editorial",
				"selected": false
			},
			"diseno_en_2d_para_web": {
				"label": "Diseño en 2D para Web",
				"selected": false
			},
			"ecologia": {
				"label": "Ecología",
				"selected": false
			},
			"educacion_artistica_d": {
				"label": "Educación Artística",
				"selected": false
			},
			"educacion_fisica": {
				"label": "Educación Física ",
				"selected": false
			},
			"elaboracion_de_estados_financieros": {
				"label": "Elaboración de Estados Financieros",
				"selected": false
			},
			"elaboracion_de_pago_de_personal": {
				"label": "Elaboración de Pago de Personal",
				"selected": false
			},
			"esem": {
				"label": "ESEM ",
				"selected": false
			},
			"espanol": {
				"label": "Español ",
				"selected": false
			},
			"etica": {
				"label": "Ética",
				"selected": false
			},
			"exploracion_de_la_naturaleza_y_la_sociedad": {
				"label": "Exploración de la Naturaleza y la Sociedad ",
				"selected": false
			},
			"fisica": {
				"label": "Física ",
				"selected": false
			},
			"fonetica": {
				"label": "Fonética ",
				"selected": false
			},
			"formacion_civica_y_etica": {
				"label": "Formación Cívica y Ética",
				"selected": false
			},
			"formacion_civica_y_etica_de_secundaria": {
				"label": "Formación Cívica y Ética de Secundaria",
				"selected": false
			},
			"geografia": {
				"label": "Geografía ",
				"selected": false
			},
			"geografia_de_mexico_y_del_mundo": {
				"label": "Geografía de México y del Mundo",
				"selected": false
			},
			"gestion_de_calidad_en_el_laboratorio": {
				"label": "Gestión de Calidad en el Laboratorio",
				"selected": false
			},
			"gestion_de_personal": {
				"label": "Gestión de Personal",
				"selected": false
			},
			"habilidades_de_estudio": {
				"label": "Habilidades de Estudio",
				"selected": false
			},
			"historia": {
				"label": "Historia",
				"selected": false
			},
			"historia_de_mexico": {
				"label": "Historia de México",
				"selected": false
			},
			"historia_mundial": {
				"label": "Historia Mundial",
				"selected": false
			},
			"humanidades": {
				"label": "Humanidades",
				"selected": false
			},
			"ingenieria_fisica": {
				"label": "Ingeniería Física",
				"selected": false
			},
			"ingles": {
				"label": "Inglés",
				"selected": false
			},
			"integracion_de_proyectos": {
				"label": "Integración de Proyectos",
				"selected": false
			},
			"interdisciplina_artistica": {
				"label": "Interdisciplina Artística",
				"selected": false
			},
			"introduccion_a_la_filosofia": {
				"label": "Introducción a la Filosofía",
				"selected": false
			},
			"introduccion_al_trabajo": {
				"label": "Introducción al Trabajo",
				"selected": false
			},
			"la_entidad_donde_vivo": {
				"label": "La Entidad Donde Vivo",
				"selected": false
			},
			"lectura": {
				"label": "Lectura ",
				"selected": false
			},
			"lengua_y_literatura": {
				"label": "Lengua y Literatura",
				"selected": false
			},
			"lenguaje_y_comunicacion": {
				"label": "Lenguaje y Comunicación",
				"selected": false
			},
			"logica_y_argumentacion": {
				"label": "Lógica y Argumentación",
				"selected": false
			},
			"matematicas": {
				"label": "Matemáticas",
				"selected": false
			},
			"musica": {
				"label": "Música",
				"selected": false
			},
			"orientacion": {
				"label": "Orientación",
				"selected": false
			},
			"ortografia": {
				"label": "Ortografía",
				"selected": false
			},
			"preparacion_de_alimentos": {
				"label": "Preparación de Alimentos",
				"selected": false
			},
			"prevencion_de_riesgos_de_trabajo": {
				"label": "Prevención de Riesgos de Trabajo",
				"selected": false
			},
			"problemas_filosoficos": {
				"label": "Problemas Filosóficos",
				"selected": false
			},
			"procesos_industriales": {
				"label": "Procesos Industriales",
				"selected": false
			},
			"programacion_de_paginas_web": {
				"label": "Programación de Páginas Web",
				"selected": false
			},
			"programacion_en_java": {
				"label": "Programación en Java",
				"selected": false
			},
			"proyecto_integrador": {
				"label": "Proyecto Integrador",
				"selected": false
			},
			"proyectos_de_gestion_social": {
				"label": "Proyectos de Gestión Social",
				"selected": false
			},
			"proyectos_de_inversion_y_finanzas_personales": {
				"label": "Proyectos de Inversión y Finanzas Personales",
				"selected": false
			},
			"quimica": {
				"label": "Química",
				"selected": false
			},
			"quimica_del_carbono": {
				"label": "Química del Carbono",
				"selected": false
			},
			"redaccion": {
				"label": "Redacción",
				"selected": false
			},
			"salud_humana": {
				"label": "Salud Humana",
				"selected": false
			},
			"servicio_de_restaurante": {
				"label": "Servicio de Restaurante",
				"selected": false
			},
			"servicios_a_usuarios": {
				"label": "Servicios a Usuarios",
				"selected": false
			},
			"sistematizacion": {
				"label": "Sistematización, Búsqueda y Recuperación de Información",
				"selected": false
			},
			"taller_de_analisis_y_produccion_de_textos": {
				"label": "Taller de Análisis y Producción de Textos ",
				"selected": false
			},
			"teatro": {
				"label": "Teatro ",
				"selected": false
			},
			"tecnologia": {
				"label": "Tecnología ",
				"selected": false
			},
			"tic": {
				"label": "TIC ",
				"selected": false
			}
		}
	},
	"reason_100_tutores": {
		"showed": false,
		"selectedAll": false,
		"label": "Razón",
		"options": {
			"preparacion_para_examenes": {
				"label": "Preparación para Exámenes",
				"selected": false
			},
			"tareas": {
				"label": "Tareas",
				"selected": false
			},
			"mejorar_calificacion": {
				"label": "Mejorar Calificación",
				"selected": false
			},
			"enriquecimiento": {
				"label": "Enriquecimiento",
				"selected": false
			},
			"entretenimiento": {
				"label": "Entretenimiento",
				"selected": false
			},
			"practica": {
				"label": "Práctica",
				"selected": false
			},
			"aprender": {
				"label": "Aprender",
				"selected": false
			}
		}
	},
	"nivel_del_estudiante_100_tutores": {
		"showed": false,
		"selectedAll": false,
		"label": "Nivel del Estudiante",
		"options": {
			"prescolar": {
				"label": "Prescolar",
				"selected": false
			},
			"primaria": {
				"label": "Primaria",
				"selected": false
			},
			"secundaria": {
				"label": "Secundaria",
				"selected": false
			},
			"preparatoria": {
				"label": "Preparatoria",
				"selected": false
			},
			"carrera_profesional": {
				"label": "Carrera Profesional",
				"selected": false
			},
			"maestria": {
				"label": "Maestría",
				"selected": false
			},
			"doctorado": {
				"label": "Doctorado",
				"selected": false
			},
			"adulto": {
				"label": "Adulto",
				"selected": false
			}
		}
	},
	"edad_del_estudiante_100_tutores": {
		"showed": false,
		"selectedAll": false,
		"label": "Edad del Estudiante",
		"options": {
			"ninos_de_3_a_5_anos": {
				"label": "Niños de 3 a 5 años",
				"selected": false
			},
			"ninos_de_6_a_12_anos": {
				"label": "Niños de 6 a 12 años",
				"selected": false
			},
			"adolescentes_13_a_18_anos": {
				"label": "Adolescentes 13 a 18 años",
				"selected": false
			},
			"adultos": {
				"label": "Adultos",
				"selected": false
			}
		}
	},
	"genero_del_tutor_100_tutores": {
		"showed": false,
		"selectedAll": false,
		"label": "Género del Tutor",
		"options": {
			"masculino": {
				"label": "Masculino",
				"selected": false
			},
			"femenino": {
				"label": "Femenino",
				"selected": false
			},
			"sin_preferencia": {
				"label": "Sin Preferencia",
				"selected": false
			}
		}
	},
	"edad_del_tutor_100_tutores": {
		"showed": false,
		"selectedAll": false,
		"label": "Edad del Tutor",
		"options": {
			"de_18_o_mas": {
				"label": "18 años o más",
				"selected": false
			},
			"de_25_o_mas": {
				"label": "25 años o más",
				"selected": false
			},
			"de_35_o_mas": {
				"label": "35 años o más",
				"selected": false
			},
			"de_45_o_mas": {
				"label": "45 años o más",
				"selected": false
			},
			"de_55_o_mas": {
				"label": "55 años o más",
				"selected": false
			},
			"de_65_o_mas": {
				"label": "65 años o más",
				"selected": false
			},
			"de_75_o_mas": {
				"label": "75 años o más",
				"selected": false
			},
		}
	}
}

export const themeOptionsLabels = {
    materias_de_preescolar_100_tutores: false,
    materias_de_primaria_100_tutores: false,
    materias_de_secundaria_100_tutores: false,
    materias_de_preparatoria_100_tutores: false,
    preparacion_para_examenes_100_tutores: false,
    idiomas_100_tutores: false,
    diferencias_en_aprendizaje_100_tutores: false,
    matematicas_100_tutores: false,
    ciencias_100_tutores: false,
    espanol_100_tutores: false,
    humanidades_100_tutores: false,
    ciencias_sociales_100_tutores: false,
    tecnologia_e_ingenieria_100_tutores: false,
    programacion_100_tutores: false,
    negocios_100_tutores: false,
    enriquecimiento_100_tutores: false,
    materias_populares_100_tutores: false,
    todas_las_materias_100_tutores: false
}

export const studentsTypeLabels = {
	reason_100_tutores: false,
	nivel_del_estudiante_100_tutores: false,
	edad_del_estudiante_100_tutores: false
}

export const preferenceLabels = {
	genero_del_tutor_100_tutores: false,
	edad_del_tutor_100_tutores: false
}