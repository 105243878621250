import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { Heading, Sub } from "../../Components/Typography";
import SecondaryLinkButton from "../../Components/Button/SecondaryLinkButton"
import assets from "../../Assets"
import ZodiacInfo from "./Components/ZodiacInfo"
import EmailSuscribe from "./Components/Emailsuscribe"
import Recommended from "../../Components/Recommended"
import {
    ZodiacAquarius,
    ZodiacAries,
    ZodiacCancer,
    ZodiacCapricorn,
    ZodiacGemini,
    ZodiacLeo,
    ZodiacLibra,
    ZodiacPisces,
    ZodiacSagittarius,
    ZodiacScorpio,
    ZodiacTaurus,
    Zodiacvirgo
} from "../../Components/Icons"
import "./index.css"
import { Context } from "../../store/useGlobalState";

const zodiacList = {
    "aquarius": "Acuario",
    "pisces": "Piscis",
    "aries": "Aries",
    "taurus": "Tauro",
    "gemini": "Géminis",
    "cancer": "Cáncer",
    "leo": "Leo",
    "virgo": "Virgo",
    "libra": "Libra",
    "scorpio": "Escorpión",
    "sagittarius": "Sagitario",
    "capricorn": "Capricornio"
}

const zodiacText = {
    "aquarius": "acuario",
    "pisces": "piscis",
    "aries": "aries",
    "taurus": "tauro",
    "gemini": "geminis",
    "cancer": "cancer",
    "leo": "leo",
    "virgo": "virgo",
    "libra": "libra",
    "scorpio": "escorpion",
    "sagittarius": "sagitario",
    "capricorn": "capricornio"
}

const HoroscopesPage = () => {
    const { zodiacsign } = useParams();
    let selectedZodiac = null;

    useEffect(() => {
        if (zodiacsign) {
            window.scrollTo(0, 0);
        }
    }, [zodiacsign])

    if (zodiacsign) {
        selectedZodiac = Object.keys(zodiacText).find(key => zodiacText[key]===zodiacsign)
    }
    
    let content = <MainContent />
    
    if (zodiacsign) {
        content = <ZodiacInfo sign={selectedZodiac} />
    }
    
    return <>
        <section className="w-full py-20">
            {content}
        </section>
        {
            (!zodiacsign) && <EmailSuscribe />
        }
        <Recommended />
    </>
}
// #region Subcomponents

const MainContent = () => {
    const { globalState } = useContext(Context)
    
    let requestUrl = undefined
    const requests = globalState.requestsNav

    if (requests && Array.isArray(requests) && 
        requests.length>=2 && requests[0].subrequests &&
        Array.isArray(requests[0].subrequests) &&
        requests[0].subrequests.length>5) {
        requestUrl = `/sesiones/${globalState.requestsNav[0]?._id}/${globalState.requestsNav[0]?.subrequests[4]._id}`
    }

    return <>
        <Heading>Explora tu horóscopo </Heading>
        <Sub className="mb-20">Explora diariamiente lo que tu zodiaco revela para ti</Sub>
        <div className="grid grid-cols-2 md:grid-cols-3 gap-4  max-w-md px-6 pt-3 mx-auto lg:max-w-7xl sm:max-w-3xl sm:px-0">
            {
                Object.keys(zodiacList).map(x => {
                    return <ZodiacIcon sign={x} label={zodiacList[x]} text={zodiacText[x]} />
                })
            }
        </div>
        <SecondaryLinkButton className="flex flex-row px-6 py-2 mx-auto mt-5" 
            url={requestUrl?requestUrl:'#'}>
            <span>Descubre más sobre consultas astrológicas</span><img className="ml-2 md:ml-3" src={assets.Icon_ChevronRight} alt="Right" />
        </SecondaryLinkButton>
    </>
}

const ZodiacIcon = ({ sign, label, text }) => {
    switch (sign) {
        case "aquarius": return <ZodiacItem sign={sign} label={label} text={text} dates="Ene 21 - Feb 18"
            icon={<ZodiacAquarius className="stroke-blue mx-auto border rounded-full border-secondary group-hover:stroke-white group-hover:bg-secondary transition duration-300" />} />

        case "pisces": return <ZodiacItem sign={sign} label={label} text={text} dates="Feb 19 - Mar 20"
            icon={<ZodiacPisces className="stroke-blue mx-auto border rounded-full border-secondary group-hover:stroke-white group-hover:bg-secondary transition duration-300" />} />

        case "aries": return <ZodiacItem sign={sign} label={label} text={text} dates="Mar 21 - Abr 20"
            icon={<ZodiacAries className="stroke-blue mx-auto border rounded-full border-secondary group-hover:stroke-white group-hover:bg-secondary transition duration-300" />} />

        case "taurus": return <ZodiacItem sign={sign} label={label} text={text} dates="Abr 21 - May 21"
            icon={<ZodiacTaurus className="stroke-blue mx-auto border rounded-full border-secondary group-hover:stroke-white group-hover:bg-secondary transition duration-300" />} />

        case "gemini": return <ZodiacItem sign={sign} label={label} text={text} dates="May 22 - Jun 21"
            icon={<ZodiacGemini className="stroke-blue mx-auto border rounded-full border-secondary group-hover:stroke-white group-hover:bg-secondary transition duration-300" />} />

        case "cancer": return <ZodiacItem sign={sign} label={label} text={text} dates="Jun 22 - Jul 22"
            icon={<ZodiacCancer className="stroke-blue mx-auto border rounded-full border-secondary group-hover:stroke-white group-hover:bg-secondary transition duration-300" />} />

        case "leo": return <ZodiacItem sign={sign} label={label} text={text} dates="Jul 23 - Ago 23"
            icon={<ZodiacLeo className="stroke-blue mx-auto border rounded-full border-secondary group-hover:stroke-white group-hover:bg-secondary transition duration-300" />} />

        case "virgo": return <ZodiacItem sign={sign} label={label} text={text} dates="Ago 24 - Sep 23"
            icon={<Zodiacvirgo className="stroke-blue mx-auto border rounded-full border-secondary group-hover:stroke-white group-hover:bg-secondary transition duration-300" />} />

        case "libra": return <ZodiacItem sign={sign} label={label} text={text} dates="Sep 24 - Oct 23"
            icon={<ZodiacLibra className="stroke-blue mx-auto border rounded-full border-secondary group-hover:stroke-white group-hover:bg-secondary transition duration-300" />} />

        case "scorpio": return <ZodiacItem sign={sign} label={label} text={text} dates="Oct 24 - Nov 22"
            icon={<ZodiacScorpio className="stroke-blue mx-auto border rounded-full border-secondary group-hover:stroke-white group-hover:bg-secondary transition duration-300" />} />

        case "sagittarius": return <ZodiacItem sign={sign} label={label} text={text} dates="Nov 23 - Dic 21"
            icon={<ZodiacSagittarius className="stroke-blue mx-auto border rounded-full border-secondary group-hover:stroke-white group-hover:bg-secondary transition duration-300" />} />

        case "capricorn": return <ZodiacItem sign={sign} label={label} text={text} dates="Dic 22 - Ene 20"
            icon={<ZodiacCapricorn className="stroke-blue mx-auto border rounded-full border-secondary group-hover:stroke-white group-hover:bg-secondary transition duration-300" />} />

        default: return "Invalid zodiac sign"
    }
}

const ZodiacItem = ({ sign, label, dates, icon, text }) => {
    return (
        <article className="grid grid-cols-1 px-2 text-center mb-6">
            <Link to={`/horoscopos/${text.toLowerCase()}`} className="zodiacIcon group mx-auto">
                <div className="col-span-1">
                    {icon}
                </div>
                <div className="col-span-1 mt-6 text-sm font-bold">{label}</div>
                <div className="col-span-1 mt-2 text-xs">{dates}</div>
            </Link>
        </article>
    )
}
// #endregion

export default HoroscopesPage