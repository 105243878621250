import { useEffect, useState } from "react"
import { IconCancel, IconCardAMEX, IconCardMasterCard, IconCardVisa, IconCheck, IconChevronRight, IconEdit } from "../../Components/Icons"
import CardForm from "../../Components/Payment/CardForm"
import ExpMonthPicker from "../../Components/Payment/ExpMonthPicker"
import ExpYearPicker from "../../Components/Payment/ExpYearPicker"
import PromptDelete from "../../Components/PromptDelete"
import TextField from "../../Components/TextField"
import { ToastError, ToastSuccess } from "../../Components/Toast"
import payment from "../../integrations/payment"
import DataGrid from "./partials/DataGrid"


const PaymentMethods = () => {
   const [formVisible, setFormVisible] = useState(false)
   const [isLoading, setIsLoading] = useState(true)
   const [paymentList, setPaymentList] = useState([])
   // Editing record values
   const [editRecordId, setEditRecordId] = useState(null)
   const [editNewExpMonth, setEditNewExpMonth] = useState('')
   const [editNewExpYear, setEditNewExpYear] = useState('')
   const [editNewName, setEditNewName] = useState('')


   //#region Form actions and behavior
   const showForm = () => {
      setFormVisible(true)
   }

   const closeForm = () => {
      setFormVisible(false)
      loadPaymentList()
   }

   const handleOnDelete = (id) => {
      ToastSuccess('Forma de pago eliminada')
      let newPaymentList = paymentList.filter((p) => p.id !== id)
      setPaymentList(newPaymentList)
   }

   const handleEditName = (newvalue) => {
      setEditNewName(newvalue)
   }

   const exitUpdateRecord = () => {
      setEditNewName('')
      setEditNewExpMonth('')
      setEditNewExpYear('')
      setEditRecordId(null)
   }

   const handleUpdate = (id) => {
      payment.updateCardInfo(id, editNewExpMonth, editNewExpYear, editNewName)
         .then(() => {
            ToastSuccess('Forma de pago actualizada.')
            exitUpdateRecord()
            loadPaymentList()
         }).catch(() => {
            ToastError('No se pudo modificar la forma de pago.')
            exitUpdateRecord()
         });
   }
   //#endregion

   //#region Paymentlist
   useEffect(() => {
      loadPaymentList();
   }, [])

   const loadPaymentList = () => {
      payment.getPaymentMethods()
         .then(({ payment_methods }) => {
            setPaymentList(Array.isArray(payment_methods) ? payment_methods : [])
            setIsLoading(false)
         }).catch((err) => {
            setPaymentList([])
            setIsLoading(false)
         });
   }
   //#endregion

   //#region DataGrid customization
   const headings = [
      '',
      'NÚMERO DE TARJETA',
      'TITULAR DE TARJETA',
      'FECHA DE EXPIRACIÓN',
      ''
   ]

   const CustomRow = ({ data }) => (
      <tr>
         <td className="py-4 text-xs tracking-wide text-center">{setCardIcon(data.brand)}</td>
         <td className="py-4 text-xs tracking-wide text-center">**** **** **** {data.last4}</td>
         <td className="py-4 text-xs tracking-wide text-center">
            {editRecordId === data.id ?
               <TextField defaultValue={editNewName} onChange={handleEditName} />
               : data.name
            }
         </td>
         <td className="py-4 text-xs tracking-wide text-center">
            {editRecordId === data.id
               ? <div className="flex">
                  <ExpMonthPicker defaultValue={editNewExpMonth} placeholder="Mes" onChange={setEditNewExpMonth} />
                  <ExpYearPicker defaultValue={editNewExpYear} placeholder="Año" onChange={setEditNewExpYear} />
               </div>
               : data.exp_month > 9 ? '' : 0 + `${data.exp_month} / ${data.exp_year}`
            }
         </td>
         {editRecordId === data.id
            ? <td className="py-4">
               <div className="flex flex-row">
                  <IconCheck className="h-4 px-2 cursor-pointer" onClick={() => handleUpdate(data.id)} />
                  <IconCancel className="h-4 px-2 cursor-pointer" onClick={exitUpdateRecord} />
               </div>
            </td>
            : <td className="py-4">
               <div className="flex flex-row">
                  <IconEdit className="w-10 sm:w-5 cursor-pointer" onClick={() => {
                     setEditRecordId(data.id)
                     setEditNewName(data.name)
                     setEditNewExpMonth(data.exp_month)
                     setEditNewExpYear(data.exp_year)
                  }} />
                  <PromptDelete id={data.id} onDelete={handleOnDelete}  />
               </div>
            </td>
         }
      </tr>
   )

   const setCardIcon = (cardissuer) => {
      switch (cardissuer) {
         case 'visa': return <IconCardVisa className="h-8 mx-auto" />

         case 'mastercard': return <IconCardMasterCard className="h-8 mx-auto" />

         case 'amex': return <IconCardAMEX className="h-8 mx-auto" />

         default: return cardissuer
      }
   }
   //#endregion

   //#region Calculate dates for the card
   const currentYear = new Date().getFullYear();
   let selectableYears = []
   let selectableMonths = []

   for (let y = currentYear; y < currentYear + 7; y++) {
      selectableYears.push(y)
   }

   for (let m = 1; m <= 12; m++) {
      selectableMonths.push(m)
   }
   //#endregion

   return (
      <div className="border rounded-lg border-riptideGray3 p-6">
         <section className="flex flex-col">
            <div className={formVisible === true ? 'hidden' : ''} >
               <section className="md:flex flex-row justify-between">
                  <div>
                     <div className="text-xl font-semibold leading-loose text-primary">Método de pago</div>
                     <p className="text-sm text-black mt-4 md:w-3/4">
                        Elige el método de pago que deseas usar para esta transacción. Si deseas usar otro
                        método de pago solo da click al botón "Agregar método de pago".
                     </p>
                  </div>
                  <div>
                     <button onClick={showForm} 
                        className="px-4 py-3 text-xs font-semibold rounded bg-secondary text-white flex items-center justify-evenly">
                           <span>Agregar método de pago</span>
                        </button>
                  </div>
               </section>

               <div className="mt-8">
                  {isLoading === true ? (
                     <span className="text-primary">Espera un momento...</span>
                  )
                     :
                     paymentList.length === 0
                        ?
                        <div className="py-2 text-center text-riptideGray2">
                           <div className="mb-6 text-2xl">No tienes formas de pago</div>
                           <p className="mx-auto lg:max-w-lg">Te sugerimos agregar una forma de pago para poder realizar tus transacciones en el portal de forma más rápida.</p>
                        </div>
                        : <DataGrid data={paymentList} headings={headings} row={CustomRow} />
                  }
               </div>
            </div>

            <div className={formVisible === true ? '' : 'hidden'} >
               <CardForm onCancel={closeForm} onSave={closeForm} showBackButton={true} />
            </div>
         </section>
      </div>
   )
}

export default PaymentMethods