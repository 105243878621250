import React from "react"
import Recommended from "../../Components/Recommended"
import { Link } from "react-router-dom"
import assets from "../../Assets"
import { IconCheck2 } from "../../Components/Icons"

const HowWorks = () => {
  const sideMenuItems = [
    {
      title: "Manifiesto",
      url: "manifiesto"
    },
    {
      title: "Código de ética",
      url: "codigo-de-etica"
    },
    {
      title: "Satisfacción garantizada",
      url: "satisfaccion-garantizada"
    },
    {
      title: "Testimonios",
      url:"testimonios"
    },
    {
      title: "Contáctanos",
      url:"contacto"
    },
    {
      title: "Preguntas frecuentes",
      url:"preguntas-frecuentes"
    },
    {
      title: "Cómo funciona",
      url:"como-funciona"
    },
    {
      title: "Precios",
      url:"precios"
    }

  ]
  return <>
    <div className="px-3 max-w-md px-6 pt-10 mx-auto lg:max-w-7xl sm:max-w-3xl lg:px-0">
      <section className="grid grid-cols-1 md:grid-cols-5 text-primary">
        <div className="col-span-2 lg:pr-10 pb-3">
          <div className="text-3xl font-semibold mb-3">Cómo funciona</div>
          <SideMenu src={sideMenuItems} />
        </div>
        <div className="col-span-3 relative">
          <div className="absolute hidden md:inline-flex items-end right-0 top-40 -mr-10 z-10">
            <IconCheck2 fill="#D2544A" width={80} height={64} />
            <IconCheck2 fill="#6F8197" width={37} height={27} />
          </div>
          <img className="rounded" src={assets.HowWorks} alt="HowWorks" />
          <div className="absolute hidden md:inline-flex flex-row items-center z-10 -mt-30 ml-10">
            <span>
              <IconCheck2 fill="white" width={42} height={33} />
            </span>
            <span>
              <IconCheck2 fill="white" width={70} height={56} />
            </span>
          </div>
          <div className="block mt-5">
            <div className="mb-5">
              <p>
                100 Tutores es una plataforma confiable de tutores, maestros y 
                profesores que se dedican a empoderar vidas impulsados por la 
                misión de ayudar a más personas a lograr sus metas de aprendizaje, 
                estudiar, aprender algo nuevo, recibir ayuda en tareas, sacar 
                una buena calificación, o pasar un examen. 
              </p>
            </div>
            <div className="mb-5">
              <p>
                Sin importar si ya eres cliente regular o nuevo, tenemos diversas 
                formas de contactar a un tutor de 100 Tutores.
              </p>
            </div>
            <div className="mb-2">
              <p>
                Conéctate fácilmente con tus tutores, maestros y profesores favoritos:
              </p>
            </div>
            <div className="mb-5">
              <ol className="pl-4">
                <li className="list-decimal">Crea una cuenta</li>
                <li className="list-decimal">Agrega saldo</li>
                <li className="list-decimal">Elige al experto de tu preferencia</li>
                <li className="list-decimal">Haz tu sesión</li>
                <li className="list-decimal">Termina la sesión cuando tú quieras.</li>
              </ol>
            </div>
            <div className="mb-2">
              <p>
                100 Tutores está abierto 24/7 y nuestros tutores, maestros y 
                profesores hacen lo posible por estar disponible en diversas 
                zonas horarias, pero como son humanos y no computadoras, algunas 
                veces, no están disponibles. Así que, si has elegido a un tutor 
                y no está disponible, puedes programar una cita. No hay necesidad 
                de que pierdas tu tiempo esperando si algún tutor en específico 
                se pone disponible. Simplemente programa una cita a la hora que 
                te sea más conveniente.
              </p>
            </div>
          </div>
          <div className="mt-8">
            <img className="h-40" src={assets.SatisfactionGuaranteed} />
          </div>
        </div>
      </section>
    </div>
    <Recommended />
  </>
}

const SideMenu = ({ src, ...props }) => {
  return <ul className="mt-30 pr-10 lg:pr-0">
    {
      src.map((x, i) => {
        return <li className="mb-6" key={`sub-consultation-${i}`}>
          <Link to={`/${x.url}`} className="pb-3 border-b block max-w-xs">{x.title}</Link>
        </li>
      })
    }
  </ul>
}


export default HowWorks