import { useEffect, useState, useContext } from "react"
import CTAPrimaryButton from "../../Components/Button/CTAPrimaryButton"
import CTASecondaryButton from "../../Components/Button/CTASecondaryButton"
import CardForm from "../../Components/Payment/CardForm"
import { PaymentConfirmation } from "../../Components/Payment/Confirmation"
import ListCards from "../../Components/Payment/ListCards"
import OtherMethods from "../../Components/Payment/OtherPaymentMethods"
import OxxoTicket from "../../Components/Payment/OxxoTicket"
import TextField from "../../Components/TextField"
import { ToastError } from "../../Components/Toast"
import credit from "../../integrations/credit"
import { formatCurrency } from "../../utils/parsers"
import payment from "../../integrations/payment"
import MessageSuccess from "../../Components/Payment/MessageSuccess"
import { parseErrorMsg } from "../../utils/misc";
import { IconLoader, AlertIcon, IconChevronRight } from "../../Components/Icons"
import { loadStripe } from '@stripe/stripe-js/pure';
import { Context } from '../../store/useGlobalState'
import ModalDialog from "../../Components/ModalDialog"

const BuyCredits = ({ onFinish }) => {
   const [creditFormVisible, setCreditFormVisible] = useState(false)
   const [oxxoTicketVisible, setOxxoTicketVisible] = useState(false)
   const [isPaymentSuccess, setIsPaymentSuccess] = useState(false)
   const [isConfirmationVisible, setIsConfirmationVisible] = useState(false)
   const [lastCardlistUpdated, setLastCardlistUpdated] = useState(null)
   const [orderid, setOrderid] = useState(null)
   const [loadingPayment, setLoadingPayment] = useState(false)
   const [loadingOxxoPayment, setLoadingOxxoPayment] = useState(false)
   const [showOxxoErr, setShowOxxoErr] = useState(false)
   const [paymentId, setPaymentId] = useState('')
   const [paymentName, setPaymentName] = useState('')
   const [cardNumber, setCardNumber] = useState('')
   const [currentCredit, setCurrentCredit] = useState(0)
   const [amount, setAmount] = useState(0)
   const [totalToPay, setTotalToPay] = useState(0)
   const [couponCode, setCouponCode] = useState('')
   const [couponData, setCouponData] = useState(null)
   const [processingPayment, setProcessingPayment] = useState(false)
   const { globalState } = useContext(Context)
   const handleAmountChange = (value) => setAmount(value)
   //#endregion

   //#region handlers
   useEffect(async () => {
      setAmount(0)
      loadAvailableCredit()
   }, [])

   const loadAvailableCredit = () => {
      credit.getCredit()
         .then(({ data }) => {
            console.log('crédito', data);
            setCurrentCredit(data)
         })
         .catch(() => {
            ToastError('No se pudo obtener tu crédito actual')
            setCurrentCredit(0)
         })
   }

   const showForm = () => {
      setCreditFormVisible(true)
   }

   const handleUpdateCardList = () => {
      setLastCardlistUpdated(new Date().valueOf())
      closeForm()
   }

   const closeForm = () => {
      setCreditFormVisible(false)
   }

   const handleChangeMethod = ({ id, name, number }) => {
      setPaymentId(id)
      setPaymentName(name)
      setCardNumber(number)
   }
   const nameFormater = (str) => {
      const res = str.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
      return res
   }

   const OxxoDataValidation = () => {
      const { user } = globalState
      let err = false
      if (!user || !user.name && !user.lastname) {
         err = true
      }
      setShowOxxoErr(err)
      return err

   }

   const confirmPayment = async () => {
      if (amount<10) {
         ToastError("La cantidad de créditos a adquirir debe ser mayor o igual a 10")
         return
      }

      setProcessingPayment(true)

      let couponRes = null
      let finalOxxoAmount = amount

      if (couponCode) {
         if (couponCode.match && !couponCode.match(/^[a-zA-Z0-9]+$/)) {
            ToastError("El código de cupón no es válido")
            setProcessingPayment(false)
            return
         }

         try {
            couponRes = await payment.validateCoupon(couponCode)
            setCouponData(couponRes)

            if (couponRes && couponRes.discountType && couponRes.value && couponRes.minPurchase) {
               if (amount<couponRes.minPurchase) {
                  ToastError("El cupón es válido para compras mayores o iguales a "+(couponRes.minPurchase)+" MXN")
                  setProcessingPayment(false)
                  return
               }

               let discountValue = couponRes.value

               try {
                  if (isNaN(discountValue)) throw Error("Discount value is NaN");
                  if (!["PRICE", "PERCENT"].includes(couponRes.discountType)) throw Error("No valid discount type");
                  if (couponRes.discountType === 'PERCENT' && discountValue > 100) discountValue = 100;

                  if (couponRes.discountType === 'PRICE') {
                     finalOxxoAmount = finalOxxoAmount - discountValue
                  } else {
                     finalOxxoAmount = finalOxxoAmount * (1 - (discountValue / 100))
                  }

                  if (finalOxxoAmount < 10) {
                     finalOxxoAmount = 10
                  } else {
                     finalOxxoAmount = Math.ceil(finalOxxoAmount)
                  }
               } catch (error) {
                  ToastError("Los datos del cupón no son válidos")
                  return
               }
            }
            //console.log("coupon res: ", couponRes)
         } catch (error) {
            ToastError("Lo sentimos, el cupón no es válido o ha expirado")
            setProcessingPayment(false)
            return
         }
      }

      setTotalToPay(finalOxxoAmount)
      let stripe = null

      try {
         stripe = await loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY)
      } catch (error) { }

      const { user } = globalState
      setLoadingOxxoPayment(true)
      if (paymentId === 'oxxopay' && amount) {
         try {
            if (!OxxoDataValidation()) {
               let oxxoPaymentData = {
                  credits: finalOxxoAmount
               }

               if (couponRes && couponRes._id) {
                  oxxoPaymentData = {
                     ...oxxoPaymentData,
                     couponId: couponRes._id
                  }
               }

               const _paymentId = await payment.requestPaymentIntentOxxo(oxxoPaymentData)

               const result = await stripe.confirmOxxoPayment(_paymentId, {
                  payment_method: {
                     billing_details: {
                        name: nameFormater(`${user.name} ${user.lastname}`),
                        email: user.email
                     }
                  }
               }, { handleActions: false })

               if (result.error) {
                  setLoadingOxxoPayment(false)
                  return ToastError('Se produjo un error, intente más tarde');
               }

               const _orderId = result.paymentIntent.next_action.oxxo_display_details.number;

               setOrderid(_orderId)
               setOxxoTicketVisible(true)
               setCreditFormVisible(false)
            }
         } catch (error) {
            ToastError('Se produjo un error, intente más tarde')
         }
      } else if (!paymentId && amount) {
         ToastError('Seleccione un método de pago')
      } else if (!amount) {
         ToastError('Indique el crédito que se añadirá a su cuenta')
      }
      else {
         setIsConfirmationVisible(true)
      }

      setLoadingOxxoPayment(false)
      setProcessingPayment(false)
   }

   const redirectTo = (url) => {
      window.open(url, '_blank')
      window.location.assign('/tutores')
   }

   const handleProcessPayment = async () => {
      setLoadingPayment(true)
      try {
         let result
         if (paymentId === "paypal") {
            let paymentData = {
               credits: totalToPay,
            }

            if (couponData && couponData._id) {
               paymentData = {
                  ...paymentData,
                  couponId: couponData._id
               }
            }

            result = await payment.requestPaymentIntentPaypal(paymentData)
            const { payment_url } = result.payload
            setCouponData(null)
            setCouponCode('')
            redirectTo(payment_url)
            setLoadingPayment(false)
            setIsConfirmationVisible(false)
            setAmount(0)
         }
         else {
            //console.log(amount)
            let paymentData = {
               credits: totalToPay,
               payment_method: paymentId,
            }

            if (couponData && couponData._id) {
               paymentData = {
                  ...paymentData,
                  couponId: couponData._id
               }
            }

            result = await payment.requestPaymentIntent(paymentData)

            //console.log("result", result)
            setOrderid(result.numOrder)
            setIsPaymentSuccess(true)
            setCouponData(null)
            setCouponCode('')
            setLoadingPayment(false)
         }
      } catch (error) {
         //console.log(parseErrorMsg(error))
         ToastError(parseErrorMsg(error))
         setLoadingPayment(false)
      }
   }

   const handleCouponChange = (value) => {
      setCouponCode(value)
   }

   if (isPaymentSuccess === true) {
      return (
         <MessageSuccess orderId={orderid} />
      )
   }

   //#endregion
   if (isConfirmationVisible === true) {
      return <aside className="border-2 rounded-lg border-secondary-lightest">
         <div className="pt-4 text-2xl text-center font-semibold">Confirmación de pago</div>
         <PaymentConfirmation paymentid={paymentId}
            paymentName={paymentName}
            cardNumber={cardNumber}
            amount={amount}
            couponData={couponData} />
         <div className="flex flex-col items-center my-4">

            {loadingPayment
               ? <CTAPrimaryButton className="px-6 py-2">
                  <IconLoader
                     className="text-white fill-current animate-spin"
                     style={{ height: 20 }}
                  />
               </CTAPrimaryButton>
               : <CTAPrimaryButton className="px-6 py-2" onClick={handleProcessPayment} label="Realizar pago" />
            }
         </div>
      </aside>
   }

   return (
      <section className="flex flex-col w-full px-2 mx-auto lg:flex-row lg:px-0 gap-y-8 lg:gap-y-0 gap-x-6">
         <aside className="flex-col w-full px-2 border rounded-lg lg:w-2/3 border-riptideGray3 lg:px-8 lg:py-8 pb-9">
            <section className="flex flex-col gap-y-2">
               <div className={"flex flex-col " + (creditFormVisible ? 'hidden' : '')} >

                  {/* Compra de crédito */}
                  <section className="md:flex flex-row text-primary">
                     <aside className="flex-col flex-grow">
                        <div className="text-xl font-bold leading-loose">Compra de crédito</div>
                        <p className="text-sm mt-4">Ingresa la cantidad de crédito a adquirir.</p>
                     </aside>
                     <aside className="flex items-center flex-shrink font-semibold">
                        <p className="flex-col text-black text-xl font-bold">Crédito actual {formatCurrency(currentCredit)}</p>
                     </aside>
                  </section>

                  {oxxoTicketVisible
                     ? <OxxoTicket amount={amount} orderId={orderid} couponData={couponData} />
                     : <>
                        {/* Adquirir crédito */}
                        <section className="flex flex-row mt-2 mb-2">
                           <div className="flex flex-col gap-y-2">
                              <div className="flex-col flex-grow mt-2">
                                 <p className="py-2 text-primary text-sm">Crédito a adquirir</p>
                                 <p className="text-xs">Ingresa el monto que deseas agregar a tu saldo de crédito.</p>
                              </div>
                              <div className="flex-row">
                                 <TextField value={amount} placeholder="$100" textClass="text-right" onChange={handleAmountChange} />
                              </div>
                           </div>
                        </section>

                        {/* Agregar método de pago */}
                        <section className="flex flex-row-reverse mt-8 text-right">
                           <button onClick={showForm} className="px-4 py-3 rounded bg-secondary text-white text-sm flex items-center justify-evenly">
                              <span>Agregar método de pago</span>
                           </button>
                        </section>

                        {/* Método de pago */}
                        <section className="flex flex-row">
                           <div className="flex-col flex-grow">
                              <p className="pb-2 text-xl font-semibold text-primary">Método de pago</p>
                              <p className="text-xs">Elige el método de pago que deseas usar para esta transacción. Si deseas agregar otro método de pago solo da clic al botón "Agregar método de pago".</p>
                           </div>
                        </section>

                        <ListCards onChange={handleChangeMethod} selectedid={paymentId} updated={lastCardlistUpdated} />

                        <OtherMethods onChange={handleChangeMethod} selectedid={paymentId} />

                        {/* Código promocional */}
                        <section className="flex flex-row mt-6 mb-2" >
                           <div className="flex flex-col gap-y-4">
                              <div className="flex-col flex-grow mt-8">
                                 <div className="flex-row">
                                    <span className="py-2 pr-2 text-xs text-primary">¿Tienes un código promocional?</span>                                 </div>
                                 <div className="flex-row mt-2">
                                    <TextField pattern="^[0-9A-Za-z]+$" maxLength={10} defaultValue="" placeholder="Ingrésalo aquí" onChange={handleCouponChange} />
                                 </div>
                                 {/* <Checkbox label="Deseo utilizar esta dirección para facturar." /> */}
                              </div>
                           </div>
                        </section>

                        {/* Confirmar pago */}
                        <section className="flex flex-row-reverse mt-6 mb-2">
                           {processingPayment
                              ? <button className="px-5 py-3 text-white text-sm bg-secondary rounded-md flex items-center justify-center">
                                 <IconLoader
                                    className="text-white fill-current animate-spin"
                                    style={{ height: 20 }}
                                 />
                              </button>
                              : <button className="px-5 py-3 text-white text-sm bg-secondary rounded-md flex items-center justify-evenly"
                                 onClick={confirmPayment} >
                                 <span>Confirmar pago</span>
                              </button>
                           }
                        </section>
                     </>
                  }
               </div>

               <div className={creditFormVisible === true ? '' : 'hidden'} >
                  {/* Formulario para agregar método de pago */}
                  <CardForm onSave={handleUpdateCardList} onCancel={closeForm} />
               </div>
            </section>
         </aside>

         <aside className="border-2 rounded-lg lg:w-1/3 border-riptideGray3">
            <PaymentConfirmation paymentid={paymentId} paymentName={paymentName} cardNumber={cardNumber} amount={amount} couponData={couponData} />
         </aside>
         {
            showOxxoErr && <ModalDialog visible={showOxxoErr} close={() => setShowOxxoErr(prev => !prev)}>
               <div className="flex items-center mb-5">
                  <AlertIcon className="w-10 m" />
                  <div className="ml-3">Datos incompletos</div>
               </div>
               <p>Para realizar un pago en OXXO, es necesario completar sus datos personales en perfil de usuario</p>
            </ModalDialog>
         }
      </section>
   )
}

export default BuyCredits