import { useEffect, useState, useContext } from 'react'
import { Redirect, useLocation, useHistory, Link } from 'react-router-dom'
import RateAnExpert from '../../Components/RateAnExpert'
import VideoCall from '../../Components/VideoContainer'
import sessionIntegration from "../../integrations/session";
import { Context } from '../../store/useGlobalState'
import moment from "moment";
import DevicesPromt from '../../Components/DevicesPromt';
import myLocalStorage, { lsKeys } from '../../utils/localStorage';
import Loader from '../../Components/Loader';

var callInfo = { answerCall: false, initTime: null, session: '', requestEnd: 0 };

const RoomCall = () => {
  const [showRate, setShowRate] = useState(false)
  const [session, setSession] = useState("");
  const [answerState, setAnswerState] = useState(false);
  const [devicesEnabled, setDevicesEnabled] = useState(localStorage.getItem('_prdgm_devices_enabled') === '1')
  const [loadingSession, setLoadingSession] = useState(true)
  const [sessionExpired, setSessionExpired] = useState(false)
  const location = useLocation()
  const history = useHistory()
  const { globalState } = useContext(Context)
  const { room, token } = location.state.call || {}

  useEffect(() => {
    checkSession()

    return () => {
      closeDevices()
    }
  }, [])

  const checkSession = () => {
    try {
      const lastToken = myLocalStorage.read(lsKeys.videoToken)
      setSessionExpired(lastToken == token)
    } catch (error) { }
    setLoadingSession(false)
  }

  const closeDevices = async () => {
    try {
      sendReplyCall();

      let devicesFlag = localStorage.getItem('_prdgm_devices_enabled')

      if (devicesFlag && window.localStream) {
        const mediaStream = window.localStream;
        mediaStream.getTracks().forEach(track => {
          track.stop();
          mediaStream.removeTrack(track);
        });
      }
    } catch (error) { }
  }

  useEffect(async () => {
    if (answerState) {
      callInfo.requestEnd = 0;
      callInfo.answerCall = answerState;
      callInfo.initTime = moment();
    }
  }, [answerState])

  const sendReplyCall = (status = false) => {
    console.log('Action this', Date.now());
    const data = {
      action: 'wsVideocallReply',
      data: {
        token: globalState.token,
        idReceiver: location.state.call.id,
        userType: 'user',
        portal: "100_tutores",
        accept: status
      }
    }

    globalState.ws.send(JSON.stringify(data));
  }

  const onOpenShowRate = async (firedByClient) => {
    if (callInfo.answerCall && callInfo.requestEnd == 0) {
      callInfo.requestEnd = 1;

      if (window?.prdgm?.session && window?.prdgm?.session?._id) {
        callInfo.session = window.prdgm.session._id;
        setSession(window.prdgm.session._id);
        callInfo = { answerCall: false, initTime: null, session: '' };
        setShowRate(true);

        if (window.prdgm?.elapsedTime && window.prdgm.elapsedTime % 10 != 0) {
          updateSessionData(window.prdgm.elapsedTime % 10, window.prdgm.elapsedTime);
        }
      } else {
        history.goBack();
      }
    }
    else if (firedByClient) history.goBack();
  }

  const updateSessionData = async (eta, etaDuration) => {
    const dataToSend = {
      expert: location.state.call.id,
      service: "Psiquico",
      date: moment().format("YYYY/MM/DD"),
      sessionType: "video_call",
      duration: etaDuration,
      eta
    }

    if (window.prdgm?.session && window.prdgm.session?._id) {
      dataToSend.idSession = window.prdgm.session._id
    }

    try {
      const dataRes = await sessionIntegration.storeSession(dataToSend);
      window.prdgm.session = dataRes?.payload;
      return dataRes?.payload;
    } catch (error) { }

    return null;
  }

  const onFinishCall = () => history.goBack()

  if (typeof location.state === 'undefined') return <Redirect to="/" />

  return (
    <>
      {
        loadingSession ? (
          <div className="flex items-center justify-center">
            <Loader />
          </div>
        ) : (
          <>
            {
              sessionExpired ? (
                <div className="text-lg p-3 text-center font-bold">
                  Esta sesión ya expiró, haga click en el enlace para
                  regresar a la página anterior
                  <span className="underline text-primary mx-2 cursor-pointer"
                    onClick={() => { history.goBack() }}>
                      Regresar
                  </span>
                </div>
              ) : (
                <>
                  {
                    devicesEnabled ? (
                      <>
                        <h1 className='p-3'>Iniciando videollamada...</h1>
                        <VideoCall
                          roomName={room}
                          token={token}
                          calling={location.state.call}
                          removeRoom={onOpenShowRate}
                          answerState={setAnswerState} />
                        {showRate && <RateAnExpert expert={location.state.call} call handleClose={onFinishCall} session={session} />}
                      </>
                    ) : (
                      <DevicesPromt camera={true} mic={true}
                        onSuccess={(stream) => { setDevicesEnabled(true); window.localStream = stream; }} />
                    )
                  }
                </>
              )
            }
          </>
        )
      }
    </>
  )
}

export default RoomCall