import React from "react"
import Recommended from "../../Components/Recommended"
import { Link } from "react-router-dom"
import assets from "../../Assets"
import { IconCheck2 } from "../../Components/Icons"

const Prices = () => {
  const sideMenuItems = [
    {
      title: "Manifiesto",
      url: "manifiesto"
    },
    {
      title: "Código de ética",
      url: "codigo-de-etica"
    },
    {
      title: "Satisfacción garantizada",
      url: "satisfaccion-garantizada"
    },
    {
      title: "Testimonios",
      url: "testimonios"
    },
    {
      title: "Contáctanos",
      url: "contacto"
    },
    {
      title: "Preguntas frecuentes",
      url: "preguntas-frecuentes"
    },
    {
      title: "Cómo funciona",
      url: "como-funciona"
    },
    {
      title: "Precios",
      url: "precios"
    }

  ]
  return <>
    <div className="px-3 max-w-md px-6 pt-10 mx-auto lg:max-w-7xl sm:max-w-3xl lg:px-0">
      <section className="grid grid-cols-1 md:grid-cols-5 text-primary">
        <div className="col-span-2 lg:pr-10 pb-3">
          <div className="text-3xl font-semibold mb-3">Precios</div>
          <SideMenu src={sideMenuItems} />
        </div>
        <div className="col-span-3 relative">
          <div className="absolute hidden md:inline-flex items-end right-0 top-40 -mr-10 z-10">
            <IconCheck2 fill="#D2544A" width={133} height={107} />
            <IconCheck2 fill="#14336C" width={37} height={29} />
          </div>
          <div className="absolute hidden md:inline-flex items-end left-10 top-0 -mt-4 z-10">
            <IconCheck2 fill="#D2544A" width={42} height={34} />
            <IconCheck2 fill="white" width={27} height={21} />
          </div>
          <img className="rounded" src={assets.Prices} alt="Prices" />
          <div className="absolute hidden md:inline-flex flex-row items-center z-10 -mt-10 ml-10">
            <span>
              <IconCheck2 fill="white" width={37} height={29} />
            </span>
          </div>
          <div className="block mt-5">
            <div className="mb-5">
              <p>
                En 100 Tutores siempre sabes cuánto estás pagando por tu sesión. Las tarifas de nuestros tutores, maestros y profesores están claramente marcadas y establecidas para tu conveniencia.
              </p>
            </div>
            <div className="mb-5">
              <div className="mb-5 font-semibold">Oferta de cliente nuevo</div>
              <p>
                Para los clientes nuevos tenemos una tarifa especial, por única vez, en su primera compra. Debido a que esta oportunidad está disponible solo una vez, les recomendamos a nuestros clientes que aprovechen la tarifa especial en una sesión larga o varias cortas dependiendo de lo que les permita la cantidad de minutos que hayan comprado. Usa la oferta inicial para construir relaciones con los tutores, maestros y profesores de nuestra comunidad en 100 Tutores.
              </p>
            </div>
            <div className="mb-5">
              <div className="mb-5 font-semibold">Precios y tarifas regulares por minuto</div>
              <p>
                Después de que hayas usado tu oferta de cliente nuevo, la tarifa regular por minuto de los tutores, maestros y profesores con los que hablas hará efecto. Estas tarifas van desde $10 pesos hasta $30 pesos por minuto dependiendo del tutor, maestro o profesor que elijas y la forma en que hagas tu sesión, ya sea por teléfono o por videollamada. Las tarifas por minuto de los tutores, maestros y profesores en nuestra comunidad están claramente publicadas en nuestro sitio web de 100 Tutores.
              </p>
            </div>
            <div className="mb-5">
              <p>
                Siempre hay oportunidades de ahorrar en tus sesiones. Suscríbete para recibir ofertas promocionales y las novedades de 100 Tutores por email.
              </p>
            </div>
          </div>
          <div>
            <img className="h-40" src={assets.SatisfactionGuaranteed} />
          </div>
        </div>
      </section>
    </div>
    <Recommended />
  </>
}

const SideMenu = ({ src, ...props }) => {
  return <ul className="mt-30 pr-10 lg:pr-0">
    {
      src.map((x, i) => {
        return <li className="mb-6" key={`sub-consultation-${i}`}>
          <Link to={`/${x.url}`} className="pb-3 border-b block max-w-xs">{x.title}</Link>
        </li>
      })
    }
  </ul>
}


export default Prices