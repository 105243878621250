import NavItem from "../NavItem"
import OfferItemRightRow from "./OfferItemRightRow"

const MenuOffers = ({ mobile = false }) => {
    // #region Menú móvil
    if (mobile === true) {
        return <NavItem mobile={true} url="/ofertas" label="Ofertas" labelStyle={{ textShadow: "0 0 black" }} />
    }
    //#endregion

    // #region Menú desktop
    return (
        <NavItem className="flex-grow" url="/ofertas" label="Ofertas">
            <section className="flex flex-row py-4 text-center">
                <aside className="flex flex-col w-7/12">
                    <span className="flex-row text-primary">
                        Oferta de introducción desde:
                    </span>
                    <span className="flex-row mt-8 text-4xl font-semibold text-secondary font-semibold tracking-wider">
                        $5.00
                    </span>
                    <span className="flex-row mt-4 font-semibold text-primary">
                        por minuto
                    </span>
                </aside>
                <aside className="flex flex-col w-full border-l gap-y-6">
                    <OfferItemRightRow title="¡3 minutos gratis!" icon="clock" description="Al realizar tu primera compra pagada." />
                    <OfferItemRightRow title="Satisfacción 100%" icon="shield" description="Si no estás satisfecho con tu última consulta pagada ¡es gratis!." />
                </aside>
            </section>
        </NavItem>
    )
    // #endregion
}

export default MenuOffers